<template>
    <div>
        <v-form id="invoicableForm" ref="form" v-model="valid">
            <v-row v-if="loading === false">
                <v-col cols="12" lg="9">
                    <v-stepper v-model="e1" vertical>
                        <div class="v-stepper-wrapper">
                            <v-stepper-step editable :complete="e1 > 1" step="1">
                                <base-icon-right
                                    :title="
                                        $t(
                                            `tooltips.addInvoicable.${
                                                historical ? 'historical' : ''
                                            }Basicinformation${type}.title`
                                        )
                                    "
                                    :content="
                                        $t(
                                            `tooltips.addInvoicable.${
                                                historical ? 'historical' : ''
                                            }Basicinformation${type}.content`
                                        )
                                    "
                                    small
                                >
                                    {{ $t('general.basicinformation') }}
                                </base-icon-right>
                            </v-stepper-step>
                            <v-stepper-content step="1">
                                <accounting-invoicable-form-general
                                    v-model="document"
                                    :historical="historical"
                                    :is-creating-new-document="isCreatingNewDocument"
                                    :type="type"
                                    :invoicable-number-text="invoicableNumberText"
                                />

                                <v-row>
                                    <v-col cols="12" sm="6" order="10">
                                        <v-btn :disabled="!finishedStep1" color="primary" small @click="e1++">{{
                                            $t('general.next')
                                        }}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </div>
                        <div class="v-stepper-wrapper">
                            <v-stepper-step
                                :editable="finishedStep1"
                                :complete="e1 > 2"
                                step="2"
                                data-action="open-items"
                            >
                                <base-icon-right :i18n-path="invoiceitemPopupi18nPath" small>
                                    {{ invoicableItemsName(type) }}
                                </base-icon-right>
                            </v-stepper-step>
                            <v-stepper-content v-if="document.customer && document.customer !== ''" step="2">
                                <invoicable-form-invoicable-items
                                    v-model="document"
                                    :historical="historical"
                                    :is-creating-new-document="isCreatingNewDocument"
                                    :type="type"
                                />

                                <v-row>
                                    <v-col cols="12" sm="6" order="10">
                                        <v-btn :disabled="!finishedStep2" color="primary" small @click="e1++">{{
                                            $t('general.next')
                                        }}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </div>

                        <div class="v-stepper-wrapper">
                            <v-stepper-step :editable="finishedStep2" :complete="e1 > 3" step="3">
                                <base-icon-right
                                    :title="
                                        $t(
                                            `tooltips.addInvoicable.${
                                                historical ? 'historical' : ''
                                            }Comment${type}.title`
                                        )
                                    "
                                    :content="
                                        $t(
                                            `tooltips.addInvoicable.${
                                                historical ? 'historical' : ''
                                            }Comment${type}.content`
                                        )
                                    "
                                    small
                                >
                                    {{ $t('general.comment') }}
                                </base-icon-right>
                            </v-stepper-step>
                            <v-stepper-content v-if="finishedStep2" step="3">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <text-area
                                            v-model="document.comment"
                                            :label="$t('general.comment')"
                                            outlined
                                            clearable
                                            persistent-hint
                                            auto-grow
                                        />
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </div>
                    </v-stepper>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-card class="pa-5 mb-5">
                        <v-card-header>
                            <template #title>
                                {{ $t('general.status') }}
                            </template>
                        </v-card-header>
                        <template v-if="type === 'invoice'">
                            <v-checkbox
                                v-model="document.payed"
                                :label="$t('general.payed')"
                                outlined
                                dense
                                hide-details
                            />
                        </template>
                        <template v-if="type === 'quotation'"> </template>
                        <template v-if="type === 'creditnote'">
                            <v-checkbox
                                v-model="document.payedback"
                                :label="$t('general.payedback')"
                                outlined
                                dense
                                hide-details
                            />
                        </template>

                        <v-checkbox
                            v-model="document.sendtocustomer"
                            :label="$t('general.sent')"
                            outlined
                            dense
                            hide-details
                        />
                    </v-card>
                    <v-card v-if="canShowPaymentLinkOption" class="pa-5 mb-5">
                        <v-card-header>
                            <template #title> {{ $t('general.options') }} </template>
                        </v-card-header>
                        <v-col cols="12" md="12" class="pa-0">
                            <v-checkbox
                                v-model="document.createPaymentlink"
                                :label="$t('addIncome.create_paymentlink')"
                            />
                        </v-col>
                    </v-card>
                    <v-card class="pa-5 mb-5">
                        <v-card-header>
                            <template #title> {{ $t('general.language') }} </template>
                        </v-card-header>
                        <v-col cols="12" md="12" class="pa-0" data-select-container="invoicableLanguage">
                            <v-select
                                v-model="document.languageId"
                                :items="languages"
                                :item-text="getLanguageText"
                                item-value="id"
                                :label="$t('general.language')"
                                outlined
                                dense
                                hide-details
                                :rules="requiredRules"
                                data-form="invoicableLanguage"
                            />
                        </v-col>
                    </v-card>
                    <v-card class="pa-5">
                        <v-card-header>
                            <template #title> {{ $t('general.actions') }} </template>
                        </v-card-header>
                        <v-col cols="12" md="12" class="pa-0">
                            <template v-if="booking">
                                <template v-if="!historical">
                                    <submit-button
                                        id="create-booking"
                                        data-action="create-booking"
                                        :disabled="!valid || document.items.length === 0"
                                        block
                                        class="my-3"
                                        @click="handleSubmitBooking"
                                    >
                                        {{ $t('general.book') }}
                                    </submit-button>
                                </template>
                                <template v-else>
                                    <submit-button
                                        id="create-historical-booking"
                                        data-action="create-published-historical"
                                        :disabled="!valid || document.items.length === 0"
                                        block
                                        class="my-3"
                                        @click="handleSubmitHistoricalBooking"
                                    >
                                        {{ $t('general.book') }}
                                    </submit-button>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="historical">
                                    <template v-if="isCreatingNewDocument">
                                        <submit-button
                                            id="create-published-historical"
                                            data-action="create-published-historical"
                                            :disabled="!valid || document.items.length === 0"
                                            block
                                            class="my-3"
                                            @click="handleSubmitPublishHistorical"
                                        >
                                            {{ $t('general.create') }}
                                        </submit-button>
                                    </template>

                                    <template v-else>
                                        <submit-button
                                            id="save"
                                            data-action="save-historical-invoicable"
                                            :disabled="!valid || document.items.length === 0"
                                            block
                                            class="my-3"
                                            @click="handleSubmitEditingInvoicable"
                                        >
                                            {{ $t('general.save') }}
                                        </submit-button>
                                    </template>
                                </template>

                                <template v-else>
                                    <template v-if="isCreatingNewDocument">
                                        <submit-button
                                            id="create-draft"
                                            data-action="create-draft"
                                            block
                                            :disabled="!valid || document.items.length === 0"
                                            class="my-3"
                                            @click="handleSubmitDraft"
                                        >
                                            {{ $t('general.save_concept_and_preview') }}
                                        </submit-button>
                                        <!-- this button saves -->
                                        <submit-button
                                            id="edit-published"
                                            data-action="publish-draft"
                                            :disabled="!valid || document.items.length === 0"
                                            block
                                            class="my-3"
                                            @click="handleSubmitPublishInvoicable"
                                        >
                                            {{ $t(`addIncome.publish${type}`) }}
                                        </submit-button>
                                    </template>

                                    <template v-else>
                                        <submit-button
                                            id="edit-published"
                                            data-action="save-invoicable"
                                            :disabled="!valid || document.items.length === 0"
                                            block
                                            class="my-3"
                                            @click="handleSubmitEditingInvoicable"
                                        >
                                            {{
                                                document.draft
                                                    ? $t('general.save_concept_and_preview')
                                                    : $t('general.save_and_preview')
                                            }}
                                        </submit-button>
                                        <template v-if="document.draft">
                                            <submit-button
                                                id="create"
                                                data-action="publish-draft"
                                                color="primary"
                                                block
                                                :disabled="!valid || document.items.length === 0"
                                                class="mb-4 text-right"
                                                @click="handleSubmitPublishInvoicable"
                                            >
                                                {{ $t(`addIncome.publish${type}`) }}
                                            </submit-button>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <loader v-if="loading === true" />
    </div>
</template>
<script>
// --- State ---
import { mapState } from 'vuex';
import Loader from '@/components/Loader';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU, PRIVATE } from '@/config/constants';

import dayjs from 'dayjs';

import _ from 'lodash';
import SubmitButton from '../../SubmitButton.vue';
import VCardHeader from '../../vCardHeader.vue';
import { isVatLiable } from '@/helpers/VATHelper';
import AccountingInvoicableFormGeneral from './AccountingInvoicableFormGeneral.vue';
import InvoicableFormInvoicableItems from '../InvoicableFormInvoicableItems.vue';
import { calculateTotalExclVAT, calculateTotalInclVAT, calculateTotalVAT } from '@/common';
import { i18nCodeToLocali18nName } from '@/helpers/language';
import { reportError } from '@/helpers/logging';
import { sendGetAllInvoicableLanguagesRequestWithDefaultsIfFail } from '@/services/invoicableLanguages';

export default {
    components: {
        Loader,
        SubmitButton,
        VCardHeader,
        AccountingInvoicableFormGeneral,
        InvoicableFormInvoicableItems
    },

    mixins: [formRulesMixin, screenSizeMixin],

    props: {
        documentInput: {
            required: true,
            type: Object
        },
        invoicableNumberText: {
            default: 'Maken',
            type: String
        },
        buttonTextPublish: {
            default: 'Maken',
            type: String
        },
        type: {
            required: true,
            type: String
        },
        historical: {
            default: false,
            type: Boolean
        },
        isCreatingNewDocument: {
            default: true,
            type: Boolean
        },
        booking: {
            default: false,
            type: Boolean
        }
    },

    data() {
        return {
            valid: true,
            PRIVATE: PRIVATE,
            BELGIAN_COMPANY: BELGIAN_COMPANY,
            COMPANY_INSIDE_EU: COMPANY_INSIDE_EU,
            COMPANY_OUTSIDE_EU: COMPANY_OUTSIDE_EU,
            customer: null,
            document: _.cloneDeep(this.documentInput),
            loading: false,
            e1: 1,
            languages: []
        };
    },

    async created() {
        this.languages = await sendGetAllInvoicableLanguagesRequestWithDefaultsIfFail();

        if (this.isCreatingNewDocument) {
            const language = this.languages.find((language) => {
                return i18nCodeToLocali18nName(language.code) === this.currentUserData.userinformation.language;
            });

            if (!language) {
                reportError(new Error('language not found'));
            }

            this.document.languageId = 1;
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData', 'currentUserIntegrations']),
        canShowPaymentLinkOption() {
            return this.currentUserIntegrations.some((_integration) => {
                return (
                    _integration.categories.find((_category) => _category === 'paymentlinkProvider') &&
                    _integration.integrationData &&
                    _integration.integrationData.enablePaymentlink === true
                );
            });
        },
        canShowSendToAccountantCheckbox() {
            if (this.historical) {
                return Boolean(['invoice', 'creditnote'].includes(this.type));
            }

            return Boolean(!this.isCreatingNewDocument && ['invoice', 'creditnote'].includes(this.type));
        },
        invoiceitemPopupi18nPath() {
            if (this.isVATLiable) {
                return `tooltips.addInvoicable.${this.type}items`;
            }

            return `tooltips.addInvoicable.${this.type}items_no_vat`;
        },
        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        },
        finishedStep1() {
            const basicExpression = Boolean(
                this.document.customer &&
                    this.document.customer !== '' &&
                    dayjs(this.document.date, 'YYYY-MM-DD', true).isValid()
            );

            if (this.isVATLiable) {
                if (this.document.customer && this.document.customer.type === COMPANY_INSIDE_EU) {
                    return Boolean(basicExpression && this.document.typeOfService !== null);
                }
            }

            if (this.type === 'creditnote') {
                if (!this.document.invoiceNumber) {
                    return false;
                }
            }

            return basicExpression;
        },
        finishedStep2() {
            return Boolean(this.document.items && this.document.items.length > 0);
        }
    },

    watch: {
        documentInput: {
            deep: true,
            handler(newDocument) {
                this.document = _.cloneDeep(newDocument);
            }
        },
        'document.date': {
            handler(value) {
                if (dayjs(value, 'YYYY-MM-DD', true).isValid()) {
                    if (!this.document.dueDate) {
                        this.document.dueDate = dayjs(value, 'YYYY-MM-DD', true)
                            .add(this.currentUserData.settings.defaultPaymentDays, 'day')
                            .format('YYYY-MM-DD');
                    }
                }
            }
        },
        'document.customer.type': {
            deep: true,

            handler(newType) {
                if (this.isVATLiable) {
                    if (this.document.btwMedecontractant && newType === BELGIAN_COMPANY) {
                        this.setAllItemsVatTo(0);
                    }

                    if (newType === COMPANY_INSIDE_EU || newType === COMPANY_OUTSIDE_EU) {
                        this.setAllItemsVatTo(0);
                    }
                }

                this.resetTypeOfService();

                this.setDocumentHasVat();
            }
        },
        'document.btwMedecontractant': function (newValue) {
            this.setDocumentHasVat();
            if (newValue === true) {
                this.setAllItemsVatTo(0);
            }
        }
    },

    methods: {
        getLanguageText(language) {
            return this.$t(`languages.${i18nCodeToLocali18nName(language.code)}`);
        },
        invoicableItemsName(type) {
            if (type === 'creditnote') {
                return this.$t('addIncome.invoicable_items_creditnote');
            }
            if (type === 'quotation') {
                return this.$t('addIncome.invoicable_items_quotation');
            }

            return this.$t('addIncome.invoicable_items');
        },
        resetTypeOfService() {
            if (!this.document.customer) {
                return;
            }

            if (
                this.document.customer.type !== COMPANY_INSIDE_EU &&
                this.document.customer.type !== COMPANY_OUTSIDE_EU
            ) {
                this.document.typeOfService = null;
            }
        },
        setDocumentHasVat() {
            if (this.isVATLiable && this.document.customer !== null && this.document.customer !== undefined) {
                const customerType = this.document.customer.type;

                if (this.document.btwMedecontractant && customerType === BELGIAN_COMPANY) {
                    this.document.vatOnInvoicable = false;
                }

                if (!this.document.btwMedecontractant && customerType === BELGIAN_COMPANY) {
                    this.document.vatOnInvoicable = true;
                }

                if (customerType === PRIVATE) {
                    this.document.vatOnInvoicable = true;
                }

                if (customerType === COMPANY_INSIDE_EU) {
                    this.document.vatOnInvoicable = false;
                }
            }
        },
        setAllItemsVatTo(newValue) {
            this.document.items.forEach((item) => {
                item.VATPercentage = newValue;
            });
        },
        async handleSubmitDraft() {
            const documentClone = _.cloneDeep(this.document);
            this.setAttributes(documentClone);

            this.$emit('submit-create-draft', documentClone);
        },
        async handleSubmitEditingInvoicable() {
            const documentClone = _.cloneDeep(this.document);
            this.setAttributes(documentClone);
            this.$emit('submit-edit', documentClone);
        },
        async handleSubmitPublishInvoicable() {
            const documentClone = _.cloneDeep(this.document);
            this.setAttributes(documentClone);
            this.$emit('submit-publish', documentClone);
        },
        async handleSubmitPublishHistorical() {
            const documentClone = _.cloneDeep(this.document);
            this.setAttributes(documentClone);
            this.$emit('submit-create-historical', documentClone);
        },
        async handleSubmitBooking() {
            const documentClone = _.cloneDeep(this.document);
            this.setAttributes(documentClone);
            this.$emit('submit-create-booking', documentClone);
        },
        async handleSubmitHistoricalBooking() {
            const documentClone = _.cloneDeep(this.document);
            this.setAttributes(documentClone);
            this.$emit('submit-create-historical-booking', documentClone);
        },
        setAttributes(documentClone) {
            documentClone.comment = documentClone.comment || '';

            documentClone.amountEuroInclVAT = calculateTotalInclVAT(this.document);
            documentClone.amountEuroExclVAT = calculateTotalExclVAT(this.document);
            documentClone.totalVAT = calculateTotalVAT(this.document);
            documentClone.customerId = documentClone.customer.id;
        }
    }
};
</script>

<style lang="scss" scoped></style>
