
// --- Error handling ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import Editor from '@/components/Editor/DefaultEditor.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import { mapState } from 'vuex';
import * as AccountantemailbccService from '@/services/accountantemailbcc';

import { isEmpty, isNil } from 'lodash';
import { InvoicableTemplate } from 'dexxter-types';
import Vue, { PropOptions } from 'vue';
import { InitializedUseraccountAuthState } from '@/store/modules/auth';
import { MapStateToComputed } from '@/helpers/types';
import { IBCCMailaddressViewModel } from '@/services/accountantemailbcc.types';

export default Vue.extend({
    components: {
        Editor,
        SubmitButton
    },

    mixins: [formRulesMixin],

    props: {
        placeholderMap: {
            required: true
        } as PropOptions<Record<string, string>>,
        templates: {
            required: true
        } as PropOptions<InvoicableTemplate[]>,
        recipientMailData: {
            required: true
        } as PropOptions<{ email: string }>
    },

    data() {
        return {
            loading: false,
            accountantEmailBCCs: [] as IBCCMailaddressViewModel[],
            email: {
                subject: null as string | null,
                to: this.recipientMailData.email,
                sendToAccountant: false,
                cc: [] as string[],
                bcc: [] as string[],
                text: null as string | null,
                replyTo: this.$store.state.auth.currentUserData.settings.replyToAddress,
                withAttachment: false
            },
            valid: null,
            showLinkNotPresentDialog: false,
            templateSet: false
        };
    },

    computed: {
        mustLinkBePresent(): boolean {
            return this.email.withAttachment === false;
        },
        ...(mapState('auth', ['currentUserData']) as MapStateToComputed<InitializedUseraccountAuthState>),
        isValid(): boolean {
            if (isEmpty(this.email.text)) {
                return false;
            }
            return Boolean(this.valid);
        },
        hasAccountantEmailBCC(): boolean {
            if (!this.$can('READ', 'AccountantEmailBCC')) {
                return false;
            }

            return this.accountantEmailBCCs.length > 0;
        }
    },

    watch: {
        hasAccountantEmailBCC: {
            handler(value) {
                if (value) {
                    this.email.sendToAccountant = true;
                }
            },
            immediate: true
        }
    },

    async created(): Promise<void> {
        try {
            this.loading = true;
            if (this.$can('READ', 'AccountantEmailBCC')) {
                this.accountantEmailBCCs = await AccountantemailbccService.getAccountantEmailBCCs();
            }
        } catch (e) {
            this.$router.replace({
                name: 'dashboard'
            });
        } finally {
            this.loading = false;
        }
    },

    methods: {
        getDocumentURL(ahrefHTMLLink: string): string {
            if (isNil(ahrefHTMLLink)) {
                return '';
            }

            const url = ahrefHTMLLink.match(/<a .*>(.*)<\/a>/);

            if (url == null) {
                return '';
            }

            if (url.length != 2) {
                return '';
            }

            return url[1];
        },
        hasTextLink(text: string): boolean {
            return text.includes(this.getDocumentURL(this.placeholderMap['#DOCUMENT_LINK#']));
        },
        checkCanSend(): void {
            if (this.mustLinkBePresent && !this.hasTextLink(this.email.text ?? '')) {
                this.showLinkNotPresentDialog = true;
            } else {
                this.sendEmail();
            }
        },
        sendEmail(): void {
            this.$emit('send-email', this.email);
        },
        setTemplate(templateID: number): void {
            const findTemplate = this.templates.find((_template) => _template.id === templateID);
            if (!findTemplate) {
                throw new Error('Template not found');
            }

            const template = findTemplate.template;

            this.email.text = this.replaceTextByPlaceholder(template.text, this.placeholderMap);
            this.email.subject = this.replaceTextByPlaceholder(template.subject, this.placeholderMap);

            this.templateSet = true;
        },
        replaceTextByPlaceholder(text: string, placeholderMap: Record<string, string>) {
            let result = text;

            Object.keys(placeholderMap).forEach((_placeholder) => {
                result = result.replaceAll(_placeholder, placeholderMap[_placeholder]);
            });

            return result;
        },
        templateText(template: InvoicableTemplate) {
            return this.templateTypeToText(template.type.type);
        },
        templateTypeToText(type: string): string {
            return this.$i18n.t(`templateTypes.${type}`) as string;
        },
        getFromEmailAddress(): string {
            return `${this.$i18n.t('general.accounting')}-${this.currentUserData.id}@clients.dexxter.be`;
        },
        goBack(): void {
            this.$router.back();
        }
    }
});
