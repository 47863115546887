
import ViewLayout from '@/components/ViewLayout.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import {
    deletePersonaltaxesSubmissionsRequest,
    getPersonaltaxesSubmissionsRequest,
    sendGetNumberOfTaxesYearsRequest
} from '@/services/tax';
import { IPersonaltaxesSubmissionViewModel } from 'dexxter-types';
import { reverse, sortBy } from 'lodash';
import Vue from 'vue';
import PersonalTaxesAlreadySubmitted from './PersonaltaxesAlreadySubmitted.vue';
import SubmitPersonalTaxes from './SubmitPersonalTaxes.vue';

export default Vue.extend({
    components: { PersonalTaxesAlreadySubmitted, SubmitPersonalTaxes, ViewLayout },
    data() {
        return {
            deletingSubmission: false,
            yearToSubmitPersonalTaxesFor: null as number | null,
            activeYears: [] as number[],
            yearsForWhichWeCanGeneratePersonalTaxes: [] as {
                label: string;
                value: number;
            }[],
            loading: false,
            personaltaxesSubmission: null as IPersonaltaxesSubmissionViewModel | null,
            suggestedTypeOfCompany: null as string | null,
            canShowTables: false
        };
    },

    async created() {
        const activeYears = await sendGetNumberOfTaxesYearsRequest();
        this.yearsForWhichWeCanGeneratePersonalTaxes = reverse(
            sortBy(
                activeYears.map((year) => ({
                    label: `De boekhouding van ${year} - aangifte personenbelasting in te dienen in ${year + 1}`,
                    value: year
                })),
                (x) => x.value
            )
        );
    },

    methods: {
        async deleteSubmission() {
            try {
                this.deletingSubmission = true;

                await deletePersonaltaxesSubmissionsRequest(
                    (this.personaltaxesSubmission as IPersonaltaxesSubmissionViewModel).id
                );

                notify.call(this, {
                    title: this.$i18n.t('general.succesfuly_deleted'),
                    text: this.$i18n.t('general.succesfuly_deleted')
                });

                this.personaltaxesSubmission = null;

                this.canShowTables = false;
                this.yearToSubmitPersonalTaxesFor = null;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.deletingSubmission = false;
            }
        },
        goBack() {
            this.yearToSubmitPersonalTaxesFor = null;
            this.canShowTables = false;
        },
        async selectYearToGeneratePersonalTaxesFor() {
            try {
                this.loading = true;

                this.personaltaxesSubmission = await getPersonaltaxesSubmissionsRequest(
                    this.yearToSubmitPersonalTaxesFor as number
                );

                this.canShowTables = true;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
});
