import { Grootboekrekening, GrootboekrekeningBookingViewModel } from 'dexxter-types';
import { sumBy } from 'lodash';

export function getTotalFromGrootboekrekening(
    bookings: GrootboekrekeningBookingViewModel[],
    grootboekrekening: Grootboekrekening | Grootboekrekening['children'][number]
): number {
    if (grootboekrekening.parentId == null) {
        return sumBy((grootboekrekening as Grootboekrekening).children, (sub) =>
            getTotalFromGrootboekrekeningSubcategory(bookings, sub)
        );
    } else {
        return getTotalFromGrootboekrekeningSubcategory(bookings, grootboekrekening);
    }
}
export function getTotalFromGrootboekrekeningSubcategory(
    bookings: GrootboekrekeningBookingViewModel[],
    grootboekrekening: Grootboekrekening['children'][number]
): number {
    return sumBy(
        bookings.filter((_booking) => _booking.grootboekrekeningNumber === grootboekrekening.number),
        (_booking) => {
            return _booking.amount;
        }
    );
}
