<template>
    <div v-if="customer">
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ customer.company.name }}
                    </template>
                </page-header>
            </template>
        </view-layout>
    </div>
</template>

<script>
// --- Components ---
// --- Mixins ---
import { screenSizeMixin } from '../mixins/screenSizeMixin';
// --- State ---
import { mapActions } from 'vuex';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';
import { sendGetCustomerRequest } from '@/services/customer';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

export default {
    components: { ViewLayout, PageHeader },

    mixins: [screenSizeMixin],

    data() {
        return {
            loading: false,
            customer: null
        };
    },

    async created() {
        try {
            this.startLoading();
            if (this.$route.params?.customerId) {
                this.customer = await sendGetCustomerRequest(this.$route.params?.customerId);
            } else {
                this.$router.push({ name: 'dashboard' });
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.stopLoading();
        }
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading'])
    }
};
</script>
