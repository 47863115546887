<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                    <sub>
                        <tooltip :underlined="false" i18n-path="tooltips.jobstudentincomes.why_do_we_use_it"
                            ><template slot="activator"> <v-icon small> mdi-information </v-icon></template></tooltip
                        >
                    </sub>
                </template>

                <template #menu>
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" v-on="on">
                                {{ $t('income.new_jobstudentincome') }}
                            </v-btn>
                        </template>
                        <span>Je kan geen inkomsten meer toevoegen. Je moet dit nu doen bij de tab ten laste zijn</span>
                    </v-tooltip>
                </template></page-header
            >
        </template>
        <template #content>
            <TableCard>
                <template #content>
                    <jobstudent-income-table @click-row="editJobstudentincome" />
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
// --- State ---
import JobstudentIncomeTable from '@/components/DataTables/JobstudentIncomeTable.vue';
import PageHeader from '@/components/PageHeader';
import TableCard from '@/components/TableCard.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        JobstudentIncomeTable,
        TableCard,
        ViewLayout,
        PageHeader
    },

    data() {
        return {
            currentTab: 0
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapState('taxes', ['taxes']),
        ...mapState(['selectedYear'])
    },

    watch: {
        selectedYear() {
            this.initFetches();
        }
    },

    created() {
        this.initFetches(this.selectedYear);
    },

    methods: {
        ...mapActions('taxes', ['getTaxes']),
        initFetches() {
            this.getTaxes({
                year: this.selectedYear
            });
        },
        editJobstudentincome(jobstudentincome) {
            this.$router.push({
                name: 'edit-income-as-employee',
                params: {
                    id: jobstudentincome.id
                }
            });
        }
    }
};
</script>
