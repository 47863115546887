
import Vue, { PropOptions } from 'vue';
// --- Components ---
// --- Type ---
import { sendGetVatDeclarationInfo, vatDeclaration } from '@/services/quarter';
import { GenerateQrCode } from '@/services/qrCodeGenerator';

export default Vue.extend({
    name: 'VatListingQrPayment',
    props: {
        currentPeriodResult: {
            type: Number,
            defaultValue: 0
        } as PropOptions<number>
    },
    data() {
        return {
            vatDeclarationInfo: undefined as vatDeclaration | undefined,
            shouldAddVatNumber: false as boolean
        };
    },
    async mounted() {
        try {
            await this.getQRCodeInformation();
            await this.generateVatSubmissionQrCode();
        } catch (error) {
            this.shouldAddVatNumber = true;
        }
    },
    methods: {
        async getQRCodeInformation(): Promise<void> {
            const { data } = await sendGetVatDeclarationInfo();
            this.vatDeclarationInfo = data;
        },
        async generateVatSubmissionQrCode(): Promise<void> {
            const canvasElement = document.getElementById('qr-code-canvas');
            const { IBAN, BIC, structuredNumber } = this.vatDeclarationInfo || {};

            await GenerateQrCode(
                canvasElement,
                `BCD\n001\n1\nSCT\n${BIC}\nDienst BTW-Ontvangsten Brussel\n${IBAN}\nEUR${this.currentPeriodResult}\n\n${structuredNumber}`,
                'L',
                0,
                3.8
            );
        }
    }
});
