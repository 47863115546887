<template>
    <base-bottom-sheet v-model="openUploadExpenseFile">
        <template #header>
            <bottom-sheet-header>
                {{ $t('expensefile.upload_expensefile') }}
            </bottom-sheet-header>
        </template>

        <template #content>
            <base-drag-and-drop
                ref="dragAndDrop"
                multiple
                :min-height="200"
                :max-file-size-mb="maxFileSizeMb"
                :accepted-file-types="allowedExtensions"
                :max-number-of-files="maxNumberOfFiles"
                class="mb-4"
                @error:max-file-limit-exceeded="handleExceedFileLimit"
                @error:wrong-file-type="handleWrongFileType"
                @error:max-upload-size-exceeded="handleExceedMaxFileSize"
                @change="setFiles"
            />

            <div class="d-flex justify-center">
                <submit-button :disabled="!hasToBeUploadedFiles" :loading="loading" @click="handleClickUploadFiles">{{
                    $t('general.upload')
                }}</submit-button>
            </div>

            <div class="mt-5">
                <v-progress-linear
                    v-if="uploadLoadingState"
                    v-model="uploadLoadingState.progessValue"
                    rounded
                    :buffer-value="uploadLoadingState.bufferValue"
                    color="primary"
                ></v-progress-linear>
            </div>
        </template>
    </base-bottom-sheet>
</template>

<script>
import BaseBottomSheet from '@/components/BaseBottomSheet.vue';
import BottomSheetHeader from '@/components/BottomSheets/BottomSheetHeader.vue';
import { apiErrorAndDisplay, displayError } from '@/helpers/errorHandling';
import { getIndexOfItem, onlyFailedPromises } from '@/helpers/general';
import { notify } from '@/helpers/successNotification';
import { analyticsTrack } from '@/services/analytics';
import { createExpenseFile } from '@/services/expensefiles';
import mime from 'mime-types';
import BaseDragAndDrop from './BaseDragAndDrop.vue';

export default {
    components: {
        BaseBottomSheet,
        BottomSheetHeader,
        BaseDragAndDrop
    },

    props: {
        value: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            allowedExtensions: ['image/jpeg', 'image/gif', 'image/png', 'application/pdf'],
            maxFileSizeMb: 10,
            maxNumberOfFiles: 100,
            toBeUploadedFiles: [],
            uploadLoadingState: null,
            loading: false
        };
    },
    computed: {
        hasToBeUploadedFiles() {
            return this.toBeUploadedFiles.length > 0;
        },
        openUploadExpenseFile: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        }
    },

    methods: {
        setUploadState({ progessValue, bufferValue }) {
            this.uploadLoadingState = {
                progessValue: (100 / bufferValue) * progessValue,
                bufferValue: 100
            };
        },
        resetUploadState() {
            this.uploadLoadingState = null;
        },
        setFiles(files) {
            this.toBeUploadedFiles = [];

            for (let i = 0; i < files.length; i++) {
                this.toBeUploadedFiles.push(files.item(i));
            }
        },
        async handleClickUploadFiles() {
            let fileUploadResults;
            try {
                this.loading = true;
                this.setUploadState({
                    progessValue: 0,
                    bufferValue: this.toBeUploadedFiles.length
                });

                fileUploadResults = await this.makeUploadRequests(this.toBeUploadedFiles);

                if (this.hasFileUploadErrors(fileUploadResults)) {
                    throw new Error();
                }

                analyticsTrack('User Uploads Expenses', {
                    Total_Expenses_uploaded: this.toBeUploadedFiles.length
                });

                notify.call(this, {
                    title: this.$t('expenses.succesfuly_uploaded.title'),
                    text: this.$t('expenses.succesfuly_uploaded.text')
                });

                this.$refs.dragAndDrop.resetFileStatus();
                this.$emit('uploaded-files');
            } catch (e) {
                // apiErrorAndDisplay.call(this, e);
                if (this.hasFileUploadErrors(fileUploadResults)) {
                    this.handleFileUploadErrors(fileUploadResults, this.toBeUploadedFiles);
                } else {
                    apiErrorAndDisplay.call(this, e);
                }
            } finally {
                this.loading = false;
                this.resetUploadState();
            }
        },
        async makeUploadRequests(filesToUpload) {
            const fileUploadRequests = [];
            for (let i = 0; i < filesToUpload.length; i++) {
                fileUploadRequests.push((await Promise.allSettled([createExpenseFile(filesToUpload[i])]))[0]);

                this.setUploadState({
                    progessValue: i + 1,
                    bufferValue: this.toBeUploadedFiles.length
                });
            }
            return fileUploadRequests;
        },
        hasFileUploadErrors(fileUploadResults) {
            return this.getFailedRequestIndexes(fileUploadResults).length > 0;
        },
        handleFileUploadErrors(fileUploadResults, filesToUpload) {
            return this.displayErrorSomeFilesFailedUploading(
                this.getItemsWithError(filesToUpload, this.getFailedRequestIndexes(fileUploadResults)),
                filesToUpload.length
            );
        },
        displayErrorSomeFilesFailedUploading(filesWithErrors, numberOfUploadedFiles) {
            if (numberOfUploadedFiles === filesWithErrors.length) {
                displayError.call(
                    this,
                    'Fout bij het opladen van de bestanden',
                    `De bestanden konden niet worden opgeladen.`
                );
            } else {
                displayError.call(
                    this,
                    'Fout bij het opladen van een aantal bestanden',
                    `De bestanden ${this.getFileNamesConcatenated(
                        filesWithErrors
                    )} konden niet worden opgeladen. Het opladen van de rest van de bestanden verliep prima.`
                );
            }
        },
        getFileNamesConcatenated(files) {
            return files.map((file) => file.name).join(', ');
        },
        getFailedRequestIndexes(fileUploadResults) {
            return fileUploadResults.filter(onlyFailedPromises).map(getIndexOfItem);
        },
        getItemsWithError(files, fileIndexesWithError) {
            return files.filter((file, index) => index in fileIndexesWithError);
        },

        handleWrongFileType() {
            displayError.call(this, 'Verkeerd bestandstype', this.getWrongFileTypeText());
        },
        handleExceedFileLimit() {
            displayError.call(
                this,
                'Maximaal aantal bestanden overschreden',
                `Je mag slechts ${this.maxNumberOfFiles} bestanden tegelijk opladen.`
            );
        },
        handleExceedMaxFileSize() {
            displayError.call(
                this,
                'Te groot bestand',
                this.$t('validation.smaller_than_xmb', {
                    size: this.maxFileSizeMb
                })
            );
        },
        getWrongFileTypeText() {
            if (this.allowedExtensions.length === 1) {
                return `Er mogen enkel bestanden van het formaat ${mime.extension(this.allowedExtensions[0])}`;
            }

            return `Er mogen enkel bestanden van het formaat ${this.allowedExtensions
                .map(mime.extension)
                .slice(0, this.allowedExtensions.length - 1)
                .join(', ')} of ${mime.extension(this.allowedExtensions[this.allowedExtensions.length - 1])}`;
        }
    }
};
</script>
