<template>
    <div class="v-stepper-wrapper">
        <v-stepper-step :editable="canEditStepper" :complete="canEditStepper" :step="step" data-action="open-comments">
            <base-icon-right i18n-path="tooltips.addExpense.extraInfo" small>
                {{ $t('addExpense.extra_info') }}
            </base-icon-right>
        </v-stepper-step>
        <v-stepper-content
            :step="step"
            :class="{
                'stepper-content-active': stepper == step
            }"
        >
            <v-row>
                <v-col cols="12" md="12" order="12">
                    <text-area
                        v-model="currentAccountingExpense.description"
                        :label="$t('general.description')"
                        outlined
                        dense
                        auto-grow
                    />
                </v-col>
            </v-row>
        </v-stepper-content>
    </div>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

export default {
    mixins: [formRulesMixin],
    props: {
        value: {},
        stepper: {
            required: true
        },
        canEditStepper: {
            type: Boolean,
            required: true
        },
        step: {
            type: Number,
            required: true
        }
    },

    data() {
        return {};
    },

    computed: {
        currentAccountingExpense: {
            get() {
                return this.value;
            },
            set(input) {
                return this.$emit('input', input);
            }
        }
    }
};
</script>
