
import { modalMixin } from '@/components/Modal/modalMixin';
import mixins from 'vue-typed-mixins';
import * as VATDeclarationServices from '@/views/VAT/businessServices/vatdeclaration.service';
import CommonModal from '@/components/Modal/CommonModal.vue';
import SubmitVatDeclarationModal from '@/components/Modal/vat-declaration/SubmitVatDeclarationModal.vue';
import { Quarters } from '@/config/constants';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { PropOptions } from 'vue';
import { GeneralFncType } from '@/types/general';
import SliderModal from '@/components/Modal/SliderModal.vue';
import { mapState } from 'vuex';
import DownloadVatDeclarationModal from '@/components/Modal/vat-declaration/DownloadVatDeclarationModal.vue';

export default mixins(modalMixin).extend({
    name: 'VatModalController',
    mixins: [modalMixin],
    components: {
        CommonModal,
        SubmitVatDeclarationModal,
        SliderModal,
        DownloadVatDeclarationModal
    },
    props: {
        toggleVatModal: {
            type: Boolean,
            required: true
        } as PropOptions<boolean>,
        quarterData: {
            type: Object,
            required: true
        },
        selectedQuarter: {
            type: String,
            required: true
        } as PropOptions<Quarters>,
        downloadVatDeclarationXmlRequestHandler: {
            required: true
        } as PropOptions<GeneralFncType>
    },
    data() {
        return {
            xmlFile: {
                loading: false as boolean,
                downloaded: false as boolean,
                file: null as File | null,
                valid: false as boolean,
                selected: false as boolean
            }
        };
    },
    computed: {
        ...mapState(['selectedYear'])
    },
    watch: {
        toggleVatModal() {
            this.checkTerugbetalingDialog();
        }
    },
    methods: {
        async downloadXml(quarter: Quarters, withRepayment: boolean): Promise<void> {
            try {
                this.xmlFile.loading = true;

                await this.downloadVatDeclarationXmlRequestHandler(
                    {
                        year: this.selectedYear,
                        quarter,
                        withRepayment
                    },
                    {
                        filename: `kwartaalaangifte-${quarter}-${this.selectedYear}.xml`
                    }
                );

                this.$emit('refresh-submission');
                this.xmlFile.downloaded = true;
            } catch (e: any) {
                this.handleDownloadXMLError(e);
            } finally {
                this.xmlFile.loading = false;
            }
        },
        handleDownloadXMLError(e: Error): void {
            apiErrorAndDisplay.call(this, e);
        },
        getAmountLowerBoundGetMoneyBackQuarter(quarter: Quarters): number {
            return VATDeclarationServices.getAmountLowerBoundGetMoneyBackQuarter(quarter);
        },
        async checkTerugbetalingDialog(): Promise<void> {
            if (VATDeclarationServices.checkIfUserGetsMoneyBackFromVAT(this.quarterData)) {
                this.setModalContent({
                    component: CommonModal,
                    props: {
                        i18nTitle: 'vatdeclaration.with_repayment',
                        i18nText: 'vatdeclaration.with_repayment_text',
                        i18nTextVariables: {
                            amount: this.getAmountLowerBoundGetMoneyBackQuarter(this.selectedQuarter)
                        },
                        actions: [
                            {
                                i18nPath: 'vatdeclaration.without',
                                action: async () => {
                                    this.openStep1Modal(false);
                                }
                            },
                            {
                                i18nPath: 'vatdeclaration.with',
                                action: async () => {
                                    this.openStep1Modal(true);
                                }
                            }
                        ]
                    }
                });
            } else {
                this.openStep1Modal(false);
            }
        },
        openStep1Modal(withRepayment: boolean): void {
            this.xmlFile.downloaded = false;

            const step1ModalProps = {
                component: DownloadVatDeclarationModal,
                props: {
                    selectedQuarter: this.selectedQuarter,
                    i18nTitle: 'vatdeclaration.whatToDoWithXMLDialog.step1.title',
                    i18nText: 'vatdeclaration.whatToDoWithXMLDialog.step1.text',
                    xmlFile: this.xmlFile
                },
                listeners: {
                    'download-vat-declaration': async () => {
                        await this.downloadXml(this.selectedQuarter, withRepayment);
                    },
                    next: () => {
                        this.openStep2Modal();
                    }
                },
                dataId: 'submitConfirmationReceiptDialog'
            };

            if (this.isModalOpen) {
                this.addAndGoToNextModal(step1ModalProps);
            } else {
                this.setModalContent(step1ModalProps);
            }
        },
        openStep2Modal(): void {
            this.addAndGoToNextModal({
                component: CommonModal,
                props: {
                    i18nTitle: 'vatdeclaration.whatToDoWithXMLDialog.step2.title',
                    i18nText: 'vatdeclaration.whatToDoWithXMLDialog.step2.text',
                    actions: [
                        {
                            appendIcon: 'mdi-arrow-right',
                            i18nPath: 'general.next',
                            dataAction: 'go-to-step-3',
                            action: async () => {
                                try {
                                    this.openStep3Modal();
                                } catch (e: any) {
                                    this.handleDownloadXMLError(e);
                                }
                            }
                        }
                    ]
                }
            });
        },
        openStep3Modal(): void {
            this.addAndGoToNextModal({
                component: SubmitVatDeclarationModal,
                props: {
                    i18nTitle: 'vatdeclaration.whatToDoWithXMLDialog.step3.title',
                    i18nText: 'vatdeclaration.whatToDoWithXMLDialog.step3.text',
                    year: parseInt(this.selectedYear),
                    selectedQuarter: this.selectedQuarter,
                    xmlFile: this.xmlFile
                },
                listeners: {
                    'vat-declaration-selected': () => {
                        this.xmlFile.selected = true;
                    },
                    'vat-declaration-reset': () => {
                        this.xmlFile.selected = false;
                    },
                    next: () => {
                        this.openStep4Modal();
                        this.$emit('refresh-submission');
                    }
                }
            });
        },
        openStep4Modal(): void {
            this.addAndGoToNextModal({
                component: CommonModal,
                props: {
                    selectedQuarter: this.selectedQuarter,
                    i18nTitle: 'vatdeclaration.whatToDoWithXMLDialog.step4.title',
                    i18nText: 'vatdeclaration.whatToDoWithXMLDialog.step4.text',
                    actions: [
                        {
                            icon: 'mdi-check',
                            i18nPath: 'general.complete',
                            dataAction: 'complete-step-4',
                            action: () => {
                                this.isModalOpen = false;
                            }
                        }
                    ]
                }
            });
        }
    }
});
