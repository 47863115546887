export const canCreateInvoicableMiddlewareMixin = {
    methods: {
        canCreateInvoicableMiddleware(next) {
            if (this.currentUserData.company.number && this.currentUserData.company.IBAN) {
                next();
                return;
            }

            this.setDialogAttributes(
                {
                    title: 'Neej mag niet',
                    message: `LOL MAG NEIT`
                },
                {
                    clickPositive: this.clickNegative
                }
            );
        }
    }
};
