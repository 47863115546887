
import dayjs from 'dayjs';
import _ from 'lodash';
import Vue, { PropOptions } from 'vue';
import TimelineItem from './TimelineItem.vue';
import { TimelineFeedViewModel } from 'dexxter-types';

export default Vue.extend({
    components: { TimelineItem },

    props: {
        forGivenYear: {
            type: Number,
            required: false
        } as PropOptions<number>,
        expandedAll: {
            type: Boolean,
            default: true
        } as PropOptions<boolean>,
        items: {
            type: Array,
            default: null
        } as PropOptions<TimelineFeedViewModel>,
        numberOfItems: {
            type: Number,
            required: false,
            default: -1
        } as PropOptions<number>,
        truncateDescription: {
            type: Boolean,
            required: false,
            default: false
        } as PropOptions<boolean>,
        mandatoryOnly: {
            type: Boolean,
            required: false,
            default: false
        } as PropOptions<boolean>
    },

    computed: {
        timelineItems(): TimelineFeedViewModel {
            let result = this.items;

            if (this.mandatoryOnly) {
                result = result.filter((_item) => _item.calendarEvent.mandatory);
            }

            result = result.filter((_event) => {
                return _event.status !== 'irrelevant';
            });

            result = this.sortItems(result);

            if (this.numberOfItems !== -1) {
                result = result.slice(0, this.numberOfItems);
            }

            return result;
        }
    },

    methods: {
        sortItems(items: TimelineFeedViewModel): TimelineFeedViewModel {
            return _.sortBy(items, [
                (item) => {
                    return dayjs.utc(item.calendarEvent.date.date, 'YYYY-MM-DD').toDate();
                }
            ]);
        }
    }
});
