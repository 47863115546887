<template>
    <div class="v-stepper-wrapper">
        <v-stepper-step editable :complete="stepper > 1" step="1" data-action="open-supplier-info">
            {{ $t('addExpense.supplier_info') }}
        </v-stepper-step>
        <v-stepper-content :class="{ 'stepper-content-active': stepper == 1 }" :editable="canEditStepper" step="1">
            <v-row>
                <v-col cols="12" sm="12" order="10">
                    <search-input
                        id="searchSupplier"
                        :label="$t('general.search_supplier')"
                        :value="getSupplierNameForSearchBox"
                        @clicked-search="searchSupplier"
                    />
                </v-col>

                <v-col v-if="canSelectBTWMedecontractant" cols="12" sm="12" order="10">
                    <v-checkbox
                        id="btwMedecontractant"
                        v-model="currentAccountingExpense.btwMedecontractant"
                        dense
                        :label="$t('accountantTerms.btw_medecontractant')"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" order="10">
                    <v-btn :disabled="!canClickNext" color="primary" small @click="$emit('next')">{{
                        $t('general.next')
                    }}</v-btn>
                </v-col>
            </v-row>
        </v-stepper-content>
        <select-table-component
            ref="selectTableComponentSupplier"
            :title="$t('general.select_supplier')"
            :input-component="suppliersTable"
            :add-button-text="$t('general.create_supplier')"
            @selected-item="handleSelectedSupplier"
            @new-item="newSupplier"
        />
    </div>
</template>

<script>
import SearchInput from '@/components/SearchInput.vue';
import SuppliersTable from '@/components/DataTables/SuppliersTable';
import { mapState } from 'vuex';
import SelectTableComponent from '@/components/SelectTableComponent.vue';

import * as BusinessExpenseService from '@/businessServices/expense/expense.service';
import { useSupplierStore } from '../../store/modules/suppliers';

export default {
    components: {
        SearchInput,
        SelectTableComponent
    },
    props: {
        value: {},
        stepper: {
            required: true
        },
        canEditStepper: {
            type: Boolean,
            required: true
        },
        canClickNext: {
            type: Boolean,
            required: true
        }
    },

    setup() {
        const { newSupplier } = useSupplierStore();

        return { newSupplier };
    },

    data() {
        return {
            suppliersTable: SuppliersTable
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),

        currentAccountingExpense: {
            get() {
                return this.value;
            },
            set(input) {
                return this.$emit('input', input);
            }
        },
        getSupplierNameForSearchBox() {
            return this.currentAccountingExpense.supplier ? this.currentAccountingExpense.supplier.company.name : '';
        },
        canSelectBTWMedecontractant() {
            if (!this.currentAccountingExpense.supplier) {
                return false;
            }

            return BusinessExpenseService.canSelectBTWMedecontractant(
                this.currentAccountingExpense.supplier,
                this.currentUserData
            );
        }
    },

    methods: {
        handleSelectedSupplier(supplier) {
            this.currentAccountingExpense.supplier = supplier;
        },
        searchSupplier() {
            this.$refs.selectTableComponentSupplier.open();
        }
    }
};
</script>

<style></style>
