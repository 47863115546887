<template>
    <div>
        <loader v-if="loading" />
    </div>
</template>

<script>
import Loader from '@/components/Loader';
import { sendVerifyAccountEmailRequest } from '@/services/usersettings';
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { mapActions } from 'vuex';

export default {
    components: {
        Loader
    },

    data() {
        return {
            message: '',
            loading: false
        };
    },
    async created() {
        this.loading = true;
        if (!this.$route.query?.token) {
            return this.$router.push({ name: 'login' });
        }

        try {
            await sendVerifyAccountEmailRequest({ token: this.$route.query.token });
            notify.call(this, {
                title: this.$i18n.t('general.verification_success'),
                text: this.$i18n.t('general.verification_success')
            });

            this.logout();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            this.$router.replace({
                name: 'dashboard'
            });
        } finally {
            this.loading = false;
        }
    },

    methods: {
        ...mapActions('auth', ['logout'])
    }
};
</script>
