
import Vue, { PropOptions } from 'vue';
import CommonModal from '@/components/Modal/CommonModal.vue';

export default Vue.extend({
    name: 'DownloadVatDeclarationModal',
    components: { CommonModal },
    props: {
        i18nTitle: {
            type: String,
            required: true
        } as PropOptions<string>,
        i18nText: {
            type: String,
            required: true
        } as PropOptions<string>,
        i18nTextVariables: {
            type: Object,
            required: false
        } as PropOptions<Record<string, unknown>>,
        xmlFile: {
            type: Object,
            required: true
        } as PropOptions<string>
    },
    methods: {
        emitDownloadEvent() {
            this.$emit('download-vat-declaration');
        },
        emitNextEvent() {
            this.$emit('next');
        }
    }
});
