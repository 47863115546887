<template>
    <div class="DataCardMultiple" outlined>
        <p class="DataCardMultiple_Text">
            <template v-if="i18nPath">
                <base-icon-right small :i18n-path="i18nPath">
                    {{ dataText }}
                </base-icon-right>
            </template>
            <template v-else-if="i18nObject">
                <base-icon-right small :title="i18nObject.title" :content="i18nObject.content">
                    {{ dataText }}
                </base-icon-right>
            </template>
            <template v-else>
                {{ dataText }}
            </template>
        </p>
        <div class="DataCardMultiple_Number">
            <loader-inline v-if="loading" />
            <template v-else>
                {{ leading }}

                <template v-if="data !== null && data !== undefined && !loading">
                    {{ disableNumericFormat ? data : $options.filters.numericFormat(data) }} {{ trailing }}
                </template>
                <v-btn v-if="appendIcon" icon @click="$emit('click:append-icon')">
                    <v-icon small>mdi-{{ appendIcon }}</v-icon>
                </v-btn>
            </template>
        </div>
    </div>
</template>

<script>
import BaseIconRight from './BaseIconRight.vue';
import LoaderInline from './LoaderInline.vue';
export default {
    components: { BaseIconRight, LoaderInline },
    props: {
        data: {
            required: true
        },
        i18nObject: {
            required: false,
            default: null
        },
        dataText: {
            type: String,
            required: true
        },
        leading: {
            type: String,
            required: false
        },
        trailing: {
            type: String,
            required: false
        },
        tooltip: {
            type: String,
            default: ''
        },
        avatarColor: {
            type: String,
            default: ''
        },
        disableNumericFormat: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        i18nPath: {
            type: String
        },
        appendIcon: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.DataCardMultiple {
    &_Number {
        display: flex;
        align-items: center;
        color: $dexxter-dark;
        font-size: 1.25rem;
        letter-spacing: -0.02em;
        font-weight: 500;
        min-height: 44px;
        vertical-align: middle;
    }

    &_Text {
        color: $color-text-grey;
        font-size: 0.875rem;
    }
}
</style>
