
import { CreateCarData, ICarViewModel } from 'dexxter-types';
import Vue, { PropOptions } from 'vue';
import SearchInput from '../../../components/SearchInput.vue';
import SelectTableComponent from '../../../components/SelectTableComponent.vue';
import { reportError } from '../../../helpers/logging';
import { DeepNullable } from '../../../helpers/types';
import { sendGetCarRequest } from '../../../services/car';
import CarModal from '../../UserApp/taxes/cars/create/components/CarModal.vue';
import CarTableVue from '../../UserApp/taxes/cars/getAll/components/CarTable.vue';
import { createCarData } from '../../UserApp/taxes/cars/model';

export default Vue.extend({
    components: { SearchInput, SelectTableComponent, CarModal },

    props: {
        value: {
            required: true
        } as PropOptions<number>
    },

    data() {
        return {
            loading: false,
            openCarModal: false,
            createCarData: null as null | DeepNullable<CreateCarData>,
            carTableComponent: CarTableVue,
            carData: null as ICarViewModel | null
        };
    },

    computed: {
        carName(): string {
            if (!this.carData) {
                return '';
            }

            return this.carData.name;
        }
    },

    watch: {
        value: {
            immediate: true,
            async handler(value) {
                if (!value) {
                    this.carData = null;
                } else {
                    try {
                        this.loading = true;
                        this.carData = await sendGetCarRequest(value);
                    } catch (e) {
                        reportError(e);
                    } finally {
                        this.loading = false;
                    }
                }
            }
        }
    },

    methods: {
        openCreateCarPopup() {
            this.createCarData = createCarData();
            this.openCarModal = true;
        },
        searchExpenseCategory() {
            (this.$refs.selectTableComponentCar as any).open();
        },
        handleSelectedCar(selected: ICarViewModel) {
            this.$emit('input', selected.id);
        },
        refreshData() {
            (this.$refs.selectTableComponentCar as any).refreshData();
        }
    }
});
