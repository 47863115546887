<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
                <template #content> </template
            ></page-header>
        </template>
        <template #content>
            <div v-if="incomestatement && currentUserData" class="incomestatement">
                <v-row>
                    <v-col cols="12" md="12" order="3" order-md="1">
                        <v-card outlined class="pa-4">
                            <v-simple-table>
                                <template #default>
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-left">
                                                {{ $t('dashboard.sales_and_services') }}
                                                {{ isVATLiable ? $t('general.excl_vat') : $t('general.incl_vat') }}
                                            </th>
                                            <th scope="col" class="text-right">
                                                {{ $t('general.amount_money') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-left">
                                                {{ $t('dashboard.incomes') }}
                                            </td>
                                            <td class="text-right">
                                                + €{{
                                                    incomestatement.totalIncomeOrganisationEuroExclVAT ||
                                                    0 | numericFormat
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">
                                                {{ $t('dashboard.expenses_services_and_goods') }}
                                            </td>
                                            <td class="text-right">
                                                - €{{
                                                    incomestatement.expenses.totalCostsGoodsAndServices ||
                                                    0 | numericFormat
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">
                                                {{ $t('dashboard.depreciations') }}
                                            </td>
                                            <td class="text-right">
                                                - €{{ incomestatement.expenses.depreciation || 0 | numericFormat }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">
                                                {{ $t('general.verworpen_uitgaven') }}
                                            </td>
                                            <td class="text-right">+ €{{ incomestatement.vu || 0 | numericFormat }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">
                                                <strong>{{ $t('dashboard.gross_profit') }}</strong>
                                            </td>
                                            <td class="text-right">
                                                <strong>{{
                                                    numericFormatEuroAndSign(incomestatement.nettoBelastbaarInkomen)
                                                }}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td class="text-left">
                                                <router-link :to="{ name: 'personalTaxesOverview' }">{{
                                                    $t('dashboard.personal_tax')
                                                }}</router-link>
                                            </td>
                                            <td class="text-right">
                                                - €{{
                                                    incomestatement.tax.personalIncomeTaxEuroImpact +
                                                        incomestatement.tax.localTaxEuro || 0 | numericFormat
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">
                                                <strong>{{ $t('dashboard.net_profit') }}</strong>
                                            </td>
                                            <td class="text-right">
                                                <strong>{{
                                                    numericFormatEuroAndSign(incomestatement.nettoProfitEuro)
                                                }}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </template>
    </view-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';
import { isVatLiable } from '@/helpers/VATHelper';

export default {
    components: { ViewLayout, PageHeader },

    data() {
        return {
            quarters: ['Q1', 'Q2', 'Q3', 'Q4'],
            incomestatement: null
        };
    },

    computed: {
        ...mapState('taxes', ['taxes']),
        ...mapState('expenses', ['newExpenseEvents']),
        ...mapState('auth', ['currentUserData']),
        ...mapState(['selectedYear']),
        ...mapState('incomes', ['newInvoiceEvents', 'newCreditnoteEvents', 'newDailyReceiptEvents']),
        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        }
    },

    watch: {
        selectedYear(newValue) {
            this.getTaxes({
                year: newValue
            });
        }
    },

    created() {
        this.getAllInitData();
    },

    methods: {
        ...mapActions('taxes', ['getTaxes', 'getIncomeStatement']),
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('expenseCategories', ['getExpenseCategories']),
        ...mapActions('calendarevents', ['getAllActiveFromYear']),
        async getAllInitData() {
            try {
                this.startLoading();
                this.incomestatement = await this.getIncomeStatement({
                    year: this.selectedYear
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        numericFormatEuroAndSign(value) {
            let sign = '+';
            // Make sure the value is a number.
            value = parseFloat(value);
            // Make positive number for proper string parsing.
            if (value < 0) {
                value *= -1;
                sign = '-';
            }
            return `${sign} €${this.$options.filters.numericFormat(value)}`;
        }
    }
};
</script>

<style lang="scss" scoped>
div.theme--light.v-data-table {
    background-color: transparent;
}

tr.expanded-row {
    td {
        height: 32px !important;
    }
}

h6 {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.32);

    .v-icon {
        margin-top: -4px;
    }
}
</style>
