<template>
    <base-bottom-sheet
        v-if="currentDailyReceipt && currentUserData"
        id="correctDailyreceiptBottomSheet"
        v-model="isEditDailyReceiptOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
    >
        <template #header>
            <bottom-sheet-header>
                {{ $t('income.correct_daily_receipts') }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12" md="12">
                        <text-area
                            v-model="correctionComment"
                            data-form="correctionComment"
                            name="correctionComment"
                            :label="$t('income.why_correct_dailyreceipt')"
                            outlined
                            hide-details="auto"
                            dense
                            required
                            auto-grow
                            :rules="minRules(3)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-btn
                            data-action="submit-dailyreceipt-correction-form"
                            color="primary"
                            small
                            :disabled="!valid"
                            @click="runMiddleware(deleteDailyreceiptMiddleware, currentDailyReceipt)"
                        >
                            {{ $t('general.delete') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <loader v-if="loading" />

            <confirmation-dialog
                v-if="showDialog"
                v-model="showDialog"
                v-bind="dialogAttributes.attributes"
                @click-positive="dialogAttributes.actions.clickPositive"
                @click-negative="dialogAttributes.actions.clickNegative"
            />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
import { mapFields } from 'vuex-map-fields';
// --- Actions ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import BottomSheetHeader from './BottomSheetHeader';
import Loader from '../Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { middlewareMixin } from '@/mixins/middlewareMixin';
// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import { dailyreceiptProactiveSmartnotificationsMixin } from '@/mixins/dailyreceiptProactiveSmartnotificationsMixin';

export default {
    components: {
        BottomSheetHeader,
        Loader,
        ConfirmationDialog
    },

    mixins: [formRulesMixin, middlewareMixin, dailyreceiptProactiveSmartnotificationsMixin],

    data() {
        return {
            valid: true,
            loading: false,
            deleteDailyreceiptMiddleware: [
                this.confirmationDeleteDailyreceipt,
                this.deleteDailyreceiptProactiveSmartnotifications,
                this.handleSubmit
            ],
            correctionComment: null
        };
    },

    computed: {
        ...mapFields('bottomSheets', ['isEditDailyReceiptOpen', 'currentDailyReceipt']),
        ...mapState('auth', ['currentUserData'])
    },

    methods: {
        ...mapActions('bottomSheets', ['closeEditDailyreceipt']),
        ...mapActions('incomes', ['correctDailyReceipt']),
        async handleSubmit() {
            try {
                this.loading = true;

                await this.correctDailyReceipt({
                    dailyreceiptID: this.currentDailyReceipt.id,
                    correctionComment: this.correctionComment
                });

                notify.call(this, {
                    title: this.$t('income.dailyreceipt_updated_success'),
                    text: this.$t('income.dailyreceipt_updated_success')
                });

                this.correctionComment = null;

                // Close bottom sheet when submit successful.
                this.closeEditDailyreceipt();
                this.$store.dispatch('incomes/newDailyReceiptUpdated');

                this.emitDeleteEvent();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        confirmationDeleteDailyreceipt(next) {
            this.openConfirmationDialog();

            this.setDialogAttributes(
                {
                    title: this.$t('addDailyreceipts.dialogs.confirmationDelete.title'),
                    message: this.$t('addDailyreceipts.dialogs.confirmationDelete.content')
                },
                {
                    clickPositive: () => {
                        this.clickPositive.call(this, next);
                    }
                }
            );
        },
        emitDeleteEvent() {
            this.$emit('deleted-item', this.currentDailyReceipt);
        }
    }
};
</script>
