<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <div class="page">
                <v-card outlined class="card-styling pa-4 mb-6">
                    <v-row>
                        <v-col cols="12" class="pa-8">
                            <p>
                                Wat mag je hier in de toekomst verwachten van Dexxter? Een analyse van je boekhouding
                                met gepersonaliseerd advies! Ontbreken er geen must-havekosten in je boekhouding? Zijn
                                je sociale bijdragen niet wat aan de lage kant? Doe je niet beter een andere
                                voorafbetaling voor je personenbelasting?
                            </p>
                            <p class="mt-4"><b>Ontdek het binnenkort in onze Analyse-module!</b></p>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
    components: { PageHeader, ViewLayout }
};
</script>

<style lang="scss"></style>
