<template>
    <div :class="{ alert: isAboveLimit }">
        <div class="DataCardLimit pa-5" outlined>
            <p class="DataCardLimit_Text">
                <template v-if="i18nPath">
                    <base-icon-right small :i18n-path="i18nPath">
                        {{ dataText }}
                    </base-icon-right>
                </template>
                <template v-else-if="i18nObject">
                    <base-icon-right small :title="i18nObject.title" :content="i18nObject.content">
                        {{ dataText }}
                    </base-icon-right>
                </template>
                <template v-else>
                    {{ dataText }}
                </template>
            </p>
            <div class="DataCardLimit_Number">
                <loader-inline v-if="loading" />
                <template v-else>
                    {{ leading }}{{ amount | numericFormat }} / {{ leading }}{{ limit | numericFormat }}
                </template>
            </div>
            <div v-if="isAboveLimit && i18nPathExceeded" class="DataCardLimit_Footer">
                <tooltip :id="exceededId" :underlined="true" data-id="limitExceeded" :i18n-path="i18nPathExceeded"
                    ><template slot="activator">{{ $t(`${i18nPathExceeded}.activator`) }}</template></tooltip
                >
            </div>
        </div>
    </div>
</template>

<script>
import BaseIconRight from './BaseIconRight.vue';
import LoaderInline from './LoaderInline.vue';
export default {
    components: { BaseIconRight, LoaderInline },
    props: {
        exceededId: {
            type: String,
            required: false
        },
        amount: {
            required: true
        },
        limit: {
            required: true
        },
        i18nObject: {
            required: false,
            default: null
        },
        dataText: {
            type: String,
            required: true
        },
        leading: {
            type: String,
            required: false
        },
        trailing: {
            type: String,
            required: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        i18nPath: {
            type: String
        },
        i18nPathExceeded: {
            type: String
        },
        appendIcon: {
            type: String,
            default: null
        }
    },

    computed: {
        isAboveLimit() {
            return this.amount > this.limit;
        }
    }
};
</script>

<style lang="scss" scoped>
.alert {
    border: 1px solid red !important;
}

.DataCardLimit {
    &_Number {
        display: flex;
        align-items: center;
        color: $dexxter-dark;
        font-size: 1.25rem;
        letter-spacing: -0.02em;
        font-weight: 500;
        min-height: 44px;
        vertical-align: middle;
    }

    &_Text {
        color: $color-text-grey;
        font-size: 0.875rem;
    }
}
</style>
