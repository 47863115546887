<template>
    <div class="quotations-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="3">
                <v-select
                    v-model="query.status"
                    :items="statusOptions"
                    :label="$t('general.status')"
                    item-text="label"
                    item-value="value"
                    clearable
                    hide-details
                    dense
                    outlined
                />
            </v-col>
            <v-col cols="12" md="9">
                <pagination-search v-model="query.q" />
            </v-col>
        </v-row>
        <pagination-data-table
            v-if="quotationIncomes.data"
            id="quotationsTable"
            ref="paginationDataTable"
            :loading="loading"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="quotationIncomes"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.quotationNumber`]="{ item }">
                {{ item.draft === false ? item.quotationNumber : '-' }}
            </template>
            <template #[`item.date`]="{ item }"> {{ item.date | formatDate }} </template>

            <template #[`item.amountEuroExclVAT`]="{ item }"
                >€ {{ item.amountEuroExclVAT || 0 | numericFormat }}</template
            >
            <template #[`item.amountEuroInclVAT`]="{ item }"
                >€ {{ item.amountEuroInclVAT || 0 | numericFormat }}</template
            >
            <template #[`item.total`]="{ item }"> € {{ item.amountEuroInclVAT || 0 | numericFormat }} </template>
            <template #[`item.status`]="{ item }">
                <quotation-table-status :item="item" @click-send-icon="navigateToEmailHistory(item)" />
            </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left data-action="open-menu">
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" data-action="open-menu" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item v-if="item.url" @click="downloadIncomePDF(item.url)">
                                <v-list-item-title>
                                    {{ $t('general.download') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical === false" :to="/edit-quotation/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="canGenerateInvoiceFromQuotation(item)"
                                data-action="createInvoice"
                                @click="handleGenerateInvoiceFromQuotation(item.id)"
                            >
                                <v-list-item-title>
                                    {{ $t('general.generate_invoice') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical" :to="/edit-historical-quotation/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.accepted !== 'accepted' && item.draft === false"
                                @click="setAccepted(item, 'accepted')"
                            >
                                <v-list-item-title>
                                    {{ $t('income.mark_as_accepted') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.accepted !== 'declined' && item.draft === false"
                                @click="setAccepted(item, 'declined')"
                            >
                                <v-list-item-title>
                                    {{ $t('income.mark_as_not_accepted') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.accepted !== 'invoiced' && item.draft === false"
                                @click="setAccepted(item, 'invoiced')"
                            >
                                <v-list-item-title>
                                    {{ $t('income.mark_as_invoiced') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="!item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_not_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                data-action="delete"
                                @click.stop="
                                    () => {
                                        dialog = true;
                                        selectedIncome = item.id;
                                    }
                                "
                            >
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu></template
            >
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeQuotation"
            @click-negative="dialog = false"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import PaginationSearch from '@/components/PaginationSearch.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

import { filteringMixin, tableName } from '@/mixins/filtering';
import PaginationDataTable from '@/components/DataTables/PaginationDataTable.vue';
import { isVatLiable } from '@/helpers/VATHelper';

import QuotationTableStatus from '@/components/QuotationTableStatus.vue';

export default {
    components: {
        ConfirmationDialog,
        PaginationSearch,
        PaginationDataTable,
        QuotationTableStatus
    },
    mixins: [filteringMixin],
    data() {
        const currentUserData = this.$store.state.auth.currentUserData;
        const vatLiable = isVatLiable(currentUserData.settings.vatLiable);

        let sortableColumns = ['date', 'dueDate', 'quotationNumber'];

        if (vatLiable) {
            sortableColumns.push('amountEuroInclVAT', 'amountEuroExclVAT');
        } else {
            sortableColumns.push('total');
        }

        const headers = [
            {
                text: this.$t('addIncome.quotation_number'),
                align: 'start',
                value: 'quotationNumber',
                sortable: sortableColumns.includes('quotationNumber')
            },
            {
                text: this.$t('general.client'),
                value: 'customer.company.name',
                sortable: sortableColumns.includes('customer')
            },
            { text: this.$t('general.date'), value: 'date' },
            vatLiable
                ? [
                      {
                          text: this.$t('general.total_excl_vat'),
                          value: 'amountEuroExclVAT',
                          sortable: sortableColumns.includes('amountEuroExclVAT')
                      },
                      {
                          text: this.$t('general.total_incl_vat'),
                          value: 'amountEuroInclVAT',
                          sortable: sortableColumns.includes('amountEuroInclVAT')
                      }
                  ]
                : {
                      text: this.$t('general.total'),
                      value: 'amountEuroExclVAT',
                      sortable: sortableColumns.includes('total')
                  },
            { text: this.$t('general.status'), value: 'status' },
            {
                text: this.$t('general.actions'),
                value: 'actions',
                sortable: false
            }
        ].flat();

        return {
            headers,
            selectedIncome: '',
            [tableName]: 'quotationTable',
            dialog: false,
            quotationIncomes: {
                data: []
            },
            query: {
                status: null
            },
            statusOptions: [
                {
                    label: this.$t('general.published'),
                    value: 'published'
                },
                {
                    label: this.$t('general.draft'),
                    value: 'draft'
                },
                {
                    label: this.$t('general.accepted'),
                    value: 'accepted'
                },
                {
                    label: this.$t('general.declined'),
                    value: 'declined'
                },
                {
                    label: this.$t('general.invoiced'),
                    value: 'invoiced'
                },
                {
                    label: this.$t('general.sent'),
                    value: 'sent'
                },
                {
                    label: this.$t('general.not_sent'),
                    value: 'notsent'
                }
            ],
            sortableColumns
        };
    },
    created() {
        this.wrapperFunction();
    },
    methods: {
        ...mapActions('incomes', [
            'getQuotationsPagination',
            'deleteQuotation',
            'toggleSendToCustomerQuotation',
            'toggleAcceptedQuotation'
        ]),
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('bottomSheets', ['editIncomeQuotation']),
        canGenerateInvoiceFromQuotation(item) {
            return item.draft === false && this.hasQuotationInvoice(item) === false;
        },
        navigateToEmailHistory(item) {
            this.$router.push({
                name: 'customer-mail',
                params: {
                    type: 'quotation',
                    id: item.id
                }
            });
        },
        async removeQuotation() {
            this.startLoading();
            this.dialog = false;
            try {
                await this.deleteQuotation(this.selectedIncome);
                await this.wrapperFunction();

                notify.call(this, {
                    title: this.$t('general.succesfuly_deleted'),
                    text: this.$t('general.succesfuly_deleted')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        async handleGenerateInvoiceFromQuotation(quotationId) {
            if (this.$can('CREATE', 'Accountinginvoicable')) {
                this.$router.push({
                    name: 'add-accounting-invoice',
                    query: {
                        quotationId
                    }
                });
            } else {
                this.$router.push({
                    name: 'add-invoice',
                    query: {
                        quotationId
                    }
                });
            }
        },
        async wrapperFunction() {
            this.loading = true;
            this.quotationIncomes = await this.getQuotationsPagination({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loading = false;
        },
        async setAccepted(item, value) {
            try {
                this.startLoading();
                await this.toggleAcceptedQuotation({
                    id: item.id,
                    value
                });
                item.accepted = value;

                notify.call(this, {
                    title: this.$t('income.updated_success'),
                    text: this.$t('income.updated_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        async toggleSendStatus(item) {
            try {
                this.startLoading();
                await this.toggleSendToCustomerQuotation(item.id);
                item.sendtocustomer = !item.sendtocustomer;

                notify.call(this, {
                    title: this.$t('income.updated_success'),
                    text: this.$t('income.updated_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        downloadIncomePDF(url) {
            window.open(url);
        },
        hasQuotationInvoice(quotation) {
            return quotation.invoiceId !== null;
        },
        handleClickRow(quotation) {
            this.$emit('click-row', quotation);
        }
    }
};
</script>
