
import TableCard from '@/components/TableCard.vue';

import Vue, { PropOptions } from 'vue';

import { Grootboekrekening, GrootboekrekeningBookingViewModel, GrootboekrekeningChild } from 'dexxter-types';

import * as domainCalculations from '../domain/calculations';

export default Vue.extend({
    components: {
        TableCard
    },

    props: {
        expenseGrootboekrekeningen: {
            required: true
        } as PropOptions<Grootboekrekening[]>,

        incomeGrootboekrekeningen: {
            required: true
        } as PropOptions<Grootboekrekening[]>,

        totalIncomeGrootboekrekeningen: {
            required: true
        } as PropOptions<number>,

        totalExpenseGrootboekrekeningen: {
            required: true
        } as PropOptions<number>,

        bookings: {
            required: true
        } as PropOptions<GrootboekrekeningBookingViewModel[]>,

        resultText: {
            required: true
        } as PropOptions<string>
    },

    methods: {
        getGrootboekrekeningIncomeTypeFromSubCategory(
            sub: Grootboekrekening['children'][number]
        ): Grootboekrekening['incomeType'] {
            for (const income of this.incomeGrootboekrekeningen) {
                if (income.children.some((_child) => _child.number === sub.number)) {
                    return 'income';
                }
            }

            for (const expense of this.expenseGrootboekrekeningen) {
                if (expense.children.some((_child) => _child.number === sub.number)) {
                    return 'expense';
                }
            }

            throw new Error('Sub not foudn');
        },

        getPercentageOfTotal(grootboekrekening: Grootboekrekening | GrootboekrekeningChild): number {
            let grootboekrekningIncomeType;

            if (grootboekrekening.parentId !== null) {
                grootboekrekningIncomeType = this.getGrootboekrekeningIncomeTypeFromSubCategory(grootboekrekening);
            } else {
                grootboekrekningIncomeType = (grootboekrekening as Grootboekrekening).incomeType;
            }

            if (grootboekrekningIncomeType === 'income') {
                if (grootboekrekening.parentId === null) {
                    return (
                        (this.getTotalFromGrootboekrekening(grootboekrekening) / this.totalIncomeGrootboekrekeningen) *
                        100
                    );
                } else {
                    return (
                        (this.getTotalFromGrootboekrekeningSubcategory(grootboekrekening) /
                            this.totalIncomeGrootboekrekeningen) *
                        100
                    );
                }
            } else if (grootboekrekningIncomeType === 'expense') {
                if (grootboekrekening.parentId === null) {
                    return (
                        (this.getTotalFromGrootboekrekening(grootboekrekening) / this.totalExpenseGrootboekrekeningen) *
                        100
                    );
                } else {
                    return (
                        (this.getTotalFromGrootboekrekeningSubcategory(grootboekrekening) /
                            this.totalExpenseGrootboekrekeningen) *
                        100
                    );
                }
            }

            throw new Error('Not possible');
        },
        openBookings(grootboekrekening: Grootboekrekening): void {
            this.$emit('openBookings', grootboekrekening);
        },
        openInventoryMutationBookings() {
            this.$emit('openInventoryMutationBookings');
        },
        openDepreciationsBookings() {
            this.$emit('openDepreciationbookings');
        },
        getTotalFromGrootboekrekening(
            grootboekrekening: Grootboekrekening | Grootboekrekening['children'][number]
        ): number {
            return domainCalculations.getTotalFromGrootboekrekening(this.bookings, grootboekrekening);
        },
        getTotalFromGrootboekrekeningSubcategory(grootboekrekening: Grootboekrekening['children'][number]): number {
            return domainCalculations.getTotalFromGrootboekrekeningSubcategory(this.bookings, grootboekrekening);
        }
    }
});
