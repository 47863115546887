
import Vue from 'vue';

import VATTable from '@/views/VAT/VATListing/components/VATTable.vue';
import VatListingQrPayment from '@/views/VAT/VATListing/components/VatListingQrPayment.vue';

export default Vue.extend({
    name: 'VatDeclarationContent',
    components: {
        VATTable,
        VatListingQrPayment
    },
    props: {
        quarterText: {
            required: true
        },
        quarterData: {
            required: true,
            type: Object
        },
        currentPeriodResult: {
            required: true,
            type: Number
        },
        currentQuarterNumber: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            numberMode: false as boolean
        };
    }
});
