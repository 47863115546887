
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue2-helpers/vue-router';

export default defineComponent({
    name: 'Components',
    setup() {
        const router = useRouter();
        onMounted(() => {
            if (process.env.NODE_ENV === 'production') {
                router.replace({ name: '404' });
            }
        });

        const loading = ref(false);

        const handleClick = () => {
            loading.value = true;
            setTimeout(() => {
                loading.value = false;
            }, 2000);
        };

        return { loading, handleClick };
    }
});
