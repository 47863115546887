<template>
    <base-bottom-sheet
        v-if="isEditInventoryOpen"
        v-model="isEditInventoryOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
        class="add-income-billed-sheet"
    >
        <template #header>
            <bottom-sheet-header>
                {{ $t('inventory.edit_inventory') }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <v-form ref="form" v-model="valid">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                            <text-field
                                v-model="inventory.inventoryBegin"
                                :label="$t('inventory.inventory_begin')"
                                outlined
                                clearable
                                dense
                                persistent-hint
                                prefix="€"
                                type="number"
                                required
                                :rules="numberRules"
                            />
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                            <text-field
                                v-model="inventory.inventoryEnd"
                                :label="$t('inventory.inventory_end')"
                                outlined
                                clearable
                                dense
                                persistent-hint
                                prefix="€"
                                type="number"
                                required
                                :rules="numberRules"
                            />
                        </v-col>

                        <v-col cols="12" md="12">
                            <submit-button
                                id="edit-published"
                                icon="mdi-chevron-right"
                                :disabled="!valid"
                                @click="handleSubmit"
                            >
                                {{ $t('general.save') }}
                            </submit-button>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>

            <loader v-if="loading" />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
// --- Components ---
import BottomSheetHeader from './BottomSheetHeader';
import Loader from '../Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Constants ---
// --- Helper ---
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import SubmitButton from '../SubmitButton.vue';
import _ from 'lodash';

export default {
    components: {
        BottomSheetHeader,
        Loader,
        SubmitButton
    },

    mixins: [formRulesMixin],

    props: {
        inventoryBegin: {
            type: Number,
            required: true
        },
        inventoryEnd: {
            type: Number,
            required: true
        },
        year: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            valid: true,
            loading: false,
            inventory: {
                inventoryBegin: _.cloneDeep(this.inventoryBegin),
                inventoryEnd: _.cloneDeep(this.inventoryEnd)
            }
        };
    },

    computed: {
        ...mapFields('bottomSheets', ['isEditInventoryOpen']),
        ...mapState('auth', ['currentUserData'])
    },
    methods: {
        ...mapActions('taxes', ['editInventory']),
        ...mapActions('bottomSheets', ['closeEditInventoryModal']),
        resetForm() {
            this.$refs.form.reset();
        },
        async handleSubmit() {
            try {
                this.loading = true;
                await this.editInventory({
                    inventory: this.inventory,
                    year: this.year
                });

                notify.call(this, {
                    title: this.$t('settings.succesfuly_saved'),
                    text: this.$t('settings.succesfuly_saved'),
                    type: 'success'
                });

                // Close bottom sheet when submit successful.
                this.$emit('updated');
                this.closeEditInventoryModal();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
