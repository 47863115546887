<template>
    <div>
        <widget class="mb-3">
            <template #title>
                {{ $t('socialcontributionsOverview.calculation_table_title') }} op een
                <template v-if="hasInactiveQuarters">
                    <tooltip i18n-path="tooltips.socialcontributions.SocialContributionInFirstYear" :underlined="true">
                        <template slot="activator"> geprorateerde winst </template>
                    </tooltip>
                </template>
                <template v-else> winst </template> van

                {{ proration(socialcontributionInformation.grossProfitEuro) | numericFormat | addEuroSign }}
            </template>
            <template #content>
                <v-simple-table id="socialcontributions-table">
                    <template #default>
                        <thead>
                            <tr>
                                <th>Kwartaal</th>
                                <th class="valuta">
                                    Te betalen sociale bijdragen
                                    <tooltip :underlined="false" i18n-path="tooltips.socialcontributions.ToPay">
                                        <template slot="activator">
                                            <v-icon small> mdi-information </v-icon>
                                        </template>
                                    </tooltip>
                                </th>
                                <th class="valuta">
                                    Beheersbijdragen sociaal verzekeringsfonds ({{
                                        socialcontributionInformation.socialContributionsSettings
                                            .socialContributionsFeePercentage
                                    }}%)
                                </th>
                                <th class="valuta">Kwartaalbijdragen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="quarter of ['Q1', 'Q2', 'Q3', 'Q4']" :key="quarter">
                                <td>
                                    {{ $t('general.quarter') }} {{ quarter[1] }} ({{
                                        $t(
                                            `settings.statuten.${socialcontributionInformation.statuten[quarter]}`
                                        ).toLowerCase()
                                    }})
                                </td>

                                <valuta-cell
                                    text-align-right
                                    :amount="
                                        isActiveQuarter(socialcontributionInformation.statuten[quarter])
                                            ? socialcontributionInformation.socialContributions
                                                  .socialeBijdragenTeBetalenPerQuarter[quarter]
                                            : undefined
                                    "
                                    currency="EUR"
                                />

                                <valuta-cell
                                    text-align-right
                                    :amount="
                                        isActiveQuarter(socialcontributionInformation.statuten[quarter])
                                            ? (socialcontributionInformation.socialContributions
                                                  .socialeBijdragenTeBetalenPerQuarter[quarter] *
                                                  socialcontributionInformation.socialContributionsSettings
                                                      .socialContributionsFeePercentage) /
                                              100
                                            : undefined
                                    "
                                    currency="EUR"
                                />

                                <valuta-cell
                                    bold
                                    text-align-right
                                    :amount="
                                        isActiveQuarter(socialcontributionInformation.statuten[quarter])
                                            ? socialcontributionInformation.socialContributions
                                                  .socialeBijdragenTeBetalenPerQuarter[quarter] *
                                              (1 +
                                                  socialcontributionInformation.socialContributionsSettings
                                                      .socialContributionsFeePercentage /
                                                      100)
                                            : undefined
                                    "
                                    currency="EUR"
                                />
                            </tr>
                            <tr>
                                <td><b>Jaar</b></td>
                                <valuta-cell bold text-align-right :amount="bijdrageSom" currency="EUR" />
                                <valuta-cell bold text-align-right :amount="administratieveKostSom" currency="EUR" />
                                <valuta-cell bold text-align-right :amount="kwartaalbijdrageSom" currency="EUR" />
                            </tr>
                            <tr>
                                <td><b>Reeds betaalde sociale bijdragen</b></td>
                                <td></td>
                                <td></td>
                                <valuta-cell
                                    bold
                                    text-align-right
                                    :amount="getTotalAlreadyPaidSocialContributions()"
                                    currency="EUR"
                                />
                            </tr>
                            <tr class="summary">
                                <td>
                                    <div class="d-flex">
                                        <template v-if="!hasToPayExtraSocialContributions">
                                            <b>{{ $t('socialcontributionsOverview.table.get_back') }}</b>

                                            <tooltip
                                                class="ml-1"
                                                i18n-path="tooltips.socialcontributions.inYourAdvantage"
                                                ><template #activator
                                                    >({{
                                                        $t('socialcontributionsOverview.table.how_to_get_back')
                                                    }})</template
                                                ></tooltip
                                            >
                                        </template>
                                        <template v-else>
                                            <b>{{ $t('socialcontributionsOverview.table.to_pay') }}</b>

                                            <tooltip class="ml-1" i18n-path="tooltips.socialcontributions.howToPay"
                                                ><template #activator
                                                    >({{
                                                        $t(
                                                            'socialcontributionsOverview.table.how_to_to_pay'
                                                        ).toLowerCase()
                                                    }})</template
                                                ></tooltip
                                            >
                                        </template>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <valuta-cell bold text-align-right :amount="totaal" currency="EUR" />
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </widget>
    </div>
</template>

<script>
import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';
import { calculateCompanyStartYearMultiplicationFactor, isActiveQuarter } from '@dexxter/accountancy-tools';
import dayjs from 'dayjs';
import { mapActions, mapGetters, mapState } from 'vuex';
import Tooltip from '../../../components/Tooltip.vue';
import ValutaCell from '../../../components/cells/ValutaCell.vue';
import * as SocialcontributionCalculations from '../domain/calculations';
import { socialcontributionStartDateFactorFractionTextString } from '../domain/utils';

export default {
    components: {
        ValutaCell,
        Tooltip
    },

    props: {
        socialcontributionInformation: {
            required: true
        }
    },

    computed: {
        ...mapGetters('auth', ['isUserType']),
        ...mapState('auth', ['currentUserData']),
        ...mapState(['selectedYear']),
        kwartaalbijdrageSom() {
            return ['Q1', 'Q2', 'Q3', 'Q4'].reduce((prev, quarter) => {
                return (
                    prev +
                    (isActiveQuarter(this.socialcontributionInformation.statuten[quarter])
                        ? this.socialcontributionInformation.socialContributions.socialeBijdragenTeBetalenPerQuarter[
                              quarter
                          ] *
                          (1 +
                              this.socialcontributionInformation.socialContributionsSettings
                                  .socialContributionsFeePercentage /
                                  100)
                        : 0)
                );
            }, 0);
        },
        totaal() {
            return this.kwartaalbijdrageSom - this.getTotalAlreadyPaidSocialContributions();
        },
        bijdrageSom() {
            return ['Q1', 'Q2', 'Q3', 'Q4'].reduce((prev, quarter) => {
                return (
                    prev +
                    (isActiveQuarter(this.socialcontributionInformation.statuten[quarter])
                        ? this.socialcontributionInformation.socialContributions.socialeBijdragenTeBetalenPerQuarter[
                              quarter
                          ]
                        : 0)
                );
            }, 0);
        },
        administratieveKostSom() {
            return ['Q1', 'Q2', 'Q3', 'Q4'].reduce((prev, quarter) => {
                return (
                    prev +
                    (isActiveQuarter(this.socialcontributionInformation.statuten[quarter])
                        ? (this.socialcontributionInformation.socialContributions.socialeBijdragenTeBetalenPerQuarter[
                              quarter
                          ] *
                              this.socialcontributionInformation.socialContributionsSettings
                                  .socialContributionsFeePercentage) /
                          100
                        : 0)
                );
            }, 0);
        },
        isUserMain() {
            return this.isUserType('main');
        },
        hasInactiveQuarters() {
            return (
                !isActiveQuarter(this.socialcontributionInformation.statuten['Q1']) ||
                !isActiveQuarter(this.socialcontributionInformation.statuten['Q2']) ||
                !isActiveQuarter(this.socialcontributionInformation.statuten['Q3']) ||
                !isActiveQuarter(this.socialcontributionInformation.statuten['Q4'])
            );
        },
        hasToPayExtraSocialContributions() {
            return this.totaal > 0;
        }
    },

    created() {
        this.getCurrentUserType();
    },

    methods: {
        ...mapActions('auth', ['getCurrentUserType']),
        ...mapActions(['toggleLearningcenter']),
        convertToLocaleValuta,
        isActiveQuarter,
        proration(amount) {
            return (
                calculateCompanyStartYearMultiplicationFactor({
                    Q1: this.socialcontributionInformation.statuten.Q1,
                    Q2: this.socialcontributionInformation.statuten.Q2,
                    Q3: this.socialcontributionInformation.statuten.Q3,
                    Q4: this.socialcontributionInformation.statuten.Q4
                }) * amount
            );
        },
        socialcontributionStartDateFactorFractionTextString,

        getTotalToPaySocialcontributions() {
            const toPay = this.getTotalToPaySocialContributions();
            const alreadyPaid = this.getTotalAlreadyPayedSocialContributions();

            return SocialcontributionCalculations.getTotalToPaySocialcontributions(toPay, alreadyPaid);
        },
        getUserStartYear(currentUserData) {
            return dayjs(currentUserData.settings.companyStartDate, 'YYYY-MM-DD').year();
        },

        hasUserStartedCompanyInTheSelectedYear(startYear) {
            return startYear == this.selectedYear;
        },
        getGrossProfit() {
            return this.socialcontributionInformation.grossProfitEuro;
        },
        getTotalToPaySocialContributions() {
            return this.socialcontributionInformation.socialContributions.socialContributionsToPayEuro;
        },
        getSocialContributionsFeePercentage() {
            return this.socialcontributionInformation.socialContributionsSettings.socialContributionsFeePercentage;
        },
        getSocialContributionUpperLimit() {
            return this.socialcontributionInformation.socialContributionsSettings.socialContributionsUpperLimit;
        },
        calculateTotalSocialconributionsWithoutFee() {
            const toPay = this.getTotalToPaySocialContributions();
            const fee = this.getSocialContributionsFeePercentage();

            return SocialcontributionCalculations.calculateTotalSocialconributionsWithoutFee(toPay, fee);
        },
        getSocialContributionsFeeEuro() {
            const toPay = this.getTotalToPaySocialContributions();
            const fee = this.calculateTotalSocialconributionsWithoutFee();

            return SocialcontributionCalculations.getSocialContributionsFeeEuro(toPay, fee);
        },
        getTotalAlreadyPaidSocialContributions() {
            return this.socialcontributionInformation.socialContributions.socialContributionsPaidEuro;
        },
        getTotalCalculatedSocialContributions() {
            return this.getTotalToPaySocialContributions();
        }
    }
};
</script>

<style lang="scss">
@import '@/sass/variables.scss';

th.valuta {
    text-align: right !important;
}

.grens {
    color: $grey-500;
}

.summary {
    background: $grey-50;
}
</style>
