import * as BusinessExpenseService from '@/businessServices/expense/expense.service.ts';
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import { BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU } from '@/config/constants';

export const accountingExpenseMiddlewareMixin = {
    data() {
        const baseSubmitMiddleware = [
            this.isSocialcontributionExpense,
            this.checkDate,
            this.maybeAnInvestement,
            this.checkInclAndExclSameSign,
            this.checkPriceExclHigherThanPriceIncl,
            this.checkPriceExclLowerThanPriceIncl,
            this.checkMaybeUserMeantCreditnote
        ];
        const baseSubmitEndMiddleware = [this.confirmSubmit];

        return {
            baseVATLiableMiddleware: this.checkCorrectlyFormattedMiddlewareFunctions([
                ...baseSubmitMiddleware,
                this.amountInclEqualsExclBelgianWithNon0VATPercentage,
                this.canShowPriceExclDoesNotEqualsPriceInclBelgianCompanyMedecontractantDialog,
                this.checkIfICSupplierInclExclAreSameWhenUserSaidSupplierDidChargeVAT,
                this.vatLiableCompanyInsideEUOrOutsideHasFilledIn0,
                this.checkPriceExclAndInclEUVATLiableFictiveVATDialog,
                this.ICSupplierHasChargedVATWarning,
                this.OutsideEUSupplierHasChargedVATWarning,
                this.outsideEUTotalDiffersFromFictiveVAT,
                ...baseSubmitEndMiddleware
            ]),
            baseNonVATLiableMiddleware: this.checkCorrectlyFormattedMiddlewareFunctions([
                ...baseSubmitMiddleware,
                this.checkPriceExclAndInclEUNotVATLiable,
                ...baseSubmitEndMiddleware
            ])
        };
    },

    computed: {
        ...mapState(['selectedYear']),
        categoryName() {
            return this.currentAccountingExpense.expensecategory.name;
        },
        createAndNewSubmitMiddleware() {
            if (this.isVATLiable) {
                return this.baseVATLiableMiddleware;
            } else {
                return this.baseNonVATLiableMiddleware;
            }
        },
        createAndOverviewSubmitMiddleware() {
            return [...this.createAndNewSubmitMiddleware, this.redirectToOverview];
        },
        createAndGoToNextExpenseFileSubmitMiddleware() {
            return [...this.createAndNewSubmitMiddleware, this.redirectToNextExpensefile];
        }
    },

    methods: {
        checkDate(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowCheckDateDialog(
                    this.currentAccountingExpense,
                    {
                        selectedYear: this.selectedYear
                    }
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    title: this.$i18n.t('general.confirmation'),
                    message: `Is de periode (${dayjs(this.currentAccountingExpense.date, 'YYYY-MM-DD').format(
                        'MM-YYYY'
                    )}) zeker correct?`
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );
            this.showDialog = true;
        },

        confirmSubmit(next) {
            let message;

            if (
                this.currentAccountingExpense.supplier.type === COMPANY_OUTSIDE_EU ||
                this.currentAccountingExpense.supplier.type === COMPANY_INSIDE_EU ||
                (this.currentAccountingExpense.supplier.type === BELGIAN_COMPANY &&
                    this.currentAccountingExpense.btwMedecontractant) ||
                !this.isVATLiable
            ) {
                message = this.$i18n.t('expense.confirm_no_vat', {
                    totalExpenseAmountInclVATEuro: this.$options.filters.numericFormat(
                        this.currentAccountingExpense.amountEuroInclVAT
                    ),
                    categoryName: this.categoryName
                });
            } else {
                message = this.$i18n.t('expense.confirm', {
                    totalVAT: this.$options.filters.numericFormat(
                        this.currentAccountingExpense.amountEuroInclVAT -
                            this.currentAccountingExpense.amountEuroExclVAT
                    ),
                    totalExpenseAmountInclVATEuro: this.$options.filters.numericFormat(
                        this.currentAccountingExpense.amountEuroInclVAT
                    ),
                    categoryName: this.categoryName
                });
            }

            this.setDialogAttributes(
                {
                    title: this.$i18n.t('general.confirmation'),
                    message
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );

            this.showDialog = true;
        },
        async isSocialcontributionExpense(next) {
            if (
                !(await BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowIsSocialcontributionExpenseDialog(
                    this.currentAccountingExpense,
                    {
                        selectedYear: this.selectedYear
                    }
                ))
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    title: this.$t('general.confirmation'),
                    message: this.$t('expense.social_contributions_no_vat')
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );

            this.showDialog = true;
        },
        ICSupplierHasChargedVATWarning(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowICSupplierHasChargedVATWarning(
                    this.currentAccountingExpense
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'ICWithVAT',
                    title: this.$i18n.t('addExpense.validationMiddleware.ICWithVAT.title'),
                    message: this.$i18n.t('addExpense.validationMiddleware.ICWithVAT.content'),
                    positiveColor: 'red'
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );

            this.showDialog = true;
        },
        OutsideEUSupplierHasChargedVATWarning(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowOutsideEUSupplierHasChargedVATWarning(
                    this.currentAccountingExpense
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'OutsideEUWithVAT',
                    title: this.$i18n.t('addExpense.validationMiddleware.OutsideEUWithVAT.title'),
                    message: this.$i18n.t('addExpense.validationMiddleware.OutsideEUWithVAT.content'),
                    positiveColor: 'red'
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );

            this.showDialog = true;
        },
        vatLiableCompanyInsideEUOrOutsideHasFilledIn0(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.vatLiableCompanyInsideEUOrOutsideHasFilledIn0(
                    this.currentAccountingExpense
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'vatLiableCompanyInsideEUOrOutsideHasFilledIn0',
                    title: this.$i18n.t(
                        'addExpense.validationMiddleware.vatLiableCompanyInsideEUOrOutsideHasFilledIn0.title'
                    ),
                    message: this.$i18n.t(
                        'addExpense.validationMiddleware.vatLiableCompanyInsideEUOrOutsideHasFilledIn0.content'
                    ),
                    positiveColor: 'red'
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );

            this.showDialog = true;
        },
        outsideEUTotalDiffersFromFictiveVAT(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowOutsideEUTotalDiffersFromFictiveVAT(
                    this.currentAccountingExpense,
                    this.isVATLiable
                )
            ) {
                next();
                return;
            }

            this.setDialogAttributes(
                {
                    dataId: 'OutsideEUTotalDiffersFromFictiveVAT',
                    title: this.$i18n.t('addExpense.validationMiddleware.outsideEUTotalDiffersFromFictiveVAT.title'),
                    message: this.$i18n.t(
                        'addExpense.validationMiddleware.outsideEUTotalDiffersFromFictiveVAT.content'
                    ),
                    positiveColor: 'red',
                    positiveButton: 'Aanpassen'
                },
                {
                    clickPositive: this.clickNegative.bind(this)
                }
            );
            this.showDialog = true;
        },
        maybeAnInvestement(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowMaybeAnInvestementDialog(
                    this.currentAccountingExpense,
                    this.isVATLiable
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'may-be-investement',
                    title: this.$i18n.t('addExpense.expense_may_be_an_investement.title'),
                    message: this.$i18n.t('addExpense.expense_may_be_an_investement.content'),
                    positiveButton: this.$i18n.t('addExpense.expense_may_be_an_investement.positiveButton')
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );
            this.showDialog = true;
        },
        checkPriceExclAndInclEUVATLiableFictiveVATDialog(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowCheckPriceExclAndInclEUVATLiableFictiveVATDialog(
                    this.currentAccountingExpense,
                    this.isVATLiable
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'checkPriceExclAndInclEUVATLiableFictiveVATDialog',
                    title: this.$t(
                        'addExpense.validationMiddleware.amountEuroInclAndExcl_VATLiable_EU_fictiveVAT.title'
                    ),
                    message: this.$t(
                        'addExpense.validationMiddleware.amountEuroInclAndExcl_VATLiable_EU_fictiveVAT.content'
                    ),
                    positiveColor: 'red',
                    positiveButton: 'Aanpassen'
                },
                {
                    clickPositive: this.clickNegative.bind(this)
                }
            );

            this.showDialog = true;
        },

        checkIfICSupplierInclExclAreSameWhenUserSaidSupplierDidChargeVAT(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.checkIfICSupplierInclExclAreSameWhenUserSaidSupplierDidChargeVAT(
                    this.currentAccountingExpense,
                    this.currentUserData.settings.vatLiable
                )
            ) {
                next();
                return;
            }

            this.setDialogAttributes(
                {
                    dataId: 'checkIfInclExclAreSameWhenUserSaidSupplierDidChargeVAT',
                    title: this.$t(
                        'addExpense.validationMiddleware.checkIfInclExclAreSameWhenUserSaidSupplierDidChargeVAT.title'
                    ),
                    message: this.$t(
                        'addExpense.validationMiddleware.checkIfInclExclAreSameWhenUserSaidSupplierDidChargeVAT.content'
                    ),
                    positiveColor: 'red',
                    positiveButton: 'Aanpassen'
                },
                {
                    clickPositive: this.clickNegative.bind(this, next),
                    clickNegative: this.clickNegative.bind(this, next)
                }
            );

            this.showDialog = true;
        },

        canShowPriceExclDoesNotEqualsPriceInclBelgianCompanyMedecontractantDialog(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.PriceExclDoesNotEqualsPriceInclBelgianCompanyMedecontractantDialog(
                    this.currentAccountingExpense,
                    this.isVATLiable
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'amountEuroInclLowerThanIncl_not_equal_Belgian',
                    title: this.$t(
                        'addExpense.validationMiddleware.amountEuroInclLowerThanIncl_not_equal_Belgian.title'
                    ),
                    message: this.$t(
                        'addExpense.validationMiddleware.amountEuroInclLowerThanIncl_not_equal_Belgian.content'
                    ),
                    positiveColor: 'red',
                    positiveButton: 'Aanpassen'
                },
                {
                    clickPositive: this.clickNegative.bind(this, next),
                    clickNegative: this.clickNegative.bind(this, next)
                }
            );

            this.showDialog = true;
        },

        checkPriceExclAndInclEUNotVATLiable(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowCheckPriceExclAndInclEUNotVATLiableDialog(
                    this.currentAccountingExpense,
                    this.isVATLiable
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'amountEuroInclAndExcl_NOT_VATLiable_EU',
                    title: this.$t('addExpense.validationMiddleware.amountEuroInclAndExcl_NOT_VATLiable_EU.title'),
                    message: this.$t('addExpense.validationMiddleware.amountEuroInclAndExcl_NOT_VATLiable_EU.content')
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );

            this.showDialog = true;
        },

        checkInclAndExclSameSign(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowInclAndExclShouldBeSameSignDialog(
                    this.currentAccountingExpense
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'incl_and_excl_dont_have_same_sign',
                    title: this.$t('addExpense.validationMiddleware.incl_and_excl_dont_have_same_sign.title'),
                    message: this.$t('addExpense.validationMiddleware.incl_and_excl_dont_have_same_sign.content'),
                    positiveColor: 'red',
                    positiveButton: 'Aanpassen'
                },
                {
                    clickPositive: this.clickNegative.bind(this, next)
                }
            );

            this.showDialog = true;
        },
        checkPriceExclHigherThanPriceIncl(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowCheckPriceExclHigherThanPriceIncl(
                    this.currentAccountingExpense,
                    this.isVATLiable
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'amountEuroInclHigherThanIncl',
                    title: this.$t('addExpense.validationMiddleware.PriceExclHigherThanPriceIncl.title'),
                    message: this.$t('addExpense.validationMiddleware.PriceExclHigherThanPriceIncl.content'),
                    positiveColor: 'red',
                    positiveButton: 'Aanpassen'
                },
                {
                    clickPositive: this.clickNegative.bind(this)
                }
            );

            this.showDialog = true;
        },
        checkMaybeUserMeantCreditnote(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowMaybeUserMeantCreditnote(
                    this.currentAccountingExpense
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'maybe_user_meant_creditnote',
                    title: this.$t('addExpense.validationMiddleware.maybe_user_meant_creditnote.title'),
                    message: this.$t('addExpense.validationMiddleware.maybe_user_meant_creditnote.content')
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );

            this.showDialog = true;
        },
        amountInclEqualsExclBelgianWithNon0VATPercentage(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowamountInclEqualsExclBelgianWithNon0VATPercentage(
                    this.currentAccountingExpense
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'incl_equals_excl_belgian_with_non_0_vatpercentage',
                    title: this.$t(
                        'addExpense.validationMiddleware.incl_equals_excl_belgian_with_non_0_vatpercentage.title'
                    ),
                    message: this.$t(
                        'addExpense.validationMiddleware.incl_equals_excl_belgian_with_non_0_vatpercentage.content'
                    ),
                    positiveColor: 'red',
                    positiveButton: 'Aanpassen'
                },
                {
                    clickPositive: this.clickNegative.bind(this)
                }
            );

            this.showDialog = true;
        },
        checkPriceExclLowerThanPriceIncl(next) {
            if (
                !BusinessExpenseService.accountingExpenseMiddlewareDialogs.canShowCheckPriceExclLowerThanPriceIncl(
                    this.currentAccountingExpense,
                    this.isVATLiable
                )
            ) {
                return next();
            }

            this.setDialogAttributes(
                {
                    dataId: 'amountEuroInclLowerThanIncl',
                    title: this.$t('addExpense.validationMiddleware.PriceExclLowerThanPriceIncl.title'),
                    message: this.$t('addExpense.validationMiddleware.PriceExclLowerThanPriceIncl.content'),
                    positiveColor: 'red',
                    positiveButton: 'Aanpassen'
                },
                {
                    clickPositive: this.clickNegative.bind(this)
                }
            );

            this.showDialog = true;
        }
    }
};
