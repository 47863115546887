import { Q1, Q2, Q3, Q4 } from '@/config/constants';
import { getQuarterOfDate } from '@/helpers/dateHelpers';
import dayjs from 'dayjs';
import { lowerCase } from 'lodash';

export function socialcontributionStartDateFactor(companyStartDate: string, selectedYear: number): number {
    if (selectedYear == null) {
        throw new Error('selected year is not a number');
    }

    if (dayjs(companyStartDate, 'YYYY-MM-DD').year() != selectedYear) {
        return 1;
    }

    const quarter = getQuarterOfDate(companyStartDate);

    if (quarter === Q1) {
        return 4 / 4;
    } else if (quarter === Q2) {
        return 4 / 3;
    } else if (quarter === Q3) {
        return 4 / 2;
    } else if (quarter === Q4) {
        return 4 / 1;
    } else {
        throw new Error('Quarter not found');
    }
}

export function socialcontributionStartDateFactorFractionString(
    companyStartDate: string,
    selectedYear: number
): string {
    if (dayjs(companyStartDate, 'YYYY-MM-DD').year() != selectedYear) {
        return '1';
    }

    const quarter = getQuarterOfDate(companyStartDate);

    if (quarter === Q1) {
        return `4 / 4`;
    } else if (quarter === Q2) {
        return `4 / 3`;
    } else if (quarter === Q3) {
        return `4 / 2`;
    } else if (quarter === Q4) {
        return `4 / 1`;
    } else {
        throw new Error('Quarter not found');
    }
}

export function socialcontributionStartDateFactorFractionTextString(
    activeQuarterText: string,
    quartersText: string,
    companyStartDate: string,
    selectedYear: number
): string {
    if (dayjs(companyStartDate, 'YYYY-MM-DD').year() != selectedYear) {
        return '';
    }

    const quarter = getQuarterOfDate(companyStartDate);

    if (quarter === Q1) {
        return `4 ${lowerCase(quartersText)} / 4 ${lowerCase(activeQuarterText)}`;
    } else if (quarter === Q2) {
        return `4 ${lowerCase(quartersText)} / 3 ${lowerCase(activeQuarterText)}`;
    } else if (quarter === Q3) {
        return `4 ${lowerCase(quartersText)} / 2 ${lowerCase(activeQuarterText)}`;
    } else if (quarter === Q4) {
        return `4 ${lowerCase(quartersText)} / 1 ${lowerCase(activeQuarterText)}`;
    } else {
        throw new Error('Quarter not found');
    }
}
