
import { mapState } from 'vuex';

import Handylinks from '@/components/Dashboard/Handylinks/Handylinks.vue';
import DashboardIncomegraph from '@/components/DashboardIncomegraph.vue';
import DashboardKPI from '@/components/DashboardKPI.vue';
import KnowsUsFromSelect from '@/components/KnowsUsFromSelect/KnowsUsFromSelect.vue';
import PageHeader from '@/components/PageHeader.vue';
import Timeline from '@/components/Timeline.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import Widget from '@/components/Widget.vue';
import { popupNotificationNames, tours } from '@/config/constants';
import { informationpopupMixin } from '@/mixins/informationpopupMixin';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import dayjs from 'dayjs';
import mixins from 'vue-typed-mixins';
import DashboardTimeline from './components/DashboardTimeline.vue';

export default mixins(screenSizeMixin, informationpopupMixin).extend({
    components: {
        KnowsUsFromSelect,
        Timeline,
        Widget,
        ViewLayout,
        PageHeader,
        Handylinks,
        DashboardIncomegraph,
        DashboardKPI,
        DashboardTimeline
    },

    mixins: [screenSizeMixin, informationpopupMixin],

    data() {
        return {
            timelineOnlyMandatoryItems: false,
            loading: {
                timeline: true
            },
            yearForTimeline: dayjs().year()
        };
    },
    computed: {
        ...mapState(['selectedYear']),
        informationpopupPriority() {
            if (this.isSmallDevice) {
                return [
                    popupNotificationNames.WELCOME_TO_DEXXTER_MOBILE,
                    popupNotificationNames.WHITELIST_DEXXTER_EMAIL,
                    tours.SECONDARY_STARTUP_TOUR,
                    tours.SECONDARY_STARTUP_TOUR_PRE_ACCOUNTING
                ];
            } else {
                return [
                    popupNotificationNames.WELCOME_TO_DEXXTER,
                    popupNotificationNames.WHITELIST_DEXXTER_EMAIL,
                    tours.SECONDARY_STARTUP_TOUR,
                    tours.SECONDARY_STARTUP_TOUR_PRE_ACCOUNTING
                ];
            }
        }
    }
});
