<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                        <sub>
                            <tooltip
                                :underlined="false"
                                :title="$t(`tooltips.addInvoicable.historicalquotationTitle.title`)"
                                :content="$t(`tooltips.addInvoicable.historicalquotationTitle.content`)"
                                ><template slot="activator">
                                    <v-icon small> mdi-information </v-icon></template
                                ></tooltip
                            >
                        </sub>
                    </template>
                </page-header>
            </template>
            <template #content>
                <InvoicableForm
                    ref="invoicableComponent"
                    type="quotation"
                    :is-creating-new-document="isCreatingNew"
                    :historical="true"
                    :invoicable-number-text="$t('general.quotation_number')"
                    :document-input="currentQuotation"
                    :button-text-publish="$t('addIncome.insert_quotation')"
                    @submit-edit="handleSubmitEdit"
                    @submit-create-historical="handleSubmitCreateHistorical"
                />
            </template>
        </view-layout>
        <loader v-if="loading || !currentUserData" />
    </div>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import InvoicableForm from '@/components/Forms/InvoicableForm';
import Loader from '@/components/Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { invoicableFormMixin } from '@/mixins/invoicableFormMixin.js';
// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
// import { notify } from '@/helpers/successNotification';
import PageHeader from '@/components/PageHeader';
import ViewLayout from '@/components/ViewLayout';
import { invoicableTypes } from '@/config/constants';
import { invoicableTransformCurrencyFieldToIntegers } from '@/helpers/invoicableHelper';
import { analyticsTrack } from '@/services/analytics';
import dayjs from 'dayjs';
import { reportError } from '../helpers/logging';
import { currentInvoicableNewInstance } from '../models/invoicable';

export default {
    components: {
        Loader,
        InvoicableForm,
        ViewLayout,
        PageHeader
    },

    mixins: [formRulesMixin, invoicableFormMixin],

    data() {
        return {
            loading: false,
            isCreatingNew: true,
            currentQuotation: currentInvoicableNewInstance(invoicableTypes.QUOTATION)
        };
    },

    async created() {
        this.startLoading();
        try {
            if (this.$route.params.id) {
                this.isCreatingNew = false;
                this.currentQuotation = await this.getQuotationById(this.$route.params.id);
            } else {
                this.isCreatingNew = true;
                this.setInitialData();
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    methods: {
        ...mapActions('incomes', ['getQuotationById']),
        ...mapActions(['startLoading', 'stopLoading']),
        setInitialData() {
            this.currentQuotation.date = dayjs.utc().format('YYYY-MM-DD');
        },

        handleAfterSubmit(newQuotation) {
            if (!newQuotation.draft && this.isCreatingNew) {
                try {
                    analyticsTrack('User Completes An Quotation', {
                        Add_Invoice_Price: newQuotation.amountEuroInclVAT,
                        Add_Invoice_Discount: newQuotation.discount,
                        Add_Invoice_Discount_Type: newQuotation.isDiscountPercentage ? 'percentage' : 'amount',
                        Add_Invoice_Comment: newQuotation.comment,
                        Type: 'historical'
                    });
                } catch (e) {
                    reportError(e);
                }
            }

            this.$store.dispatch('incomes/newQuotationCreated');

            this.isCreatingNew = false;
            this.currentQuotation = newQuotation;

            this.$router.push({
                name: 'invoicable-view',
                params: {
                    id: this.currentQuotation.id,
                    type: 'quotation'
                },
                query: {
                    newlyCreated: true
                }
            }).catch;
        },

        setCorrectValues(invoicableCopy) {
            invoicableCopy.draft = false;
            invoicableCopy.historical = true;
            invoicableCopy.creator = this.currentUserData.company;

            invoicableCopy.type = invoicableTypes.QUOTATION;

            return invoicableTransformCurrencyFieldToIntegers(invoicableCopy, invoicableTypes.QUOTATION);
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}
</style>
