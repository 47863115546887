
import Vue, { PropOptions } from 'vue';
// --- Components ---
// --- State ---
// --- Error handling ---

export default Vue.extend({
    name: 'QuestionMarksVisual',
    props: {
        extraClass: {
            type: String
        } as PropOptions<string>
    }
});
