import { render, staticRenderFns } from "./Timeline.vue?vue&type=template&id=58436566&scoped=true&"
import script from "./Timeline.vue?vue&type=script&lang=ts&"
export * from "./Timeline.vue?vue&type=script&lang=ts&"
import style0 from "./Timeline.vue?vue&type=style&index=0&id=58436566&prod&lang=scss&"
import style1 from "./Timeline.vue?vue&type=style&index=1&id=58436566&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58436566",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTimeline } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VTimeline})
