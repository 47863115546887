
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import Loader from '@/components/Loader.vue';
import Timeline from '@/components/Timeline.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';

import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import VCardHeader from '@/components/vCardHeader.vue';

import { mapState } from 'vuex';
import { sendGetTimelineRequest } from '@/services/timeline';
import mixins from 'vue-typed-mixins';
import { TimelineFeedViewModel } from 'dexxter-types';

export default mixins(formRulesMixin).extend({
    components: { Loader, Timeline, ViewLayout, PageHeader, VCardHeader },

    mixins: [formRulesMixin],

    data() {
        return {
            loading: false,
            timelineOnlyMandatoryItems: false,
            timeline: null as null | TimelineFeedViewModel
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapState(['selectedYear'])
    },

    async created() {
        await this.getTimeline();
    },

    methods: {
        async getTimeline(): Promise<void> {
            this.loading = true;
            try {
                this.timeline = await sendGetTimelineRequest(this.selectedYear);
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
});
