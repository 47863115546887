<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                    <sub>
                        <tooltip
                            :underlined="false"
                            :title="$t(`tooltips.addInvoicable.creditnoteTitle.title`)"
                            :content="$t(`tooltips.addInvoicable.creditnoteTitle.content`)"
                            ><template slot="activator"> <v-icon small> mdi-information </v-icon></template></tooltip
                        >
                    </sub>
                </template>
            </page-header>
        </template>
        <template #content>
            <AccountingInvoicableForm
                ref="invoicableComponent"
                type="creditnote"
                :is-creating-new-document="isCreatingNew"
                :invoicable-number-text="$t('general.creditnote_number')"
                :document-input="currentCreditnote"
                :button-text-publish="$t('addIncome.create_creditnote')"
                @submit-create-draft="handleSubmitCreateDraft"
                @submit-edit="runMiddleware(editMiddleware, $event)"
                @submit-publish="runMiddleware(createMiddleware, $event)"
            />

            <confirmation-dialog
                v-if="showDialog"
                v-model="showDialog"
                v-bind="dialogAttributes.attributes"
                @click-positive="dialogAttributes.actions.clickPositive"
                @click-negative="dialogAttributes.actions.clickNegative"
            />

            <Loader v-if="loading" />
        </template>
    </view-layout>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import AccountingInvoicableForm from '@/components/Forms/accounting/AccountingInvoicableForm';
import Loader from '@/components/Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Helper ---
import { invoicableTransformCurrencyFieldToIntegers } from '@/helpers/invoicableHelper';

import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import PageHeader from '@/components/PageHeader';
import ViewLayout from '@/components/ViewLayout';
import { invoicableTypes } from '@/config/constants';
import { accountingInvoicableFormMixin } from '@/mixins/accountingInvoicableFormMixin.js';
import { creditnoteProactiveSmartnotificationsMixin } from '@/mixins/creditnoteProactiveSmartnotificationsMixin';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import { currentInvoicableNewInstance } from '@/models/invoicable';
import { getAccountingCreditnote } from '@/services/accountingcreditnotes';
import { getInvoice } from '@/services/invoice';
import dayjs from 'dayjs';
import { reportError } from '../../../helpers/logging';
import { analyticsTrack } from '../../../services/analytics';

export default {
    components: {
        Loader,
        AccountingInvoicableForm,
        ViewLayout,
        PageHeader,
        ConfirmationDialog
    },

    mixins: [
        middlewareMixin,
        formRulesMixin,
        accountingInvoicableFormMixin,
        creditnoteProactiveSmartnotificationsMixin
    ],

    data() {
        return {
            loading: false,
            invoice: null,
            previousCreditnote: null,
            currentCreditnote: currentInvoicableNewInstance(invoicableTypes.CREDITNOTE),
            editMiddleware: [this.creditnoteProactiveSmartnotifications, this.handleEditSubmitMiddleware],
            createMiddleware: [this.creditnoteProactiveSmartnotifications, this.handleSubmitMiddleware]
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    async created() {
        this.startLoading();
        this.loading = true;
        try {
            if (this.$route.params.id) {
                // Edit creditnote
                this.isCreatingNew = false;
                const fetchedCreditnote = await getAccountingCreditnote(this.$route.params.id);
                this.currentCreditnote = fetchedCreditnote;
                this.previousCreditnote = fetchedCreditnote;

                if (this.currentCreditnote.invoiceId) {
                    this.invoice = await getInvoice(this.currentCreditnote.invoiceId);
                }
            } else {
                // Create creditnote from invoiceId

                this.isCreatingNew = true;
                if (this.$route.query?.invoiceId) {
                    this.invoice = await getInvoice(this.$route.query?.invoiceId);

                    this.currentCreditnote.invoiceId = this.invoice.id;
                    this.currentCreditnote.customer = this.invoice.customer;
                    this.currentCreditnote.items = this.invoice.items;
                    this.currentCreditnote.typeOfService = this.invoice.typeOfService;
                    this.currentCreditnote.invoiceNumber = this.invoice.invoiceNumber;
                }
                this.setInitialData();
            }
        } catch (e) {
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
            this.loading = false;
        }
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),

        handleEditSubmitMiddleware(next, creditnote) {
            return this.handleSubmitEdit(creditnote);
        },
        handleSubmitMiddleware(next, creditnote) {
            return this.handleSubmitPublish(creditnote);
        },

        resetForm() {
            this.$refs.form.reset();
        },
        setInitialData() {
            this.currentCreditnote.date = dayjs.utc().format('YYYY-MM-DD');
        },
        handleAfterSubmit(newCreditnote) {
            if (!newCreditnote.draft && this.isCreatingNew) {
                try {
                    analyticsTrack('User Completes An Invoice', {
                        Add_Invoice_Price: newCreditnote.amountEuroInclVAT,
                        Add_Invoice_Discount: newCreditnote.discount,
                        Add_Invoice_Discount_Type: newCreditnote.isDiscountPercentage ? 'percentage' : 'amount',
                        Add_Invoice_Comment: newCreditnote.comment,
                        Type: 'normal'
                    });
                } catch (e) {
                    reportError(e);
                }
            }
            this.$store.dispatch('incomes/newCreditnoteCreated');

            this.isCreatingNew = false;
            this.currentCreditnote = newCreditnote;

            if (this.currentCreditnote.draft === false) {
                this.increaseLocalCreditnoteCounter();
            }

            return this.$router.push({
                name: 'accounting-invoicable-view',
                params: {
                    id: this.currentCreditnote.id,
                    type: 'creditnote'
                },
                query: {
                    newlyCreated: true
                }
            }).catch;
        },
        setCorrectValues(creditnoteClone) {
            creditnoteClone.historical = false;
            creditnoteClone.creditnoteNumber = null;
            creditnoteClone.creator = this.currentUserData.company;

            creditnoteClone.type = 'creditnote';

            return invoicableTransformCurrencyFieldToIntegers(creditnoteClone, 'creditnote');
        },
        increaseLocalCreditnoteCounter() {
            // Increment the counter
            this.currentUserData.settings.creditnoteCounter++;
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}
</style>
