
import i18n from '@/i18n';
import { defineComponent } from 'vue';

import { getReadableDate } from '@/helpers/dateHelpers';

import OrderedRawHtmlList from '@/components/ordered-raw-html-list/OrderedRawHtmlList.vue';

export default defineComponent({
    name: 'SubmitPersonalTaxesStep5',
    components: { OrderedRawHtmlList },
    props: {
        personalTaxesSubmission: {
            type: Object
        },
        submitting: {
            required: true,
            type: Boolean
        }
    },
    setup(props, { emit }) {
        const steps = i18n.t('personalTaxes.submit_personal_taxes_step_3.children_steps', {
            returnObjects: true
        });

        return {
            emit,
            steps,
            i18n
        };
    },
    methods: { getReadableDate }
});
