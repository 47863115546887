<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <confirmation-dialog
                v-if="showDialog"
                v-model="showDialog"
                v-bind="dialogAttributes.attributes"
                @click-positive="dialogAttributes.actions.clickPositive"
                @click-negative="dialogAttributes.actions.clickNegative"
            />

            <AccountingInvoicableForm
                ref="invoicableComponent"
                type="invoice"
                :booking="true"
                :is-creating-new-document="isCreatingNew"
                :document-input="currentInvoice"
                :invoicable-number-text="$t('general.invoice_number')"
                :button-text-publish="$t('addIncome.create_invoice')"
                @submit-create-booking="handleSubmitBooking"
            />
        </template>
    </view-layout>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import AccountingInvoicableForm from '@/components/Forms/accounting/AccountingInvoicableForm.vue';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { middlewareMixin } from '@/mixins/middlewareMixin';

import { currentInvoicableNewInstance } from '../../models/invoicable';

// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
// import { notify } from '@/helpers/successNotification';
import { invoicableTransformCurrencyFieldToIntegers } from '@/helpers/invoicableHelper';
import { invoicableTypes, popupNotificationNames } from '@/config/constants';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import { getInvoice } from '@/services/invoice';
import { notify } from '@/helpers/successNotification';
import _ from 'lodash';
import { bookHistoricalInvoice } from '@/services/accountinginvoice';
import { sellInvestementDialogMixin } from '@/mixins/invoices/middleware/sellInvestement';

export default {
    components: {
        AccountingInvoicableForm,
        ViewLayout,
        PageHeader,
        ConfirmationDialog
    },
    mixins: [formRulesMixin, middlewareMixin, sellInvestementDialogMixin],

    data() {
        return {
            currentInvoice: currentInvoicableNewInstance(invoicableTypes.INVOICE),
            submitMiddleware: [this.sellInvestementDialog, this.handleSubmit]
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        informationpopupPriority() {
            return [popupNotificationNames.INVOICESETTINGS_OPENFIRSTTIME];
        }
    },

    async created() {
        this.startLoading();
        try {
            if (!this.$route.params.id) {
                this.$router.push({ name: 'dashboard' });
            }

            this.isCreatingNew = false;
            this.currentInvoice = await getInvoice(this.$route.params.id);
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
        }
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),

        async handleSubmitBooking(invoicable) {
            this.startLoading();
            let invoicableCopy = _.cloneDeep(invoicable);

            try {
                invoicableCopy = this.setCorrectValues(invoicableCopy);
                invoicableCopy.draft = false;
                invoicableCopy.historical = true;

                await bookHistoricalInvoice(invoicableCopy);

                notify.call(this, {
                    title: this.$t('income.created_success'),
                    text: this.$t('income.created_success')
                });

                return this.$router.push({
                    name: 'documentsToBook'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.stopLoading();
            }
        },
        setCorrectValues(invoicableCopy) {
            invoicableCopy.invoiceNumber = null;
            invoicableCopy.historical = false;
            invoicableCopy.creator = this.currentUserData.company;
            invoicableCopy.type = 'invoice';

            return invoicableTransformCurrencyFieldToIntegers(invoicableCopy, 'invoice');
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}
</style>
