
import { LearningCenterType } from '@/config/constants';
import Vue, { PropOptions } from 'vue';

// --- Components ---
import VideoModal from '@/components/Modal/VideoModal.vue';
// --- Helpers ---
import { readArticle } from '@/helpers/errorModals';

export default Vue.extend({
    name: 'LearningCenterCard',
    props: {
        id: {
            type: Number,
            required: true
        } as PropOptions<number>,
        url: {
            type: String,
            required: true
        } as PropOptions<string>,
        type: {
            type: String,
            required: true
        } as PropOptions<LearningCenterType>,
        title: {
            type: String,
            required: true
        } as PropOptions<string>,
        summary: {
            type: String,
            required: true
        } as PropOptions<string>
    },
    computed: {
        icon(): string {
            switch (this.type) {
                case 'VIDEO': {
                    return 'mdi-video';
                }
                case 'BLOG': {
                    return 'mdi-school';
                }
                case 'POPUPTEXT': {
                    return 'mdi-text';
                }
                default: {
                    return 'mdi-school';
                }
            }
        }
    },
    methods: {
        handleItemClick() {
            switch (this.type) {
                case 'VIDEO': {
                    return this.$store.commit('modal/setModalContent', {
                        component: VideoModal,
                        props: {
                            title: this.title,
                            url: this.url
                        }
                    });
                }
                case 'BLOG': {
                    return readArticle(this.url);
                }
                case 'POPUPTEXT': {
                    return;
                }
                default: {
                    return;
                }
            }
        }
    }
});
