<template>
    <v-row>
        <v-col cols="12" sm="6">
            <number-field
                id="amountEuroTotalExclVAT"
                v-model="currentAccountingExpense.amountEuroExclVAT"
                :label="$t('general.amount_excl_vat')"
                outlined
                prefix="€"
                :allow-negative="true"
                dense
                :rules="
                    currentAccountingExpense.isCreditnote
                        ? numberRulesNotRequired
                        : numberRulesNotRequiredNegativePossible
                "
            />
        </v-col>
        <v-col cols="12" sm="6">
            <number-field
                id="amountEuroTotalInclVAT"
                v-model="currentAccountingExpense.amountEuroInclVAT"
                :label="$t('general.amount_incl_vat')"
                outlined
                prefix="€"
                :allow-negative="true"
                dense
                :rules="
                    currentAccountingExpense.isCreditnote
                        ? numberRulesNotRequired
                        : numberRulesNotRequiredNegativePossible
                "
            />
        </v-col>

        <div v-if="videoTooltips.length > 0" data-id="videoPopupsForEUSupplierVatLiable">
            <v-col v-for="video in videoTooltips" :key="video" cols="12" sm="12">
                <tooltip-video-wrapper :i18n-path="video" />
            </v-col>
        </div>
    </v-row>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { COMPANY_INSIDE_EU } from '@/config/constants';
import TooltipVideoWrapper from '@/components/TooltipVideoWrapper.vue';
import { isVatLiable } from '@/helpers/VATHelper';
import { mapState } from 'vuex';

export default {
    components: { TooltipVideoWrapper },
    mixins: [formRulesMixin],
    props: {
        value: {
            required: true
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData']),

        videoTooltips() {
            const supplierType = this.currentAccountingExpense.supplier.type;

            if (
                supplierType === COMPANY_INSIDE_EU &&
                this.currentAccountingExpense.supplierChargedVAT === true &&
                this.isVATLiable
            ) {
                return ['videotooltips.expenses.vatLiable.ICCustomer.IncorrectlyAssignedVAT'];
            }

            return [];
        },
        currentAccountingExpense: {
            get() {
                return this.value;
            },
            set(input) {
                return this.$emit('input', input);
            }
        },
        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        }
    }
};
</script>
