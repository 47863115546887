import _ from 'lodash';
import { mapActions } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

export const accountingInvoicableFormMixin = {
    data() {
        return {
            isCreatingNew: true
        };
    },

    methods: {
        ...mapActions('incomes', ['updateAccountingInvoicable', 'createAccountingInvoicable']),
        ...mapActions(['startLoading', 'stopLoading']),

        async handleSubmitCreateDraft(invoicable) {
            this.startLoading();
            let invoicableCopy = _.cloneDeep(invoicable);

            try {
                invoicableCopy = this.setCorrectValues(invoicableCopy);

                invoicableCopy.draft = true;

                const newInvoice = await this.createAccountingInvoicable(invoicableCopy);

                notify.call(this, {
                    title: this.$i18n.t('income.created_success'),
                    text: this.$i18n.t('income.created_success')
                });

                this.handleAfterSubmit(newInvoice);
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.stopLoading();
            }
        },

        async handleSubmitPublish(invoicable) {
            this.startLoading();
            let invoicableCopy = _.cloneDeep(invoicable);

            try {
                invoicableCopy = this.setCorrectValues(invoicableCopy);

                invoicableCopy.draft = false;

                let newInvoice;

                if (invoicableCopy.id) {
                    newInvoice = await this.updateAccountingInvoicable(invoicableCopy);
                } else {
                    newInvoice = await this.createAccountingInvoicable(invoicableCopy);
                }

                notify.call(this, {
                    title: this.$i18n.t('income.created_success'),
                    text: this.$i18n.t('income.created_success')
                });

                this.handleAfterSubmit(newInvoice);
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.stopLoading();
            }
        },

        async handleSubmitEdit(invoicable) {
            this.startLoading();
            let invoicableCopy = _.cloneDeep(invoicable);

            try {
                invoicableCopy = this.setCorrectValues(invoicableCopy);

                const newInvoice = await this.updateAccountingInvoicable(invoicableCopy);

                notify.call(this, {
                    title: this.$i18n.t('income.saved_success'),
                    text: this.$i18n.t('income.saved_success')
                });

                this.handleAfterSubmit(newInvoice);
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.stopLoading();
            }
        },
        async handleSubmitCreateHistorical(invoicable) {
            this.startLoading();
            let invoicableCopy = _.cloneDeep(invoicable);

            try {
                invoicableCopy = this.setCorrectValues(invoicableCopy);

                invoicableCopy.draft = false;
                invoicableCopy.historical = true;

                const newInvoicable = await this.createAccountingInvoicable(invoicableCopy);

                notify.call(this, {
                    title: this.$i18n.t('income.created_success'),
                    text: this.$i18n.t('income.created_success')
                });

                this.handleAfterSubmit(newInvoicable);
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.stopLoading();
            }
        }
    }
};
