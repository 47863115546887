<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                </page-header>
            </template>
            <template #content>
                <div v-if="!load.loadPersonaltaxesInformation">
                    <div class="horizontal-scroll-mobile-wrapper">
                        <div class="horizontal-scroll-mobile">
                            <v-row>
                                <v-col cols>
                                    <v-card class="Widget pa-5" outlined>
                                        <data-card-multiple
                                            :data="getTotalAlreadyPaidPersonalTaxes(personaltaxesInformation)"
                                            leading="€"
                                            :trailing="undefined"
                                            :data-text="$t('personalTaxes.paid_taxes')"
                                            :loading="false"
                                        />
                                    </v-card>
                                </v-col>
                                <v-col cols>
                                    <v-card class="Widget pa-5" outlined>
                                        <data-card-multiple
                                            :data="getTotalToPayAfterDespositsPersonalTaxes(personaltaxesInformation)"
                                            leading="€"
                                            :trailing="undefined"
                                            :data-text="$t('personalTaxes.impact_personal_taxes')"
                                            :loading="false"
                                            i18n-path="tooltips.personaltaxes.disclaimer"
                                        >
                                        </data-card-multiple>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>

                    <v-row>
                        <v-col cols="12">
                            <table-card>
                                <template #content>
                                    <v-simple-table id="socialcontributions-table">
                                        <template #default>
                                            <tbody>
                                                <tr>
                                                    <td>{{ $t('personalTaxes.income') }}</td>
                                                    <td>
                                                        €{{ getTotalIncome(personaltaxesInformation) | numericFormat }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t('personalTaxes.expenses') }}</td>
                                                    <td>
                                                        €{{
                                                            getTotalAccountingExpenses(personaltaxesInformation)
                                                                | numericFormat
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t('general.verworpen_uitgaven') }}</td>
                                                    <td>€{{ personaltaxesInformation.vu | numericFormat }}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t('general.grossprofit') }}</td>
                                                    <td>
                                                        €{{ getGrossProfit(personaltaxesInformation) | numericFormat }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>{{ $t('personalTaxes.to_pay_personal_taxes') }}</td>
                                                    <td>
                                                        €{{
                                                            getTotalToPayPersonaltaxes(personaltaxesInformation)
                                                                | numericFormat
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr class="child">
                                                    <td colspan="2">
                                                        <strong>{{ $t('personalTaxes.total_exist_out_of') }}</strong>
                                                    </td>
                                                </tr>
                                                <tr class="child">
                                                    <td>{{ $t('personalTaxes.personal_taxes') }}</td>
                                                    <td>
                                                        €{{
                                                            personaltaxesInformation.tax.personalIncomeTaxEuroImpact
                                                                | numericFormat
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr class="child">
                                                    <td>{{ $t('personalTaxes.city_taxes') }}</td>
                                                    <td>
                                                        €{{ personaltaxesInformation.tax.localTaxEuro | numericFormat }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <router-link :to="{ name: 'deposits' }">{{
                                                            $t('personalTaxes.deposits')
                                                        }}</router-link>
                                                    </td>
                                                    <td>
                                                        €{{
                                                            getTotalAlreadyPaidPersonalTaxes(personaltaxesInformation)
                                                                | numericFormat
                                                        }}
                                                    </td>
                                                </tr>

                                                <tr v-for="quarter of [1, 2, 3, 4]" :key="quarter" class="child">
                                                    <td>{{ $t('general.quarter') }} {{ quarter }}</td>
                                                    <td>
                                                        €{{
                                                            personaltaxesInformation.tax.deposits[`Q${quarter}`]
                                                                | numericFormat
                                                        }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>{{ $t('general.total') }}</td>
                                                    <td>
                                                        €{{
                                                            getTotalToPayAfterDespositsPersonalTaxes(
                                                                personaltaxesInformation
                                                            ) | numericFormat
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                            </table-card>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </view-layout>

        <loader v-if="loading" />
    </div>
</template>

<script>
import DataCardMultiple from '@/components/DataCardMultiple';
import Loader from '@/components/Loader.vue';
import PageHeader from '@/components/PageHeader.vue';
import TableCard from '@/components/TableCard.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    data() {
        return {
            valid: false,
            load: {
                loadPersonaltaxesInformation: false
            },
            personaltaxesInformation: null
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData', 'currentUserType']),
        ...mapGetters('auth', ['isUserType']),
        ...mapState(['selectedYear', 'globalSettingsStudent', 'globalSettingsSelfEmployed']),
        loading() {
            return Object.values(this.load).some((loading) => loading);
        }
    },

    async created() {
        this.getCurrentUserType();
        this.loadPersonaltaxesStatement(this.selectedYear);
    },

    methods: {
        ...mapActions('auth', ['getCurrentUserType']),
        ...mapActions('taxes', ['getPersonaltaxesStatement']),
        async loadPersonaltaxesStatement(year) {
            try {
                this.load.loadPersonaltaxesInformation = true;
                this.$set(this, 'personaltaxesInformation', await this.getPersonaltaxesStatement({ year }));
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
                this.$router.push({
                    name: 'dashboard'
                });
            } finally {
                this.load.loadPersonaltaxesInformation = false;
            }
        },
        getGrossProfit(personaltaxesInformation) {
            if (!personaltaxesInformation) {
                throw new Error('personaltaxesInformation not defined');
            }
            return personaltaxesInformation.grossProfitEuro;
        },
        getTotalIncome(personaltaxesInformation) {
            if (!personaltaxesInformation) {
                throw new Error('personaltaxesInformation not defined');
            }
            return personaltaxesInformation.totalIncomeOrganisationEuroExclVAT;
        },
        getTotalAccountingExpenses(personaltaxesInformation) {
            if (!personaltaxesInformation) {
                throw new Error('personaltaxesInformation not defined');
            }
            return personaltaxesInformation.expenses;
        },
        getTotalAlreadyPaidPersonalTaxes(personaltaxesInformation) {
            if (!personaltaxesInformation) {
                throw new Error('personaltaxesInformation not defined');
            }

            return (
                personaltaxesInformation.tax.deposits.Q1 +
                personaltaxesInformation.tax.deposits.Q2 +
                personaltaxesInformation.tax.deposits.Q3 +
                personaltaxesInformation.tax.deposits.Q4
            );
        },
        getTotalToPayAfterDespositsPersonalTaxes(personaltaxesInformation) {
            if (!personaltaxesInformation) {
                throw new Error('personaltaxesInformation not defined');
            }

            return (
                personaltaxesInformation.tax.personalIncomeTaxEuroImpact +
                personaltaxesInformation.tax.localTaxEuro -
                this.getTotalAlreadyPaidPersonalTaxes(personaltaxesInformation)
            );
        },
        getTaxfreeUpperlimit(taxrates) {
            if (!Array.isArray(taxrates)) {
                throw new Error('taxrates is not an array');
            }

            const taxFree = taxrates.find((_taxrate) => {
                return _taxrate.taxToPayPercentage === 0;
            });

            if (!taxFree) {
                return 0;
            }

            return taxFree.upperLimitEuro;
        },
        getTotalToPayPersonaltaxes(personaltaxesInformation) {
            if (!personaltaxesInformation) {
                throw new Error('personaltaxesInformation not defined');
            }

            return personaltaxesInformation.tax.personalIncomeTaxEuroImpact + personaltaxesInformation.tax.localTaxEuro;
        }
    },

    components: {
        ViewLayout,
        PageHeader,
        Loader,
        TableCard,
        DataCardMultiple
    }
};
</script>

<style lang="scss" scoped>
#socialcontributions-table table {
    tr td:first-child {
        width: 75%;
    }
    tr td:last-child {
        width: 25%;
    }

    tr.child td:first-child {
        padding-left: 50px;
    }
    tr.child td {
        font-size: 0.75em;
    }
}
</style>
