<template>
    <base-bottom-sheet
        v-if="isViewingCurrentRasterBookingsOpen && currentRasterWithBookings"
        v-model="isViewingCurrentRasterBookingsOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
        class="add-account-sheet"
    >
        <template #header>
            <bottom-sheet-header>
                {{
                    $t('accountantTerms.watch_rasters', {
                        raster: currentRasterWithBookings.raster
                    })
                }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="currentRasterWithBookings.data"
                            sort-by="createdAt"
                            sort-desc
                        >
                            <template #[`item.bookableType`]="{ item }">
                                {{ getBookableTypeTitle(item) }}
                            </template>

                            <template #[`item.date`]="{ item }">
                                {{ item.date | formatDate }}
                            </template>

                            <template #[`item.number`]="{ item }">
                                <template v-if="item.bookable">
                                    <template v-if="item.bookableType === 'invoice'">
                                        {{ item.bookable.invoiceNumber }}
                                    </template>
                                    <template v-if="item.bookableType === 'creditnote'">
                                        {{ item.bookable.creditnoteNumber }}
                                    </template>
                                    <template v-if="item.bookableType === 'expense'">
                                        {{ item.bookable.invoiceNumber }}
                                    </template>
                                </template>
                            </template>

                            <template #[`item.amount`]="{ item }">
                                {{ item.amount | numericFormat | addEuroSign }}
                            </template>

                            <template #[`item.actions`]="{ item }">
                                <v-menu v-if="item.bookableType === 'transfer'" bottom left>
                                    <template #activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-list dense>
                                            <v-list-item
                                                @click.stop="
                                                    {
                                                        dialog = true;
                                                        selectedBookingId = item.id;
                                                    }
                                                "
                                            >
                                                <v-list-item-title class="red--text">
                                                    {{ $t('general.delete') }}
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
            <loader v-if="loading" />

            <confirmation-dialog
                v-if="dialog"
                v-model="dialog"
                :title="$t('general.delete')"
                :message="$t('general.confirm_delete_message')"
                :negative-button="$t('general.cancel')"
                :positive-button="$t('general.delete')"
                @click-positive="removeBooking"
                @click-negative="dialog = false"
            />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
import { mapFields } from 'vuex-map-fields';
// --- Components ---
import BottomSheetHeader from '@/components/BottomSheets/BottomSheetHeader';
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import Loader from '@/components/Loader';

// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Actions ---
import { mapActions } from 'vuex';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';

export default {
    components: {
        BottomSheetHeader,
        ConfirmationDialog,
        Loader
    },
    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false,
            dialog: false,
            selectedBookingId: null,
            errorMessage: '',
            headers: [
                {
                    text: this.$t('general.type'),
                    align: 'start',
                    value: 'bookableType'
                },
                {
                    text: this.$t('general.date'),
                    value: 'date'
                },
                {
                    text: this.$t('general.number'),
                    value: 'number'
                },
                {
                    text: this.$t('general.amount_money'),
                    value: 'amount'
                },
                {
                    text: this.$t('general.actions'),
                    value: 'actions'
                }
            ]
        };
    },
    computed: {
        ...mapFields('bottomSheets', ['isViewingCurrentRasterBookingsOpen', 'currentRasterWithBookings'])
    },

    methods: {
        ...mapActions('auth', ['createUser', 'updateUser']),
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('bookings', ['getBookingsFromQuarterAndRaster', 'deleteBooking']),
        getBookableTypeTitle(item) {
            if (item.bookableType === 'invoice') {
                return this.$t(`general.salesInvoice`);
            }
            return this.$t(`general.${item.bookableType}`);
        },
        async removeBooking() {
            this.startLoading();
            try {
                await this.deleteBooking(this.selectedBookingId);
                notify.call(this, {
                    title: this.$t('income.deleted_invoice_succesfuly'),
                    text: this.$t('income.deleted_invoice_succesfuly')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.$emit('deleted-item');
                this.dialog = false;
            }
        }
    }
};
</script>
