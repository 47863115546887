
import TableCard from '@/components/TableCard.vue';

import Vue, { PropOptions } from 'vue';

import { Grootboekrekening, GrootboekrekeningBookingViewModel } from 'dexxter-types';

import * as domainCalculations from '../domain/calculations';

export default Vue.extend({
    components: {
        TableCard
    },

    props: {
        expenseGrootboekrekeningen: {
            required: true
        } as PropOptions<Grootboekrekening[]>,
        incomeGrootboekrekeningen: {
            required: true
        } as PropOptions<Grootboekrekening[]>,

        totalIncomeGrootboekrekeningen: {
            required: true
        } as PropOptions<number>,

        totalExpenseGrootboekrekeningen: {
            required: true
        } as PropOptions<number>,

        bookings: {
            required: true
        } as PropOptions<GrootboekrekeningBookingViewModel[]>,

        resultText: {
            required: true
        } as PropOptions<string>
    },

    methods: {
        openBookings(grootboekrekening: Grootboekrekening): void {
            this.$emit('openBookings', grootboekrekening);
        },
        openDepreciationsBookings() {
            this.$emit('openDepreciationbookings');
        },
        openInventoryMutationBookings() {
            this.$emit('openInventoryMutationBookings');
        },
        getTotalFromGrootboekrekening(grootboekrekening: Grootboekrekening): number {
            return domainCalculations.getTotalFromGrootboekrekening(this.bookings, grootboekrekening);
        },
        getTotalFromGrootboekrekeningSubcategory(grootboekrekening: Grootboekrekening['children'][number]): number {
            return domainCalculations.getTotalFromGrootboekrekeningSubcategory(this.bookings, grootboekrekening);
        }
    }
});
