var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('view-layout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('page-header',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.title))+" "+_vm._s(_vm.selectedYear)+" ")]},proxy:true}])})]},proxy:true},{key:"content",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('h3',[_vm._v(_vm._s(_vm.$t('dependentOn.title')))]),(_vm.globalSettingsStudent)?_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-simple-table',{staticClass:"calculationTable",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('dependentOn.explentation'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t('dependentOn.amount')))])])]),_c('tbody',[_c('tr',[_c('td',{domProps:{"innerHTML":_vm._s(
                                                        _vm.$t('dependentOn.belastbaar_inkomen_zelfstandige_activiteit')
                                                    )}}),_c('td',[_vm._v(" "+_vm._s(_vm._f("addEuroSign")(_vm._f("numericFormat")(_vm.dependendOnNumbers.studentNettoIncome)))+" ")])]),_c('tr',[_c('td',{domProps:{"innerHTML":_vm._s(_vm.$t('dependentOn.belastbaarloon_explanation'))}}),_c('td',[_c('number-field',{model:{value:(
                                                            _vm.dependendOnNumbers.tenLaste_belastbaarLoonAndereVerdiensten
                                                        ),callback:function ($$v) {_vm.$set(_vm.dependendOnNumbers, "tenLaste_belastbaarLoonAndereVerdiensten", $$v)},expression:"\n                                                            dependendOnNumbers.tenLaste_belastbaarLoonAndereVerdiensten\n                                                        "}})],1)]),_c('tr',[_c('td',[_vm._v(" Totaal ("),_c('b',[_vm._v("op de achtergrond tellen we de eerste "+_vm._s(_vm._f("addEuroSign")(_vm._f("numericFormat")(_vm.globalSettingsStudent.dependOnCalculation .ignoreTotalIncomeAmount)))+" niet mee")]),_vm._v(") ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("addEuroSign")(_vm._f("numericFormat")(!_vm.hasAbove0Total ? 0 : (_vm.dependendOnNumbers.studentNettoIncome + _vm.dependendOnNumbers.tenLaste_belastbaarLoonAndereVerdiensten - _vm.globalSettingsStudent.dependOnCalculation .ignoreTotalIncomeAmount))))+" ")])]),_c('tr',[_c('td',{domProps:{"innerHTML":_vm._s(_vm.$t('dependentOn.leefloon_explanation'))}}),_c('td',[_c('number-field',{model:{value:(_vm.dependendOnNumbers.tenLaste_leefloonEnAnderen),callback:function ($$v) {_vm.$set(_vm.dependendOnNumbers, "tenLaste_leefloonEnAnderen", $$v)},expression:"dependendOnNumbers.tenLaste_leefloonEnAnderen"}})],1)]),_c('tr',[_c('td',{domProps:{"innerHTML":_vm._s(
                                                        _vm.$t('dependentOn.alimentatie_explentation', {
                                                            amount: _vm.$options.filters.addEuroSign(
                                                                _vm.$options.filters.numericFormat(
                                                                    _vm.globalSettingsStudent.dependOnCalculation
                                                                        .alimentationMoneyIgnore
                                                                )
                                                            )
                                                        })
                                                    )}}),_c('td',[_c('number-field',{model:{value:(_vm.dependendOnNumbers.tenLaste_alimentatiegeld),callback:function ($$v) {_vm.$set(_vm.dependendOnNumbers, "tenLaste_alimentatiegeld", $$v)},expression:"dependendOnNumbers.tenLaste_alimentatiegeld"}})],1)]),_c('tr',[_c('td',{domProps:{"innerHTML":_vm._s(_vm.$t('dependentOn.situatation_parents'))}}),_c('td',[_c('v-select',{attrs:{"label":"Situatie ouders","item-text":"label","item-value":"value","hide-details":"auto","outlined":"","dense":"","required":"","clearable":"","items":_vm.dependentOnItems,"rules":_vm.requiredRules},model:{value:(_vm.dependendOnNumbers.tenLaste_situatieOuders),callback:function ($$v) {_vm.$set(_vm.dependendOnNumbers, "tenLaste_situatieOuders", $$v)},expression:"dependendOnNumbers.tenLaste_situatieOuders"}})],1)]),_c('tr',[_c('td'),_c('td',[_vm._v("€"+_vm._s(_vm._f("numericFormat")(_vm.nettoBestaandsMinimum)))])])])]},proxy:true}],null,false,1636125057)}),_c('submit-button',{staticClass:"mt-3",attrs:{"disabled":!_vm.valid},on:{"click":_vm.startDependentOnCalculation}},[_vm._v(_vm._s(_vm.$t('dependentOn.calculate')))])],1):_vm._e()]},proxy:true}])})],1)],1)]},proxy:true}])}),(_vm.resultDialog)?_c('confirmation-dialog',{attrs:{"title":_vm.$t('dependentOn.resultDialog.title'),"message":_vm.resultDialogText,"loading":_vm.loading,"positive-button":_vm.$t('general.confirm'),"positive-color":"green"},on:{"click-positive":function($event){_vm.resultDialog = false}},model:{value:(_vm.resultDialog),callback:function ($$v) {_vm.resultDialog=$$v},expression:"resultDialog"}}):_vm._e(),(_vm.loading)?_c('loader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }