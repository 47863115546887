<template>
    <div class="billed_income-table">
        <card v-if="canShowPartOne" padding="32" class="mb-5">
            <template #header>
                <card-header heading-tag="h3">{{ $t('personalTaxes.part_one') }}</card-header>
            </template>
            <template #default>
                <v-simple-table id="personaltax-information-table-part1">
                    <template #default>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="2">
                                    {{ $t('personalTaxes.field') }} XII ({{ $t('personalTaxes.deposits') }})
                                </th>
                            </tr>
                            <tr
                                v-for="raster of Object.keys(personalTaxRasters.part1.XII)"
                                :key="raster"
                                class="same-width-cols"
                            >
                                <td>
                                    <tooltip
                                        :title="raster"
                                        :content="$t(`tooltips.personalTax.raster${raster}.content`)"
                                        ><template slot="activator">{{ fieldText(raster) }}</template></tooltip
                                    >
                                </td>
                                <td>{{ convertToLocaleValuta(personalTaxRasters.part1.XII[raster]) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </card>

        <card class="mb-3" padding="32">
            <template #header>
                <card-header heading-tag="h3">
                    {{ $t('personalTaxes.part_two') }}
                </card-header>
            </template>
            <template #default>
                <v-simple-table id="personaltax-information-table-part2">
                    <template #default>
                        <tbody>
                            <tr>
                                <th scope="col" colspan="2">{{ $t('personalTaxes.field') }} XIV</th>
                            </tr>
                            <tr class="same-width-cols">
                                <td scope="col" colspan="2">
                                    <ol>
                                        <li>
                                            {{ $t('personalTaxes.describe_activity') }}
                                        </li>
                                        <li v-if="hasCompanyNumber">
                                            {{ companyNumberTextDoesExist }}
                                        </li>
                                        <li v-else>
                                            <b>{{ companyNumberTextDoesNotExist }}</b>
                                        </li>
                                    </ol>
                                </td>
                            </tr>

                            <template v-if="personalTaxRasters.part2.XVII">
                                <tr>
                                    <th scope="col" colspan="2">{{ $t('personalTaxes.field') }} XVII</th>
                                </tr>
                                <tr class="same-width-cols">
                                    <td>
                                        <tooltip title="1600" :content="$t(`tooltips.personalTax.raster1600.content`)"
                                            ><template slot="activator">{{ fieldText(1600) }}</template></tooltip
                                        >
                                    </td>
                                    <td>
                                        {{ convertToLocaleValuta(personalTaxRasters.part2.XVII[1600]) }}
                                    </td>
                                </tr>
                                <tr class="same-width-cols">
                                    <td>
                                        <tooltip title="1632" :content="$t(`tooltips.personalTax.raster1632.content`)"
                                            ><template slot="activator">{{ fieldText(1632) }}</template></tooltip
                                        >
                                    </td>
                                    <td>
                                        {{ convertToLocaleValuta(personalTaxRasters.part2.XVII[1632]) }}
                                    </td>
                                </tr>
                                <tr class="same-width-cols">
                                    <td>
                                        <tooltip title="1606" :content="$t(`tooltips.personalTax.raster1606.content`)"
                                            ><template slot="activator">{{ fieldText(1606) }}</template></tooltip
                                        >
                                    </td>
                                    <td>
                                        {{ convertToLocaleValuta(personalTaxRasters.part2.XVII[1606]) }}
                                    </td>
                                </tr>
                                <tr v-if="personalTaxRasters.part2.XVII[1617] != null" class="same-width-cols">
                                    <td>
                                        <tooltip title="1617" :content="$t(`tooltips.personalTax.raster1617.content`)"
                                            ><template slot="activator">{{ fieldText(1617) }}</template></tooltip
                                        >
                                    </td>
                                    <td>
                                        {{ convertToLocaleValuta(personalTaxRasters.part2.XVII[1617]) }}
                                    </td>
                                </tr>
                                <tr v-if="personalTaxRasters.part2.XVII[1627]" class="same-width-cols">
                                    <td>
                                        <tooltip title="1627" :content="$t(`tooltips.personalTax.raster1627.content`)"
                                            ><template slot="activator">{{ fieldText(1627) }}</template></tooltip
                                        >
                                    </td>
                                    <td>
                                        {{ personalTaxRasters.part2.XVII[1627] | formatDate }}
                                    </td>
                                </tr>
                            </template>
                            <template v-if="personalTaxRasters.part2.XVIII">
                                <tr>
                                    <th scope="col" colspan="2">{{ $t('personalTaxes.field') }} XVIII</th>
                                </tr>
                                <tr class="same-width-cols">
                                    <td>
                                        <tooltip title="1650" :content="$t(`tooltips.personalTax.raster1650.content`)"
                                            ><template slot="activator">{{ fieldText(1650) }}</template></tooltip
                                        >
                                    </td>
                                    <td>
                                        {{ convertToLocaleValuta(personalTaxRasters.part2.XVIII[1650]) }}
                                    </td>
                                </tr>
                                <tr class="same-width-cols">
                                    <td>
                                        <tooltip title="1656" :content="$t(`tooltips.personalTax.raster1656.content`)"
                                            ><template slot="activator">{{ fieldText(1656) }}</template></tooltip
                                        >
                                    </td>
                                    <td>
                                        {{ convertToLocaleValuta(personalTaxRasters.part2.XVIII[1656]) }}
                                    </td>
                                </tr>
                                <tr class="same-width-cols">
                                    <td>
                                        <tooltip title="1657" :content="$t(`tooltips.personalTax.raster1657.content`)"
                                            ><template slot="activator">{{ fieldText(1657) }}</template></tooltip
                                        >
                                    </td>
                                    <td>
                                        {{ convertToLocaleValuta(personalTaxRasters.part2.XVIII[1657]) }}
                                    </td>
                                </tr>
                                <tr v-if="personalTaxRasters.part2.XVIII[1668] != null" class="same-width-cols">
                                    <td>
                                        <tooltip title="1668" :content="$t(`tooltips.personalTax.raster1668.content`)"
                                            ><template slot="activator">{{ fieldText(1668) }}</template></tooltip
                                        >
                                    </td>
                                    <td>
                                        {{ convertToLocaleValuta(personalTaxRasters.part2.XVIII[1668]) }}
                                    </td>
                                </tr>
                                <tr v-if="personalTaxRasters.part2.XVIII[1672]" class="same-width-cols">
                                    <td>
                                        <tooltip title="1672" :content="$t(`tooltips.personalTax.raster1672.content`)"
                                            ><template slot="activator">{{ fieldText(1672) }}</template></tooltip
                                        >
                                    </td>
                                    <td>
                                        {{ personalTaxRasters.part2.XVIII[1672] | formatDate }}
                                    </td>
                                </tr>
                            </template>
                            <template v-if="personalTaxRasters.part2.XXII">
                                <tr>
                                    <th scope="col" colspan="2">{{ $t('personalTaxes.field') }} XXII</th>
                                </tr>
                                <tr class="same-width-cols">
                                    <td>1552</td>
                                    <td>
                                        {{ $t('personalTaxes.1552_info') }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </card>
    </div>
</template>

<script>
import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';
import { mapState } from 'vuex';

export default {
    props: {
        personalTaxRasters: {
            type: Object,
            required: true
        },
        wettelijkSamenwonend: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        canShowPartOne() {
            return Boolean(this.personalTaxRasters.part1);
        },
        companyNumber() {
            return this.currentUserData.company.number;
        },
        hasCompanyNumber() {
            return this.currentUserData.company.number === false;
        },
        companyNumberTextDoesExist() {
            return this.$t('personalTaxes.company_number_exists', {
                companyNumber: this.companyNumber
            });
        },
        companyNumberTextDoesNotExist() {
            return this.$t('personalTaxes.company_number_does_not_exists');
        }
    },

    methods: {
        convertToLocaleValuta,
        fieldText(field) {
            if (!this.wettelijkSamenwonend) {
                return field;
            } else {
                return this.transformLabelWettelijkSamenwonend(field);
            }
        },
        transformLabelWettelijkSamenwonend(field) {
            return `${field} ${this.$t('general.of').toLowerCase()} ${parseInt(field) + 1000}`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

#personaltax-information-table-part1,
#personaltax-information-table-part2 {
    tr.same-width-cols td:first-child {
        width: 20%;
    }

    th,
    td {
        padding: $space-16 0;
    }
}

.personal-taxes-information {
    margin-top: 110px;

    &.has-submitted-personal-taxes {
        margin-top: 0;
    }
}

.personal-taxes-status {
    :deep(.alert) {
        margin-bottom: $space-24;
    }
}
</style>
