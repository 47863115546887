// import { BELGIAN_COMPANY } from '@/config/constants';
// import _ from 'lodash';
import { INVESTEMENTS } from '@/config/constants';
import { DependencyGraph } from '@/helpers/dependencyCodeMixin';
import dayjs from 'dayjs';
import {
    AccountingExpenseModel,
    newAccountingExpense,
    newAccountingExpenseDepreciationModel
} from '../../models/accountingexpense';

export function getModelMutateFunction<T>(depGraph: DependencyGraph<T>) {
    return function mutateModel(_property: string, model: any, newValue: any, oldValue: any, props: any) {
        return depGraph[_property].onChange(model, newValue, oldValue, props);
    };
}

export function createDependencyGraphAccountingExpense(): DependencyGraph<AccountingExpenseModel> {
    return {
        documentDate: {
            onChange: (model: AccountingExpenseModel, newValue: AccountingExpenseModel['documentDate']) => {
                if (!dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                    return;
                }

                model.date = newValue;

                if (model.depreciation) {
                    model.depreciation.dateStart = newValue;
                }
            }
        },
        btwMedecontractant: {
            onChange: (model: AccountingExpenseModel) => {
                model.amountEuroExcl0VAT = null;
                model.amountEuroExcl6VAT = null;
                model.amountEuroExcl12VAT = null;
                model.amountEuroExcl21VAT = null;
            }
        },
        supplierChargedVAT: {
            onChange: (
                model: AccountingExpenseModel,
                newValue: AccountingExpenseModel['supplierChargedVAT'],
                oldValue: AccountingExpenseModel['supplierChargedVAT'],
                props: any
            ) => {
                model.amountEuroExcl0VAT = null;
                model.amountEuroExcl6VAT = null;
                model.amountEuroExcl12VAT = null;
                model.amountEuroExcl21VAT = null;

                // Set this to 0, because some fields depend on it (deductability step when updating an invoice)
                model.amountEuroExclVAT = null;
                model.amountEuroInclVAT = null;

                function resetExpenseCategory() {
                    model.businessPercentage = null;
                    model.VATDeductiblePercentage = null;
                    model.expensecategory = newAccountingExpense({
                        id: model.id,
                        vatLiable: props.isVATLiable
                    }).expensecategory;
                    model.expensecategoryId = null;
                }
                resetExpenseCategory();
            }
        },
        supplier: {
            onChange: (
                model: AccountingExpenseModel,
                newValue: AccountingExpenseModel['supplier'],
                oldValue: AccountingExpenseModel['supplier'],
                props: any
            ) => {
                if (newValue === oldValue && newValue !== null) {
                    // eslint-disable-next-line no-console
                    console.log(newValue, oldValue);
                    // eslint-disable-next-line no-console
                    console.error('Updating unjustified!');
                }

                const newObj = newAccountingExpense({
                    id: model.id,
                    vatLiable: props.isVATLiable
                });

                // Change everything, except for the new supplier that is been set
                Object.keys(newObj).forEach((_key) => {
                    const tmpKey: keyof AccountingExpenseModel = _key as any;

                    if (
                        tmpKey === 'supplier' ||
                        tmpKey === 'date' ||
                        tmpKey === 'dueDate' ||
                        tmpKey === 'documentDate' ||
                        tmpKey === 'isCreditnote'
                    ) {
                        return;
                    } else {
                        (model as any)[_key] = (newObj as any)[_key];
                    }
                });
            }
        },
        expensecategory: {
            onChange: (model: AccountingExpenseModel, newValue: AccountingExpenseModel['expensecategory']) => {
                if (newValue.forTypeOfService === INVESTEMENTS) {
                    model.depreciation = newAccountingExpenseDepreciationModel();
                    model.isDepreciation = true;
                } else {
                    model.depreciation = null;
                    model.isDepreciation = false;
                }

                function setVatDeductibleAndBusinessPercentage() {
                    if (model.recordInVATDeclaration) {
                        model.VATDeductiblePercentage = newValue.VATDeductible;
                    } else {
                        model.VATDeductiblePercentage = 0;
                    }
                    model.businessPercentage = newValue.businessPercentage;
                }

                function setTypeOfService() {
                    model.expensecategory.forTypeOfService = newValue.forTypeOfService;
                }

                setVatDeductibleAndBusinessPercentage();
                setTypeOfService();
                model.carId = null;
            }
        },
        recordInVATDeclaration: {
            onChange: (
                model: AccountingExpenseModel,
                newValue: AccountingExpenseModel['recordInVATDeclaration'],
                oldValue: AccountingExpenseModel['recordInVATDeclaration'],
                props: any
            ) => {
                const newObj = newAccountingExpense({
                    id: model.id,
                    vatLiable: props.isVATLiable
                });

                // Change everything, except for the new supplier that is been set
                Object.keys(newObj).forEach((_key) => {
                    const tmpKey: keyof AccountingExpenseModel = _key as any;

                    if (
                        tmpKey === 'reference' ||
                        tmpKey === 'supplier' ||
                        tmpKey === 'date' ||
                        tmpKey === 'dueDate' ||
                        tmpKey === 'documentDate' ||
                        tmpKey === 'isCreditnote' ||
                        tmpKey === 'btwMedecontractant' ||
                        tmpKey === 'amountEuroExclVAT' ||
                        tmpKey === 'amountEuroInclVAT' ||
                        tmpKey === 'recordInVATDeclaration'
                    ) {
                        return;
                    } else {
                        (model as any)[_key] = (newObj as any)[_key];
                    }
                });
            }
        }
    } as Record<keyof AccountingExpenseModel, any>;
}
