var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('number-field',{attrs:{"id":"amountEuroTotalInclVAT","value":_vm.currentAccountingExpense.amountEuroInclVAT,"label":_vm.$t('general.total'),"outlined":"","prefix":"€","allow-negative":true,"dense":"","rules":_vm.currentAccountingExpense.isCreditnote
                    ? _vm.numberRulesNotRequired
                    : _vm.numberRulesNotRequiredNegativePossible},on:{"change":_vm.changeValues}})],1),(_vm.canShowAmountEuroExcl0VATField)?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('number-field',{attrs:{"id":"amountEuroExcl0VAT","label":_vm.$t('general.amount_excl_vat_percentage_placeholder', {
                    percentage: 0
                }),"outlined":"","persistent-hint":"","prefix":"€","allow-negative":true,"dense":"","rules":_vm.currentAccountingExpense.isCreditnote
                    ? _vm.numberRulesNotRequired
                    : _vm.numberRulesNotRequiredNegativePossible},model:{value:(_vm.currentAccountingExpense.amountEuroExcl0VAT),callback:function ($$v) {_vm.$set(_vm.currentAccountingExpense, "amountEuroExcl0VAT", $$v)},expression:"currentAccountingExpense.amountEuroExcl0VAT"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('number-field',{attrs:{"id":"amountEuroExcl6VAT","label":_vm.$t('general.amount_excl_vat_percentage_placeholder', {
                    percentage: 6
                }),"outlined":"","persistent-hint":"","prefix":"€","allow-negative":true,"dense":"","rules":_vm.currentAccountingExpense.isCreditnote
                    ? _vm.numberRulesNotRequired
                    : _vm.numberRulesNotRequiredNegativePossible},model:{value:(_vm.currentAccountingExpense.amountEuroExcl6VAT),callback:function ($$v) {_vm.$set(_vm.currentAccountingExpense, "amountEuroExcl6VAT", $$v)},expression:"currentAccountingExpense.amountEuroExcl6VAT"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('number-field',{attrs:{"id":"amountEuroExcl12VAT","label":_vm.$t('general.amount_excl_vat_percentage_placeholder', {
                    percentage: 12
                }),"outlined":"","persistent-hint":"","prefix":"€","allow-negative":true,"dense":"","rules":_vm.currentAccountingExpense.isCreditnote
                    ? _vm.numberRulesNotRequired
                    : _vm.numberRulesNotRequiredNegativePossible},model:{value:(_vm.currentAccountingExpense.amountEuroExcl12VAT),callback:function ($$v) {_vm.$set(_vm.currentAccountingExpense, "amountEuroExcl12VAT", $$v)},expression:"currentAccountingExpense.amountEuroExcl12VAT"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('number-field',{attrs:{"id":"amountEuroExcl21VAT","label":_vm.$t('general.amount_excl_vat_percentage_placeholder', {
                    percentage: 21
                }),"outlined":"","persistent-hint":"","prefix":"€","allow-negative":true,"dense":"","rules":_vm.currentAccountingExpense.isCreditnote
                    ? _vm.numberRulesNotRequired
                    : _vm.numberRulesNotRequiredNegativePossible},model:{value:(_vm.currentAccountingExpense.amountEuroExcl21VAT),callback:function ($$v) {_vm.$set(_vm.currentAccountingExpense, "amountEuroExcl21VAT", $$v)},expression:"currentAccountingExpense.amountEuroExcl21VAT"}})],1),_c('div',{attrs:{"data-id":"videoPopupsForEUSupplierVatLiable"}},_vm._l((_vm.videoTooltips),function(video){return _c('v-col',{key:video,attrs:{"cols":"12","sm":"12"}},[_c('tooltip-video-wrapper',{attrs:{"i18n-path":video}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }