<template>
    <div class="as_employee_income-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="12">
                <pagination-search v-model="query.q" />
            </v-col>
        </v-row>
        <pagination-data-table
            v-if="jobstudentIncomes.data"
            ref="paginationDataTable"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="jobstudentIncomes"
            sort-by="date"
            custom-thing
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.year`]="{ item }">
                {{ item.year }}
            </template>

            <template #[`item.grossAmountEuro`]="{ item }">
                € {{ item.grossAmountEuro || 0 | numericFormat }}
            </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item :to="/edit-income-as-employee/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click.stop="
                                    {
                                        dialog = true;
                                        selectedIncomeId = item.id;
                                    }
                                "
                            >
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            v-bind="{
                title: $t('general.delete'),
                message: $t('general.confirm_delete_message'),
                loading: loading,
                negativeButton: $t('general.cancel'),
                positiveButton: $t('general.delete')
            }"
            @click-positive="removeIncome"
            @click-negative="dialog = false"
            @click-outside="dialog = false"
        />
    </div>
</template>

<script>
// --- State ---
import { mapActions } from 'vuex';
// --- Components ---
import PaginationSearch from '../PaginationSearch.vue';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { filteringMixin } from '../../mixins/filtering';
import PaginationDataTable from './PaginationDataTable.vue';

export default {
    components: {
        ConfirmationDialog,
        PaginationSearch,
        PaginationDataTable
    },
    mixins: [filteringMixin],
    data() {
        const sortableColumns = ['employername', 'gross_income'];

        return {
            headers: [
                {
                    text: this.$t('general.employer'),
                    value: 'employername',
                    sortable: sortableColumns.includes('employername')
                },
                {
                    text: this.$t('general.description'),
                    value: 'description',
                    sortable: sortableColumns.includes('description')
                },
                {
                    text: this.$t('general.year'),
                    value: 'year',
                    sortable: sortableColumns.includes('year')
                },
                {
                    text: this.$t('accountantTerms.gross_income'),
                    value: 'grossAmountEuro',
                    sortable: sortableColumns.includes('gross_income')
                },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            selectedIncomeId: '',
            dialog: false,
            loading: false,
            jobstudentIncomes: {
                data: []
            },
            sortableColumns
        };
    },

    watch: {
        newDailyReceiptEvents: {
            handler: function () {
                this.wrapperFunction();
            },
            deep: true
        }
    },

    created() {
        this.wrapperFunction();
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('incomes', ['getJobstudentIncomePagination', 'deleteJobstudentIncome']),
        async removeIncome() {
            this.loading = true;
            try {
                await this.deleteJobstudentIncome(this.selectedIncomeId);
                await this.wrapperFunction();
                notify.call(this, {
                    title: this.$t('general.succesfuly_deleted'),
                    text: this.$t('general.succesfuly_deleted')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        },
        async wrapperFunction() {
            this.startLoading();
            this.loading = true;
            this.jobstudentIncomes = await this.getJobstudentIncomePagination({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loading = false;
            this.stopLoading();
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        }
    }
};
</script>
