
import EmptyState from '@/components/Icons/EmptyState.vue';
import QuestionMarksVisual from '@/components/Icons/QuestionMarksVisual.vue';
import { LearningcenterItem } from '@/components/LearningCenterDrawer.vue';
import { searchItem } from '@/helpers/learningcenterItems';
import { MapStateToComputed } from '@/helpers/types';
import { sendGetLearningcenteritemByLanguageRequest } from '@/services/learningcenter';
import { InitializedUseraccountAuthState } from '@/store/modules/auth';
import LearningCenterCard from '@/views/learningCenter/components/LearningCenterCard.vue';
import { UserBookYearType } from '@dexxter/accountancy-tools';
import { mapState as mapStatePinia } from 'pinia';
import Vue from 'vue';
import { mapState } from 'vuex';
import { useTaxStore } from '../../store/pinia/tax/tax.store';
import LearningcenterSearch from './components/LearningcenterSearch.vue';

export default Vue.extend({
    name: 'LearningCenter',
    components: { EmptyState, LearningCenterCard, QuestionMarksVisual, LearningcenterSearch },
    data() {
        return {
            searchQuery: '' as string,
            allLearningCenterItems: [] as LearningcenterItem[],
            refreshKey: true
        };
    },
    computed: {
        ...(mapState(['selectedYear']) as MapStateToComputed<{ selectedYear: number }>),
        ...mapStatePinia(useTaxStore, ['tax']),
        ...(mapState('auth', ['currentUserData']) as MapStateToComputed<InitializedUseraccountAuthState>),
        items(): any[] {
            this.refreshKey;
            let _items = this.allLearningCenterItems
                .filter(({ translations }) => {
                    return Array.isArray(translations) && translations.length > 0;
                })
                .map(({ type, translations, VATtypes, usertypes, locations }) => {
                    return {
                        id: translations[0].id,
                        url: translations[0].url,
                        type,
                        title: translations[0].title,
                        summary: translations[0].description,
                        tags: translations[0].tags.split(' '),
                        userType: usertypes.map((type) => type.name),
                        VATType: VATtypes.map((type) => type.name),
                        locations: locations.map((location) => location.name)
                    };
                });
            if (this.searchQuery !== '') {
                _items = searchItem(
                    _items,
                    this.searchQuery,
                    !this.tax[this.selectedYear as number]?.statuutQ1
                        ? [this.currentUserData.userType]
                        : [
                              this.tax[this.selectedYear as number]?.statuutQ1 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ2 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ3 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ4 as UserBookYearType
                          ],
                    this.currentUserData.settings.vatLiable
                );
            }

            return _items;
        }
    },
    async created() {
        this.allLearningCenterItems = await sendGetLearningcenteritemByLanguageRequest(
            this.currentUserData.userinformation.language
        );
    },
    methods: {
        invalidateItems() {
            this.refreshKey = !this.refreshKey;
        }
    }
});
