// --- Package ---
import QRCode from 'qrcode';

export async function GenerateQrCode(
    canvasElement: HTMLElement | null,
    text: string,
    errorCorrectionLevel?: QRCode.QRCodeErrorCorrectionLevel,
    margin?: number,
    scale?: number
): Promise<void> {
    return QRCode.toCanvas(canvasElement, text, {
        errorCorrectionLevel,
        margin,
        scale
    });
}
