<template>
    <div class="v-stepper-wrapper">
        <v-stepper-step :editable="canEditStepper" :complete="stepper > 5" step="5" data-action="open-investements">
            <base-icon-right i18n-path="tooltips.addExpense.investements.year">
                {{ $t('addExpense.investement') }}
            </base-icon-right>
        </v-stepper-step>
        <v-stepper-content step="5" :class="{ 'stepper-content-active': stepper == 5 }">
            <v-row>
                <v-col id="typeOfDepreciationSelect" cols="6">
                    <v-select
                        v-model="currentAccountingExpense.depreciation.category"
                        :items="depreciationTypes"
                        :label="$t('general.type')"
                        item-text="label"
                        item-value="id"
                        outlined
                        dense
                        persistent-hint
                        return-object
                        hide-details="auto"
                        required
                        :rules="requiredRules"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <date-field
                        v-model="currentAccountingExpense.depreciation.dateStart"
                        data-form="depreciationStartDate"
                        :label="$t('depreciation.date_start')"
                        outlined
                        dense
                        clearable
                        persistent-hint
                        required
                    />
                </v-col>

                <v-col cols="12">
                    <select-car v-if="canShowSelectedCar" v-model="currentAccountingExpense.depreciation.carId" />
                </v-col>

                <v-col cols="12" order="12">
                    <number-field
                        id="numberOfYears"
                        v-model="currentAccountingExpense.depreciation.numberOfYears"
                        :label="$t('accountantTerms.depreciation_years')"
                        outlined
                        dense
                        persistent-hint
                        :suffix="$t('general.year').toLowerCase()"
                        required
                        :value-as-integer="true"
                        :rules="numberRules"
                    />
                </v-col>

                <v-col cols="12" sm="12" order="12">
                    <text-area
                        id="investementDescription"
                        v-model="currentAccountingExpense.depreciation.description"
                        data-form="investementDescription"
                        label="Geef een beschrijving van je investering"
                        outlined
                        dense
                        persistent-hint
                        required
                        :rules="requiredRules"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" order="10">
                    <v-btn :disabled="!canClickNext" color="primary" small @click="$emit('next')">{{
                        $t('general.next')
                    }}</v-btn>
                </v-col>
            </v-row>
        </v-stepper-content>
    </div>
</template>

<script>
import { INVESTEMENTS } from '@/config/constants';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { getAllDepreciationfichecategoriesRequest } from '@/services/depreciationfichecategories';
import selectCar from '../addAccountingExpense/components/select-car.vue';

export default {
    components: { selectCar },
    mixins: [formRulesMixin],
    props: {
        value: {},
        stepper: {
            required: true
        },
        canEditStepper: {
            type: Boolean,
            required: true
        },
        canClickNext: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            depreciationfichecategories: [],
            INVESTEMENTS
        };
    },
    computed: {
        canShowSelectedCar() {
            if (!this.currentAccountingExpense.depreciation || !this.currentAccountingExpense.depreciation.category) {
                return false;
            }

            return this.currentAccountingExpense.depreciation.category.name === 'ELECTIRC_CAR';
        },

        currentAccountingExpense: {
            get() {
                return this.value;
            },
            set(input) {
                return this.$emit('input', input);
            }
        },

        depreciationTypes() {
            return this.depreciationfichecategories.map((_type) => {
                return {
                    id: _type.id,
                    label: this.$t(`depreciation.types.${_type.name}`),
                    name: _type.name
                };
            });
        }
    },
    watch: {
        canShowSelectedCar: {
            immediate: true,
            handler(value) {
                if (!value) {
                    this.currentAccountingExpense.depreciation.carId = null;
                }
            }
        }
    },
    async created() {
        this.depreciationfichecategories = await getAllDepreciationfichecategoriesRequest();

        this.setDepreciationStartDateBasedOnDocumentDate();
    },
    methods: {
        setDepreciationStartDateBasedOnDocumentDate() {
            const documentDateIsFilledIn = this.currentAccountingExpense.documentDate;
            const depreciationStartDateIsEmpty = this.currentAccountingExpense.depreciation.dateStart === null;

            if (documentDateIsFilledIn && depreciationStartDateIsEmpty) {
                this.currentAccountingExpense.depreciation.dateStart = this.currentAccountingExpense.documentDate;
            }
        }
    }
};
</script>

<style></style>
