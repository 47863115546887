<template>
    <div>
        <v-row>
            <v-col v-if="infoText" cols="12">
                <h3>{{ infoText }}</h3>
            </v-col>
        </v-row>

        <div v-if="canShowSupplierChargedVAT" class="pt-2">
            <v-checkbox
                id="supplierChargedVAT"
                v-model="currentAccountingExpense.supplierChargedVAT"
                hide-details="auto"
                dense
                label="Heeft leverancier btw aangerekend?"
                class="mb-4"
            />
            <v-divider class="mb-4" />
        </div>

        <component :is="amountComponentFactory" v-model="currentAccountingExpense" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { isVatLiable } from '@/helpers/VATHelper';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

import { BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU, PRIVATE } from '@/config/constants';
import AmountsWithOneFieldVue from './AmountsWithOneField.vue';
import AmountsWithInclAndExclVue from './AmountsWithInclAndExcl.vue';
import * as expenseService from '@/businessServices/expense/expense.service';
import AmountsWithFictiveVATVue from './AmountsWithBtwVerlegd.vue';
import { getInfoText } from '@/views/addAccountingExpense/addAccountingexpense';

export default {
    mixins: [formRulesMixin],
    props: {
        value: {
            required: true
        }
    },
    data() {
        return {};
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        canShowSupplierChargedVAT() {
            return expenseService.canShowSupplierChargedVAT(
                this.currentAccountingExpense,
                this.currentUserData.settings.vatLiable
            );
        },
        amountComponentFactory() {
            if (!this.currentAccountingExpense.supplier) {
                throw new Error('Must first pick supplier');
            }

            if (this.isVATLiable) {
                if (
                    expenseService.mustUseFictiveAmounts(
                        this.currentAccountingExpense,
                        this.currentUserData.settings.vatLiable
                    )
                ) {
                    return AmountsWithFictiveVATVue;
                } else {
                    if (this.currentAccountingExpense.supplier.type === PRIVATE) {
                        return AmountsWithOneFieldVue;
                    } else {
                        return AmountsWithInclAndExclVue;
                    }
                }
            } else {
                switch (this.currentAccountingExpense.supplier.type) {
                    case PRIVATE:
                        return AmountsWithOneFieldVue;
                    case BELGIAN_COMPANY:
                        return AmountsWithInclAndExclVue;
                    case COMPANY_INSIDE_EU:
                        return AmountsWithInclAndExclVue;
                    case COMPANY_OUTSIDE_EU:
                        return AmountsWithOneFieldVue;
                    default:
                        throw new Error('Cannot happen');
                }
            }
        },
        currentAccountingExpense: {
            get() {
                return this.value;
            },
            set(input) {
                return this.$emit('input', input);
            }
        },
        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        },
        infoText() {
            return getInfoText({
                currentAccountingExpense: this.currentAccountingExpense,
                isVATLiable: this.isVATLiable
            });
        }
    }
};
</script>
