<template>
    <div class="billed_income-table">
        <pagination-data-table
            v-if="depreciations.data"
            ref="paginationDataTable"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="depreciations"
            :loading="loading"
            sort-by="startDate"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.description`]="{ item }">
                {{ item.description | truncate(30) }}
                <v-chip v-if="item.status === 'SOLD'" class="ma-2" x-small color="grey" text-color="white">
                    {{ $t('general.sold') }}
                </v-chip>
            </template>

            <template #[`item.dateStart`]="{ item }">
                {{ item.dateStart | formatDate }}
            </template>

            <template #[`item.purchaseDate`]="{ item }">
                {{ item.purchaseDate | formatDate }}
            </template>

            <template #[`item.category`]="{ item }">
                <span>{{ $t(`depreciation.types.${item.category.name}`) }}</span>
            </template>

            <template #[`item.numberOfYears`]="{ item }"> {{ item.numberOfYears }} {{ $t('general.year') }} </template>

            <template #[`item.totalDepreciationBeginAmount`]="{ item }">
                € {{ (depreciationValueBegin(item, selectedYear) || 0) | numericFormat }}
            </template>

            <template #[`item.depreciationValueThisYear`]="{ item }">
                € {{ (depreciationValueThisYear(item, selectedYear) || 0) | numericFormat }}
            </template>

            <template #[`item.totalDepreciationEndAmount`]="{ item }">
                € {{ (depreciationValueEnd(item, selectedYear) || 0) | numericFormat }}
            </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item :to="/edit-depreciationfiche/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="!isDepreciationSold(item)"
                                :to="{ name: 'add-accounting-invoice', query: { depreciationficheId: item.id } }"
                            >
                                <v-list-item-title>
                                    {{ $t('depreciation.sell') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="handleDeleteClick(item)">
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :loading="loading"
            :title="$t('depreciation.deleteDepreciationficheDialog.title')"
            :message="$t('depreciation.deleteDepreciationficheDialog.content')"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="handleDeleteDepreciationfiche"
            @click-negative="dialog = false"
        />

        <confirmation-dialog
            v-if="cannotDeleteDepreciationAttachedToExpenseDialog"
            v-model="cannotDeleteDepreciationAttachedToExpenseDialog"
            :loading="loading"
            :title="$t('depreciation.cannotDeleteDepreciationAttachedToExpenseDialog.title')"
            :message="$t('depreciation.cannotDeleteDepreciationAttachedToExpenseDialog.content')"
            :positive-button="$t('general.cancel')"
            positive-color="grey"
            @click-positive="cannotDeleteDepreciationAttachedToExpenseDialog = false"
        />
    </div>
</template>

<script>
// --- State ---
import { mapState, mapActions } from 'vuex';
// --- Components ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

import { deleteDepreciationficheByIDRequest } from '@/services/depreciationfiche';

import * as DepreciationService from '../../../domain/depreciation.service';

import { filteringMixin, tableName } from '@/mixins/filtering';
import { depreciationStatus } from '@/config/constants';
import PaginationDataTable from '@/components/DataTables/PaginationDataTable.vue';
import { result, find } from 'lodash';

export default {
    components: { PaginationDataTable },
    mixins: [filteringMixin],

    data() {
        const sortableColumns = [];

        return {
            headers: [
                {
                    text: this.$t('general.description'),
                    value: 'description',
                    sortable: sortableColumns.includes('description')
                },
                {
                    text: this.$t('general.type'),
                    value: 'category',
                    sortable: sortableColumns.includes('category')
                },
                {
                    text: this.$t('depreciation.purchase_date'),
                    value: 'purchaseDate',
                    sortable: sortableColumns.includes('purchaseDate')
                },
                {
                    text: this.$t('general.depriciation_years'),
                    value: 'numberOfYears',
                    sortable: sortableColumns.includes('numberOfYears')
                },
                {
                    text: this.$t('depreciation.start_date'),
                    value: 'dateStart',
                    sortable: sortableColumns.includes('dateStart')
                },
                {
                    text: this.$t('depreciation.begin_value'),
                    value: 'totalDepreciationBeginAmount',
                    sortable: sortableColumns.includes('totalDepreciationBeginAmount')
                },
                {
                    text: this.$t('depreciation.depreciate_current_year'),
                    value: 'depreciationValueThisYear',
                    sortable: sortableColumns.includes('depreciationValueThisYear')
                },
                {
                    text: this.$t('depreciation.end_value'),
                    value: 'totalDepreciationEndAmount',
                    sortable: sortableColumns.includes('totalDepreciationEndAmount')
                },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            cannotDeleteDepreciationAttachedToExpenseDialog: false,
            [tableName]: 'depreciationTable',
            depreciations: {
                data: []
            },
            sortableColumns,
            selectedDepreciationficheId: null
        };
    },

    created() {
        this.wrapperFunction();
    },

    computed: {
        ...mapState(['selectedYear'])
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('depreciationfiches', ['getDepreciationfichesPagination']),
        async wrapperFunction() {
            this.loading = true;
            this.depreciations = await this.getDepreciationfichesPagination({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit,
                    q: this.pagination.search
                },
                query: this.query
            });
            this.loading = false;
        },
        isDepreciationSold(depreciation) {
            return depreciation.status === depreciationStatus.SOLD;
        },
        depreciationValueBegin(depreciationfiche, year) {
            let result = depreciationfiche.totalDepreciationAmount;

            const sortedDepreciations = [...depreciationfiche.depreciations].sort((a, b) => {
                return a.year - b.year;
            });

            for (const depreciation of sortedDepreciations) {
                if (depreciation.year >= year) {
                    break;
                }
                result -= depreciation.depreciationAmount;
            }

            return result;
        },
        depreciationValueThisYear(depreciationfiche, year) {
            return result(
                find(depreciationfiche.depreciations, (depreciation) => {
                    return depreciation.year == year;
                }),
                'depreciationAmount'
            );
        },
        depreciationValueEnd(depreciationfiche, year) {
            let result = depreciationfiche.totalDepreciationAmount;

            const sortedDepreciations = [...depreciationfiche.depreciations].sort((a, b) => {
                return a.year - b.year;
            });

            for (const depreciation of sortedDepreciations) {
                if (depreciation.year > year) {
                    break;
                }
                result -= depreciation.depreciationAmount;
            }

            return result;
        },
        handleDeleteClick(item) {
            if (DepreciationService.canDeleteDepreciation(item)) {
                this.dialog = true;
                this.selectedDepreciationficheId = item.id;
            } else {
                this.cannotDeleteDepreciationAttachedToExpenseDialog = true;
            }
        },
        async handleDeleteDepreciationfiche() {
            this.startLoading();
            try {
                await deleteDepreciationficheByIDRequest(this.selectedDepreciationficheId);

                notify.call(this, {
                    title: this.$t('depreciation.succesfuly_deleted'),
                    text: this.$t('depreciation.succesfuly_deleted')
                });

                await this.wrapperFunction();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.stopLoading();
                this.dialog = false;
            }
        },
        handleClickRow(depreciationfiche) {
            this.$router.push({
                name: 'edit-depreciationfiche',
                params: {
                    id: depreciationfiche.id
                }
            });
        }
    }
};
</script>
