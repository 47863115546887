import { dependendOnNumbers, DEPENDENT_ON_TYPE, IGlobalsettingsStudentViewModel } from 'dexxter-types';

type dependendOnRate = { type: DEPENDENT_ON_TYPE; upperLimit: number };

export function calculateDependentOn(
    dependendOnNumbers: dependendOnNumbers,
    globalSettingsStudent: IGlobalsettingsStudentViewModel
): boolean {
    const dependentOnType = globalSettingsStudent.dependentOnRates.find((_dependentOnItem: dependendOnRate) => {
        return _dependentOnItem.type === dependendOnNumbers.tenLaste_situatieOuders;
    });
    if (!dependentOnType) {
        throw new Error('dependentOnType Not found');
    }

    const upperlimit = dependentOnType.upperLimit;

    return calculateNettoBestaandsMinimum(dependendOnNumbers, globalSettingsStudent) < upperlimit;
}

export function calculateNettoBestaandsMinimum(
    dependendOnNumbers: dependendOnNumbers,
    globalSettingsStudent: IGlobalsettingsStudentViewModel
): number {
    return (
        studentNettoIncomeCalculation(dependendOnNumbers, globalSettingsStudent) +
        dependendOnNumbers.tenLaste_leefloonEnAnderen * 0.8 +
        calculateAlimentation(dependendOnNumbers, globalSettingsStudent)
    );
}

function calculateAlimentation(
    dependendOnNumbers: dependendOnNumbers,
    globalSettingsStudent: IGlobalsettingsStudentViewModel
) {
    //De eerste €3 380 tel je niet mee. Trek van dit bedrag 20% af.
    return dependendOnNumbers.tenLaste_alimentatiegeld -
        globalSettingsStudent.dependOnCalculation.alimentationMoneyIgnore <=
        0
        ? 0
        : (dependendOnNumbers.tenLaste_alimentatiegeld -
              globalSettingsStudent.dependOnCalculation.alimentationMoneyIgnore) *
              0.8;
}

export function studentNettoIncomeCalculation(
    dependendOnNumbers: dependendOnNumbers,
    globalSettingsStudent: IGlobalsettingsStudentViewModel
): number {
    const total = dependendOnNumbers.studentNettoIncome + dependendOnNumbers.tenLaste_belastbaarLoonAndereVerdiensten;

    return total - globalSettingsStudent.dependOnCalculation.ignoreTotalIncomeAmount <= 0
        ? 0
        : total - globalSettingsStudent.dependOnCalculation.ignoreTotalIncomeAmount;
}
