<template>
    <div>
        <template v-if="item.draft === true">
            <table-draft-status />
        </template>
        <template v-else>
            <table-send-status :sendtocustomer="item.sendtocustomer" @click.stop="$emit('click-send-icon')" />
            <table-paid-status :paid="item.payedback" />
        </template>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import TableDraftStatus from './TableDraftStatus.vue';
import TableSendStatus from './TableSendStatus.vue';
import TablePaidStatus from './TablePaidStatus.vue';
export default {
    components: { TableDraftStatus, TableSendStatus, TablePaidStatus },
    props: ['item'],

    methods: {
        invoicePayedInTime(invoice) {
            if (invoice.dueDate && invoice.payed === false) {
                const dueDate = dayjs(invoice.dueDate, 'YYYY-MM-DD');
                const today = dayjs();

                return today.isSameOrBefore(dueDate);
            }

            return true;
        }
    }
};
</script>

<style lang="scss" scoped></style>
