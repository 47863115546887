import { GrootboekrekeningNumbersIncome } from '@/config/constants';

export function isExpenseGrootboekrekening(grootboekrekeningNumber: number): boolean {
    return getGrootboekrekeningIncomeType(grootboekrekeningNumber) === 'expense';
}

export function isIncomeGrootboekrekening(grootboekrekeningNumber: number): boolean {
    return getGrootboekrekeningIncomeType(grootboekrekeningNumber) === 'income';
}

export function isCreditnoteGrootboekrekening(grootboekrekeningNumber: number): boolean {
    return grootboekrekeningNumber === GrootboekrekeningNumbersIncome.income_creditnote;
}

export function getGrootboekrekeningIncomeType(grootboekrekeningNumber: number): 'income' | 'expense' {
    const startNumber: number = parseInt(grootboekrekeningNumber.toString().substring(0, 2));

    if (startNumber >= 70) {
        return 'income';
    } else {
        return 'expense';
    }
}
