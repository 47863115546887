<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                    <sub>
                        <tooltip :underlined="false" i18n-path="tooltips.quotationOverview.title"
                            ><template slot="activator"> <v-icon small> mdi-information </v-icon></template></tooltip
                        >
                    </sub>
                </template>

                <template #menu>
                    <submit-button @click="$router.push('/add-quotation')">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        {{ $t('income.new_quotation') }}
                    </submit-button>
                    <submit-button
                        :class="{ 'ml-2': isSmallDesktopAndUp }"
                        @click="$router.push('/add-quotation-historical')"
                    >
                        <v-icon class="mr-2">mdi-cloud-upload-outline</v-icon>
                        {{ $t('income.new_historical_quotation') }}
                    </submit-button>
                </template>
            </page-header>
        </template>

        <template #content>
            <TableCard>
                <template #content>
                    <quotation-income-table @click-row="handleRowClick" />
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import TableCard from '@/components/TableCard';
import ViewLayout from '@/components/ViewLayout';
import { usePreventDuplicateRoute } from '@/composables/usePreventDuplicateRoute';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import QuotationIncomeTable from './components/QuotationTable.vue';

export default {
    components: {
        QuotationIncomeTable,
        TableCard,
        PageHeader,
        ViewLayout
    },
    mixins: [screenSizeMixin],
    setup() {
        const { preventDuplicateRoute } = usePreventDuplicateRoute();

        return { preventDuplicateRoute };
    },
    methods: {
        routeToViewQuotation(quotation) {
            if (quotation.historical && !quotation.url) {
                return this.$router.push({
                    name: 'edit-historical-quotation',
                    params: {
                        id: quotation.id
                    }
                });
            }

            return this.$router.push({
                name: 'invoicable-view',
                params: {
                    type: 'quotation',
                    id: quotation.id
                }
            });
        },
        handleRowClick(quotation) {
            this.preventDuplicateRoute(this.routeToViewQuotation, quotation);
        }
    }
};
</script>
