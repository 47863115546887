<template>
    <v-row>
        <v-col cols="12">
            <number-field
                id="amountEuroTotalInclVAT"
                :value="currentAccountingExpense.amountEuroInclVAT"
                :label="$t('general.total')"
                outlined
                prefix="€"
                :allow-negative="true"
                dense
                :rules="
                    currentAccountingExpense.isCreditnote
                        ? numberRulesNotRequired
                        : numberRulesNotRequiredNegativePossible
                "
                @change="changeValues"
            />
        </v-col>

        <v-col v-if="canShowAmountEuroExcl0VATField" cols="12" sm="12">
            <number-field
                id="amountEuroExcl0VAT"
                v-model="currentAccountingExpense.amountEuroExcl0VAT"
                :label="
                    $t('general.amount_excl_vat_percentage_placeholder', {
                        percentage: 0
                    })
                "
                outlined
                persistent-hint
                prefix="€"
                :allow-negative="true"
                dense
                :rules="
                    currentAccountingExpense.isCreditnote
                        ? numberRulesNotRequired
                        : numberRulesNotRequiredNegativePossible
                "
            />
        </v-col>

        <v-col cols="12" sm="12">
            <number-field
                id="amountEuroExcl6VAT"
                v-model="currentAccountingExpense.amountEuroExcl6VAT"
                :label="
                    $t('general.amount_excl_vat_percentage_placeholder', {
                        percentage: 6
                    })
                "
                outlined
                persistent-hint
                prefix="€"
                :allow-negative="true"
                dense
                :rules="
                    currentAccountingExpense.isCreditnote
                        ? numberRulesNotRequired
                        : numberRulesNotRequiredNegativePossible
                "
            />
        </v-col>

        <v-col cols="12" sm="12">
            <number-field
                id="amountEuroExcl12VAT"
                v-model="currentAccountingExpense.amountEuroExcl12VAT"
                :label="
                    $t('general.amount_excl_vat_percentage_placeholder', {
                        percentage: 12
                    })
                "
                outlined
                persistent-hint
                prefix="€"
                :allow-negative="true"
                dense
                :rules="
                    currentAccountingExpense.isCreditnote
                        ? numberRulesNotRequired
                        : numberRulesNotRequiredNegativePossible
                "
            />
        </v-col>

        <v-col cols="12" sm="12">
            <number-field
                id="amountEuroExcl21VAT"
                v-model="currentAccountingExpense.amountEuroExcl21VAT"
                :label="
                    $t('general.amount_excl_vat_percentage_placeholder', {
                        percentage: 21
                    })
                "
                outlined
                persistent-hint
                prefix="€"
                :allow-negative="true"
                dense
                :rules="
                    currentAccountingExpense.isCreditnote
                        ? numberRulesNotRequired
                        : numberRulesNotRequiredNegativePossible
                "
            />
        </v-col>

        <div data-id="videoPopupsForEUSupplierVatLiable">
            <v-col v-for="video in videoTooltips" :key="video" cols="12" sm="12">
                <tooltip-video-wrapper :i18n-path="video" />
            </v-col>
        </div>
    </v-row>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import * as ExpenseBusinessService from '@/businessServices/expense/expense.service';
import { mapState } from 'vuex';
import TooltipVideoWrapper from '@/components/TooltipVideoWrapper.vue';
import { COMPANY_INSIDE_EU } from '@/config/constants';

export default {
    components: { TooltipVideoWrapper },

    mixins: [formRulesMixin],
    props: {
        value: {
            required: true
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        videoTooltips() {
            const supplierType = this.currentAccountingExpense.supplier.type;

            if (supplierType === COMPANY_INSIDE_EU) {
                return [
                    'videotooltips.expenses.vatLiable.ICCustomer.charges0ProcentVAT',
                    'videotooltips.expenses.vatLiable.ICCustomer.IncorrectlyAssignedVAT'
                ];
            }

            return [];
        },
        currentAccountingExpense: {
            get() {
                return this.value;
            },
            set(input) {
                return this.$emit('input', input);
            }
        },
        canShowAmountEuroExcl0VATField() {
            return ExpenseBusinessService.canShowAmountEuroExcl0VATField(
                this.currentAccountingExpense,
                this.currentUserData.settings.vatLiable
            );
        }
    },

    methods: {
        changeValues(value) {
            this.currentAccountingExpense.amountEuroInclVAT = value;
            this.currentAccountingExpense.amountEuroExclVAT = value;
        }
    }
};
</script>
