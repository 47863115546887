export function newDepreciationfiche() {
    return {
        category: {
            id: null,
            name: null
        },
        status: null,
        numberOfYears: null,
        totalDepreciationAmount: null,
        dateStart: null,
        description: null,
        carId: null
    };
}
