import { isNil } from 'lodash';
import Vue from 'vue';

export const computedWatcherResetMixin = Vue.extend({
    created() {
        const computedProps = this.$options.computed ? this.$options.computed : {};
        const watchedProps = this.$options.watch ? this.$options.watch : {};

        Object.keys(computedProps).forEach((_computedProp) => {
            if (_computedProp.includes('canShow')) {
                if (isNil(watchedProps[_computedProp])) {
                    throw new Error(
                        `${_computedProp} computed property has no correspondig watcher, this means that when the computed property says the form should be hidden, the value is still set. With the watcher, the input will be reset`
                    );
                }
            }
        });
    }
});
