import { generateEndpoint } from '@/services/config';
import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { filteringOptions, PaginationResponse } from '@/common/types';
import { Activity } from '@/services/activity.types';

export const BASE_URL_ACTIVITY = generateEndpoint('/api/activity');

export async function getActivitiesPagination(options: filteringOptions): Promise<PaginationResponse<Activity>> {
    return axios
        .get(`${BASE_URL_ACTIVITY}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}
