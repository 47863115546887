<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <InvoicableForm
                ref="invoicableComponent"
                type="quotation"
                :is-creating-new-document="isCreatingNew"
                :invoicable-number-text="$t('general.quotation_number')"
                :document-input="currentQuotation"
                :button-text-publish="$t('addIncome.create_quotation')"
                @submit-create-draft="handleSubmitCreateDraft"
                @submit-edit="handleSubmitEdit"
                @submit-publish="handleSubmitPublish"
            />
        </template>
    </view-layout>
</template>

<script>
// --- State ---
import InvoicableForm from '@/components/Forms/InvoicableForm';
import { mapActions, mapState } from 'vuex';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { invoicableFormMixin } from '@/mixins/invoicableFormMixin.js';
// --- Helper ---
import PageHeader from '@/components/PageHeader';
import ViewLayout from '@/components/ViewLayout';
import { invoicableTypes } from '@/config/constants';
import { invoicableTransformCurrencyFieldToIntegers } from '@/helpers/invoicableHelper';
import { currentInvoicableNewInstance } from '../../../models/invoicable';

import { analyticsTrack } from '@/services/analytics';
import dayjs from 'dayjs';
import { reportError } from '../../../helpers/logging';

export default {
    components: {
        InvoicableForm,
        ViewLayout,
        PageHeader
    },

    mixins: [formRulesMixin, invoicableFormMixin],

    data() {
        return {
            currentQuotation: currentInvoicableNewInstance(invoicableTypes.QUOTATION)
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    async created() {
        this.startLoading();
        try {
            if (this.$route.params.id) {
                this.isCreatingNew = false;
                this.currentQuotation = await this.getQuotationById(this.$route.params.id);
            } else {
                this.isCreatingNew = true;
                this.setInitialData();

                const depreciationficheId = this.$route.query?.depreciationficheId;
                if (depreciationficheId) {
                    this.currentQuotation.depreciationficheId = parseInt(depreciationficheId);
                }
            }
        } catch (e) {
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
        }
    },

    methods: {
        ...mapActions('incomes', ['createInvoicable', 'updateIncome', 'getQuotationById']),
        handleAfterSubmit(newQuotation) {
            if (!newQuotation.draft && this.isCreatingNew) {
                try {
                    analyticsTrack('User Completes An Quotation', {
                        Add_Invoice_Price: newQuotation.amountEuroInclVAT,
                        Add_Invoice_Discount: newQuotation.discount,
                        Add_Invoice_Discount_Type: newQuotation.isDiscountPercentage ? 'percentage' : 'amount',
                        Add_Invoice_Comment: newQuotation.comment,
                        Type: 'normal'
                    });
                } catch (e) {
                    reportError(e);
                }
            }

            this.$store.dispatch('incomes/newQuotationCreated');

            this.isCreatingNew = false;
            this.currentQuotation = newQuotation;

            if (newQuotation.draft === false) {
                this.increaseLocalQuotationCounter();
            }

            this.$router.push({
                name: 'invoicable-view',
                params: {
                    id: this.currentQuotation.id,
                    type: 'quotation'
                },
                query: {
                    newlyCreated: true
                }
            });
        },
        setInitialData() {
            this.currentQuotation.date = dayjs.utc().format('YYYY-MM-DD');
            this.currentQuotation.dueDate = dayjs
                .utc()
                .add(this.currentUserData.settings.defaultPaymentDays, 'day')
                .format('YYYY-MM-DD');
        },
        setCorrectValues(invoicableCopy) {
            invoicableCopy.historical = false;
            invoicableCopy.quotationNumber = null;
            invoicableCopy.creator = this.currentUserData.company;

            invoicableCopy.type = 'quotation';

            return invoicableTransformCurrencyFieldToIntegers(invoicableCopy, 'quotation');
        },
        increaseLocalQuotationCounter() {
            // Increment the counter
            this.currentUserData.settings.quotationCounter++;
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}
</style>
