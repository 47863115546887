var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"as_employee_income-table"},[_c('v-row',{staticClass:"filtering-container"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('pagination-search',{model:{value:(_vm.query.q),callback:function ($$v) {_vm.$set(_vm.query, "q", $$v)},expression:"query.q"}})],1)],1),(_vm.jobstudentIncomes.data)?_c('pagination-data-table',{ref:"paginationDataTable",attrs:{"headers":_vm.headers,"pagination":_vm.pagination,"pagination-data":_vm.jobstudentIncomes,"sort-by":"date","custom-thing":""},on:{"refetch-data":_vm.$_handlePaginationRefresh,"sort-by":_vm.$_filtering_handleSortColumn,"sort-desc":_vm.$_filtering_handleSortDirectionDesc,"click-row":_vm.handleClickRow},scopedSlots:_vm._u([{key:`item.year`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.year)+" ")]}},{key:`item.grossAmountEuro`,fn:function({ item }){return [_vm._v(" € "+_vm._s(_vm._f("numericFormat")(item.grossAmountEuro || 0))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":/edit-income-as-employee/ + item.id}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('general.edit'))+" ")])],1),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();{
                                    _vm.dialog = true;
                                    _vm.selectedIncomeId = item.id;
                                }}}},[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t('general.delete'))+" ")])],1)],1)],1)],1)]}}],null,true)}):_vm._e(),(_vm.dialog)?_c('confirmation-dialog',_vm._b({on:{"click-positive":_vm.removeIncome,"click-negative":function($event){_vm.dialog = false},"click-outside":function($event){_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},'confirmation-dialog',{
            title: _vm.$t('general.delete'),
            message: _vm.$t('general.confirm_delete_message'),
            loading: _vm.loading,
            negativeButton: _vm.$t('general.cancel'),
            positiveButton: _vm.$t('general.delete')
        },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }