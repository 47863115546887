<template>
    <div>
        <view-layout id="expensePage">
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                    <template #subTitle>
                        Bekijk de
                        <tooltip
                            underlined
                            :title="$t('tooltips.expenses.deliveryMethods.title')"
                            :content="
                                $t('tooltips.expenses.deliveryMethods.content', {
                                    mailPrefix: currentUserData.userinformation.emailSenderAddress,
                                    size: 3
                                })
                            "
                        >
                            <template slot="activator"> aanlevermethodes </template>
                        </tooltip>
                        van Dexxter.
                    </template>
                    <template #menu>
                        <v-menu>
                            <template #activator="{ on, attrs }">
                                <v-btn color="primary" v-bind="attrs" @click="openUploadExpenseFile = true" v-on="on">
                                    <v-icon class="mr-2">mdi-cloud-upload-outline</v-icon>
                                    {{ $t('expenses.new_expense') }}
                                </v-btn>
                            </template>
                        </v-menu>
                    </template></page-header
                >
            </template>
            <template #content>
                <v-tabs v-model="currentTab">
                    <v-tab>Verwerkte uitgaven</v-tab>
                    <v-tab>
                        <v-badge
                            class="totalNumberOfUnprocessedFilesBadge"
                            offset-x="-2"
                            color="primary"
                            :content="totalNumberOfUnprocessedFiles.toString()"
                        >
                            Onverwerkte uitgaven
                        </v-badge>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="currentTab">
                    <v-tab-item>
                        <TableCard>
                            <template #content>
                                <component :is="expenseTable" />
                            </template>
                        </TableCard>
                    </v-tab-item>
                    <v-tab-item>
                        <TableCard>
                            <template #content>
                                <expense-files-table ref="expensefilesTable" />
                            </template>
                        </TableCard>
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </view-layout>

        <expense-upload-expense-file-modal v-model="openUploadExpenseFile" @uploaded-files="getNewExpenseFiles" />
    </div>
</template>

<script>
// --- Components ---
import AccountingExpensesTable from '@/components/DataTables/AccountingExpensesTable';
import ExpensesTable from '@/components/DataTables/ExpensesTable';
import PageHeader from '@/components/PageHeader';
import TableCard from '@/components/TableCard';
import ViewLayout from '@/components/ViewLayout.vue';

// --- State ---
import ExpenseFilesTable from '@/components/DataTables/ExpenseFilesTable.vue';
import ExpenseUploadExpenseFileModal from '@/components/ExpenseUploadExpenseFileModal.vue';
import { reportError } from '@/helpers/logging';
import { sendGetTotalUnprocessedExpenseFilesRequest } from '@/services/expensefiles';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        TableCard,
        ViewLayout,
        PageHeader,
        ExpenseFilesTable,
        ExpenseUploadExpenseFileModal
    },

    data() {
        return {
            currentTab: 0,
            openUploadExpenseFile: false,
            totalNumberOfUnprocessedFiles: 0
        };
    },

    async created() {
        try {
            this.startLoading();

            await this.getTotalUnprocessedExpenseFiles();
        } catch (e) {
            reportError(e);
        } finally {
            this.stopLoading();
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        expenseTable() {
            if (this.$can('READ', 'Accountingexpense')) {
                return AccountingExpensesTable;
            } else if (this.$can('READ', 'Expense')) {
                return ExpensesTable;
            }

            throw new Error('Permissions problem in expense pagination');
        }
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        async getTotalUnprocessedExpenseFiles() {
            this.totalNumberOfUnprocessedFiles = await sendGetTotalUnprocessedExpenseFilesRequest();
        },
        getNewExpenseFiles() {
            this.getTotalUnprocessedExpenseFiles();
            if (this.$refs.expensefilesTable) {
                this.$refs.expensefilesTable.wrapperFunction();
            }
        }
    }
};
</script>
<style lang="scss">
#expensePage {
    .theme--light.v-tabs > .v-tabs-bar {
        background-color: rgba($color: #000000, $alpha: 0) !important;
    }
    .v-tabs {
        margin-bottom: 1rem !important;
    }

    .totalNumberOfUnprocessedFilesBadge {
        font-size: 13px !important;
    }
}
</style>
