<template>
    <tbody v-if="!isLoadingReceipts" class="ma-0">
        <tr :class="{ deleted: dailyreceiptItem.isCorrected, [`id-${dailyreceiptItem.id}`]: true }">
            <td>
                {{ getDescription(dailyreceiptItem) }}
            </td>

            <template v-if="isVatLiable">
                <td>
                    {{
                        dailyreceiptItem.amountEuroExclVAT + dailyreceiptItem.amountEuroVAT ||
                        0 | numericFormat | addEuroSign
                    }}
                </td>
            </template>

            <template v-if="isVatLiable">
                <td>
                    {{ dailyreceiptItem.amountEuroVAT || 0 | numericFormat | addEuroSign }} ({{
                        dailyreceiptItem.VATPercentage
                    }}%)
                </td>
            </template>

            <td>{{ dailyreceiptItem.amountEuroExclVAT || 0 | numericFormat | addEuroSign }}</td>

            <td class="td-action">
                <v-btn
                    v-if="!dailyreceiptItem.isCorrected"
                    data-action="correct-dailyreceipt"
                    icon
                    color="primary"
                    @click="editIncomeDailyReceipt(dailyreceiptItem)"
                >
                    <v-icon small> mdi-delete </v-icon>
                </v-btn>
            </td>
        </tr>
        <tr v-if="dailyreceiptItem.isCorrected">
            <td colspan="999" class="grey--text">{{ `${$t('general.remark')}: ${item.correctionComment}` }}</td>
        </tr>
    </tbody>
</template>

<script>
export default {
    props: {
        item: {
            required: true
        },
        isVatLiable: {
            required: true,
            type: Boolean
        },
        isLoadingReceipts: {
            required: true,
            type: Boolean
        }
    },

    computed: {
        dailyreceiptItem() {
            return this.item;
        }
    },

    methods: {
        editIncomeDailyReceipt(item) {
            this.$emit('edit-dailyreceipt', item);
        },

        getDescription(item) {
            if (item.status === 'CLOSED') {
                return 'Gesloten';
            } else if (item.status === 'ZERO_SALE') {
                return 'Geen verkopen';
            }

            return item.description;
        }
    }
};
</script>
