import { mapState } from 'vuex';

import { shouldGivePopupIfUserMayWantToUserBtwMedecibtractant } from '@/views/accountingInvoice/domain/accountinginvoice.service';

import maybeMedecontractantDialogComponent from './maybeMedecontractantDialog';

export const maybeMedecontractantDialogMixin = {
    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    methods: {
        maybeMedecontractantDialog(next, invoice) {
            if (!shouldGivePopupIfUserMayWantToUserBtwMedecibtractant(invoice, this.currentUserData)) {
                return next();
            }

            this.showDialog = true;

            this.dialogAttributes = {
                component: maybeMedecontractantDialogComponent,
                attributes: {},
                actions: {
                    'click-positive': this.clickPositive.bind(this, next),
                    'click-negative': this.clickNegative
                }
            };
        }
    }
};
