
import PageHeader from '@/components/PageHeader.vue';
import TableCard from '@/components/TableCard.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { mapState } from 'vuex';

import { flatten, isNil, sumBy } from 'lodash';

import Loader from '@/components/Loader.vue';
import Vue from 'vue';
import ViewBookings from './components/ViewBookings.vue';

import { Grootboekrekening, GrootboekrekeningBookingViewModel, ResultatenrekeningViewModel } from 'dexxter-types';

import { sendGetResultatenrekeningRequest } from '@/services/tax';
import advancedResultatenrekeningVue from './components/advancedResultatenrekening.vue';
import basicResultatenrekeningVue from './components/basicResultatenrekening.vue';
import { PartialBookings } from './types/index';

export default Vue.extend({
    components: {
        TableCard,
        PageHeader,
        ViewLayout,
        ViewBookings,
        Loader
    },

    data() {
        return {
            loading: false,
            selectedBookings: [] as PartialBookings[],
            viewBookingsOpen: false,
            viewAdvancedResultatenrekening: false,
            resultatenrekeningReport: null as null | ResultatenrekeningViewModel
        };
    },

    computed: {
        ...mapState(['selectedYear']),
        bookings(): GrootboekrekeningBookingViewModel[] {
            if (isNil(this.resultatenrekeningReport)) {
                return [];
            }

            return this.resultatenrekeningReport.bookings;
        },
        resultatenrekeningData(): {
            expenseGrootboekrekeningen: Grootboekrekening[];
            incomeGrootboekrekeningen: Grootboekrekening[];
            totalIncomeGrootboekrekeningen: number;
            totalExpenseGrootboekrekeningen: number;
            bookings: GrootboekrekeningBookingViewModel[];
            resultText: string;
            depreciationfiches: GrootboekrekeningBookingViewModel[];
        } {
            return {
                expenseGrootboekrekeningen: this.resultatenrekeningReport
                    ? this.resultatenrekeningReport.expenseGrootboekrekeningen
                    : [],
                incomeGrootboekrekeningen: this.resultatenrekeningReport
                    ? this.resultatenrekeningReport.incomeGrootboekrekeningen
                    : [],
                totalIncomeGrootboekrekeningen: this.resultatenrekeningReport
                    ? this.resultatenrekeningReport.totalIncomeGrootboekrekeningen
                    : 0,
                totalExpenseGrootboekrekeningen: this.resultatenrekeningReport
                    ? this.resultatenrekeningReport.totalExpenseGrootboekrekeningen
                    : 0,
                bookings: this.resultatenrekeningReport ? this.resultatenrekeningReport.bookings : [],
                resultText: this.resultText,
                depreciationfiches: this.depreciationfiches
            };
        },
        resultatenrekeningView() {
            if (this.viewAdvancedResultatenrekening) {
                return advancedResultatenrekeningVue;
            }
            return basicResultatenrekeningVue;
        },
        totalInventoryMutation(): number {
            if (this.resultatenrekeningReport === null) {
                return 0;
            }

            return sumBy(
                this.resultatenrekeningReport.bookings.filter((_bookings) => {
                    return _bookings.bookableType === 'inventory';
                }),
                (_depreciationfiche) => {
                    return _depreciationfiche.amount;
                }
            );
        },
        depreciationfiches(): GrootboekrekeningBookingViewModel[] {
            if (isNil(this.resultatenrekeningReport)) {
                return [];
            }
            return this.resultatenrekeningReport.bookings.filter((_bookings) => {
                return _bookings.bookableType === 'depreciationfiche';
            });
        },
        resultText(): string {
            if (isNil(this.resultatenrekeningReport)) {
                return '...';
            }
            const result = this.resultatenrekeningReport.total;

            if (result >= 0) {
                return `${this.$t('general.profit')}: ${this.$options.filters?.addEuroSign(
                    this.$options.filters.numericFormat(result)
                )}`;
            } else {
                return `${this.$t('general.loss')}: ${this.$options.filters?.addEuroSign(
                    this.$options.filters.numericFormat(-result)
                )}`;
            }
        }
    },

    async created() {
        try {
            this.loading = true;
            this.resultatenrekeningReport = await sendGetResultatenrekeningRequest(this.selectedYear);
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        openDepreciationsBookings(): void {
            this.selectedBookings = this.depreciationfiches.map((_depreciationfiche) => {
                return {
                    bookableType: 'depreciationfiche',
                    bookableId: _depreciationfiche.bookableId,
                    date: _depreciationfiche.date,
                    amount: _depreciationfiche.amount
                };
            });
            this.viewBookingsOpen = true;
        },
        openInventoryMutationBookings(): void {
            this.selectedBookings = [
                {
                    bookableType: 'inventory',
                    bookableId: null,
                    date: `${this.selectedYear}`,
                    amount: -this.totalInventoryMutation
                }
            ];
            this.viewBookingsOpen = true;
        },
        openBookings(grootboekrekening: Grootboekrekening): void {
            const bookings = [];

            if (grootboekrekening.parentId == null) {
                grootboekrekening.children.forEach((_sub) => {
                    bookings.push(this.bookings.filter((_booking) => _booking.grootboekrekeningNumber === _sub.number));
                });
            } else {
                bookings.push(
                    this.bookings.filter((_booking) => _booking.grootboekrekeningNumber === grootboekrekening.number)
                );
            }

            this.selectedBookings = flatten(bookings).map((_booking) => {
                return {
                    bookableType: _booking.bookableType,
                    bookableId: _booking.bookableId,
                    date: _booking.date,
                    amount: _booking.amount
                };
            });
            this.viewBookingsOpen = true;
        },
        printResulatenrekening() {
            const printContent = document.getElementById('print-resultatenrekening');
            const documentTitle = `${this.$t(this.$route.meta?.title.toString())} ${this.selectedYear}`;

            // TODO: Only pass relevant css to print
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('style, link')]) {
                stylesHtml += node.outerHTML;
            }

            // Keep space in url, otherwise about:blank will appear in the footer of the document when printing headers and footers
            const printWindow = window.open(' ', '', `left=0,top=0,right=0,bottom=0,toolbar=0,scrollbars=0,status=0`);

            if (printWindow && printContent) {
                let printHtml = printContent.innerHTML;

                printWindow.document.write(`<!DOCTYPE html>
                  <html>
                    <head>
                      <title>${documentTitle}</title>
                      ${stylesHtml}
                    </head>
                    <body><div id="print-resultatenrekening"><h1>${documentTitle}</h1>`);
                printWindow.document.write(printHtml);
                printWindow.document.write('</div></body></html>');

                printWindow.addEventListener('load', () => {
                    printWindow.focus();
                    printWindow.print();
                    printWindow.close();
                });

                printWindow.document.close();
            }
        }
    }
});
