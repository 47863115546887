<template>
    <confirmation-dialog
        v-model="isOpen"
        :title="this.$t('addIncome.addInvoicable.maybe_btwmedecontractant.title')"
        :message="this.$t('addIncome.addInvoicable.maybe_btwmedecontractant.content')"
        :negative-button="$i18n.t('general.cancel')"
        :positive-button="$i18n.t('general.confirm')"
        negative-color="message"
        positive-color="green"
        data-id="maybeMedecontractantDialog"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #extraContent>
            <tooltip-video-wrapper i18n-path="videotooltips.accountingInvoice.maybe_btwmedecontractant" />
        </template>
    </confirmation-dialog>
</template>

<script>
import TooltipVideoWrapper from '@/components/TooltipVideoWrapper.vue';
export default {
    components: { TooltipVideoWrapper },

    props: ['value'],

    computed: {
        isOpen: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>
