<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template></page-header
            >
        </template>
        <template #content>
            <TableCard>
                <template #content>
                    <activity-table />
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import TableCard from '@/components/TableCard.vue';
// --- Mixins ---
import { screenSizeMixin } from '../mixins/screenSizeMixin';
// --- State ---
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';
import ActivityTable from '@/components/DataTables/Activity/ActivityTable.vue';

export default {
    components: {
        TableCard,
        ViewLayout,
        PageHeader,
        ActivityTable
    },

    mixins: [screenSizeMixin]
};
</script>
