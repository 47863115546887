<template>
    <v-dialog
        :value="value"
        data-id="iclisting-downloaded"
        :max-width="maxWidth"
        v-bind="$attrs"
        scrollable
        @click:outside="dismiss"
    >
        <v-card :id="id">
            <v-card-title class="headline">
                {{ $t('iclisting.afterDownloadICListing.title') }}
            </v-card-title>
            <v-card-text class="preserveEnters" v-html="$t('iclisting.afterDownloadICListing.content')"></v-card-text>
            <v-card-text class="py-2">
                <v-form v-model="valid">
                    <v-file-input
                        id="fileUploadForm"
                        v-model="file"
                        data-form="upload-xml"
                        :label="$t('vatdeclaration.submit_confirmation_file')"
                        persistent-hint
                        single
                        outlined
                        dense
                        prepend-icon=""
                        :rules="uploadXMLRules"
                        append-icon="mdi-paperclip"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn data-action="clickNegative" text color="red" @click="$emit('click-negative')">
                    {{ $t('general.cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                    :disabled="valid === false"
                    data-action="clickPositive"
                    :loading="loading"
                    text
                    color="green"
                    @click="submitFile"
                >
                    {{ $t('vatdeclaration.submitted') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { uploadICSubmissionRequest } from '@/services/iclisting';

export default {
    mixins: [screenSizeMixin, formRulesMixin],
    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        id: {
            type: String,
            required: false
        },
        value: {
            type: Boolean,
            required: true
        },
        year: {
            type: Number,
            required: true
        },
        quarter: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            file: null,
            valid: false,
            loading: false
        };
    },

    computed: {
        maxWidth() {
            if (this.isPhone) {
                return '500px';
            }

            return '50%';
        }
    },

    methods: {
        async submitFile() {
            try {
                this.loading = true;

                await uploadICSubmissionRequest(this.file, this.year, this.quarter);

                this.$emit('click-positive');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        dismiss() {
            this.$emit('input', false);
            this.$emit('click-outside');
        }
    }
};
</script>
