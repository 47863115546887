<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>

                    <template #menu>
                        <v-btn
                            data-action="openAddInvoiceitemBottomsheet"
                            color="primary"
                            @click="createNewInvoiceitem"
                        >
                            {{ $t('invoiceitem.add_invoiceitem') }}
                        </v-btn>
                    </template></page-header
                >
            </template>
            <template #content>
                <TableCard>
                    <template #content>
                        <invoiceitem-table />
                    </template>
                </TableCard>
            </template>
        </view-layout>
        <loader v-if="loading" />
        <add-invoiceitem :units="units" />
    </div>
</template>

<script>
// --- Mixins ---
import { mapActions, mapState } from 'vuex';
import Loader from '@/components/Loader';
import { screenSizeMixin } from '../mixins/screenSizeMixin';
// --- State ---
import InvoiceitemTable from '@/components/DataTables/InvoiceitemTable.vue';
import AddInvoiceitem from '@/components/BottomSheets/AddInvoiceitem.vue';
import TableCard from '@/components/TableCard.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';
import { newInvoiceitem } from '../models/invoiceitem.js';

export default {
    components: {
        InvoiceitemTable,
        TableCard,
        Loader,
        AddInvoiceitem,
        ViewLayout,
        PageHeader
    },

    mixins: [screenSizeMixin],

    data() {
        return {
            loading: false,
            units: []
        };
    },

    created() {
        this.loading = true;
        this.fetchUnits().finally(() => {
            this.loading = false;
        });
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    methods: {
        ...mapActions('units', ['getUnits']),
        ...mapActions('bottomSheets', ['newInvoiceitem']),
        createNewInvoiceitem() {
            this.newInvoiceitem(
                newInvoiceitem({
                    VATPercentage: this.currentUserData.settings.defaultVATPercentage
                })
            );
        },
        fetchUnits() {
            return this.getUnits().then((units) => (this.units = units));
        }
    }
};
</script>
