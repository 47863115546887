<template>
    <div class="v-stepper-wrapper">
        <v-stepper-step :editable="canEditStepper" :complete="stepper > 4" step="4" data-action="open-deductability">
            <base-icon-right i18n-path="tooltips.addExpense.deductability" small>
                {{ $t('addExpense.cost_categories') }}
            </base-icon-right>
        </v-stepper-step>
        <v-stepper-content step="4" :class="{ 'stepper-content-active': stepper == 4 }">
            <v-row>
                <v-col cols="12" sm="12">
                    <search-input
                        id="searchExpensecategory"
                        :label="$t('general.search_expense_category')"
                        :value="
                            currentAccountingExpense.expensecategoryId
                                ? currentAccountingExpense.expensecategory.name
                                : ''
                        "
                        @clicked-search="searchExpenseCategory"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <select-car v-if="canShowSelectedCar" v-model="currentAccountingExpense.carId" />
                </v-col>
            </v-row>

            <v-row>
                <template v-if="currentAccountingExpense.expensecategoryId">
                    <v-col cols="12" md="6" order="12">
                        <number-field
                            id="businessPercentage"
                            v-model="currentAccountingExpense.businessPercentage"
                            :label="$t('addExpense.business_percentage')"
                            outlined
                            dense
                            persistent-hint
                            suffix="%"
                            required
                            :rules="percentagesRules"
                        />
                    </v-col>

                    <v-col cols="12" md="6" order="12">
                        <number-field
                            :value="beroepsmatigeUitgaven"
                            :label="$t('addExpense.cost_in_boekhouding')"
                            outlined
                            persistent-hint
                            prefix="€"
                            dense
                            required
                            disabled
                        />
                    </v-col>

                    <template v-if="canShowVATDeductabilityPercentage">
                        <v-col cols="12" md="6" order="12">
                            <number-field
                                id="VATDeductiblePercentage"
                                ref="VATDeductiblePercentage"
                                v-model="currentAccountingExpense.VATDeductiblePercentage"
                                :label="$t('addExpense.vat_deductible')"
                                outlined
                                persistent-hint
                                suffix="%"
                                dense
                                required
                                :rules="VATDeductiblePercentageRules"
                            />
                        </v-col>

                        <v-col cols="12" md="6" order="12">
                            <number-field
                                :value="businessAccountingCostVAT"
                                :label="$t('addExpense.cost_in_boekhouding_vat')"
                                outlined
                                persistent-hint
                                prefix="€"
                                dense
                                required
                                disabled
                                :rules="numberRulesRequiredNegativePossible"
                            />
                        </v-col>
                    </template>
                </template>
            </v-row>

            <select-table-component
                ref="selectTableComponentExpenseCategory"
                i18n-path="tooltips.addExpense.deductability"
                :add-button-text="$t('general.create_expense_category')"
                :title="$t('general.select_expense_category')"
                :input-component="typeOfServiceTable"
                @selected-item="handleSelectedExpenseCategory"
                @new-item="newExpenseCategory"
            />

            <v-row>
                <v-col cols="12" sm="6" order="10">
                    <v-btn :disabled="!canClickNext" color="primary" small @click="$emit('next')">{{
                        $t('general.next')
                    }}</v-btn>
                </v-col>
            </v-row>
        </v-stepper-content>
    </div>
</template>

<script>
import * as BusinessExpenseService from '@/businessServices/expense/expense.service';
import ExpensecategoriesTable from '@/components/DataTables/ExpensecategoriesTable';
import NumberField from '@/components/FormComponents/NumberField.vue';
import SearchInput from '@/components/SearchInput.vue';
import SelectTableComponent from '@/components/SelectTableComponent.vue';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { isGrootboekrekeningCar } from '@/views/UserApp/grootboekrekening/grootboekrekening';
import { isNil } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { initCurrentExpenseCategory } from '../../../models/expenseCategory';
import SelectCar from './select-car.vue';

export default {
    components: {
        SearchInput,
        SelectTableComponent,
        NumberField,
        SelectCar
    },

    mixins: [formRulesMixin],
    props: {
        value: {},
        stepper: {
            required: true
        },
        canEditStepper: {
            type: Boolean,
            required: true
        },
        canClickNext: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            typeOfServiceTable: ExpensecategoriesTable,
            deductabilityCar: null,
            fetchingDeductabilityCar: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),

        currentAccountingExpense: {
            get() {
                return this.value;
            },
            set(input) {
                return this.$emit('input', input);
            }
        },

        canShowSelectedCar() {
            if (!this.currentAccountingExpense.expensecategory) {
                return false;
            }

            const number = this.currentAccountingExpense.expensecategory.number;

            if (isNil(number)) {
                return false;
            }

            if (!isGrootboekrekeningCar(number)) {
                return false;
            }

            return true;
        },
        beroepsmatigeUitgaven() {
            if (
                isNil(this.currentAccountingExpense.VATDeductiblePercentage) ||
                isNil(this.currentAccountingExpense.businessPercentage) ||
                isNil(this.currentAccountingExpense.amountEuroInclVAT) ||
                isNil(this.currentAccountingExpense.amountEuroExclVAT)
            ) {
                return 0;
            }

            return BusinessExpenseService.beroepsmatigeUitgaven(
                this.currentAccountingExpense,
                this.currentUserData.settings.vatLiable
            );
        },

        businessAccountingCostVAT() {
            if (
                isNil(this.currentAccountingExpense.VATDeductiblePercentage) ||
                isNil(this.currentAccountingExpense.businessPercentage) ||
                isNil(this.currentAccountingExpense.amountEuroInclVAT) ||
                isNil(this.currentAccountingExpense.amountEuroExclVAT)
            ) {
                return 0;
            }

            return BusinessExpenseService.calculateBusinessAccountingCostVAT(
                this.currentAccountingExpense,
                this.currentUserData.settings.vatLiable
            );
        },

        VATDeductiblePercentageRules() {
            const rules = [...this.percentagesRules];

            const businessPercentage = this.currentAccountingExpense.businessPercentage;

            rules.push((value) => {
                return (
                    businessPercentage >= parseFloat(value) ||
                    'Beroepsmatig percentage moet groter dan of gelijk zijn aan het percenage btw-aftrek.'
                );
            });

            return rules;
        },
        mustVATDeductabilityPercentageBeOtherThanZero() {
            return true;
        },
        canShowVATDeductabilityPercentage() {
            return BusinessExpenseService.canShowVATDeductabilityPercentage(
                this.currentAccountingExpense,
                this.currentUserData.settings.vatLiable
            );
        }
    },

    watch: {
        'currentAccountingExpense.businessPercentage'() {
            this.$nextTick(() => {
                if (!this.$refs.VATDeductiblePercentage) {
                    return;
                }

                this.$refs.VATDeductiblePercentage.validate();
            });
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['newExpenseCategory']),
        searchExpenseCategory() {
            this.$refs.selectTableComponentExpenseCategory.open();
        },
        handleSelectedExpenseCategory(expenseCategory) {
            this.currentAccountingExpense.expensecategory = initCurrentExpenseCategory({
                id: expenseCategory.id,
                name: expenseCategory.name,
                forTypeOfService: expenseCategory.forTypeOfService,
                businessPercentage: expenseCategory.businessPercentage,
                VATDeductible: expenseCategory.VATDeductible,
                potentialInvestement: expenseCategory.potentialInvestement,
                number: expenseCategory.number
            });

            if (
                !BusinessExpenseService.mustVATDeductabilityPercentageBeOtherThanZero(
                    this.currentAccountingExpense,
                    this.currentUserData.settings.vatLiable
                )
            ) {
                this.currentAccountingExpense.expensecategory.VATDeductible = 0;
            }

            this.currentAccountingExpense.VATDeductiblePercentage =
                this.currentAccountingExpense.expensecategory.VATDeductible;
            this.currentAccountingExpense.businessPercentage =
                this.currentAccountingExpense.expensecategory.businessPercentage;

            this.currentAccountingExpense.expensecategoryId = expenseCategory.id;
        }
    }
};
</script>
