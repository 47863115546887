export function hasToPayExtraSocialContributions(
    socialContributionsPaidEuro: number,
    socialContributionsToPayEuro: number
): boolean {
    return socialContributionsPaidEuro <= socialContributionsToPayEuro;
}

export function calculateTotalSocialconributionsWithoutFee(toPay: number, fee: number): number {
    return toPay / (1 + fee / 100);
}

export function getSocialContributionsFeeEuro(toPay: number, fee: number): number {
    return toPay - fee;
}

export function checkAboveSocialcontributionsLimit(
    socialContributionUpperLimit: number,
    calculationBasis: number
): boolean {
    if (socialContributionUpperLimit === 0 && calculationBasis === 0) {
        return false;
    }

    return socialContributionUpperLimit <= calculationBasis;
}

export function getTotalToPaySocialcontributions(toPay: number, totalAlreadyPaid: number): number {
    return toPay - totalAlreadyPaid;
}
