import { Quarters } from '../config/constants';
import { reportError } from './logging';

const bonificationAmounts: Record<number, { Q1: number; Q2: number; Q3: number; Q4: number }> = {
    2025: {
        Q1: 12,
        Q2: 10,
        Q3: 8,
        Q4: 6
    },
    2024: {
        Q1: 12,
        Q2: 10,
        Q3: 8,
        Q4: 6
    },
    2023: {
        Q1: 6,
        Q2: 5,
        Q3: 4,
        Q4: 3
    },
    2022: {
        Q1: 3,
        Q2: 2.5,
        Q3: 2,
        Q4: 1.5
    },
    2021: {
        Q1: 3,
        Q2: 2.5,
        Q3: 2,
        Q4: 1.5
    },
    2020: {
        Q1: 3,
        Q2: 2.5,
        Q3: 2,
        Q4: 1.5
    }
};

const fallBack = {
    Q1: 3,
    Q2: 2.5,
    Q3: 2,
    Q4: 1.5
};

export function getAdvantageAmount(year: number, quarter: Quarters): number {
    const quarters = bonificationAmounts[year];

    if (!quarters) {
        reportError(`Bonification for year ${year} is not provided`);
        return fallBack[quarter] / 100;
    }

    const amount = quarters[quarter];

    return amount / 100;
}
