
import Vue, { PropOptions } from 'vue';
import { mapState } from 'vuex';

import VCardHeader from '@/components/vCardHeader.vue';
import CommonModal from '@/components/Modal/CommonModal.vue';

import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { downloadBlobFile } from '@/services/utils';

import { IVATSubmissionViewModel } from '@/views/VAT/models/models';
import { GeneralFncType } from '@/types/general';

export default Vue.extend({
    name: 'VatSubmissionStatus',
    components: {
        VCardHeader
    },
    props: {
        vatSubmission: {
            required: true
        } as PropOptions<IVATSubmissionViewModel | null>,
        currentQuarterNumber: {
            required: true,
            type: Number
        },
        deleteVatSubmissionsRequestHandler: {
            required: true
        } as PropOptions<GeneralFncType>
    },
    data() {
        return {
            selectedVATSubmissionIDToDelete: null as number | null
        };
    },
    computed: {
        ...mapState(['selectedYear'])
    },
    methods: {
        async downloadVATSubmission(url: string): Promise<void> {
            try {
                await downloadBlobFile({
                    url,
                    downloadFileName: `vatdeclaration-submission-${this.selectedYear}-Q${this.currentQuarterNumber}.xml`,
                    method: 'get'
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }
        },
        openDeleteVATSubmissionDialog(submissionID: number): void {
            this.selectedVATSubmissionIDToDelete = submissionID;

            this.$store.commit('modal/setModalContent', {
                component: CommonModal,
                props: {
                    i18nTitle: this.$i18n.t('vatdeclaration.deleteVATSubmission.title'),
                    i18nText: this.$i18n.t('vatdeclaration.deleteVATSubmission.content'),
                    actions: [
                        {
                            action: () => {
                                this.$store.commit('modal/setIsModalOpen', false);
                            },
                            i18nPath: 'general.cancel',
                            icon: 'mdi-close'
                        },
                        {
                            action: () => {
                                this.deleteVatSubmission();
                                this.$store.commit('modal/setIsModalOpen', false);
                            },
                            color: '#ff5252',
                            i18nPath: 'general.delete',
                            icon: 'mdi-delete',
                            dataAction: 'confirm-delete-vat-submission'
                        }
                    ]
                }
            });
        },
        emitRefreshSubmission(): void {
            this.$emit('refresh-submission');
        },
        async deleteVatSubmission(): Promise<void> {
            try {
                if (!this.selectedVATSubmissionIDToDelete) {
                    throw new Error('No VATSubmissionId has been defined');
                }
                await this.deleteVatSubmissionsRequestHandler(this.selectedVATSubmissionIDToDelete);

                notify.call(this, {
                    title: this.$i18n.t('general.succesfuly_deleted'),
                    text: this.$i18n.t('general.succesfuly_deleted')
                });

                this.emitRefreshSubmission();

                this.selectedVATSubmissionIDToDelete = null;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }
        }
    }
});
