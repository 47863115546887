import {
    BELGIAN_COMPANY,
    COMPANY_INSIDE_EU,
    COMPANY_OUTSIDE_EU,
    INVESTEMENTS,
    PRIVATE,
    VatStatus,
    vatStatuses
} from '@/config/constants';
import { isVatLiable } from '@/helpers/VATHelper';
import { AccountingExpenseModel } from '@/models/accountingexpense';
import { sendIsSocialcontributionExpensecategoryRequest } from '@/services/expensecategories';
import dayjs from 'dayjs';
import { expenseTypeOfServiceType, ICreateAccountingExpenseData, IUseraccountViewModel } from 'dexxter-types';
import Fraction from 'fraction.js';
import { cloneDeep, isNil, isNumber } from 'lodash';
import { calculateTotalVATFromExpense } from './calculations/expenseCalculations';

export * from './calculations/expenseCalculations';

interface ISupplier {
    type: number;
}

export const accountingExpenseMiddlewareDialogs = {
    canShowCheckDateDialog(
        { date }: { date: AccountingExpenseModel['date'] },
        { selectedYear }: { selectedYear: number }
    ): boolean {
        const yearOfExpense = dayjs(date, 'YYYY-MM-DD').year();

        if (selectedYear == yearOfExpense) {
            return false;
        }

        return true;
    },
    canShowamountInclEqualsExclBelgianWithNon0VATPercentage(currentAccountingExpense: AccountingExpenseModel): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (
            currentAccountingExpense.btwMedecontractant === false &&
            currentAccountingExpense.supplier.type === BELGIAN_COMPANY &&
            currentAccountingExpense.amountEuroExclVAT === currentAccountingExpense.amountEuroInclVAT &&
            currentAccountingExpense.VATDeductiblePercentage !== 0
        ) {
            return true;
        }

        return false;
    },

    canShowOutsideEUTotalDiffersFromFictiveVAT(
        currentAccountingExpense: AccountingExpenseModel,
        isVATLiable: boolean
    ): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (currentAccountingExpense.recordInVATDeclaration === false) {
            return false;
        }

        if (!isVATLiable) {
            return false;
        }

        if (currentAccountingExpense.supplier.type !== COMPANY_OUTSIDE_EU) {
            return false;
        }

        if (
            new Fraction(currentAccountingExpense.amountEuroExclVAT ?? 0).equals(
                new Fraction(currentAccountingExpense.amountEuroExcl0VAT ?? 0)
                    .add(currentAccountingExpense.amountEuroExcl6VAT ?? 0)
                    .add(currentAccountingExpense.amountEuroExcl12VAT ?? 0)
                    .add(currentAccountingExpense.amountEuroExcl21VAT ?? 0)
            )
        ) {
            return false;
        }

        return true;
    },

    vatLiableCompanyInsideEUOrOutsideHasFilledIn0(currentAccountingExpense: AccountingExpenseModel): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (
            ((currentAccountingExpense.supplier.type === COMPANY_INSIDE_EU &&
                currentAccountingExpense.supplierChargedVAT === false) ||
                currentAccountingExpense.supplier.type === COMPANY_OUTSIDE_EU) &&
            (currentAccountingExpense.amountEuroExcl0VAT ?? 0) != 0
        ) {
            return true;
        }

        return false;
    },

    canShowMaybeAnInvestementDialog(currentAccountingExpense: AccountingExpenseModel, isVATLiable: boolean): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        let amount = 0;
        if (isVATLiable) {
            // Use ExclVATAmount
            amount = currentAccountingExpense.amountEuroExclVAT as number;
        } else {
            // Use InclVATAmount
            amount = currentAccountingExpense.amountEuroInclVAT as number;
        }

        const limitItMayBeAnInvestement = 500;

        if (amount < limitItMayBeAnInvestement) {
            return false;
        }

        if (currentAccountingExpense.expensecategory.forTypeOfService === INVESTEMENTS) {
            return false;
        }

        if (currentAccountingExpense.expensecategory.potentialInvestement === false) {
            return false;
        }

        return true;
    },

    async canShowIsSocialcontributionExpenseDialog({
        expensecategoryId,
        amountEuroExclVAT,
        amountEuroInclVAT
    }: {
        expensecategoryId: AccountingExpenseModel['expensecategoryId'];
        amountEuroExclVAT: AccountingExpenseModel['amountEuroExclVAT'];
        amountEuroInclVAT: AccountingExpenseModel['amountEuroInclVAT'];
    }): Promise<boolean> {
        const isSocialContributionCategory = await sendIsSocialcontributionExpensecategoryRequest(
            expensecategoryId as number
        );

        if (isSocialContributionCategory === false) {
            return false;
        }

        if (
            calculateTotalVATFromExpense({
                amountEuroExclVAT: amountEuroExclVAT || 0,
                amountEuroInclVAT: amountEuroInclVAT || 0
            }) !== 0
        ) {
            return true;
        }

        return false;
    },

    canShowICSupplierHasChargedVATWarning(currentAccountingExpense: AccountingExpenseModel): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (currentAccountingExpense.supplier.type !== COMPANY_INSIDE_EU) {
            return false;
        }

        if (currentAccountingExpense.amountEuroInclVAT === currentAccountingExpense.amountEuroExclVAT) {
            return false;
        }

        return true;
    },

    canShowOutsideEUSupplierHasChargedVATWarning(currentAccountingExpense: AccountingExpenseModel): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (
            currentAccountingExpense.recordInVATDeclaration === false &&
            currentAccountingExpense.supplier.type === COMPANY_OUTSIDE_EU &&
            currentAccountingExpense.amountEuroInclVAT !== currentAccountingExpense.amountEuroExclVAT
        ) {
            return true;
        }

        return false;
    },
    canShowCheckPriceExclAndInclEUVATLiableFictiveVATDialog(
        currentAccountingExpense: AccountingExpenseModel,
        isVATLiable: boolean
    ): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (!isVATLiable) {
            return false;
        }

        if (currentAccountingExpense.supplier.type !== COMPANY_INSIDE_EU) {
            return false;
        }

        if (currentAccountingExpense.recordInVATDeclaration === false) {
            return false;
        }

        if (currentAccountingExpense.supplierChargedVAT === true) {
            return false;
        }

        if (
            new Fraction(currentAccountingExpense.amountEuroExclVAT ?? 0).equals(
                new Fraction(currentAccountingExpense.amountEuroExcl0VAT ?? 0)
                    .add(currentAccountingExpense.amountEuroExcl6VAT ?? 0)
                    .add(currentAccountingExpense.amountEuroExcl12VAT ?? 0)
                    .add(currentAccountingExpense.amountEuroExcl21VAT ?? 0)
            )
        ) {
            return false;
        }

        return true;
    },

    checkIfICSupplierInclExclAreSameWhenUserSaidSupplierDidChargeVAT(
        currentAccountingExpense: AccountingExpenseModel,
        vattype: VatStatus
    ): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (!canShowSupplierChargedVAT(currentAccountingExpense, vattype)) {
            return false;
        }

        if (
            currentAccountingExpense.supplier.type === COMPANY_INSIDE_EU &&
            currentAccountingExpense.supplierChargedVAT &&
            currentAccountingExpense.amountEuroExclVAT === currentAccountingExpense.amountEuroInclVAT
        ) {
            return true;
        }

        return false;
    },

    canShowCheckIfInclExclAreDifferentWhenUserSaidSupplierDidNotChargeVATDialog(
        currentAccountingExpense: AccountingExpenseModel,
        vattype: VatStatus
    ): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (!canShowSupplierChargedVAT(currentAccountingExpense, vattype)) {
            return false;
        }

        if (
            currentAccountingExpense.supplier.type === COMPANY_INSIDE_EU &&
            currentAccountingExpense.supplierChargedVAT === false &&
            !new Fraction(currentAccountingExpense.amountEuroExclVAT ?? 0).equals(
                new Fraction(currentAccountingExpense.amountEuroExcl0VAT ?? 0)
                    .add(currentAccountingExpense.amountEuroExcl6VAT ?? 0)
                    .add(currentAccountingExpense.amountEuroExcl12VAT ?? 0)
                    .add(currentAccountingExpense.amountEuroExcl21VAT ?? 0)
            )
        ) {
            return true;
        }

        return false;
    },

    PriceExclDoesNotEqualsPriceInclBelgianCompanyMedecontractantDialog(
        currentAccountingExpense: AccountingExpenseModel
    ): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (currentAccountingExpense.supplier.type === BELGIAN_COMPANY && currentAccountingExpense.btwMedecontractant) {
            if (currentAccountingExpense.recordInVATDeclaration === false) {
                if (currentAccountingExpense.amountEuroInclVAT === currentAccountingExpense.amountEuroExclVAT) {
                    return false;
                } else {
                    return true;
                }
            } else {
                const addFictiveVATAmounts = new Fraction(currentAccountingExpense.amountEuroExcl6VAT || 0)
                    .add(currentAccountingExpense.amountEuroExcl12VAT || 0)
                    .add(currentAccountingExpense.amountEuroExcl21VAT || 0);

                if (
                    addFictiveVATAmounts.equals(currentAccountingExpense.amountEuroInclVAT || 0) &&
                    currentAccountingExpense.amountEuroInclVAT === currentAccountingExpense.amountEuroExclVAT &&
                    addFictiveVATAmounts.equals(currentAccountingExpense.amountEuroExclVAT || 0)
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        return false;
    },

    canShowCheckPriceExclAndInclEUNotVATLiableDialog(
        currentAccountingExpense: AccountingExpenseModel,
        isVATLiable: boolean
    ): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (
            isVATLiable === false &&
            currentAccountingExpense.supplier.type === COMPANY_INSIDE_EU &&
            currentAccountingExpense.amountEuroInclVAT === currentAccountingExpense.amountEuroExclVAT
        ) {
            return true;
        }

        return false;
    },

    canShowCheckPriceExclHigherThanPriceIncl(currentAccountingExpense: AccountingExpenseModel): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (
            (currentAccountingExpense.amountEuroInclVAT as number) /
                Math.abs(currentAccountingExpense.amountEuroInclVAT as number) !==
            (currentAccountingExpense.amountEuroExclVAT as number) /
                Math.abs(currentAccountingExpense.amountEuroExclVAT as number)
        ) {
            throw new Error('Must both be same sign');
        }

        const sign =
            (currentAccountingExpense.amountEuroInclVAT as number) /
            Math.abs(currentAccountingExpense.amountEuroInclVAT as number);

        if (sign === 1) {
            if (
                (currentAccountingExpense.amountEuroInclVAT as number) <
                (currentAccountingExpense.amountEuroExclVAT as number)
            ) {
                return true;
            }
        }

        return false;
    },

    canShowCheckPriceExclLowerThanPriceIncl(currentAccountingExpense: AccountingExpenseModel): boolean {
        if (!currentAccountingExpense.supplier) {
            throw new Error('No supplier defined');
        }

        if (
            (currentAccountingExpense.amountEuroInclVAT as number) /
                Math.abs(currentAccountingExpense.amountEuroInclVAT as number) !==
            (currentAccountingExpense.amountEuroExclVAT as number) /
                Math.abs(currentAccountingExpense.amountEuroExclVAT as number)
        ) {
            throw new Error('Must both be same sign');
        }

        const sign =
            (currentAccountingExpense.amountEuroInclVAT as number) /
            Math.abs(currentAccountingExpense.amountEuroInclVAT as number);

        if (sign === -1) {
            if (
                (currentAccountingExpense.amountEuroInclVAT as number) >
                (currentAccountingExpense.amountEuroExclVAT as number)
            ) {
                return true;
            }
        }

        return false;
    },

    canShowInclAndExclShouldBeSameSignDialog(currentAccountingExpense: AccountingExpenseModel): boolean {
        if (
            (currentAccountingExpense.amountEuroInclVAT as number) /
                Math.abs(currentAccountingExpense.amountEuroInclVAT as number) !==
            (currentAccountingExpense.amountEuroExclVAT as number) /
                Math.abs(currentAccountingExpense.amountEuroExclVAT as number)
        ) {
            return true;
        }

        return false;
    },

    canShowMaybeUserMeantCreditnote(currentAccountingExpense: AccountingExpenseModel): boolean {
        const sign =
            (currentAccountingExpense.amountEuroInclVAT as number) /
            Math.abs(currentAccountingExpense.amountEuroInclVAT as number);

        if (sign === -1) {
            return true;
        }

        return false;
    }
};

export function canShowVATDeductabilityPercentage(
    currentAccountingExpense: AccountingExpenseModel,
    vattype: VatStatus
): boolean {
    // Moet laten zien als het niet-null is

    function originalChecks() {
        if (!currentAccountingExpense.supplier) {
            return false;
        }

        if (currentAccountingExpense.recordInVATDeclaration === false) {
            return false;
        }

        if (vattype === 'VATLIABLE') {
            if (
                currentAccountingExpense.supplier.type === COMPANY_INSIDE_EU &&
                currentAccountingExpense.supplierChargedVAT === true
            ) {
                return false;
            }

            if (currentAccountingExpense.supplier.type === PRIVATE) {
                return false;
            }

            return true;
        } else {
            return false;
        }
    }

    const underNormalCircumstances = originalChecks();

    if (underNormalCircumstances) {
        return true;
    } else {
        if (isNumberDifferentThan0OrNil(currentAccountingExpense.VATDeductiblePercentage)) {
            return true;
        }

        return false;
    }
}

export function canShowAmountEuroExcl0VATField(
    currentAccountingExpense: AccountingExpenseModel,
    vatLiable: VatStatus
): boolean {
    if (isNumberDifferentThan0OrNil(currentAccountingExpense.amountEuroExcl0VAT)) {
        return true;
    }

    if (!mustUseFictiveAmounts(currentAccountingExpense, vatLiable)) {
        throw new Error('Cannot show because fictive amounts cannot be shown');
    }

    if (currentAccountingExpense.btwMedecontractant) {
        return false;
    }

    return true;
}

export function canShowSupplierChargedVAT(
    currentAccountingExpense: AccountingExpenseModel,
    vatLiable: VatStatus
): boolean {
    if (!currentAccountingExpense.supplier) {
        return false;
    }

    if (vatLiable === vatStatuses.VATLIABLE && currentAccountingExpense.supplier.type === COMPANY_INSIDE_EU) {
        return true;
    }

    return false;
}

export function canSelectBTWMedecontractant(supplier: ISupplier, currentUserData: IUseraccountViewModel): boolean {
    if (!supplier) {
        throw new Error('Supplier is falsy');
    }

    return supplier.type == BELGIAN_COMPANY && currentUserData.settings.btwMedecontractant;
}

export function canShowDepreciationForm(typeOfService: expenseTypeOfServiceType, isCreditnote: boolean): boolean {
    return typeOfService == INVESTEMENTS && isCreditnote === false;
}

export function canRecordInVATDeclaration(currentUserData: IUseraccountViewModel): boolean {
    return isVatLiable(currentUserData.settings.vatLiable);
}

export function mustVATDeductabilityPercentageBeOtherThanZero(
    currentAccountingExpense: AccountingExpenseModel,
    VATType: VatStatus
): boolean {
    if (!currentAccountingExpense.supplier) {
        throw new Error('Supplier not defined');
    }

    if (currentAccountingExpense.recordInVATDeclaration === false) {
        return false;
    }

    if (currentAccountingExpense.supplier.type === PRIVATE) {
        return false;
    }

    if (!isVatLiable(VATType)) {
        return false;
    }

    if (canShowSupplierChargedVAT(currentAccountingExpense, VATType) && currentAccountingExpense.supplierChargedVAT) {
        return false;
    }

    return true;
}

export function isExpenseDataCompleteForSubmit(currentAccountingExpense: AccountingExpenseModel): boolean {
    if (currentAccountingExpense.supplier === null) {
        return false;
    }

    if (currentAccountingExpense.expensecategoryId === null) {
        return false;
    }

    if (currentAccountingExpense.isDepreciation) {
        if (!currentAccountingExpense.depreciation) {
            return false;
        }
        if (
            !(
                currentAccountingExpense.depreciation &&
                !isNil(currentAccountingExpense.depreciation.numberOfYears) &&
                currentAccountingExpense.depreciation.numberOfYears > 0
            )
        ) {
            return false;
        }
    }

    return true;
}

export function setCorrectValuesForSubmission(
    expenseInput: AccountingExpenseModel,
    isVATLiable: boolean,
    expenseFileId: number
): ICreateAccountingExpenseData {
    let expense = cloneDeep(expenseInput);

    if (!isVATLiable) {
        expense.VATDeductiblePercentage = 0;
        expense.businessAccountingCostVAT = 0;
    }

    if (!expenseInput.supplier) {
        throw new Error('Supplier not set');
    }

    expense = setNumbersToFloats(expense);

    function createDepreciationObj(): ICreateAccountingExpenseData['depreciation'] | null {
        if (expense.isDepreciation && expense.depreciation && !expense.isCreditnote) {
            if (
                isNil(expense.depreciation.dateStart) ||
                isNil(expense.depreciation.numberOfYears) ||
                isNil(expense.depreciation.description) ||
                isNil(expense.depreciation.category.id)
            ) {
                throw new Error('Cannot be null');
            }

            return {
                categoryId: expense.depreciation.category.id,
                dateStart: expense.depreciation.dateStart,
                description: expense.depreciation.description,
                numberOfYears: expense.depreciation.numberOfYears,
                carId: expense.depreciation.carId ?? null
            };
        } else {
            return null;
        }
    }

    const depreciationObj = createDepreciationObj();

    if (
        isNil(expense.amountEuroExcl0VAT) ||
        isNil(expense.amountEuroExcl6VAT) ||
        isNil(expense.amountEuroExcl12VAT) ||
        isNil(expense.amountEuroExcl21VAT) ||
        isNil(expense.amountEuroExclVAT) ||
        isNil(expense.amountEuroInclVAT) ||
        isNil(expense.businessPercentage) ||
        isNil(expense.date) ||
        isNil(expense.documentDate) ||
        isNil(expense.expensecategoryId) ||
        isNil(expense.VATDeductiblePercentage)
    ) {
        throw new Error('Cannot be null');
    }

    const result: ICreateAccountingExpenseData = {
        amountEuroExcl0VAT: expense.amountEuroExcl0VAT,
        amountEuroExcl6VAT: expense.amountEuroExcl6VAT,
        amountEuroExcl12VAT: expense.amountEuroExcl12VAT,
        amountEuroExcl21VAT: expense.amountEuroExcl21VAT,
        amountEuroExclVAT: expense.amountEuroExclVAT,
        amountEuroInclVAT: expense.amountEuroInclVAT,
        btwMedecontractant: expense.btwMedecontractant,
        businessPercentage: expense.businessPercentage,
        date: expense.date,
        description: expense.description ?? '',
        depreciation: depreciationObj,
        documentDate: expense.documentDate,
        dueDate: expense.dueDate,
        expensecategoryId: expense.expensecategoryId,
        expenseFileId: expenseFileId,
        isCreditnote: expense.isCreditnote,
        isDepreciation: expense.isDepreciation,
        payed: expense.payed,
        recordInVATDeclaration: expense.recordInVATDeclaration,
        reference: expense.reference,
        supplierId: expenseInput.supplier.id,
        VATDeductiblePercentage: expense.VATDeductiblePercentage,
        carId: expense.carId
    };

    return result;
}

function setNumbersToFloats(currentAccountingExpense: AccountingExpenseModel): AccountingExpenseModel {
    const expense = cloneDeep(currentAccountingExpense);

    expense.amountEuroExcl0VAT = expense.amountEuroExcl0VAT || 0;
    expense.amountEuroExcl6VAT = expense.amountEuroExcl6VAT || 0;
    expense.amountEuroExcl12VAT = expense.amountEuroExcl12VAT || 0;
    expense.amountEuroExcl21VAT = expense.amountEuroExcl21VAT || 0;

    expense.businessAccountingCostVAT = expense.businessAccountingCostVAT || 0;
    expense.accountingCostEuro = expense.accountingCostEuro || 0;

    expense.amountEuroInclVAT = expense.amountEuroInclVAT || 0;
    expense.amountEuroExclVAT = expense.amountEuroExclVAT || 0;

    return expense;
}

// *********** Amount euro INCL vat calculations

export function areAmountsFilledIn(
    currentAccountingExpense: Pick<
        AccountingExpenseModel,
        | 'amountEuroExcl0VAT'
        | 'amountEuroExcl6VAT'
        | 'amountEuroExcl12VAT'
        | 'amountEuroExcl21VAT'
        | 'amountEuroExclVAT'
        | 'amountEuroInclVAT'
        | 'supplier'
        | 'recordInVATDeclaration'
        | 'btwMedecontractant'
        | 'supplierChargedVAT'
    >,
    VATType: VatStatus
): boolean {
    if (!currentAccountingExpense.supplier) {
        throw new Error('No supplier defined');
    }
    if (mustUseFictiveAmounts(currentAccountingExpense, VATType)) {
        return (
            new Fraction(currentAccountingExpense.amountEuroExcl0VAT || 0)
                .add(currentAccountingExpense.amountEuroExcl6VAT || 0)
                .add(currentAccountingExpense.amountEuroExcl12VAT || 0)
                .add(currentAccountingExpense.amountEuroExcl21VAT || 0)
                .equals(0) === false &&
            isNumberDifferentThan0OrNil(currentAccountingExpense.amountEuroExclVAT) &&
            isNumberDifferentThan0OrNil(currentAccountingExpense.amountEuroInclVAT)
        );
    } else {
        return (
            isNumberDifferentThan0OrNil(currentAccountingExpense.amountEuroExclVAT) &&
            isNumberDifferentThan0OrNil(currentAccountingExpense.amountEuroInclVAT)
        );
    }
}

function isNumberDifferentThan0OrNil(amount: number | null): boolean {
    if (isNil(amount)) {
        return false;
    }

    if (!isNumber(amount)) {
        return false;
    }

    if (amount === 0) {
        return false;
    }

    if (amount === null) {
        return false;
    }

    return true;
}

export function mustUseFictiveAmounts(
    accountingExpense: Pick<
        AccountingExpenseModel,
        | 'supplier'
        | 'amountEuroInclVAT'
        | 'amountEuroExclVAT'
        | 'btwMedecontractant'
        | 'recordInVATDeclaration'
        | 'supplierChargedVAT'
    >,
    vattype: VatStatus
): boolean {
    if (!accountingExpense.supplier) {
        throw new Error('Supplier not set');
    }

    // Alle scenarios waarvan we de fictieve btw totale nodig hebben

    const mustThisUserTypeFilledInAmounts: boolean =
        accountingExpense.supplier.type === COMPANY_OUTSIDE_EU ||
        (accountingExpense.supplier.type === COMPANY_INSIDE_EU && accountingExpense.supplierChargedVAT === false) ||
        (accountingExpense.supplier.type === BELGIAN_COMPANY && accountingExpense.btwMedecontractant === true);

    return (
        vattype === 'VATLIABLE' && accountingExpense.recordInVATDeclaration === true && mustThisUserTypeFilledInAmounts
    );
}
