<template>
    <div v-if="VATCustomers">
        <v-data-table
            v-if="VATCustomers"
            :headers="headers"
            :items="VATCustomers"
            :loading="loadingCustomers"
            :disable-sort="true"
            sort-by="date"
            sort-desc
        >
            <template #[`item.companyNumber`]="{ item }">
                {{ item.number }}
            </template>

            <template #[`item.amountEuroExclVATTotal`]="{ item }">
                {{ item.amountEuroExclVATTotal | numericFormat | addEuroSign }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { formatAddress } from '@/helpers/stringFormats';

export default {
    components: {
        // PaginationSearch,
    },

    props: {
        VATCustomers: {
            required: true
        },
        loadingCustomers: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            headers: [
                {
                    text: this.$t('general.company_number'),
                    value: 'number'
                },
                {
                    text: this.$t('general.name'),
                    value: 'name'
                },
                {
                    text: this.$t('dataTables.companyTotal'),
                    value: 'amountEuroExclVATTotal'
                }
            ],
            userDataExportLoading: false
        };
    },
    methods: {
        getFormattedAddress(address) {
            return formatAddress(address);
        }
    }
};
</script>
