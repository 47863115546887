import { COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU } from '@/config/constants';
import { AccountingExpenseModel } from '@/models/accountingexpense';
import i18n from '@/i18n';

export function getInfoText({
    currentAccountingExpense,
    isVATLiable
}: {
    currentAccountingExpense: AccountingExpenseModel;
    isVATLiable: boolean;
}): string | null {
    let result = '';

    if (currentAccountingExpense.isCreditnote) {
        result = result.concat(i18n.t('addExpense.creditnote_must_be_positive_text') as string).concat(' ');
    }

    if (currentAccountingExpense.recordInVATDeclaration === true) {
        if (isVATLiable && currentAccountingExpense.supplier) {
            if (currentAccountingExpense.btwMedecontractant) {
                result = result.concat(i18n.t('addExpense.precentage_btw_ic') as string).concat(' ');
            }
            if (currentAccountingExpense.supplier.type === COMPANY_INSIDE_EU) {
                result = result.concat(i18n.t('addExpense.precentage_btw_ic') as string).concat(' ');
            }
            if (currentAccountingExpense.supplier.type === COMPANY_OUTSIDE_EU) {
                result = result.concat(i18n.t('addExpense.precentage_btw_ic') as string).concat(' ');
            }
        }
    }

    return result.length === 0 ? null : result;
}
