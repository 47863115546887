<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <confirmation-dialog
                v-if="!canCreateCreditnote"
                :value="true"
                persistent
                :title="$t('addIncome.information_needed')"
                :message="$t('addIncome.need_IBAN_and_customer_number')"
                :positive-button="$t('addIncome.fill_in')"
                positive-color="primary"
                @click-positive="$router.push('/user-settings/general')"
            />

            <confirmation-dialog
                v-if="showDialog"
                v-model="showDialog"
                v-bind="dialogAttributes.attributes"
                @click-positive="dialogAttributes.actions.clickPositive"
                @click-negative="dialogAttributes.actions.clickNegative"
            />

            <AccountingInvoicableForm
                ref="invoicableComponent"
                type="creditnote"
                :booking="true"
                :is-creating-new-document="isCreatingNew"
                :invoicable-number-text="$t('general.creditnote_number')"
                :document-input="currentCreditnote"
                :button-text-publish="$t('addIncome.create_creditnote')"
                @submit-create-booking="handleSubmitBooking"
            />
        </template>
    </view-layout>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import AccountingInvoicableForm from '@/components/Forms/accounting/AccountingInvoicableForm.vue';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { middlewareMixin } from '@/mixins/middlewareMixin';

import { currentInvoicableNewInstance } from '../../models/invoicable';

// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
// import { notify } from '@/helpers/successNotification';
import { invoicableTransformCurrencyFieldToIntegers } from '@/helpers/invoicableHelper';
import { invoicableTypes, popupNotificationNames } from '@/config/constants';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import { notify } from '@/helpers/successNotification';
import _ from 'lodash';
import { getCreditnote } from '@/services/creditnote';
import { bookCreditnote } from '@/services/accountingcreditnotes';

export default {
    components: {
        AccountingInvoicableForm,
        ViewLayout,
        PageHeader,
        ConfirmationDialog
    },
    mixins: [formRulesMixin, middlewareMixin],

    data() {
        return {
            currentCreditnote: currentInvoicableNewInstance(invoicableTypes.CREDITNOTE),
            submitMiddleware: [this.handleSubmit]
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        canCreateCreditnote() {
            return this.currentUserData.company.number && this.currentUserData.company.IBAN;
        },
        informationpopupPriority() {
            return [popupNotificationNames.INVOICESETTINGS_OPENFIRSTTIME];
        }
    },

    async created() {
        this.startLoading();
        try {
            if (!this.$route.params.id) {
                this.$router.push({ name: 'dashboard' });
            }

            this.isCreatingNew = false;
            this.currentCreditnote = await getCreditnote(this.$route.params.id);
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
        }
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),

        async handleSubmitBooking(invoicable) {
            this.startLoading();
            let invoicableCopy = _.cloneDeep(invoicable);

            try {
                invoicableCopy = this.setCorrectValues(invoicableCopy);

                await bookCreditnote(invoicableCopy);

                notify.call(this, {
                    title: this.$t('income.created_success'),
                    text: this.$t('income.created_success')
                });

                return this.$router.push({
                    name: 'documentsToBook',
                    query: {
                        page: 'creditnotes'
                    }
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.stopLoading();
            }
        },
        setCorrectValues(creditnoteClone) {
            creditnoteClone.draft = false;
            creditnoteClone.historical = false;
            creditnoteClone.creditnoteNumber = null;
            creditnoteClone.creator = this.currentUserData.company;

            creditnoteClone.type = 'creditnote';

            return invoicableTransformCurrencyFieldToIntegers(creditnoteClone, 'creditnote');
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}
</style>
