import { render, staticRenderFns } from "./SubmitPersonalTaxesStep4.vue?vue&type=template&id=3afb2f7a&scoped=true&"
import script from "./SubmitPersonalTaxesStep4.vue?vue&type=script&lang=ts&"
export * from "./SubmitPersonalTaxesStep4.vue?vue&type=script&lang=ts&"
import style0 from "./SubmitPersonalTaxesStep4.vue?vue&type=style&index=0&id=3afb2f7a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3afb2f7a",
  null
  
)

export default component.exports