<template>
    <div>
        <loader-inline v-if="loading" />
        <template v-if="error">
            <p>{{ error }}</p>
        </template>
        <template v-else>
            <v-row class="statuten-wrapper">
                <v-col cols data-cy="statuten-wrapper-Q1">
                    <v-select
                        v-model="Q1Data"
                        :items="statutenOptions"
                        item-value="id"
                        item-text="label"
                        outlined
                        dense
                        hide-details
                        :menu-props="{ 'content-class': 'statutenMenuQ1' }"
                        :label="$t(`settings.quarter_1_statuut`)"
                        :rules="requiredRules"
                    />
                </v-col>
                <v-col cols data-cy="statuten-wrapper-Q2">
                    <v-select
                        v-model="Q2Data"
                        :items="statutenOptions"
                        item-value="id"
                        item-text="label"
                        outlined
                        dense
                        hide-details
                        :menu-props="{ 'content-class': 'statutenMenuQ2' }"
                        :label="$t(`settings.quarter_2_statuut`)"
                        :rules="requiredRules"
                    />
                </v-col>
                <v-col cols data-cy="statuten-wrapper-Q3">
                    <v-select
                        v-model="Q3Data"
                        :items="statutenOptions"
                        item-value="id"
                        item-text="label"
                        outlined
                        :menu-props="{ 'content-class': 'statutenMenuQ3' }"
                        dense
                        hide-details
                        :label="$t(`settings.quarter_3_statuut`)"
                        :rules="requiredRules"
                    />
                </v-col>
                <v-col cols data-cy="statuten-wrapper-Q4">
                    <v-select
                        v-model="Q4Data"
                        :items="statutenOptions"
                        item-value="id"
                        item-text="label"
                        outlined
                        dense
                        hide-details
                        :label="$t(`settings.quarter_4_statuut`)"
                        :menu-props="{ 'content-class': 'statutenMenuQ4' }"
                        :rules="requiredRules"
                    />
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import LoaderInline from '@/components/LoaderInline.vue';
import i18n from '@/i18n';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

export default {
    components: {
        LoaderInline
    },
    mixins: [formRulesMixin],

    props: {
        dense: {
            type: Boolean,
            default: false
        },
        q1: {
            type: String
        },
        q2: {
            type: String
        },
        q3: {
            type: String
        },
        q4: {
            type: String
        }
    },

    data() {
        return {
            error: null,
            loading: false,
            loadingSave: false,
            statutenOptions: [
                {
                    id: 'student',
                    label: i18n.t('settings.statuten.student')
                },
                {
                    id: 'secondary',
                    label: i18n.t('settings.statuten.secondary')
                },
                {
                    id: 'main',
                    label: i18n.t('settings.statuten.main')
                },
                {
                    id: 'not_started',
                    label: i18n.t('settings.statuten.not_started')
                },
                {
                    id: 'stopped',
                    label: i18n.t('settings.statuten.stopped')
                }
            ],
            valid: false
        };
    },

    computed: {
        Q1Data: {
            get() {
                return this.q1;
            },
            set(value) {
                this.$emit('update:q1', value);
            }
        },
        Q2Data: {
            get() {
                return this.q2;
            },
            set(value) {
                this.$emit('update:q2', value);
            }
        },
        Q3Data: {
            get() {
                return this.q3;
            },
            set(value) {
                this.$emit('update:q3', value);
            }
        },
        Q4Data: {
            get() {
                return this.q4;
            },
            set(value) {
                this.$emit('update:q4', value);
            }
        }
    }
};
</script>
