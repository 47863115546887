import axios from 'axios';
import { generateEndpoint } from '@/services/config';

const BASE_URL = generateEndpoint('/api/incomes');

export function getIncomeSum({ year, group }: { year: number; group: 'month' | 'quarter' }): Promise<
    Record<
        string,
        {
            amountEuroExclVAT: number;
            amountEuroInclVAT: number;
            amountEuroExclVATUnpaid: number;
            amountEuroInclVATUnpaid: number;
        }
    >
> {
    return axios
        .get(`${BASE_URL}/grouped/year/${year}`, {
            params: {
                group
            }
        })
        .then((response) => response.data);
}

export function getTotalRevenue({ year }: { year: number }): Promise<{ revenueExclVAT: number }> {
    return axios.get(`${BASE_URL}/revenue/year/${year}`).then((response) => response.data);
}
