<template>
    <div v-if="numberOfToProcessFiles" class="d-flex justify-space-between align-center mb-3">
        <p class="subtitle-1 pl-1">
            {{ totalNumberOfToProcessFiles - numberOfToProcessFiles }} van de {{ totalNumberOfToProcessFiles }} uitgaven
            verwerkt
        </p>
        <submit-button small @click="skipCurrentExpenseFile">Overslaan</submit-button>
    </div>
</template>

<script>
export default {
    props: {
        expenseFileIdsUserWantsToProcess: {
            type: Array,
            default: () => []
        },
        totalExpenseFileIdsUserWantsToProcess: {
            type: Number,
            required: true
        }
    },

    computed: {
        numberOfToProcessFiles() {
            return this.expenseFileIdsUserWantsToProcess.length;
        },
        totalNumberOfToProcessFiles() {
            return this.totalExpenseFileIdsUserWantsToProcess ?? 0;
        }
    },

    methods: {
        skipCurrentExpenseFile() {
            this.$emit('skip-current-expense-file');
        }
    }
};
</script>

<style></style>
