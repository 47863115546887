import { VatStatus } from '@/config/constants';
import { isVatLiable } from '@/helpers/VATHelper';
import { AccountingExpenseModel } from '@/models/accountingexpense';
import { mustVATDeductabilityPercentageBeOtherThanZero } from '../expense.service';
import { getVATAmountToUseRealOrFictive } from './expenseCalculations';

/**
 * Calculates the amount of VAT the user recoveres from the given accountingExpense.
 *
 * In some cases, the user cannot recover anything:
 *  - The user is not vatLiable
 *  - The expense is one that uses fictive vat (meaning the expense has no VAT, but it is btw-verlegd)
 */
export function calculateDeductibleVATOnExpense(accountingExpense: AccountingExpenseModel, vattype: VatStatus): number {
    if (!canHaveDeductibleVATOnExpense(accountingExpense, vattype)) {
        return 0;
    }

    if (
        accountingExpense.VATDeductiblePercentage == null ||
        accountingExpense.amountEuroExclVAT == null ||
        accountingExpense.amountEuroInclVAT == null
    ) {
        throw new Error(`Cannot be null ${JSON.stringify(accountingExpense)}`);
    }

    // In some scenarios there is no VAT
    if (!mustVATDeductabilityPercentageBeOtherThanZero(accountingExpense, vattype)) {
        return 0;
    }

    const totalVAT = getVATAmountToUseRealOrFictive(accountingExpense, vattype);

    return totalVAT * (accountingExpense.VATDeductiblePercentage / 100);
}

function canHaveDeductibleVATOnExpense(accountingExpense: AccountingExpenseModel, vattype: VatStatus) {
    if (!isVatLiable(vattype)) {
        return false;
    }

    return true;
}
