
import { getReadableDate } from '@/helpers/dateHelpers';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import {
    sendGetCalculatedTypeOfCompanyRequest,
    sendGetPersonalTaxRastersRequest,
    sendGetTaxesRequest
} from '@/services/tax';
import { PersonalTaxesViewModel } from '@/services/tax.types';
import { ITaxViewModel } from '@/store/modules/taxes.types';
import { IPersonaltaxesSubmissionViewModel } from 'dexxter-types';
import Vue, { PropOptions } from 'vue';
import LoaderInline from '../../../../components/LoaderInline.vue';
import PersonalTaxesTable from './PersonalTaxesTable.vue';

export default Vue.extend({
    components: { PersonalTaxesTable, LoaderInline },

    props: {
        deletingSubmission: {
            required: true,
            type: Boolean
        },
        yearOfPersonalTaxes: {
            type: Number,
            required: true
        },
        personaltaxesSubmission: {
            required: true
        } as PropOptions<IPersonaltaxesSubmissionViewModel>
    },
    data() {
        return {
            tax: null as ITaxViewModel | null,
            selectedPersonaltaxesSubmissionIDToDelete: null,
            deletePersonaltaxesSubmissionDialog: false,
            loadingPersonalTaxesDataFetching: false,
            loading: false,
            personaltaxRasters: null as null | PersonalTaxesViewModel,
            activeYears: [] as number[],
            calculatedTypeOfCompany: null as null | string
        };
    },
    computed: {
        wettelijkSamenwonend(): boolean {
            return this.tax?.married ?? false;
        }
    },

    async created() {
        try {
            this.loading = true;

            [this.tax] = await Promise.all([sendGetTaxesRequest(this.yearOfPersonalTaxes)]);

            if (!this.tax.pb_typeOfCompany) {
                this.calculatedTypeOfCompany = await sendGetCalculatedTypeOfCompanyRequest(this.yearOfPersonalTaxes);
            }

            this.personaltaxRasters = await sendGetPersonalTaxRastersRequest(
                this.yearOfPersonalTaxes,
                this.tax.pb_typeOfCompany ?? this.calculatedTypeOfCompany
            );
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        getReadableDate,
        deletePersonaltaxesSubmission() {
            this.$emit('deleteSubmission');
        },
        openDeletePersonaltaxesSubmissionDialog() {
            this.deletePersonaltaxesSubmissionDialog = true;
        }
    }
});
