
import ChangeStatutenPureForm from '@/components/Forms/ChangeStatutenPureForm.vue';
import { UserBookYearType } from '@dexxter/accountancy-tools';
import { computed, defineComponent, reactive, ref, toRaw } from 'vue';

interface SubmitPersonalTaxesStep4Props {
    submittingForm: boolean;
    wettelijkSamenwonend: boolean;
    typeOfCompany: string | null;
    statuten: {
        Q1: UserBookYearType;
        Q2: UserBookYearType;
        Q3: UserBookYearType;
        Q4: UserBookYearType;
    };
    yearOfPersonalTaxes: number;
}

export default defineComponent<SubmitPersonalTaxesStep4Props>({
    name: 'SubmitPersonalTaxesStep1',
    components: { ChangeStatutenPureForm },
    props: {
        submittingForm: {
            type: Boolean,
            required: true
        },
        yearOfPersonalTaxes: {
            type: Number,
            required: true
        },
        wettelijkSamenwonend: {
            type: Boolean,
            required: true
        },
        statuten: {
            type: Object,
            required: true
        },
        typeOfCompany: {
            type: String,
            required: false
        }
    },
    setup(props, { emit }) {
        const formData = reactive({
            wettelijkSamenwonend: props.wettelijkSamenwonend,
            companyType: props.typeOfCompany,
            statuten: props.statuten
        });

        const companyType = computed(() => (formData.companyType === 'good' ? 'XVII' : 'XVIII'));

        const typeOfCompanyItems = [
            {
                id: 'good',
                label: 'VAK XVII'
            },
            {
                id: 'service',
                label: 'VAK XVIII'
            }
        ] as const;

        const showTypeOfCompany = (value: boolean) => {
            if (value === false) {
                emit('reset:typeOfCompany', value);
            }
        };

        const submitForm = () => {
            emit('next', toRaw(formData));
        };

        const formValidity = ref<boolean | null>(null);

        const canShowTypeOfCompany = ref(false);

        const canGoToNext = computed(() => {
            return formValidity.value === true;
        });

        return {
            canShowTypeOfCompany,
            submitForm,
            formData,
            formValidity,
            showTypeOfCompany,
            canGoToNext,
            companyType,
            typeOfCompanyItems
        };
    }
});
