<template>
    <v-form v-model="valid">
        <loader-inline v-if="loading" />
        <template v-if="error">
            <p>{{ error }}</p>
        </template>
        <template v-else>
            <v-row class="statuten-wrapper">
                <v-col v-for="quarter of [1, 2, 3, 4]" :key="quarter" cols>
                    <v-select
                        v-model="data[`Q${quarter}`]"
                        :items="statuten"
                        item-value="id"
                        item-text="label"
                        outlined
                        dense
                        hide-details
                        :label="$t(`settings.quarter_${quarter}_statuut`)"
                        :rules="requiredRules"
                    />
                </v-col>
            </v-row>

            <submit-button class="mt-3" :loading="loadingSave" :disabled="!valid" @click="handleSubmit">
                {{ $t('general.save') }}
            </submit-button>
        </template>
    </v-form>
</template>

<script>
import LoaderInline from '@/components/LoaderInline.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import i18n from '@/i18n';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { useTaxStore } from '@/store/pinia/tax/tax.store';
import { computed, reactive, watch } from 'vue';

export default {
    components: {
        LoaderInline
    },
    mixins: [formRulesMixin],

    props: {
        dense: {
            type: Boolean,
            default: false
        },
        year: {
            type: Number,
            required: true
        }
    },

    setup(props) {
        const taxStore = useTaxStore();

        const currentYear = computed(() => props.year);

        taxStore.loadTax(currentYear.value);

        const data = reactive({ Q1: null, Q2: null, Q3: null, Q4: null });

        watch(
            taxStore.tax,
            () => {
                if (taxStore.tax[currentYear.value]) {
                    data.Q1 = taxStore.tax[currentYear.value].statuutQ1;
                    data.Q2 = taxStore.tax[currentYear.value].statuutQ2;
                    data.Q3 = taxStore.tax[currentYear.value].statuutQ3;
                    data.Q4 = taxStore.tax[currentYear.value].statuutQ4;
                }
            },
            { immediate: true, deep: true }
        );

        return {
            updateStatuten: taxStore.updateStatuten,
            data
        };
    },

    data() {
        return {
            error: null,
            loading: false,
            loadingSave: false,
            statuten: [
                {
                    id: 'student',
                    label: i18n.t('settings.statuten.student')
                },
                {
                    id: 'secondary',
                    label: i18n.t('settings.statuten.secondary')
                },
                {
                    id: 'main',
                    label: i18n.t('settings.statuten.main')
                },
                {
                    id: 'not_started',
                    label: i18n.t('settings.statuten.not_started')
                },
                {
                    id: 'stopped',
                    label: i18n.t('settings.statuten.stopped')
                }
            ],
            valid: false
        };
    },

    methods: {
        async handleSubmit() {
            this.loadingSave = true;
            try {
                await this.updateStatuten(this.year, this.data);

                // Request succeeded
                this.$notify({
                    title: this.$t('settings.statuten_succesfuly_changed'),
                    text: this.$t('settings.statuten_succesfuly_changed'),
                    type: 'success'
                });

                this.$emit('changed');
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loadingSave = false;
            }
        }
    }
};
</script>
