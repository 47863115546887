import axios from 'axios';
import { IStartResultatenrekeningBookingViewModel } from 'dexxter-types';
import { generateEndpoint } from '@/services/config';

const BASE_URL = generateEndpoint('/api/startResultatenrekeningbookings');

export function sendGetStartResultatenrekeningRequest(
    year: number
): Promise<IStartResultatenrekeningBookingViewModel[]> {
    return axios.get(`${BASE_URL}/${year}`).then((response) => response.data);
}

export function sendSetStartResultatenrekeningRequest(year: number, data: any): Promise<void> {
    return axios.post(`${BASE_URL}/${year}`, data).then((response) => response.data);
}
