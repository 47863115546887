
import { CalculateCO2TypeData, CreateCarData, UpdateCarData } from 'dexxter-types';

import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import mixins from 'vue-typed-mixins';
import { DeepNullable } from '@/helpers/types';
import { PropOptions } from 'vue';
import { computedWatcherResetMixin } from '@/mixins/computedWatcherResetMixin';
import CarForm from './CarForm.vue';
import { MiddlewareFnc, middlewareMixin, MiddlewareNextFnc } from '@/mixins/middlewareMixin';
import { apiErrorAndDisplay } from '../../../../../../helpers/errorHandling';
import { sendCreateCarRequest, sendGetCO2TypeCarRequest, sendUpdateCarRequest } from '../../../../../../services/car';
import ConfirmationDialogVue from '../../../../../../components/Dialogs/ConfirmationDialog.vue';
import { notify } from '../../../../../../helpers/successNotification';

type CarData = DeepNullable<CreateCarData> | DeepNullable<UpdateCarData>;

export default mixins(formRulesMixin, computedWatcherResetMixin, middlewareMixin).extend({
    components: { CarForm },
    mixins: [formRulesMixin],

    props: {
        carData: {
            required: true
        } as PropOptions<DeepNullable<CarData>>,
        carId: { required: true } as PropOptions<number | null>
    },

    data() {
        return {
            submitMiddleware: [] as MiddlewareFnc[],
            loading: false
        };
    },

    created() {
        this.submitMiddleware.push(this.showCO2InformationDialog);
        this.submitMiddleware.push(this.createOrUpdateCar);
    },

    methods: {
        async showCO2InformationDialog(next: MiddlewareNextFnc): Promise<void> {
            try {
                this.loading = true;

                const result = await sendGetCO2TypeCarRequest(this.carData as CalculateCO2TypeData);

                if (result === 'NORMAL_CO2') {
                    next();
                    return;
                }

                this.setDialogAttributes(
                    {
                        component: ConfirmationDialogVue,
                        dataId: 'show-co2-dialog',
                        title: this.$t('cars.form.middleware.co2Dialog.title') as string,
                        message: this.$t('cars.form.middleware.co2Dialog.content') as string,
                        positiveButton: this.$t('cars.form.middleware.co2Dialog.positiveClick') as string,
                        negativeButton: this.$t('cars.form.middleware.co2Dialog.negativeClick') as string
                    },
                    {
                        clickPositive: this.clickPositive.bind(this, next),
                        clickNegative: this.clickNegative.bind(this)
                    }
                );
                this.showDialog = true;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        handleSubmit() {
            this.runMiddleware(this.submitMiddleware);
        },
        async createOrUpdateCar() {
            try {
                this.loading = true;

                if (this.carId) {
                    await sendUpdateCarRequest(this.carId, this.carData as CreateCarData);

                    notify.call(this, {
                        title: this.$t('cars.createdSuccesfully'),
                        text: this.$t('cars.createdSuccesfully')
                    });
                } else {
                    await sendCreateCarRequest(this.carData as CreateCarData);

                    notify.call(this, {
                        title: this.$t('cars.updatedSuccesfully'),
                        text: this.$t('cars.updatedSuccesfully')
                    });
                }

                this.$emit('submission-done');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
});
