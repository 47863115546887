<template>
    <div class="creditnotes-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="3">
                <v-select
                    v-model="query.status"
                    :items="statusOptions"
                    :label="$t('general.status')"
                    item-text="label"
                    item-value="value"
                    clearable
                    hide-details
                    dense
                    outlined
                />
            </v-col>
            <v-col cols="12" md="9">
                <pagination-search v-model="query.q" />
            </v-col>
        </v-row>
        <pagination-data-table
            v-if="creditnoteIncomes.data"
            id="creditnotesTable"
            ref="paginationDataTable"
            :loading="loading"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="creditnoteIncomes"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
        >
            <template #[`item.creditnoteNumber`]="{ item }">
                {{ item.draft === false ? item.creditnoteNumber : '-' }}
            </template>

            <template #[`item.invoiceNumber`]="{ item }">
                <span v-if="item.invoiceId">
                    <v-btn small color="primary" :to="/edit-invoice/ + item.invoiceId">{{ item.invoiceNumber }}</v-btn>
                </span>
                <span v-else>
                    {{ item.invoiceNumber }}
                </span>
            </template>

            <template #[`item.date`]="{ item }">
                {{ item.date | formatDate }}
            </template>
            <template #[`item.amountEuroExclVAT`]="{ item }">
                € {{ item.amountEuroExclVAT || 0 | numericFormat }}
            </template>
            <template #[`item.amountEuroInclVAT`]="{ item }">
                € {{ item.amountEuroInclVAT || 0 | numericFormat }}
            </template>
            <template #[`item.total`]="{ item }"> € {{ item.amountEuroInclVAT || 0 | numericFormat }} </template>

            <template #[`item.status`]="{ item }">
                <creditnote-table-status :item="item" @click-send-icon="navigateToEmailHistory(item)" />
            </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" data-action="open-menu" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item v-if="item.url" @click="downloadIncomePDF(item.url)">
                                <v-list-item-title>
                                    {{ $t('general.download') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical === false" :to="/edit-creditnote/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical" :to="/edit-historical-creditnote/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!item.payedback && !item.draft" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.add_payment_back') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.payedback && !item.draft" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.undo_payment_back') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="!item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_not_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item data-action="delete" @click.stop="startDeleteMiddleware(item)">
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="showDialog"
            v-model="showDialog"
            v-bind="dialogAttributes.attributes"
            @click-positive="dialogAttributes.actions.clickPositive"
            @click-negative="dialogAttributes.actions.clickNegative"
        />
        <loader v-if="loading" />
    </div>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import PaginationDataTable from './PaginationDataTable.vue';
import PaginationSearch from '../PaginationSearch.vue';
// --- helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { filteringMixin } from '../../mixins/filtering';

import {
    deleteCreditnote,
    getCreditnotesPagination,
    togglePayedCreditnote,
    toggleSendToCustomerCreditnote
} from '@/services/creditnote';

import { isVatLiable } from '@/helpers/VATHelper';

import CreditnoteTableStatus from '../creditnoteTableStatus.vue';

import { middlewareMixin } from '@/mixins/middlewareMixin';
import { creditnoteProactiveSmartnotificationsMixin } from '@/mixins/creditnoteProactiveSmartnotificationsMixin';

export default {
    components: {
        ConfirmationDialog,
        PaginationSearch,
        PaginationDataTable,
        CreditnoteTableStatus
    },
    mixins: [filteringMixin, middlewareMixin, creditnoteProactiveSmartnotificationsMixin],

    clickable: {
        type: Boolean,
        default: false
    },
    data() {
        const currentUserData = this.$store.state.auth.currentUserData;

        const VATLiable = isVatLiable(currentUserData.settings.vatLiable);

        let sortableColumns = ['date', 'dueDate', 'invoiceNumber'];

        if (VATLiable) {
            sortableColumns.push('amountEuroInclVAT', 'amountEuroExclVAT');
        } else {
            sortableColumns.push('total');
        }

        const headers = [
            {
                text: this.$t('accountantTerms.credit_note_number'),
                align: 'start',
                value: 'creditnoteNumber',
                sortable: sortableColumns.includes('creditnoteNumber')
            },
            {
                text: this.$t('general.invoice'),
                value: 'invoiceNumber',
                sortable: sortableColumns.includes('invoiceNumber')
            },
            {
                text: this.$t('general.client'),
                value: 'customer.company.name',
                sortable: sortableColumns.includes('company')
            },
            { text: this.$t('general.date'), value: 'date' },
            VATLiable
                ? [
                      {
                          text: this.$t('general.total_excl_vat'),
                          value: 'amountEuroExclVAT',
                          sortable: sortableColumns.includes('amountEuroExclVAT')
                      },
                      {
                          text: this.$t('general.total_incl_vat'),
                          value: 'amountEuroInclVAT',
                          sortable: sortableColumns.includes('amountEuroInclVAT')
                      }
                  ]
                : {
                      text: this.$t('general.total'),
                      value: 'amountEuroExclVAT',
                      sortable: sortableColumns.includes('total')
                  },
            { text: this.$t('general.status'), value: 'status', sortable: sortableColumns.includes('status') },

            {
                text: this.$t('general.actions'),
                value: 'actions',
                sortable: false
            }
        ].flat();

        return {
            deleteCreditnoteMiddleware: [
                this.confirmationDeleteCreditnote,
                this.deleteProactiveSmartnotifications,
                this.removeCreditnote
            ],
            loading: false,
            headers,
            selectedIncome: '',
            dialog: false,
            creditnoteIncomes: {
                data: []
            },
            query: {
                status: null
            },
            statusOptions: [
                {
                    label: this.$t('general.published'),
                    value: 'published'
                },
                {
                    label: this.$t('general.draft'),
                    value: 'draft'
                },
                {
                    label: this.$t('general.payedback'),
                    value: 'payedback'
                },
                {
                    label: this.$t('general.not_payedback'),
                    value: 'notpayedback'
                },
                {
                    label: this.$t('general.sent'),
                    value: 'sent'
                },
                {
                    label: this.$t('general.not_sent'),
                    value: 'notsent'
                }
            ],
            sortableColumns
        };
    },

    computed: {
        ...mapState('accountantEmailBCC', ['accountantEmailBCCs']),
        ...mapState(['selectedYear']),
        hasUserAccountantEmailBCCs() {
            if (!this.accountantEmailBCCs) {
                return false;
            }

            return this.accountantEmailBCCs.length > 0;
        }
    },

    async created() {
        try {
            this.loading = true;
            await Promise.all([this.wrapperFunction()]);
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions(['startLoading', 'stopLoading']),

        confirmationDeleteCreditnote(next) {
            this.openConfirmationDialog();

            this.setDialogAttributes(
                {
                    title: this.$t('general.delete'),
                    message: this.$t('general.confirm_delete_message')
                },
                {
                    clickPositive: () => {
                        this.clickPositive.call(this, next);
                    }
                }
            );
        },
        async startDeleteMiddleware(creditnote) {
            this.runMiddleware(this.deleteCreditnoteMiddleware, creditnote);
        },
        navigateToEmailHistory(item) {
            this.$router.push({
                name: 'customer-mail',
                params: {
                    type: 'creditnote',
                    id: item.id
                }
            });
        },
        async removeCreditnote(next, creditnote) {
            this.startLoading();
            this.dialog = false;
            try {
                await deleteCreditnote(creditnote.id);
                await this.wrapperFunction();
                notify.call(this, {
                    title: this.$t('general.succesfuly_deleted'),
                    text: this.$t('general.succesfuly_deleted')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        async wrapperFunction() {
            this.loading = true;
            this.creditnoteIncomes = await getCreditnotesPagination(this.selectedYear, {
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loading = false;
        },
        async togglePayment(item) {
            try {
                this.startLoading();
                await togglePayedCreditnote(item.id);
                item.payedback = !item.payedback;

                notify.call(this, {
                    title: this.$t('income.payed_success'),
                    text: this.$t('income.payed_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        async toggleSendStatus(item) {
            try {
                this.startLoading();
                await toggleSendToCustomerCreditnote(item.id);
                item.sendtocustomer = !item.sendtocustomer;

                notify.call(this, {
                    title: this.$t('income.updated_success'),
                    text: this.$t('income.updated_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        downloadIncomePDF(url) {
            window.open(url);
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        }
    }
};
</script>
