<template>
    <div>
        <v-row justify="space-between">
            <v-col cols="12" sm="6" md="3" lg="3" class="searchbar">
                <slot name="search" />
            </v-col>
            <v-col cols="12" sm="3" lg="2">
                <toggle-menu-button
                    icon="mdi-filter-variant"
                    :active="extraFiltersVisible"
                    @click="extraFiltersVisible = !extraFiltersVisible"
                >
                    {{ $t('general.filter') }}
                </toggle-menu-button>
            </v-col>
        </v-row>
        <v-row class="filtering-container" :class="{ 'filters-visible': extraFiltersVisible }">
            <v-col cols="12" class="pt-0 pb-5">
                <v-row class="filter-grid">
                    <slot name="filters" />
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ToggleMenuButton from '../Button/ToggleMenuButton.vue';
export default {
    components: { ToggleMenuButton },
    inheritAttrs: false,

    data() {
        return {
            extraFiltersVisible: false
        };
    }
};
</script>
<style lang="scss" scoped>
@import '../../sass/variables';

.toggle-button {
    width: 100%;
}

:deep() {
    .searchbar .v-input {
        height: 44px !important;
    }
}

.filtering-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;

    &.filters-visible {
        max-height: 360px;

        @media (min-width: $xs) {
            max-height: 200px;
        }

        @media (min-width: $md) {
            max-height: 160px;
        }

        @media (min-width: $lg) {
            max-height: 120px;
        }
    }

    .filter-grid {
        display: grid;
        grid-template-columns: 1fr;
        align-items: flex-start;
        column-gap: 15px;

        @media (min-width: $xs) {
            grid-template-columns: 1fr 1fr;
        }

        @media (min-width: $md) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (min-width: $lg) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }

        .col {
            max-height: 60px;
        }

        .date-cols {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            grid-column: auto / span 1;

            .arrow-icon-wrapper {
                display: none;
            }

            @media (min-width: $xs) {
                flex-direction: row;
                grid-column: auto / span 2;
                grid-row: 2;

                .arrow-icon-wrapper {
                    display: block;
                }
            }

            @media (min-width: $lg) {
                grid-column: 4 / span 2;
                grid-row: 1;
            }
        }
    }
}
</style>
