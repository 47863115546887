<template>
    <div>
        <div v-for="link in content" :key="link.link">
            <handylink :link="link" />
            <v-divider />
        </div>
    </div>
</template>

<script>
import Handylink from './Handylink.vue';

export default {
    components: { Handylink },

    data() {
        return {
            content: [
                {
                    name: 'KBO',
                    description: 'Een handige pagina om gegevens van je klanten of leveranciers terug te vinden',
                    link: 'https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html'
                },
                {
                    name: 'Intervat',
                    description: 'Het digitale platform van de btw-administratie',
                    link: 'https://eservices.minfin.fgov.be/InterVAT/extra/vatScheme'
                },
                {
                    name: 'VIES',
                    description: 'Hier kan je een Europees btw-nummer controleren',
                    link: 'https://ec.europa.eu/taxation_customs/vies/?locale=nl'
                },
                {
                    name: 'Dexxter-blog',
                    description: 'Al onze nieuwtjes kan je via deze link vinden',
                    link: 'http://dexxter.be/blog'
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped></style>
