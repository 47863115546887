import { findExistingExpense } from '@/services/expenses';
import { apiWarningAndDisplay } from '@/helpers/warningHandling';
import dayjs from 'dayjs';

export const accountingExpenseCheckIfFileExistMixin = {
    watch: {
        'currentAccountingExpense.date': 'checkExpenseExists',
        'currentAccountingExpense.supplier': 'checkExpenseExists',
        'currentAccountingExpense.amountEuroExcl0VAT': 'checkExpenseExists',
        'currentAccountingExpense.amountEuroExcl6VAT': 'checkExpenseExists',
        'currentAccountingExpense.amountEuroExcl12VAT': 'checkExpenseExists',
        'currentAccountingExpense.amountEuroExcl21VAT': 'checkExpenseExists',
        'currentAccountingExpense.amountEuroExclVAT': 'checkExpenseExists',
        'currentAccountingExpense.amountEuroInclVAT': 'checkExpenseExists'
    },

    methods: {
        checkExpenseExists() {
            if (
                !this.currentAccountingExpense.id &&
                Boolean(this.currentAccountingExpense.supplier) &&
                Boolean(this.currentAccountingExpense.documentDate) &&
                dayjs(this.currentAccountingExpense.documentDate, 'YYYY-MM-DD', true).isValid()
            ) {
                if (!this.isCreatingNewExpense) {
                    return;
                }

                this.validateExpense();
            }
        },
        validateExpense() {
            findExistingExpense({
                documentDate: this.currentAccountingExpense.documentDate,
                supplier: this.currentAccountingExpense.supplier,
                amountEuroInclVAT: this.currentAccountingExpense.amountEuroInclVAT
            }).then((result) => {
                if (result.found === true) {
                    apiWarningAndDisplay.call(
                        this,
                        this.$i18n.t('addExpense.found_a_possible_duplicate'),
                        'expense-may-already-exist'
                    );
                }
            });
        }
    }
};
