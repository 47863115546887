import { VatStatus } from '@/config/constants';
import { AccountingExpenseModel } from '@/models/accountingexpense';
import { mustUseFictiveAmounts } from '../expense.service';
import { calculateBusinessAccountingCost } from './calculateBusinessAccountingCost';
import { calculateDeductibleVATOnExpense } from './calculateDeductibleVATOnExpense';

export function beroepsmatigeUitgaven(currentAccountingExpense: AccountingExpenseModel, vattype: VatStatus): number {
    if (!currentAccountingExpense.supplier) {
        return 0;
    }

    const businessPercentage = currentAccountingExpense.businessPercentage || 0;
    const VATDeductiblePercentage = currentAccountingExpense.VATDeductiblePercentage || 0;

    if (businessPercentage < VATDeductiblePercentage) {
        return 0;
    }

    if (businessPercentage === 0) {
        return 0;
    }

    return calculateBusinessAccountingCost(currentAccountingExpense, vattype);
}

export function calculateBusinessAccountingCostVAT(
    currentAccountingExpense: AccountingExpenseModel,
    vattype: VatStatus
): number {
    return calculateDeductibleVATOnExpense(currentAccountingExpense, vattype);
}

export function calculateTotalVATFromExpense({
    amountEuroExclVAT,
    amountEuroInclVAT
}: {
    amountEuroExclVAT: number;
    amountEuroInclVAT: number;
}): number {
    return amountEuroInclVAT - amountEuroExclVAT;
}

export function calculateTotalFictiveVATOnExpense({
    amountEuroExcl6VAT,
    amountEuroExcl12VAT,
    amountEuroExcl21VAT
}: {
    amountEuroExcl6VAT: number;
    amountEuroExcl12VAT: number;
    amountEuroExcl21VAT: number;
}): number {
    return amountEuroExcl21VAT * 0.21 + amountEuroExcl12VAT * 0.12 + amountEuroExcl6VAT * 0.06;
}

export function onlyFilledIn0ProcentAmount(currentAccountingExpense: AccountingExpenseModel): boolean {
    return !(
        currentAccountingExpense.amountEuroExcl6VAT ||
        currentAccountingExpense.amountEuroExcl12VAT ||
        currentAccountingExpense.amountEuroExcl21VAT
    );
}

export function getVATAmountToUseRealOrFictive(accountingexpense: AccountingExpenseModel, vattype: VatStatus): number {
    let VATOnExpense = 0;
    if (mustUseFictiveAmounts(accountingexpense, vattype)) {
        VATOnExpense = calculateTotalFictiveVATOnExpense({
            amountEuroExcl6VAT: accountingexpense.amountEuroExcl6VAT || 0,
            amountEuroExcl12VAT: accountingexpense.amountEuroExcl12VAT || 0,
            amountEuroExcl21VAT: accountingexpense.amountEuroExcl21VAT || 0
        });
    } else {
        VATOnExpense = calculateTotalVATFromExpense({
            amountEuroExclVAT: accountingexpense.amountEuroExclVAT || 0,
            amountEuroInclVAT: accountingexpense.amountEuroInclVAT || 0
        });
    }

    return VATOnExpense;
}
