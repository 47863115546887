import { generateEndpoint } from '@/services/config';
import { initializePaginationVars } from '@/store/utils';
import axios from 'axios';
import {
    CO2_CODES,
    CalculateCO2TypeData,
    CarPaginationQueryOptionsType,
    CreateCarData,
    ICarViewModel,
    UpdateCarData
} from 'dexxter-types';
import { PaginationResponse } from '../common/types';

const BASE_URL = generateEndpoint('/api/cars');

export function sendGetAllCarsPaginationRequest(options: {
    pagination: {
        currentPage: number;
        limit: number;
    };
    query: CarPaginationQueryOptionsType;
}): Promise<PaginationResponse<ICarViewModel>> {
    return axios
        .get(`${BASE_URL}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function sendDeleteCarRequest(carId: number): Promise<PaginationResponse<ICarViewModel>> {
    return axios.delete(`${BASE_URL}/${carId}`).then((response) => response.data);
}

export function sendCreateCarRequest(carData: CreateCarData): Promise<ICarViewModel> {
    return axios.post(`${BASE_URL}`, carData).then((response) => response.data);
}

export function sendGetCarRequest(carId: number): Promise<ICarViewModel> {
    return axios.get(`${BASE_URL}/${carId}`).then((response) => response.data);
}

export function sendUpdateCarRequest(carId: number, carData: UpdateCarData): Promise<ICarViewModel> {
    return axios.put(`${BASE_URL}/${carId}`, carData).then((response) => response.data);
}

export function sendGetCO2TypeCarRequest(carData: CalculateCO2TypeData): Promise<CO2_CODES> {
    return axios
        .get(`${BASE_URL}/typeofco2`, {
            params: carData
        })
        .then((response) => response.data);
}

export function sendGetDeductabilityRequest({
    carId,
    date,
    expenseCategoryId
}: {
    carId: number;
    date: string;
    expenseCategoryId: number;
}): Promise<CO2_CODES> {
    return axios
        .get(`${BASE_URL}/${carId}/deductability`, {
            params: {
                expenseDate: date,
                expenseCategoryId
            }
        })
        .then((response) => response.data);
}
