<template>
    <div id="expensesTable" class="billed_income-table">
        <v-row>
            <v-col cols="12" md="3">
                <v-select
                    v-model="query.status"
                    :items="statusOptions"
                    :label="$t('general.status')"
                    item-text="label"
                    item-value="value"
                    clearable
                    hide-details
                    dense
                    outlined
                />
            </v-col>
            <v-col cols="12" md="9">
                <pagination-search v-model="query.q" />
            </v-col>
        </v-row>

        <pagination-data-table
            ref="paginationDataTable"
            :loading="loading"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="expenses"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="handleClickRow"
            @selected-items="markSelectedItems"
        >
            <template #[`item.documentDate`]="{ item }">
                {{ item.documentDate | formatDate }}
            </template>

            <template #[`item.reference`]="{ item }">
                <v-tooltip v-if="item.reference" right>
                    <template #activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ item.reference | truncate(10) }}</span>
                    </template>
                    <span>{{ item.reference }}</span>
                </v-tooltip>
            </template>

            <template #[`item.amountEuroInclVAT`]="{ item }">
                € {{ (item.amountEuroInclVAT || 0) | numericFormat }}
            </template>

            <template #[`item.amountEuroExclVAT`]="{ item }">
                € {{ (item.amountEuroExclVAT || 0) | numericFormat }}
            </template>

            <template #[`item.status`]="{ item }">
                <chip v-if="item.sendToAccountant" color="green">
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-icon v-bind="attrs" small @click="navigateToEmailHistory(item)" v-on="on"
                                >mdi-send-check-outline</v-icon
                            >
                        </template>
                        <span> {{ $t('income.invoice_send') }}</span>
                    </v-tooltip>
                </chip>
                <expense-table-status :item="item" />
            </template>

            <template #[`item.dueDate`]="{ item }">
                {{ item.dueDate | formatDate }}
            </template>

            <template #[`item.supplier`]="{ item }">
                {{ item.supplier.company.name }}
            </template>

            <template #[`item.type`]="{ item }">
                {{
                    $te('expenseCategory.' + item.expensecategory.number)
                        ? $t('expenseCategory.' + item.expensecategory.number)
                        : item.expensecategory.name
                }}
            </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon data-action="open-menu" v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item v-if="item.expensefile" @click="downloadExpensePDF(item.expensefile.url)">
                                <v-list-item-title>
                                    {{ $t('general.download') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item :to="/edit-expense/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!item.payed" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.add_payment') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.payed" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.remove_payment') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                data-action="delete"
                                @click.stop="
                                    {
                                        dialog = true;
                                        selectedExpenseId = item.id;
                                    }
                                "
                            >
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :loading="loading"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeExpense"
            @click-negative="dialog = false"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';

import { filteringMixin, tableName } from '../../mixins/filtering';

// --- Helpers ---
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import PaginationSearch from '../PaginationSearch.vue';
import PaginationDataTable from './PaginationDataTable.vue';
import { isVatLiable } from '@/helpers/VATHelper';

import { deleteExpense, getExpenses, togglePayedExpense } from '@/services/expenses';

import _ from 'lodash';
import ExpenseTableStatus from '../ExpenseTableStatus.vue';
import { usePreventDuplicateRoute } from '@/composables/usePreventDuplicateRoute';

export default {
    components: {
        ConfirmationDialog,
        PaginationDataTable,
        PaginationSearch,
        ExpenseTableStatus
    },
    mixins: [filteringMixin],
    setup() {
        const { preventDuplicateRoute } = usePreventDuplicateRoute();

        return { preventDuplicateRoute };
    },
    data() {
        const sortableColumns = ['date', 'documentDate', 'dueDate'];

        return {
            headers: [
                {
                    text: this.$t('general.supplier'),
                    value: 'supplier',
                    sortable: sortableColumns.includes('supplier')
                },
                {
                    text: this.$t('general.reference'),
                    value: 'reference',
                    sortable: sortableColumns.includes('reference')
                },
                {
                    text: this.$t('general.date'),
                    value: 'documentDate',
                    sortable: sortableColumns.includes('documentDate')
                },
                { text: this.$t('general.due_date'), value: 'dueDate', sortable: sortableColumns.includes('dueDate') },
                { text: this.$t('general.type'), value: 'type', sortable: sortableColumns.includes('type') },
                {
                    text: this.$t('general.amount_incl_vat'),
                    value: 'amountEuroInclVAT',
                    sortable: sortableColumns.includes('amountEuroInclVAT')
                },
                {
                    text: this.$t('general.amount_excl_vat'),
                    value: 'amountEuroExclVAT',
                    sortable: sortableColumns.includes('amountEuroExclVAT')
                },
                { text: this.$t('general.status'), value: 'status', sortable: sortableColumns.includes('status') },

                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            selectedExpenseId: '',
            query: {
                status: null,
                type: null
            },
            [tableName]: 'expenseTable',
            statusOptions: [
                {
                    label: this.$t('general.payed'),
                    value: 'payed'
                },
                {
                    label: this.$t('general.unpaid'),
                    value: 'unpaid'
                },
                {
                    label: this.$t('general.creditnote'),
                    value: 'creditnote'
                },
                {
                    label: this.$t('general.expired'),
                    value: 'expired'
                }
            ],
            sortableColumns,
            loading: false,
            expenses: {
                data: []
            },
            selectedExpenses: []
        };
    },
    computed: {
        ...mapState('expenses', ['newExpenseEvents']),
        ...mapState(['selectedYear']),
        ...mapState('auth', ['currentUserData']),
        ...mapState('accountantEmailBCC', ['accountantEmailBCCs']),
        hasUserAccountantEmailBCCs() {
            if (!this.accountantEmailBCCs) {
                return false;
            }

            return this.accountantEmailBCCs.length > 0;
        },
        hasSelectedItems() {
            return Array.isArray(this.selectedExpenses) && this.selectedExpenseId.length > 0;
        }
    },
    watch: {
        currentUserData: {
            handler(newCurrentUserData) {
                if (newCurrentUserData && !isVatLiable(newCurrentUserData.settings.vatLiable)) {
                    this.statusOptions = _.filter(this.statusOptions, (item) => {
                        return !['recordInVATDeclaration', 'notRecordInVATDeclaration'].includes(item.value);
                    });
                }
            },
            deep: true,
            immediate: true
        },
        newExpenseEvents: {
            handler() {
                this.wrapperFunction();
            },
            deep: true
        }
    },
    async created() {
        try {
            this.loading = true;
            await Promise.all([this.wrapperFunction()]);
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        navigateToEmailHistory(item) {
            this.$router.push({
                name: 'customer-mail',
                params: {
                    type: 'expense',
                    id: item.id
                }
            });
        },
        async removeExpense() {
            this.startLoading();
            this.loading = true;
            try {
                await deleteExpense(this.selectedExpenseId);
                notify.call(this, {
                    title: this.$t('income.deleted_invoice_succesfuly'),
                    text: this.$t('income.deleted_invoice_succesfuly')
                });

                await this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialog = false;
                this.loading = false;
            }
        },
        async wrapperFunction() {
            try {
                this.startLoading();
                this.loading = true;

                this.expenses = await getExpenses(this.selectedYear, {
                    pagination: {
                        force: true,
                        currentPage: this.pagination.currentPage,
                        limit: this.pagination.limit
                    },
                    query: this.query
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.loading = false;
            }
        },
        downloadExpensePDF(url) {
            window.open(url);
        },
        async togglePayment(item) {
            try {
                this.startLoading();
                await togglePayedExpense(item.id);
                item.payed = !item.payed;

                this.$notify({
                    title: this.$t('expense.payed_success'),
                    text: this.$t('expense.payed_success'),
                    type: 'success'
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        routeToEditExpense(expenseId) {
            this.$router.push({
                name: 'edit-expense',
                params: {
                    id: expenseId
                }
            });
        },
        handleClickRow(expense) {
            this.preventDuplicateRoute(this.routeToEditExpense, expense.id);
        },
        markSelectedItems(items) {
            this.selectedExpenses = items;
        }
    }
};
</script>
