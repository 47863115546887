
import dayjs from 'dayjs';
import Timeline from '@/components/Timeline.vue';

import { informationpopupMixin } from '@/mixins/informationpopupMixin';

import mixins from 'vue-typed-mixins';
import { TimelineFeedViewModel } from 'dexxter-types';
import { sendGetTimelineRequest } from '@/services/timeline';

export default mixins(informationpopupMixin).extend({
    components: {
        Timeline
    },

    props: {
        forGivenYear: {
            type: Number,
            required: false
        },
        mandatoryOnly: {
            required: true,
            type: Boolean
        }
    },

    data() {
        return {
            loading: {
                timeline: true
            },
            timelineData: null as TimelineFeedViewModel | null
        };
    },

    async created() {
        try {
            this.loading.timeline = true;
            this.timelineData = await sendGetTimelineRequest(this.forGivenYear).then((timelineData) =>
                this.filterTimelineitemsRelevantForDashboard(timelineData)
            );
        } finally {
            this.loading.timeline = false;
        }
    },

    methods: {
        filterTimelineitemsRelevantForDashboard(timelineData: TimelineFeedViewModel) {
            return timelineData.filter((_event) => {
                const getDateOfSelectedYear = _event.calendarEvent.date;

                if (!getDateOfSelectedYear) {
                    return false;
                }

                if (
                    _event.status === 'notDone' &&
                    dayjs(getDateOfSelectedYear.date, 'YYYY-MM-DD').isBefore(dayjs(), 'date')
                ) {
                    return true;
                }

                return dayjs(getDateOfSelectedYear.date, 'YYYY-MM-DD').isSameOrAfter(dayjs(), 'date');
            });
        }
    }
});
