import { DeepNullable } from '@/helpers/types';
import { CreateCarData } from 'dexxter-types';

export function createCarData(): DeepNullable<CreateCarData> {
    return {
        name: null,
        licenseplate: null,
        co2: null,
        fuelType: null,
        purchaseDate: null,
        registrationDate: null,
        fiscalePK: null,
        weight: null,
        kWh: null
    };
}

export type FormFields = keyof ReturnType<typeof createCarData>;
