import { Quarters } from '@/config/constants';
import axios from 'axios';
import { ICCustomerViewModel } from './viewmodels/general';
import { downloadBlobFile } from '@/services/utils';
import { generateEndpoint } from '@/services/config';

const BASE_URL = generateEndpoint('/api/vat');
const BASE_URL_QUARTERS = generateEndpoint('/api/quarters');
export const DELETE_VAT_SUBMISSION = (id: number): string => `${BASE_URL}/vatsubmission/${id}`;

export function sendDownloadVATDeclarationXMLRequest(
    {
        year,
        quarter,
        withRepayment
    }: {
        year: number;
        quarter: Quarters;
        withRepayment: boolean;
    },
    {
        filename
    }: {
        filename: string;
    }
) {
    return downloadBlobFile({
        url: `${BASE_URL_QUARTERS}/year/${year}/${quarter}/declaration/xml`,
        method: 'post',
        downloadFileName: filename,
        data: {
            withRepayment
        }
    });
}

export function getAllICCustomersRequest(year: number, quarter: Quarters): Promise<ICCustomerViewModel[]> {
    return axios.get(`${BASE_URL}/ic/year/${year}/quarter/${quarter}`).then((response) => response.data);
}

export function getUserNotVATLiableBorderRequest(year: number): Promise<number> {
    return axios.get(`${BASE_URL}/userNotVATLiableBorder/${year}`).then((response) => response.data);
}

export function downloadClientICListingXMLRequest({
    year,
    quarter,
    filename
}: {
    year: number;
    quarter: Quarters;
    filename: string;
}) {
    return downloadBlobFile({
        url: `${BASE_URL}/year/${year}/quarter/${quarter}/iclisting`,
        method: 'get',
        downloadFileName: filename,
        headers: {
            accept: 'application/xml'
        }
    });
}

export function getVATSubmissionsRequest(year: number) {
    return axios.get(`${BASE_URL}/vatsubmission/${year}`).then((response) => response.data);
}

export function sendDeleteVATSubmissionsRequest(id: number) {
    return axios.delete(DELETE_VAT_SUBMISSION(id)).then((response) => response.data);
}

export function uploadVATSubmissionRequest(file: Blob, year: number, quarter: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('year', year.toString());
    formData.append('quarter', quarter);

    return axios.post(`${BASE_URL}/vatsubmission`, formData);
}
