import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import {
    IMailViewDTO,
    InvoicableTemplatesViewModels,
    IPlaceholderMapCreditnote,
    IPlaceholderMapInvoice,
    IPlaceholderMapQuotation,
    mailableType
} from './email.types';

const BASE_URL = generateEndpoint('/api/emails');

export function getTemplates(): Promise<InvoicableTemplatesViewModels> {
    return axios.get(`${BASE_URL}/templates`).then((response) => response.data);
}

export function getMails(id: number, type: mailableType): Promise<IMailViewDTO[]> {
    return axios.get(`${BASE_URL}/${type}/${id}`).then((response) => response.data);
}

export function updateTemplate(templateId: number, template: { subject: string; text: string }): Promise<void> {
    return axios.put(`${BASE_URL}/templates/${templateId}`, { template }).then((response) => response.data);
}

export function getInvoicePlaceholderMap(invoiceID: number): Promise<IPlaceholderMapInvoice> {
    return axios.get(`${BASE_URL}/invoice/${invoiceID}/placholdermap`).then((response) => response.data);
}

export function getQuotationPlaceholderMap(invoiceID: number): Promise<IPlaceholderMapQuotation> {
    return axios.get(`${BASE_URL}/quotation/${invoiceID}/placholdermap`).then((response) => response.data);
}

export function getCreditnotePlaceholderMap(invoiceID: number): Promise<IPlaceholderMapCreditnote> {
    return axios.get(`${BASE_URL}/creditnote/${invoiceID}/placholdermap`).then((response) => response.data);
}
