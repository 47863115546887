<template>
    <div id="expense_files-table">
        <pagination-data-table
            ref="paginationDataTable"
            :top-pagination="false"
            :loading="loading"
            :headers="headers"
            :hide-default-header="hasSelectedExpenseFiles"
            :pagination="pagination"
            :pagination-data="expensefiles"
            show-select
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @selected-items="handleSelectedItems"
        >
            <template v-if="hasSelectedExpenseFiles" #header="{ on, props }">
                <thead>
                    <tr>
                        <th colspan="40">
                            <div class="selectBar d-flex align-center">
                                <div class="pr-5">
                                    <div v-if="props.everyItem" class="check" @click="on[`toggle-select-all`](false)">
                                        <v-icon>mdi-checkbox-marked</v-icon>
                                    </div>
                                    <template v-else>
                                        <div
                                            v-if="!props.everyItem && !hasSelectedExpenseFiles"
                                            class="check"
                                            @click="on[`toggle-select-all`](true)"
                                        >
                                            <v-icon>mdi-checkbox-blank-outline</v-icon>
                                        </div>
                                        <div v-else class="check" @click="on[`toggle-select-all`](true)">
                                            <v-icon>mdi-minus-box</v-icon>
                                        </div>
                                    </template>
                                </div>
                                <submit-button small @click="startProcessSelectedExpenseFiles"
                                    >Verwerk uitgaven</submit-button
                                >
                            </div>
                        </th>
                    </tr>
                </thead>
            </template>

            <template #[`item.originalFileName`]="{ item }">
                <router-link :to="getAddExpenseLink(item.id)">{{ item.originalFileName }}</router-link>
            </template>
            <template #[`item.createdAt`]="{ item }">
                {{ item.createdAt | formatTimestamp }}
            </template>
            <template #[`item.deliveryMethod`]="{ item }">
                <chip>{{ $t(`expensefile.deliveryMethods.${item.deliveryMethod}`) }}</chip>
            </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item v-if="item.url" @click="downloadExpensePDF(item.url)">
                                <v-list-item-title>
                                    {{ $t('general.download') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click.stop="
                                    {
                                        dialog = true;
                                        selectedExpense = item.id;
                                    }
                                "
                            >
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :loading="loading"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeExpenseFile"
            @click-negative="dialog = false"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';

import { filteringMixin } from '../../mixins/filtering';

// --- Helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { deleteExpenseFile, getExpenseFilesPagination } from '@/services/expensefiles';
import Chip from '../Chip.vue';
import SubmitButton from '../SubmitButton.vue';
import PaginationDataTable from './PaginationDataTable.vue';

export default {
    components: {
        ConfirmationDialog,
        PaginationDataTable,
        SubmitButton,
        Chip
    },
    mixins: [filteringMixin],

    data() {
        const sortableColumns = [];
        return {
            headers: [
                {
                    text: this.$t('general.name'),
                    value: 'originalFileName',
                    sortable: sortableColumns.includes('originalFileName')
                },
                {
                    text: this.$t('expensefile.deliveryMethod'),
                    value: 'deliveryMethod',
                    sortable: sortableColumns.includes('deliveryMethod')
                },
                {
                    text: this.$t('expensefile.deliveredAt'),
                    value: 'createdAt',
                    sortable: sortableColumns.includes('createdAt')
                },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            selectedExpense: '',
            query: {
                status: null,
                type: null
            },
            sortableColumns,
            loading: false,
            expensefiles: {
                data: []
            },
            selectedExpenseFiles: []
        };
    },

    computed: {
        hasSelectedExpenseFiles() {
            return this.selectedExpenseFiles.length > 0;
        }
    },

    created() {
        this.wrapperFunction();
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('expensefiles', ['setToProcessExpenseFiles']),
        startProcessSelectedExpenseFiles() {
            this.setToProcessExpenseFiles(this.selectedExpenseFiles.map((file) => file.id));
            this.$router.push(this.getProcessSelectedExpenseFileLink(this.selectedExpenseFiles[0].id));
        },
        getProcessSelectedExpenseFileLink(beginFileId) {
            // TODO: Rework for OCP (open closed principle)
            if (this.$can('CREATE', 'Accountingexpense')) {
                return `/add-accounting-expense?expenseFileId=${beginFileId}`;
            }
            return `/add-expense?expenseFileId=${beginFileId}`;
        },
        handleSelectedItems(selectedItems) {
            this.selectedExpenseFiles = selectedItems;
        },
        async removeExpenseFile() {
            this.startLoading();
            this.loading = true;
            try {
                await deleteExpenseFile(this.selectedExpense);
                notify.call(this, {
                    title: this.$t('expensefile.succesfuly_deleted.title'),
                    text: this.$t('expensefile.succesfuly_deleted.text')
                });

                await this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialog = false;
                this.loading = false;
            }
        },
        async wrapperFunction() {
            try {
                this.startLoading();
                this.loading = true;

                this.expensefiles = await getExpenseFilesPagination({
                    pagination: {
                        force: true,
                        currentPage: this.pagination.currentPage,
                        limit: this.pagination.limit
                    },
                    query: this.query
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.loading = false;
            }
        },
        downloadExpensePDF(url) {
            window.open(url);
        },
        getAddExpenseLink(expenseFileId) {
            // TODO: Rework for OCP (open closed principle)
            if (this.$can('CREATE', 'Accountingexpense')) {
                return `/add-accounting-expense?expenseFileId=${expenseFileId}`;
            }

            return `/add-expense?expenseFileId=${expenseFileId}`;
        }
    }
};
</script>
<style lang="scss">
#expense_files-table {
    .selectBar {
        padding: 5px 0 17px 0;
    }

    tbody tr td:first-child {
        width: 1% !important;
    }

    .check {
        cursor: pointer;
    }
}
</style>
