
// --- Components ---
import Loader from '@/components/Loader.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import ICListingTable from './components/ICVatListingTable.vue';
// --- State ---
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import PageHeader from '@/components/PageHeader.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import VCardHeader from '@/components/vCardHeader.vue';
import { isVatLiable } from '@/helpers/VATHelper';
import { mapMutations, mapState } from 'vuex';

import { notify } from '@/helpers/successNotification';
import { MiddlewareNextFnc, middlewareMixin } from '@/mixins/middlewareMixin';
import { deleteICSubmissionsRequest, getICSubmissionsRequest, uploadICSubmissionRequest } from '@/services/iclisting';
import { downloadClientICListingXMLRequest, getAllICCustomersRequest } from '@/services/vat';
import SubmitICConfirmationReceiptDialog from './components/submitICConfirmationReceiptDialog.vue';

import { downloadBlobFile } from '@/services/utils';
import { GeneralFncType } from '@/types/general';
import mixins from 'vue-typed-mixins';

import { Quarters } from '@/config/constants';

import ErrorModal from '@/components/Modal/ErrorModal.vue';
import { getCurrentDateString, getQuarterFromQuarterNumber } from '@/helpers/dateHelpers';
import { ICCustomerViewModel, IICSubmissionViewModel } from '@/services/viewmodels/general';
import { canDownloadVatOrIcListing, getUserFriendlyQuarter } from '@/views/VAT/businessServices/vatdeclaration.service';
import { ICCustomer } from './domain/types';

export default mixins(middlewareMixin).extend({
    components: {
        Loader,
        ICListingTable,
        ViewLayout,
        PageHeader,
        ConfirmationDialog,
        VCardHeader,
        SubmitICConfirmationReceiptDialog
    },

    data() {
        return {
            submitConfirmationReceiptDialog: false as boolean,
            showDialog: false as boolean,
            loading: false as boolean,
            currentTab: 0 as number,
            ICCustomers: {
                Q1: null,
                Q2: null,
                Q3: null,
                Q4: null
            } as {
                Q1: null | ICCustomer[];
                Q2: null | ICCustomer[];
                Q3: null | ICCustomer[];
                Q4: null | ICCustomer[];
            },
            deleteICSubmissionDialog: false as boolean,
            quarters: Quarters,
            selectedICSubmissionIDToDelete: null as null | number,
            userDataExportLoading: false as boolean,
            icListingSubmissions: [] as IICSubmissionViewModel[],
            ICDeclarationSubmitMiddleware: [] as GeneralFncType[]
        };
    },

    async created() {
        await Promise.all([
            this.getICCustomers(`Q1`),
            this.getICCustomers(`Q2`),
            this.getICCustomers(`Q3`),
            this.getICCustomers(`Q4`),
            this.getICSubmissions()
        ]);

        const currentQuarter = getUserFriendlyQuarter(this.selectedYear, this.icListingSubmissions);

        // Tabs are indexed from 0, so we need to subtract 1 from the current quarter to get the correct index
        this.currentTab = parseInt(currentQuarter.replace('Q', '')) - 1;

        this.ICDeclarationSubmitMiddleware = [
            this.checkIfUserCanSubmitVatListing,
            this.checkIfICListingEmpty,
            this.openDownloadICListingDialog
        ];
    },

    computed: {
        ...mapState(['selectedYear']),
        ...mapState('auth', ['currentUserData']),
        vatLiable(): boolean {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        },
        selectedQuarterNumber(): number {
            return this.currentTab + 1;
        },
        selectedQuarter(): string {
            return `Q${this.selectedQuarterNumber}`;
        }
    },

    methods: {
        ...mapMutations('modal', ['setModalContent']),
        async getICSubmissions() {
            try {
                this.loading = true;
                this.icListingSubmissions = await getICSubmissionsRequest(this.selectedYear);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        getICSubmission() {
            return this.icListingSubmissions.find((_submission) => {
                return _submission.quarter === this.selectedQuarterNumber;
            });
        },
        reformatURLForGoogleStorageCORS(url: string): string {
            const pieces = url.split('/');
            const storageName = pieces[2];
            const bucketName = pieces[3];

            const newBucketName = `${bucketName}.${storageName}`;

            return url.replace(`${storageName}/${bucketName}`, newBucketName);
        },
        async downloadICSubmission(url: string): Promise<void> {
            try {
                this.loading = true;

                await downloadBlobFile({
                    url,
                    downloadFileName: `iclisting-submission-${this.selectedYear}-Q${this.selectedQuarterNumber}.xml`,
                    method: 'get'
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        async submitFile() {
            try {
                this.loading = true;

                await uploadICSubmissionRequest(null, this.selectedYear, this.selectedQuarterNumber);

                this.$emit('click-positive');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        isICListingEmpty(quarter: Quarters): boolean {
            const quarterCustomers = this.ICCustomers[quarter];

            if (!quarterCustomers) {
                return true;
            }

            return quarterCustomers.length === 0;
        },
        checkIfUserCanSubmitVatListing(next: MiddlewareNextFnc) {
            if (
                !canDownloadVatOrIcListing(getCurrentDateString(), this.selectedQuarter as Quarters, this.selectedYear)
            ) {
                this.openCannotSubmitIcModalYet();
            } else {
                next();
            }
        },
        openCannotSubmitIcModalYet(): void {
            this.setModalContent({
                component: ErrorModal,
                props: {
                    i18nTitle: 'videotooltips.errors.cannotUploadIcListingWhenQuarterIsNotOver.title',
                    i18nText: 'videotooltips.errors.cannotUploadIcListingWhenQuarterIsNotOver.text',
                    actions: [
                        {
                            action: () => this.$store.commit('modal/setIsModalOpen', false),
                            i18nPath: 'videotooltips.errors.cannotUploadIcListingWhenQuarterIsNotOver.primary_button',
                            icon: 'mdi-check'
                        }
                    ]
                },
                dataId: 'cannot-submit-ic-modal'
            });
        },

        async openDownloadICListingDialog(next: MiddlewareNextFnc, quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4'): Promise<void> {
            try {
                const clickPositive = () => {
                    this.submitConfirmationReceiptDialog = false;
                    this.getICSubmissions();
                };

                const clickNegative = () => {
                    this.submitConfirmationReceiptDialog = false;
                };

                this.dialogAttributes = {
                    component: null,
                    attributes: {
                        ...this.dialogAttributes.attributes,
                        year: parseInt(this.selectedYear),
                        quarter: parseInt(quarter.slice(1, 2))
                    },
                    actions: {
                        clickPositive,
                        clickNegative
                    }
                };

                await downloadClientICListingXMLRequest({
                    filename: `iclisting-${this.selectedYear}-${parseInt(quarter.slice(1, 2))}.xml`,
                    year: this.selectedYear,
                    quarter: this.getCurrentQuarterString()
                });

                this.submitConfirmationReceiptDialog = true;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
                this.submitConfirmationReceiptDialog = false;
            }
        },
        async checkIfICListingEmpty(next: MiddlewareNextFnc, quarter: Quarters): Promise<void> {
            if (!this.isICListingEmpty(quarter)) {
                next();
                return;
            }

            this.showDialog = true;

            const clickPositive = async () => {
                await this.submitFile();
                await this.getICSubmissions();
                this.showDialog = false;
                return;
            };

            this.setDialogAttributes(
                {
                    title: this.$t('iclisting.ICListingIsEmpty.title') as string,
                    message: this.$t('iclisting.ICListingIsEmpty.content') as string,
                    positiveButton: this.$t('general.confirm') as string,
                    positiveColor: 'green',
                    negativeButton: null,
                    dataId: 'ICListingIsEmpty'
                },
                { clickPositive }
            );
        },

        async deleteICSubmission(): Promise<void> {
            try {
                if (!this.selectedICSubmissionIDToDelete) {
                    throw new Error('ID is not defined');
                }

                this.loading = true;

                await deleteICSubmissionsRequest(this.selectedICSubmissionIDToDelete);

                notify.call(this, {
                    title: this.$t('general.succesfuly_deleted'),
                    text: this.$t('general.succesfuly_deleted')
                });

                this.icListingSubmissions = await getICSubmissionsRequest(this.selectedYear);
                this.selectedICSubmissionIDToDelete = null;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.deleteICSubmissionDialog = false;
            }
        },

        openDeleteICSubmissionDialog(submissionID: number) {
            this.selectedICSubmissionIDToDelete = submissionID;
            this.deleteICSubmissionDialog = true;
        },

        async getICCustomers(quarter: Quarters): Promise<void> {
            this.loading = true;
            try {
                this.ICCustomers[quarter] = this.preprocessICCustomers(
                    await getAllICCustomersRequest(this.selectedYear, quarter)
                );
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },

        preprocessICCustomers(ICCustomers: ICCustomerViewModel[]): ICCustomer[] {
            return this.createNewICCustomerPerTypeOfIC(ICCustomers);
        },

        // Split up every entry of S and L per customer
        createNewICCustomerPerTypeOfIC(ICCustomers: ICCustomerViewModel[]): ICCustomer[] {
            const newICListing: ICCustomer[] = [];
            ICCustomers.forEach((customer) => {
                if (customer.amountEuroExclVATTotalServices) {
                    newICListing.push({
                        ...customer,
                        typeOfIC: 'S',
                        newId: customer.id + 'S'
                    });
                }
                if (customer.amountEuroExclVATTotalGoods) {
                    newICListing.push({
                        ...customer,
                        typeOfIC: 'L',
                        newId: customer.id + 'L'
                    });
                }
            });

            return newICListing;
        },

        getCurrentQuarterString(): Quarters {
            return getQuarterFromQuarterNumber(this.currentTab + 1);
        }
    }
});
