export function mustShowSellInvestementDialog(data: {
    invoice: { depreciationficheId?: number | null };
    previousInvoice?: { depreciationficheId?: number | null };
}): boolean {
    if (!data.invoice.depreciationficheId) {
        return false;
    }

    if (didThePreviousVersionAlreadySoldADepreciation(data.previousInvoice)) {
        return false;
    }

    return true;
}

function didThePreviousVersionAlreadySoldADepreciation(previousInvoice?: { depreciationficheId?: number | null }) {
    return previousInvoice && previousInvoice.depreciationficheId;
}
