<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                        <sub>
                            <tooltip :underlined="false" i18n-path="tooltips.addExpense.deductability"
                                ><template slot="activator">
                                    <v-icon small> mdi-information </v-icon></template
                                ></tooltip
                            >
                        </sub>
                    </template>
                </page-header>
            </template>
            <template #content>
                <section v-if="currentUserData">
                    <v-row>
                        <v-col cols="12">
                            <v-card outlined class="mb-4 pa-8">
                                <div class="d-flex">
                                    <v-spacer />

                                    <submit-button @click="newExpenseCategory">{{
                                        $t('general.create_expense_category')
                                    }}</submit-button>
                                </div>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <expensecategories-table dense @click-row="editExpenseCategory" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </section>
                <loader v-if="loading" />
            </template>
        </view-layout>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// --- Components ---
import ExpensecategoriesTable from '@/components/DataTables/ExpensecategoriesTable.vue';

import Loader from '@/components/Loader.vue';
// --- State ---

// --- Error handling ---
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import SubmitButton from '@/components/SubmitButton.vue';

export default {
    components: {
        Loader,
        ExpensecategoriesTable,
        ViewLayout,
        PageHeader,
        SubmitButton
    },

    data() {
        return {
            loading: false
        };
    },

    computed: {
        ...mapState('accountantUsers', ['accountants']),
        ...mapState('invitations', ['invitations']),
        ...mapState('auth', ['currentUserData']),
        userHasAccountant() {
            return this.accountants.length > 0;
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['newExpenseCategory', 'editExpenseCategory'])
    }
};
</script>
