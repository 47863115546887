
import Vue from 'vue';

export default Vue.extend({
    name: 'CurrentPreviousQuarterResult',
    props: {
        currentPeriodResult: {
            required: true,
            type: Number
        },
        previousPeriodResult: {
            required: true,
            type: Number
        }
    }
});
