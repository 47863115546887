import { changeIncomeErrorHandler } from '@/helpers/changeIncome/changeincome';
import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';
import { changeIncomeCheck } from '@/services/proactivesmartnotifications';
import { calculateProratedVatExemptBorder } from '@dexxter/accountancy-tools';
import dayjs from 'dayjs';
import { sumBy } from 'lodash';

export const dailyreceiptProactiveSmartnotificationsMixin = {
    methods: {
        async dailyreceiptProactiveSmartnotifications(next, dailyreceipts) {
            const amountEuroExclVAT = this.getTotalEuroExclVATBulk(dailyreceipts);

            const postChecks = await changeIncomeCheck({
                year: dayjs(this.date, 'YYYY-MM-DD').year(),
                amountEuroExclVAT
            }).catch(changeIncomeErrorHandler);

            if (postChecks.length === 0) {
                return next();
            }

            this.showDialog = true;

            this.setDialogAttributes(
                {
                    title: this.$i18n.t('general.confirmation'),
                    message: postChecks
                        .map((code) => {
                            if (code === 'TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE') {
                                return this.$i18n.t(
                                    `proactiveSmartNotifications.addIncome.TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE`,
                                    {
                                        grens: convertToLocaleValuta(
                                            calculateProratedVatExemptBorder(
                                                25000,
                                                dayjs(this.date, 'YYYY-MM-DD').year(),
                                                this.currentUserData.settings.companyStartDate
                                            ),
                                            {
                                                currency: 'EUR'
                                            }
                                        )
                                    }
                                );
                            }

                            return this.$i18n.t(`proactiveSmartNotifications.addIncome.${code}`);
                        })
                        .join('\n\n'),
                    negativeColor: 'message',
                    positiveColor: 'orange'
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );
        },

        async deleteDailyreceiptProactiveSmartnotifications(next, dailyreceipt) {
            const amountEuroExclVAT = -this.getTotalEuroExclVAT(dailyreceipt);

            const postChecks = await changeIncomeCheck({
                year: dayjs(dailyreceipt.date, 'YYYY-MM-DD').year(),
                amountEuroExclVAT
            }).catch(changeIncomeErrorHandler);

            if (postChecks.length === 0) {
                return next();
            }

            this.showDialog = true;

            this.setDialogAttributes(
                {
                    title: this.$i18n.t('general.confirmation'),
                    message: postChecks
                        .map((code) => {
                            if (code === 'TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE') {
                                return this.$i18n.t(
                                    `proactiveSmartNotifications.addIncome.TOTAL_REVENUE_ABOVE_LIMIT_NONVATLIABLE`,
                                    {
                                        grens: convertToLocaleValuta(
                                            calculateProratedVatExemptBorder(
                                                25000,
                                                dayjs(dailyreceipt.date, 'YYYY-MM-DD').year(),
                                                this.currentUserData.settings.companyStartDate
                                            ),
                                            {
                                                currency: 'EUR'
                                            }
                                        )
                                    }
                                );
                            }

                            return this.$i18n.t(`proactiveSmartNotifications.addIncome.${code}`);
                        })
                        .join('\n\n'),
                    negativeColor: 'message',
                    positiveColor: 'orange'
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );
        },

        getTotalEuroExclVATBulk(dailyreceipts) {
            return sumBy(dailyreceipts, (dailyreceipt) => {
                return dailyreceipt.amountEuroExclVAT;
            });
        },

        getTotalEuroExclVAT(dailyreceipt) {
            return dailyreceipt.amountEuroExclVAT;
        }
    }
};
