
import { formatAddress, Address } from '@/helpers/stringFormats';
import { ICCustomer } from '../domain/types';
import Vue, { PropOptions } from 'vue';
import { Quarters } from '@/config/constants';

export default Vue.extend({
    props: {
        icCustomers: {
            required: true,
            type: Array
        } as PropOptions<ICCustomer[]>
    },

    data() {
        return {
            headers: [
                {
                    text: this.$t('general.company_name'),
                    align: 'start',
                    value: 'company.name'
                },
                {
                    text: this.$t('general.company_number'),
                    value: 'company.number'
                },
                { text: this.$t('general.address'), value: 'address' },
                {
                    text: this.$t('dataTables.typeOfIC'),
                    value: 'typeOfIC'
                },
                {
                    text: this.$t('dataTables.companyTotal'),
                    value: 'total'
                }
            ],
            userDataExportLoading: false as boolean,
            quarters: Quarters
        };
    },

    methods: {
        getFormattedAddress(address: Partial<Address>): string {
            return formatAddress(address);
        }
    }
});
