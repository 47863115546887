import { VatStatus } from '@/config/constants';

export function mustSubmitEmptyClientlisting(usertype: VatStatus): boolean {
    if (usertype === 'VATLIABLE') {
        return true;
    }

    if (usertype === 'VATEXEMPT') {
        throw new Error('User cannot submit clientlisting');
    }

    return false;
}
