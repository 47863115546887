
import _, { isNil } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import NumberField from '@/components/FormComponents/NumberField.vue';
import SelectInvoiceitem from '@/components/BottomSheets/SelectInvoiceitem.vue';
import { isVatLiable } from '@/helpers/VATHelper';
import mixins from 'vue-typed-mixins';
import { PropOptions } from 'vue';
import { DailyreceiptInput, IInvoicableItem } from '../../businesslogic/types';
import { invoiceItemToDailyreceipt } from '../../businesslogic/dailyreceipt.service';

export default mixins(formRulesMixin).extend({
    components: {
        NumberField,
        SelectInvoiceitem
    },

    mixins: [formRulesMixin],
    props: {
        value: {
            type: Array,
            default: () => []
        } as PropOptions<DailyreceiptInput[]>
    },

    data() {
        return {
            vatPercentages: [0, 6, 12, 21] as const,
            searchSelectinvoiceitemIndex: null as number | null
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        newDailyreceipts: {
            get(): DailyreceiptInput[] {
                return this.value;
            },
            set(value: DailyreceiptInput[]) {
                this.$emit('input', value);
            }
        },
        isVATLiable(): boolean {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['openSelectInvoiceitem']),
        searchInvoiceitem(index: number) {
            this.searchSelectinvoiceitemIndex = index;
            this.openSelectInvoiceitem();
        },
        addNewItem() {
            const newItem: DailyreceiptInput = {
                date: null,
                amountEuroExclVAT: null,
                amountEuroInclVAT: null,
                amountEuroVAT: 0,
                VATPercentage: this.currentUserData.settings.defaultVATPercentage,
                description: ''
            };
            this.newDailyreceipts.push({ ...newItem });
        },
        removeItem(index: number) {
            if (this.newDailyreceipts.length > 0) {
                this.newDailyreceipts.splice(index, 1);
            }
        },
        setExclVAT(totalInclVAT: number, item: DailyreceiptInput) {
            if (_.isNil(item.VATPercentage) || _.isNil(totalInclVAT)) {
                return;
            }

            item.amountEuroExclVAT = totalInclVAT / (1 + (item.VATPercentage !== null ? item.VATPercentage : 1) / 100);
        },

        handleSelectInvoiceitem(selectedItem: Readonly<IInvoicableItem>) {
            if (typeof selectedItem === 'object') {
                if (isNil(this.searchSelectinvoiceitemIndex)) {
                    throw new Error('No index selected');
                }

                const { description, amountEuroExclVAT, amountEuroInclVAT, VATPercentage } = invoiceItemToDailyreceipt(
                    selectedItem,
                    this.isVATLiable
                );

                this.newDailyreceipts[this.searchSelectinvoiceitemIndex] = {
                    ...this.newDailyreceipts[this.searchSelectinvoiceitemIndex],
                    description,
                    amountEuroExclVAT,
                    VATPercentage,
                    amountEuroInclVAT
                };
            }
        }
    }
});
