
import { defineComponent } from 'vue';
import { LocaleMessages } from 'vue-i18n';

interface OrderedRawHtmlListProps {
    items: string[] | LocaleMessages;
}

export default defineComponent<OrderedRawHtmlListProps>({
    name: 'OrderedRawHtmlList',
    props: {
        items: {
            type: Array,
            required: true
        }
    }
});
