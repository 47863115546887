
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'vuex';
import Loader from '@/components/Loader.vue';
import TotalToProcessFiles from './TotalToProcessFiles.vue';
import SelectSupplierStep from './SelectSupplierStep.vue';
import DepreciationStep from './DepreciationStep.vue';
import BasicInfoStep from './BasicInfoStep.vue';
import DescriptionStep from './DescriptionStep.vue';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU } from '@/config/constants';
import ViewLayout from '@/components/ViewLayout.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import SearchInput from '@/components/SearchInput.vue';
import SelectTableComponent from '@/components/SelectTableComponent.vue';
import NumberField from '@/components/FormComponents/NumberField.vue';
import VCardHeader from '@/components/vCardHeader.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import DeductabilityStep from '@/views/addAccountingExpense/components/DeductabilityStep.vue';
import BaseIconRight from '@/components/BaseIconRight.vue';
import PDFViewer from '@/components/PDFViewer.vue';
import FileViewer from '@/components/FileViewer.vue';
import { accountingExpenseCheckIfFileExistMixin } from '@/mixins/expenses/expenseCheckIfAccountingExpenseExistMixin.js';
import { isVatLiable } from '@/helpers/VATHelper';
import Amounts from '@/views/addAccountingExpense/components/Amounts.vue';
import * as BusinessExpenseService from '@/businessServices/expense/expense.service';
import { isPDFFile } from '@/helpers/general';
import { createDependencyGraphAccountingExpense, getModelMutateFunction } from './dependencyCode';
import { DepedencyCodeMixin } from '@/helpers/dependencyCodeMixin';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import { accountingExpenseMiddlewareMixin } from '@/mixins/expenses/accountingExpenseMiddleware';
import { AccountingExpenseModel } from '@/models/accountingexpense';
import { accountingExpenseStepCompleteMixin } from '@/mixins/expenses/accountingExpenseStepCompleteMiddleware';

export default defineComponent({
    components: {
        Loader,
        TotalToProcessFiles,
        PDFViewer,
        SelectSupplierStep,
        ViewLayout,
        SearchInput,
        SubmitButton,
        BasicInfoStep,
        DescriptionStep,
        SelectTableComponent,
        NumberField,
        ConfirmationDialog,
        VCardHeader,
        DeductabilityStep,
        DepreciationStep,
        BaseIconRight,
        Amounts,
        FileViewer
    },
    mixins: [
        middlewareMixin,
        formRulesMixin,
        DepedencyCodeMixin,
        accountingExpenseStepCompleteMixin,
        accountingExpenseMiddlewareMixin,
        accountingExpenseCheckIfFileExistMixin
    ],
    props: {
        value: {
            required: true,
            type: Object as PropType<AccountingExpenseModel>
        },
        expensefile: {
            required: true,
            type: Object as PropType<{
                originalFileName: string;
                url: string;
            }>
        },
        expenseFileIdsUserWantsToProcess: {
            required: true,
            type: Array as PropType<number[]>
        },
        totalExpenseFileIdsUserWantsToProcess: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            BELGIAN_COMPANY,
            COMPANY_OUTSIDE_EU,
            COMPANY_INSIDE_EU,
            valid: true,
            loading: false,
            stepper: 1,
            showDialog: false
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        isValid(): boolean {
            return (
                this.valid &&
                this.stepGeneralDataComplete &&
                this.stepSupplierDataComplete &&
                this.stepInvestementComplete &&
                this.stepBusinessPercentageDataComplete &&
                this.stepAmountDataComplete
            );
        },
        currentAccountingExpense: {
            get(): AccountingExpenseModel {
                return this.value;
            },
            set(input: AccountingExpenseModel) {
                this.$emit('input', input);
            }
        },
        canShowDepreciationForm(): boolean {
            return BusinessExpenseService.canShowDepreciationForm(
                this.currentAccountingExpense.expensecategory.forTypeOfService,
                this.currentAccountingExpense.isCreditnote
            );
        },
        canShowFilesToProcessInfo(): boolean {
            return Array.isArray(this.expenseFileIdsUserWantsToProcess);
        },
        numberOfToProcessFiles(): number {
            return this.expenseFileIdsUserWantsToProcess.length;
        },
        fileName(): string | null {
            return this.expensefile ? this.expensefile.originalFileName : null;
        },
        fileUrl(): string | null {
            return this.expensefile ? this.expensefile.url : null;
        },
        hasFile(): boolean {
            return !!this.expensefile;
        },
        viewComponent(): undefined | any {
            if (!this.expensefile) return;

            if (isPDFFile(this.fileUrl)) return PDFViewer;

            return FileViewer;
        },
        isVATLiable(): boolean {
            if (!this.currentUserData) {
                return false;
            }

            return isVatLiable(this.currentUserData.settings.vatLiable);
        },
        isCreatingNewExpense(): boolean {
            return this.currentAccountingExpense.id === null;
        },
        isExpenseDataCompleteForSubmit(): boolean {
            return BusinessExpenseService.isExpenseDataCompleteForSubmit(this.currentAccountingExpense);
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(): void {
                this.resetFormValidation();

                const depGraph = createDependencyGraphAccountingExpense();
                const model = this.currentAccountingExpense;

                this.initiateWatchersBasedOnGraph(depGraph, model, getModelMutateFunction(depGraph), {
                    isVATLiable: this.isVATLiable
                });
            }
        }
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        submitGoToOverview(): void {
            this.runMiddleware(this.createAndOverviewSubmitMiddleware);
        },
        submitGoToNextFile(): void {
            this.runMiddleware(this.createAndGoToNextExpenseFileSubmitMiddleware);
        },
        skipCurrentExpenseFile(): void {
            this.$emit('skip-current-expense-file');
        },
        resetFormValidation(): void {
            this.stepper = 1;
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });
        },
        redirectToNextExpensefile(): void {
            this.$refs.scrollableFormColumn.scrollTo(0, 0);
            this.$emit('submit-go-to-next-file');
        },
        redirectToOverview(): void {
            this.$emit('submit-go-to-overview');
        }
    }
});
