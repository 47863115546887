<template>
    <text-field
        v-model="content"
        data-action="search"
        prepend-inner-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        dense
        autofocus
        outlined
        hide-details
        @change="handleInput"
        @input="emitInput"
    />
</template>

<script>
export default {
    props: {
        value: {
            required: true
        }
    },
    data() {
        return {
            content: this.value
        };
    },
    methods: {
        handleInput() {
            this.$emit('input', this.content);
            this.$emit('new-search-term', this.content);
        },
        emitInput() {
            this.$emit('input', this.content);
        }
    }
};
</script>
