import { Dailyreceipt, IInvoicableItem } from './types';

const maximumLineAmountInEuro = 250;

export function isOverMaximumLineItemAmount(dailyreceipt: Dailyreceipt): boolean {
    return dailyreceipt.amountEuroExclVAT * (1 + dailyreceipt.VATPercentage / 100) >= maximumLineAmountInEuro;
}

export function canShowDayButton(dailyReceipts: any[]): boolean {
    if (dailyReceipts.length === 0) {
        return true;
    }

    return !!dailyReceipts.every((_dailyReceipt) => _dailyReceipt.isCorrected);
}

export function calculateTotalVAT(allDailyreceipt: Dailyreceipt[]): number {
    let totalVAT = 0;
    allDailyreceipt.forEach((item) => {
        if (item.isCorrected) {
            return 0;
        }
        totalVAT += item.amountEuroExclVAT * (item.VATPercentage / 100) || 0;
    });
    return totalVAT;
}

export function calculateSum(allDepreciations: Dailyreceipt[]): number {
    let total = 0;
    allDepreciations.forEach((item) => {
        if (item.isCorrected) {
            return 0;
        }
        total += item.amountEuroExclVAT * (1 + item.VATPercentage / 100) || 0;
    });
    return total;
}

export function invoiceItemToDailyreceipt(
    selectedItem: Readonly<IInvoicableItem>,
    isUserVatLiable: boolean
): {
    description: string;
    amountEuroExclVAT: number;
    amountEuroInclVAT: number;
    VATPercentage: number;
} {
    const data = {
        amountEuroExclVAT: 0,
        amountEuroInclVAT: 0,
        description: '',
        VATPercentage: 0
    };

    data.description = selectedItem.name;
    if (isUserVatLiable === false) {
        data.VATPercentage = 0;
    } else {
        data.VATPercentage = selectedItem.VATPercentage;
    }
    data.amountEuroExclVAT = selectedItem.priceExclVAT;
    data.amountEuroInclVAT = selectedItem.priceExclVAT * (1 + data.VATPercentage / 100);

    return data;
}
