import { FuelType } from 'dexxter-types';
import { FormFields } from './model';

export function shouldDisableFieldOnUpdate(fieldName: FormFields): boolean {
    if (fieldName !== 'name') {
        return true;
    }

    return false;
}

export function canShow_co2(fuelType: FuelType): boolean {
    return fuelType !== 'waterstof' && fuelType !== 'elektriciteit';
}

export function canShow_kWh(fuelType: FuelType): boolean {
    return fuelType === 'plugin-hybride-diesel' || fuelType === 'plugin-hybride-benzine';
}

export function canShow_weight(fuelType: FuelType): boolean {
    return fuelType === 'plugin-hybride-diesel' || fuelType === 'plugin-hybride-benzine';
}

export function canShow_fiscalePK(fuelType: FuelType): boolean {
    return fuelType === 'cng';
}
