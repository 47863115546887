<template>
    <div>
        <div>
            <v-row>
                <v-col v-if="canShowSecondaryGrenzen" cols>
                    <v-card
                        class="Widget pa-5 fill-height"
                        :class="{ warn: isSecondaryOverVrijstellingsGrens }"
                        outlined
                    >
                        <data-card-multiple
                            :data="limitExemptSocialContributionsTextSecondary"
                            :data-text="$t('socialcontributionsOverview.limit_secondary')"
                            :i18n-path="i18nPathUpperLimitRangeSecondary"
                            :loading="false"
                            :disable-numeric-format="true"
                        />
                        <tooltip
                            v-if="isSecondaryOverVrijstellingsGrens"
                            :underlined="true"
                            :i18n-path="i18nPathUpperLimitRangeSecondary"
                            ><template slot="activator"> Je zit boven je grens, wat nu? </template></tooltip
                        >
                    </v-card>
                </v-col>
                <v-col v-if="canShowStudentGrenzen" cols>
                    <v-card class="Widget pa-5 fill-height" :class="{ warn: isStudentOverVrijstellingsGrens }" outlined>
                        <data-card-multiple
                            :data="limitExemptSocialContributionsTextStudent"
                            :data-text="$t('socialcontributionsOverview.limit_student')"
                            :i18n-path="i18nPathUpperLimitRangeStudent"
                            :loading="false"
                            :disable-numeric-format="true"
                        />
                        <tooltip
                            v-if="isStudentOverVrijstellingsGrens"
                            :underlined="true"
                            :i18n-path="i18nPathUpperLimitRangeStudent"
                            ><template slot="activator"> Je zit boven je grens, wat nu? </template></tooltip
                        >
                    </v-card>
                </v-col>
                <v-col v-if="canShowStudentGrenzen" cols>
                    <v-card
                        class="Widget pa-5 fill-height"
                        :class="{ warn: isStudentAboveLimitAndBecomesMain }"
                        outlined
                    >
                        <data-card-multiple
                            :data="studentAboveUpperLimitStudentToMain"
                            :data-text="$t('socialcontributionsOverview.limit_stay_student_entrepreneur')"
                            :loading="false"
                            :i18n-object="i18nObjectStudentMainMonitor"
                            :disable-numeric-format="true"
                        />
                        <tooltip
                            v-if="isStudentAboveLimitAndBecomesMain"
                            :underlined="true"
                            :title="i18nObjectStudentMainMonitor.title"
                            :content="i18nObjectStudentMainMonitor.content"
                            ><template slot="activator"> Je zit boven je grens, wat nu? </template></tooltip
                        >
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import DataCardMultiple from '@/components/DataCardMultiple.vue';
import { calculateCompanyStartYearMultiplicationFactor, getSocialContributionData } from '@dexxter/accountancy-tools';
import { maxBy } from 'lodash';
import { mapState } from 'vuex';
import * as SocialcontributionCalculations from '../domain/calculations';
import { socialcontributionStartDateFactor } from '../domain/utils';

export default {
    components: {
        DataCardMultiple
    },
    props: {
        socialcontributionInformation: {}
    },

    computed: {
        ...mapState(['selectedYear']),
        ...mapState('auth', ['currentUserData']),
        socialContributionDataFromCurrentYearOrMostRecentOne() {
            return (
                getSocialContributionData().find((_x) => _x.year === this.selectedYear) ??
                maxBy(getSocialContributionData(), (x) => x.year)
            );
        },
        isSecondaryOverVrijstellingsGrens() {
            return (
                this.getCalculationBasis >
                this.socialContributionDataFromCurrentYearOrMostRecentOne.bijberoepVrijstellingsGrens
            );
        },
        isStudentOverVrijstellingsGrens() {
            return (
                this.getCalculationBasis >
                this.socialContributionDataFromCurrentYearOrMostRecentOne.studentVrijstellingsGrens
            );
        },
        i18nsocialcontributionExempt() {
            if (this.socialcontributionExempt) {
                return 'tooltips.socialcontributions.vrijstelling';
            }

            return 'tooltips.socialcontributions.noVrijstellingStudent';
        },
        i18nPathUpperLimitRangeSecondary() {
            return this.getI18nPathUpperLimitRangeSecondary();
        },
        i18nPathUpperLimitRangeStudent() {
            return this.getI18nPathUpperLimitRangeStudent();
        },
        i18nObjectStudentMainMonitor() {
            return {
                title: this.$t('tooltips.socialcontributions.overviewbanner.studentOverLimitBecomesMain.title'),
                content: this.$t('tooltips.socialcontributions.overviewbanner.studentOverLimitBecomesMain.content', {
                    upperLimitCurrentYear: this.$options.filters.numericFormat(
                        this.socialContributionDataFromCurrentYearOrMostRecentOne.ondergrensHoofdberoep
                    )
                })
            };
        },
        canShowTotalToPay() {
            return this.socialcontributionExempt === false;
        },
        canShowSecondaryGrenzen() {
            return ['Q1', 'Q2', 'Q3', 'Q4'].some(
                (quarter) => this.socialcontributionInformation.statuten[quarter] === 'secondary'
            );
        },
        canShowStudentGrenzen() {
            return ['Q1', 'Q2', 'Q3', 'Q4'].some(
                (quarter) => this.socialcontributionInformation.statuten[quarter] === 'student'
            );
        },
        isAlertCard() {
            return this.checkAboveSocialcontributionsLimit;
        },
        isStudentAboveLimitAndBecomesMain() {
            return (
                this.socialContributionDataFromCurrentYearOrMostRecentOne.ondergrensHoofdberoep <=
                this.getCalculationBasis
            );
        },
        limitExemptSocialContributionsTextSecondary() {
            return `€${this.$options.filters.numericFormat(
                this.getCalculationBasis
            )} / €${this.$options.filters.numericFormat(
                this.socialContributionDataFromCurrentYearOrMostRecentOne.bijberoepVrijstellingsGrens
            )}`;
        },
        limitExemptSocialContributionsTextStudent() {
            return `€${this.$options.filters.numericFormat(
                this.getCalculationBasis
            )} / €${this.$options.filters.numericFormat(
                this.socialContributionDataFromCurrentYearOrMostRecentOne.studentVrijstellingsGrens
            )}`;
        },
        studentAboveUpperLimitStudentToMain() {
            return `€${this.$options.filters.numericFormat(
                this.getCalculationBasis
            )} / €${this.$options.filters.numericFormat(
                this.socialContributionDataFromCurrentYearOrMostRecentOne.ondergrensHoofdberoep
            )}`;
        },
        getCalculationBasis() {
            return (
                this.getGrossProfit() *
                calculateCompanyStartYearMultiplicationFactor({
                    Q1: this.socialcontributionInformation.statuten.Q1,
                    Q2: this.socialcontributionInformation.statuten.Q2,
                    Q3: this.socialcontributionInformation.statuten.Q3,
                    Q4: this.socialcontributionInformation.statuten.Q4
                })
            );
        },
        loading() {
            return Object.values(this.load).some((loading) => loading);
        },
        socialSecretariatName() {
            if (!this.socialcontributionInformation) {
                return '';
            }

            return this.socialcontributionInformation.socialContributionsSettings.socialsecretariat;
        },
        socialcontributionExempt() {
            if (!this.socialcontributionInformation) {
                return '';
            }

            return this.socialcontributionInformation.socialContributionsSettings.exempt;
        },
        checkAboveSocialcontributionsLimit() {
            if (!this.socialcontributionInformation) {
                return false;
            }

            return this.getSocialContributionUpperLimit(this.socialcontributionInformation) <= this.getGrossProfit();
        }
    },

    methods: {
        socialcontributionStartDateFactor,
        getTotalToPaySocialcontributions() {
            const toPay = this.getTotalToPaySocialContributions(this.socialcontributionInformation);
            const alreadyPaid = this.getTotalAlreadyPayedSocialContributions(this.socialcontributionInformation);

            return SocialcontributionCalculations.getTotalToPaySocialcontributions(toPay, alreadyPaid);
        },
        hasUserStartedCompanyInTheSelectedYear(startYear) {
            return startYear == this.selectedYear;
        },
        getSocialContributionUpperLimit(socialcontributionInformation) {
            if (!socialcontributionInformation) {
                throw new Error('socialcontributionInformation not defined');
            }
            return socialcontributionInformation.socialContributionsSettings.socialContributionsUpperLimit;
        },
        getGrossProfit() {
            return this.socialcontributionInformation.grossProfitEuro;
        },
        getTotalAlreadyPayedSocialContributions(socialcontributionInformation) {
            if (!socialcontributionInformation) {
                throw new Error('socialcontributionInformation not defined');
            }
            return socialcontributionInformation.socialContributions.socialContributionsPaidEuro;
        },
        getTotalCalculatedSocialContributions(socialcontributionInformation) {
            return this.getTotalToPaySocialContributions(socialcontributionInformation);
        },
        getSocialContributionsFeePercentage(socialcontributionInformation) {
            if (!socialcontributionInformation) {
                throw new Error('socialcontributionInformation not defined');
            }
            return socialcontributionInformation.socialContributionsSettings.socialContributionsFeePercentage;
        },
        getTotalToPaySocialContributions(socialcontributionInformation) {
            if (!socialcontributionInformation) {
                throw new Error('socialcontributionInformation not defined');
            }
            return socialcontributionInformation.socialContributions.socialContributionsToPayEuro;
        },
        hasToPayExtraSocialContributions(socialcontributionInformation) {
            if (!socialcontributionInformation) {
                throw new Error('socialcontributionInformation not defined');
            }

            return (
                socialcontributionInformation.socialContributions.socialContributionsPaidEuro <=
                socialcontributionInformation.socialContributions.socialContributionsToPayEuro
            );
        },
        getI18nPathUpperLimitRangeStudent() {
            if (this.checkAboveSocialcontributionsLimit) {
                return this.getI18nPathUpperLimitRangeStudentAboveUpperLimit();
            }

            return this.getI18nPathUpperLimitRangeStudentBelowUpperLimit();
        },
        getI18nPathUpperLimitRangeSecondary() {
            if (this.checkAboveSocialcontributionsLimit) {
                return this.getI18nPathUpperLimitRangeSecondaryAboveUpperLimit();
            }

            return this.getI18nPathUpperLimitRangeSecondaryBelowUpperLimit();
        },
        getI18nPathUpperLimitRangeStudentAboveUpperLimit() {
            return `${this.getI18nPathUpperLimitRangePrefix()}.students.aboveLimit`;
        },
        getI18nPathUpperLimitRangeStudentBelowUpperLimit() {
            return `${this.getI18nPathUpperLimitRangePrefix()}.students.belowLimit`;
        },
        getI18nPathUpperLimitRangeSecondaryAboveUpperLimit() {
            return `${this.getI18nPathUpperLimitRangePrefix()}.secondary.aboveLimit`;
        },
        getI18nPathUpperLimitRangeSecondaryBelowUpperLimit() {
            return `${this.getI18nPathUpperLimitRangePrefix()}.secondary.belowLimit`;
        },
        getI18nPathUpperLimitRangePrefix() {
            return 'tooltips.socialcontributions.overviewbanner.upperlimitRange';
        }
    }
};
</script>

<style lang="scss" scoped>
.horizontal-scroll-mobile {
    .Widget.warn {
        border: 1px solid rgb(253, 164, 54) !important;
    }
}
</style>
