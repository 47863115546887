<template>
    <div>
        <view-layout v-if="currentAccountingExpense">
            <template #content>
                <expense-accounting-view
                    ref="invoicableForm"
                    v-model="currentAccountingExpense"
                    :expensefile="expensefile"
                    :document-id="documentId"
                />
            </template>
        </view-layout>

        <loader v-if="loading" />
    </div>
</template>
<script>
// --- State ---
import { mapActions, mapState } from 'vuex';

// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

// --- Constants ---
import ViewLayout from '@/components/ViewLayout';

// --- Modules ---
import { newAccountingExpense } from '@/models/accountingexpense';

import { getExpenseFileById } from '@/services/expensefiles';

import * as AccountingExpenseService from '@/services/accountingexpenses';
import { isVatLiable } from '@/helpers/VATHelper';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import ExpenseAccountingView from './components/ExpenseAccountingView.vue';

export default {
    components: { ViewLayout, ExpenseAccountingView },

    mixins: [middlewareMixin],

    async beforeRouteUpdate(to, from, next) {
        await this.initData(to.params.id, to.query.expenseFileId);
        next();
    },

    data() {
        return {
            currentExpenseId: undefined,
            currentDocumentId: undefined,
            currentExpenseFileId: undefined,
            currentAccountingExpense: null,
            loading: false,
            expensefile: null
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),

        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        },
        documentId() {
            return this.currentAccountingExpense.documentId;
        }
    },

    async created() {
        await this.initData(this.$route.params.id, this.$route.query.expenseFileId);
    },

    methods: {
        ...mapActions('expenses', ['newExpenseEvent']),
        ...mapActions('expensefiles', [
            'skipCurrentProcessExpenseFile',
            'markCurrentProcessExpenseFileAsHandled',
            'setToProcessExpenseFiles'
        ]),
        ...mapActions(['startLoading', 'stopLoading']),

        async initData(currentExpenseId, currentExpenseFileId) {
            this.currentExpenseId = currentExpenseId;
            this.currentExpenseFileId = currentExpenseFileId;

            await this.fetchData();
        },
        async fetchData() {
            this.startLoading();

            try {
                if (this.currentExpenseId) {
                    await this.fetchAccountingExpense(this.currentExpenseId);

                    this.expensefile = this.currentAccountingExpense.expensefile;
                } else {
                    if (!this.currentExpenseFileId) {
                        this.$router.push('/dashboard');
                    }

                    this.expensefile = await getExpenseFileById(this.currentExpenseFileId);

                    if (this.currentUserData.settings.vatLiable) {
                        this.$set(
                            this,
                            'currentAccountingExpense',
                            newAccountingExpense({ vatLiable: this.isVATLiable })
                        );
                    }
                }
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
                if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                    this.$router.push('/dashboard');
                }
            } finally {
                this.stopLoading();
            }
        },
        async fetchAccountingExpense(expenseId) {
            this.$set(this, 'currentAccountingExpense', await AccountingExpenseService.getExpense(expenseId));
            this.preprocessFetchedExpense();
        },
        preprocessFetchedExpense() {
            // Save this, because there is a watcher that resets when settings the parent category
            const expensecategoryId = this.currentAccountingExpense.expensecategoryId;

            this.currentAccountingExpense.expensecategoryId = expensecategoryId;

            // Preprocessing to match the form
            if (this.currentAccountingExpense.isDepreciation) {
                this.$set(this.currentAccountingExpense, 'depreciation', {
                    numberOfYears: this.currentAccountingExpense.depreciationfiche.numberOfYears,
                    category: this.currentAccountingExpense.depreciationfiche.category,
                    description: this.currentAccountingExpense.depreciationfiche.description,
                    dateStart: this.currentAccountingExpense.depreciationfiche.dateStart,
                    annualAmountEuro: null
                });
            } else if (this.currentUserData.settings.vatLiable) {
                this.$set(
                    this.currentAccountingExpense,
                    'depreciation',
                    newAccountingExpense({ vatLiable: this.isVATLiable }).depreciation
                );
            }
        },
        redirectToOverview() {
            this.$router.push({
                name: 'expenses'
            });
        }
    }
};
</script>
