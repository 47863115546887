<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>

                    <template #menu>
                        <v-btn
                            data-action="openCreateCustomerBottomsheet"
                            color="primary"
                            @click="openAddNewCustomerModal"
                        >
                            {{ $t('customer.add_customer') }}
                        </v-btn>
                    </template></page-header
                >
            </template>
            <template #content>
                <TableCard>
                    <template #content>
                        <customers-table />
                    </template>
                </TableCard>

                <add-customer-modal
                    v-if="addCustomerDialog"
                    v-model="addCustomerDialog"
                    :current-customer="createNewCustomerData"
                />
            </template>
        </view-layout>
    </div>
</template>

<script>
// --- Components ---
import TableCard from '@/components/TableCard.vue';
import CustomersTable from '@/components/DataTables/CustomersTable';
// --- Mixins ---
// --- State ---
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { newCustomer } from '@/models/customer';
import AddCustomerModal from '@/views/customers/add/components/AddCustomer.modal.vue';

export default {
    components: {
        CustomersTable,
        TableCard,
        ViewLayout,
        PageHeader,
        AddCustomerModal
    },

    mixins: [screenSizeMixin],

    data() {
        return {
            addCustomerDialog: false,
            createNewCustomerData: null
        };
    },

    methods: {
        openAddNewCustomerModal() {
            this.createNewCustomerData = newCustomer();
            this.addCustomerDialog = true;
        }
    }
};
</script>
