<template>
    <div>
        <view-layout>
            <template #header>
                <PageHeader>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                </PageHeader>
            </template>
            <template #content>
                <v-row>
                    <v-col cols="12" md="12">
                        <table-card class="mb-3 pa-5">
                            <template #content>
                                <send-email
                                    v-if="fetchedAllData"
                                    :templates="templates"
                                    :placeholder-map="placeholderMap"
                                    :recipient-mail-data="recipientMailData"
                                    @send-email="sendEmailToCustomer"
                                />
                            </template>
                        </table-card>
                    </v-col> </v-row
            ></template>
        </view-layout>

        <loader v-if="isLoading" />
    </div>
</template>

<script>
// --- Error handling ---
import ViewLayout from '@/components/ViewLayout.vue';
import Loader from '@/components/Loader.vue';
import TableCard from '@/components/TableCard.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import { getCreditnote, sendCreditnoteEmail } from '@/services/creditnote';
import { getCreditnotePlaceholderMap, getTemplates } from '@/services/email';
import { mapState } from 'vuex';
import SendEmail from '@/components/sendInvoicableEmail.vue';
import PageHeader from '@/components/PageHeader';
import { notify } from '@/helpers/successNotification';

export default {
    data() {
        return {
            loading: false,
            creditnoteData: null,
            recipientMailData: null,
            placeholderMap: null,
            templates: null
        };
    },
    async created() {
        try {
            if (!this.$route.params.id) {
                this.$router.push({
                    name: 'dashboard'
                });
            }

            await Promise.all([
                getCreditnotePlaceholderMap(this.$route.params.id).then((result) => {
                    this.placeholderMap = result;
                }),
                getTemplates().then((result) => {
                    this.templates = result.creditnoteTemplates;
                }),
                getCreditnote(this.$route.params.id).then((result) => {
                    this.creditnoteData = result;
                    this.recipientMailData = {
                        email: result.customer.company.email
                    };
                })
            ]);
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            this.$router.push({
                name: 'dashboard'
            });
        } finally {
            this.loading = false;
        }
    },

    methods: {
        async sendEmailToCustomer(emailData) {
            try {
                this.loading = true;
                await sendCreditnoteEmail(this.creditnoteData.id, emailData);

                notify.call(this, {
                    title: this.$t('templates.succesfuly_send'),
                    text: this.$t('templates.succesfuly_send_text')
                });

                this.$router.push({
                    name: 'invoicable-view',
                    params: {
                        id: this.creditnoteData.id,
                        type: 'creditnote'
                    }
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        fetchedAllData() {
            return Boolean(this.templates) && Boolean(this.placeholderMap) && Boolean(this.recipientMailData);
        },
        isLoading() {
            return !this.fetchedAllData || this.loading;
        }
    },

    components: {
        PageHeader,
        ViewLayout,
        Loader,
        TableCard,
        SendEmail
    }
};
</script>

<style lang="scss" scoped></style>
