import axios from 'axios';
import { IICSubmissionViewModel } from './viewmodels/general';
import { generateEndpoint } from '@/services/config';

const BASE_URL = generateEndpoint('/api/vat');

export function getICSubmissionsRequest(year: number): Promise<IICSubmissionViewModel[]> {
    return axios.get(`${BASE_URL}/icsubmission/${year}`).then((response) => response.data);
}

export function uploadICSubmissionRequest(
    file: Blob | null,
    year: number,
    quarter: number
): Promise<IICSubmissionViewModel> {
    const formData = new FormData();
    if (file) {
        formData.append('file', file);
    }
    formData.append('year', year.toString());
    formData.append('quarter', quarter.toString());

    return axios.post(`${BASE_URL}/icsubmission`, formData);
}

export function deleteICSubmissionsRequest(id: number): Promise<void> {
    return axios.delete(`${BASE_URL}/icsubmission/${id}`).then((response) => response.data);
}
