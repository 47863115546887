<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                        <sub>
                            <tooltip
                                :underlined="false"
                                :title="$t(`tooltips.addInvoicable.historicalinvoiceTitle.title`)"
                                :content="$t(`tooltips.addInvoicable.historicalinvoiceTitle.content`)"
                                ><template slot="activator">
                                    <v-icon small> mdi-information </v-icon></template
                                ></tooltip
                            >
                        </sub>
                    </template>
                </page-header>
            </template>
            <template #content>
                <AccountingInvoicableForm
                    ref="invoicableComponent"
                    type="invoice"
                    :is-creating-new-document="isCreatingNew"
                    :historical="true"
                    :invoicable-number-text="$t('general.invoice_number')"
                    :document-input="currentInvoice"
                    :button-text-publish="$t('addIncome.insert_invoice')"
                    @submit-edit="runMiddleware(editMiddleware, $event)"
                    @submit-create-historical="runMiddleware(createMiddleware, $event)"
                />
            </template>
        </view-layout>

        <template v-if="dialogAttributes.component">
            <component
                :is="dialogAttributes.component"
                v-model="showDialog"
                v-bind="dialogAttributes.attributes"
                v-on="dialogAttributes.actions"
            />
        </template>
        <template v-else>
            <confirmation-dialog
                v-if="showDialog"
                v-model="showDialog"
                v-bind="dialogAttributes.attributes"
                @click-positive="dialogAttributes.actions.clickPositive"
                @click-negative="dialogAttributes.actions.clickNegative"
            >
            </confirmation-dialog>
        </template>

        <loader v-if="loading || !currentUserData" />
    </div>
</template>
<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import AccountingInvoicableForm from '@/components/Forms/accounting/AccountingInvoicableForm';
import Loader from '@/components/Loader';
// --- Mixins ---
import { accountingInvoicableFormMixin } from '@/mixins/accountingInvoicableFormMixin.js';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
// import { notify } from '@/helpers/successNotification';
import PageHeader from '@/components/PageHeader';
import ViewLayout from '@/components/ViewLayout';
import { invoicableTypes } from '@/config/constants';
import { invoicableTransformCurrencyFieldToIntegers } from '@/helpers/invoicableHelper';
import { currentInvoicableNewInstance } from '@/models/invoicable';

import Tooltip from '@/components/Tooltip.vue';
import { invoiceProactiveSmartnotificationsMixin } from '@/mixins/invoiceProactiveSmartnotificationsMixin';
import { maybeMedecontractantDialogMixin } from '@/mixins/invoices/middleware/maybeMedecontractant';
import { sellInvestementDialogMixin } from '@/mixins/invoices/middleware/sellInvestement';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import { getAccountingInvoice } from '@/services/accountinginvoice';
import { analyticsTrack } from '@/services/analytics';
import dayjs from 'dayjs';
import { reportError } from '../../../helpers/logging';

export default {
    components: {
        Loader,
        AccountingInvoicableForm,
        ViewLayout,
        PageHeader,
        Tooltip
    },

    mixins: [
        middlewareMixin,
        formRulesMixin,
        accountingInvoicableFormMixin,
        invoiceProactiveSmartnotificationsMixin,
        sellInvestementDialogMixin,
        maybeMedecontractantDialogMixin
    ],

    data() {
        return {
            loading: false,
            isCreatingNew: true,
            previousInvoice: null,
            currentInvoice: currentInvoicableNewInstance(invoicableTypes.INVOICE),
            editMiddleware: [
                this.sellInvestementDialog,
                this.maybeMedecontractantDialog,
                this.invoiceProactiveSmartnotifications,
                this.handleEditSubmitMiddleware
            ],
            createMiddleware: [
                this.sellInvestementDialog,
                this.maybeMedecontractantDialog,
                this.invoiceProactiveSmartnotifications,
                this.handleSubmitMiddleware
            ]
        };
    },

    async created() {
        this.startLoading();
        try {
            if (this.$route.params.id) {
                this.isCreatingNew = false;
                const fetchedInvoice = await getAccountingInvoice(this.$route.params.id);
                this.currentInvoice = fetchedInvoice;
                this.previousInvoice = fetchedInvoice;
            } else {
                this.isCreatingNew = true;
                this.setInitialData();

                const depreciationficheId = this.$route.query?.depreciationficheId;
                if (depreciationficheId) {
                    this.currentInvoice.depreciationficheId = parseInt(depreciationficheId);
                }
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),

        handleEditSubmitMiddleware(next, invoice) {
            return this.handleSubmitEdit(invoice);
        },
        handleSubmitMiddleware(next, invoice) {
            return this.handleSubmitCreateHistorical(invoice);
        },

        setInitialData() {
            this.currentInvoice.date = dayjs.utc().format('YYYY-MM-DD');
            this.currentInvoice.dueDate = dayjs
                .utc()
                .add(this.currentUserData.settings.defaultPaymentDays, 'day')
                .format('YYYY-MM-DD');
        },

        handleAfterSubmit(newInvoice) {
            if (this.isCreatingNew) {
                try {
                    analyticsTrack('User Completes An Invoice', {
                        Add_Invoice_Price: newInvoice.amountEuroInclVAT,
                        Add_Invoice_Discount: newInvoice.discount,
                        Add_Invoice_Discount_Type: newInvoice.isDiscountPercentage ? 'percentage' : 'amount',
                        Add_Invoice_Comment: newInvoice.comment,
                        Type: 'historical'
                    });
                } catch (e) {
                    reportError(e);
                }
            }

            this.$store.dispatch('incomes/newInvoiceCreated');

            this.isCreatingNew = false;
            this.currentInvoice = newInvoice;

            this.$router.push({
                name: 'accounting-invoicable-view',
                params: {
                    id: this.currentInvoice.id,
                    type: 'invoice'
                },
                query: {
                    newlyCreated: true
                }
            }).catch;
        },

        setCorrectValues(invoicableCopy) {
            invoicableCopy.draft = false;
            invoicableCopy.historical = true;
            invoicableCopy.creator = this.currentUserData.company;

            invoicableCopy.type = invoicableTypes.INVOICE;

            return invoicableTransformCurrencyFieldToIntegers(invoicableCopy, invoicableTypes.INVOICE);
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}
</style>
