import { BELGIAN_COMPANY, VatStatus } from '@/config/constants';
import { isVatLiable } from '@/helpers/VATHelper';
import { CustomerType } from 'dexxter-types/customers/customer.types';

export function shouldGivePopupIfUserMayWantToUserBtwMedecibtractant(
    invoice: { items: { VATPercentage: number }[]; customer: { type: CustomerType }; btwMedecontractant: boolean },
    currentUserData: { settings: { vatLiable: VatStatus } }
): boolean {
    if (!invoice.items.some((_item) => _item.VATPercentage === 0)) {
        return false;
    }

    if (invoice.customer.type !== BELGIAN_COMPANY) {
        return false;
    }

    if (!isVatLiable(currentUserData.settings.vatLiable)) {
        return false;
    }

    if (invoice.btwMedecontractant) {
        return false;
    }

    return true;
}
