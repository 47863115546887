import { getDiff } from '@/helpers/general';
import dayjs, { Dayjs } from 'dayjs';

type yearDepreciationAmount = {
    [key: number]: number;
};

export const calculateDepreciationMixin = {
    methods: {
        calculateAnnualDepreciationAmount(
            startDateYYYYMMDD: string,
            numberOfYears: number,
            totalAmount: number,
            fullObject = false,
            requestedYear?: number
        ): yearDepreciationAmount | number {
            const result: yearDepreciationAmount = {};

            const startDate = dayjs(startDateYYYYMMDD, 'YYYY-MM-DD');
            const endDate = dayjs(startDateYYYYMMDD, 'YYYY-MM-DD').add(numberOfYears, 'year');

            const amountPerDay = this.getAmountPerDay(totalAmount, startDate, endDate);

            const yearsWhichDepreciationExpands = this.getDepreciationYearArray(startDateYYYYMMDD, numberOfYears);
            yearsWhichDepreciationExpands.forEach((year) => {
                result[year] = this.getTotalDepreciationAmountEuroForGivenYear(year, startDate, endDate, amountPerDay);
            });

            if (fullObject === true) {
                return result;
            }

            if (requestedYear == null) {
                throw new Error();
            }

            return result[requestedYear];
        },

        getTotalDepreciationAmountEuroForGivenYear(
            year: number,
            startDate: Dayjs,
            endDate: Dayjs,
            amountPerDay: number
        ) {
            if (this.isStartYear(year, startDate)) {
                const daysFirstYear = getDiff(dayjs(`${year + 1}-01-01`, 'YYYY-MM-DD'), startDate, 'day');
                return daysFirstYear * amountPerDay;
            }

            if (this.isLastYear(year, endDate)) {
                const daysLastYear = getDiff(endDate, dayjs(`${year}-01-01`, 'YYYY-MM-DD'), 'day');
                return daysLastYear * amountPerDay;
            }

            const daysInYear = getDiff(
                dayjs(`${year + 1}-01-01`, 'YYYY-MM-DD'),
                dayjs(`${year}-01-01`, 'YYYY-MM-DD'),
                'day'
            );
            return daysInYear * amountPerDay;
        },

        isStartYear(givenYear: number, startDate: Dayjs) {
            return givenYear === startDate.year();
        },

        isLastYear(givenYear: number, endDate: Dayjs) {
            return givenYear === endDate.year();
        },

        getAmountPerDay(totalAmount: number, startDate: Dayjs, endDate: Dayjs) {
            return totalAmount / getDiff(endDate, startDate, 'day');
        },

        getDepreciationYearArray(startDate: string, numberOfYears: number) {
            const startYear = dayjs(startDate, 'YYYY-MM-DD').year();

            // Don't include end day. ex: startDate = 2020-01-01 for 3 years, endDate = 2023-01-01.
            // In this case we don't want to make a deprication in 2023 for 1 day.
            // So we always exclude the end day.
            const endYear = dayjs(startDate, 'YYYY-MM-DD').add(numberOfYears, 'year').subtract(1, 'day').year();
            const correctedNumberOfYears = endYear - startYear;
            const years: number[] = [];
            for (let i = 0; i <= correctedNumberOfYears; i++) {
                years.push(startYear + i);
            }

            if (years.length === 0) {
                throw new Error('No years');
            }

            return years;
        }
    }
};
