<template>
    <view-layout id="viewDailyreceiptPage">
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                    <sub>
                        <tooltip :underlined="false" i18n-path="tooltips.dailyreceiptOverview.title"
                            ><template slot="activator"> <v-icon small> mdi-information </v-icon></template></tooltip
                        >
                    </sub>
                </template>
                <template #menu>
                    <submit-button v-if="!showEditModal" @click="toggleEditView">Bulk wijzigen</submit-button>
                </template>
            </page-header>
        </template>
        <template #content>
            <v-row>
                <v-col v-if="showEditModal" md="3">
                    <widget class="mb-3">
                        <template #title> {{ $t('dailyreceiptsView.change_bulk') }} </template>
                        <template #content>
                            <v-radio-group v-model="statusMode">
                                <base-icon-right i18n-path="tooltips.dailyreceiptOverview.editCalendar.nothingSoldDay">
                                    <v-radio :label="$t('dailyreceiptsView.sold_nothing')" :value="ZERO_SALE" />
                                </base-icon-right>
                                <base-icon-right i18n-path="tooltips.dailyreceiptOverview.editCalendar.closedDay">
                                    <v-radio :label="$t('dailyreceiptsView.closed')" :value="CLOSED" />
                                </base-icon-right>
                            </v-radio-group>
                            <submit-button :disabled="!canSubmitChanges" block class="mb-4" @click="saveCalendar">{{
                                $t('dailyreceiptsView.submit_changes')
                            }}</submit-button>
                            <submit-button block @click="cancelChanges">
                                {{ $t('dailyreceiptsView.cancel') }}</submit-button
                            >
                        </template>
                    </widget>
                </v-col>
                <v-col :md="showEditModal ? 9 : 12">
                    <v-sheet tile height="54" class="d-flex align-center">
                        <v-btn icon class="ma-2" @click="goToPreviousMonth">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        {{ viewingMonth }} {{ viewingYear }}
                        <v-btn icon class="ma-2" @click="goToNextMonth">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-sheet>

                    <v-calendar
                        ref="calendar"
                        v-model="value"
                        :type="type"
                        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                        :events="events"
                        :event-color="getEventColor"
                        @click:date="handleSelectDate"
                        @click:day="handleSelectDate"
                        @click:event="handleSelectEvent"
                /></v-col>
            </v-row>
        </template>
    </view-layout>
</template>

<script>
import BaseIconRight from '@/components/BaseIconRight.vue';
import PageHeader from '@/components/PageHeader';
import SubmitButton from '@/components/SubmitButton.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import Widget from '@/components/Widget.vue';
import { usePreventDuplicateRoute } from '@/composables/usePreventDuplicateRoute';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import * as DailyreceiptService from '@/services/dailyreceipt.ts';
import dayjs from 'dayjs';
import VueRouter from 'vue-router';
import { mapActions, mapState } from 'vuex';
import { getInitialDateForCalendar } from './Dailyreceipt.helpers';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

export default {
    components: {
        ViewLayout,
        PageHeader,
        Widget,
        SubmitButton,
        BaseIconRight
    },
    beforeRouteLeave(to, from, next) {
        if (this.hasPendingChanges) {
            const userWantsToLeavePage = confirm(this.$t('general.unsaved_items'));
            if (userWantsToLeavePage === true) {
                return next();
            }

            return;
        }

        next();
    },
    setup() {
        const { preventDuplicateRoute } = usePreventDuplicateRoute();

        return { preventDuplicateRoute };
    },
    data() {
        return {
            months: [
                this.$t('months.jan'),
                this.$t('months.feb'),
                this.$t('months.mar'),
                this.$t('months.apr'),
                this.$t('months.may'),
                this.$t('months.jun'),
                this.$t('months.jul'),
                this.$t('months.aug'),
                this.$t('months.sep'),
                this.$t('months.oct'),
                this.$t('months.nov'),
                this.$t('months.dec')
            ],
            value: undefined,
            existingEvents: [],
            type: 'month',
            CLOSED: 'CLOSED',
            ZERO_SALE: 'ZERO_SALE',
            statusMode: 'CLOSED',
            currentTab: 0,
            changes: [],
            statusModes: ['CLOSED', 'ZERO_SALE'],
            showEditModal: false
        };
    },
    computed: {
        ...mapState(['selectedYear']),
        events() {
            return [...this.changes, ...this.existingEvents];
        },
        canSubmitChanges() {
            return this.changes.length > 0;
        },
        hasPendingChanges() {
            return this.changes.length > 0;
        },
        viewingMonth() {
            return this.months[dayjs(this.value, 'YYYY-MM-DD').month()];
        },
        viewingYear() {
            return dayjs(this.value, 'YYYY-MM-DD').year();
        }
    },
    async created() {
        this.updateDate(
            getInitialDateForCalendar(this.$route.query.date, this.selectedYear, dayjs().format('YYYY-MM-DD'))
        );
        this.existingEvents = await this.getAllEvents();
    },
    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        setNewDateQuery(date) {
            this.$router
                .replace({
                    query: {
                        date
                    }
                })
                .catch((e) => {
                    if (isNavigationFailure(e, NavigationFailureType.duplicated)) {
                        return;
                    }

                    throw e;
                });
        },
        goToNextMonth() {
            const newCalendarDate = this.getSelectedDateInViewDayjs().add(1, 'M').format('YYYY-MM-DD');
            this.updateDate(newCalendarDate);
        },
        goToPreviousMonth() {
            const newCalendarDate = this.getSelectedDateInViewDayjs().subtract(1, 'M').format('YYYY-MM-DD');
            this.updateDate(newCalendarDate);
        },
        updateDate(newCalendarDate) {
            this.setNewDateQuery(newCalendarDate);
            this.value = newCalendarDate;
        },
        getSelectedDateInViewDayjs() {
            return dayjs(this.getSelectedDateInView(), 'YYYY-M-DD');
        },
        getSelectedDateInView() {
            const date = dayjs(this.value, 'YYYY-MM-DD');
            return `${date.year()}-${date.month() + 1}-01`;
        },
        toggleEditView() {
            this.showEditModal = !this.showEditModal;
        },
        async routeToDailyReceiptsOfDateAndDoALotOfOtherStuff(date) {
            if (this.showEditModal === false) {
                // TODO: Navigation to change page
                return this.$router.push({
                    name: 'add-daily-receipt',
                    params: {
                        date: date
                    }
                });
            }

            if (this.existingEvents.some((_event) => _event.start === date)) {
                // Cannot edit an event that is in the commited event list
                return;
            }

            if (this.changes.some((_event) => _event.start === date)) {
                return (this.changes = this.changes.filter((_event) => _event.start !== date));
            }

            this.changes.push({
                start: date,
                end: date,
                color: this.getColor(this.statusMode),
                name: this.getText(this.statusMode),
                timed: true,
                status: this.statusMode
            });
        },
        handleSelectDate({ date }) {
            this.preventDuplicateRoute(this.routeToDailyReceiptsOfDateAndDoALotOfOtherStuff, date);
        },
        async routeToDailyReceiptsOfDate(date) {
            return this.$router.push({
                name: 'add-daily-receipt',
                params: {
                    date
                }
            });
        },
        handleSelectEvent({ day: { date } }) {
            this.preventDuplicateRoute(this.routeToDailyReceiptsOfDate, date);
        },
        async getAllEvents() {
            return (await DailyreceiptService.getAllDays(this.selectedYear))
                .filter((_dailyreceiptdate) => _dailyreceiptdate.status !== null)
                .map((_dailyreceiptdate) => {
                    return {
                        start: _dailyreceiptdate.date,
                        end: _dailyreceiptdate.date,
                        status: _dailyreceiptdate.status,
                        color: this.getColor(_dailyreceiptdate.status),
                        name: this.getText(_dailyreceiptdate.status),
                        timed: true
                    };
                });
        },
        getColor(mode) {
            if (mode === this.CLOSED) {
                return 'orange';
            }

            if (mode === 'ENTRY') {
                return 'green';
            }

            return 'blue';
        },
        getText(mode) {
            if (mode === this.CLOSED) {
                return this.$t('dailyreceiptsView.closed');
            } else if (mode === 'ENTRY') {
                return this.$t('dailyreceiptsView.sold');
            }

            return this.$t('dailyreceiptsView.sold_nothing');
        },
        getEventColor(event) {
            return event.color;
        },
        cancelChanges() {
            this.changes = [];
            this.closeEditModal();
        },
        async saveCalendar() {
            try {
                this.startLoading();
                await DailyreceiptService.markDays(
                    this.changes.map((_day) => {
                        return {
                            date: _day.start,
                            status: _day.status
                        };
                    })
                );

                this.changes = [];

                notify.call(this, {
                    title: this.$t('calendarevent.succesfuly_saved'),
                    text: this.$t('calendarevent.succesfuly_saved')
                });

                this.existingEvents = await this.getAllEvents(this.selectedYear);

                this.closeEditModal();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        closeEditModal() {
            this.showEditModal = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/sass/mixin';

#viewDailyreceiptPage :deep() {
    @include respond-to('phone') {
        .Wrapper {
            display: block !important;
        }
    }

    .v-event {
        margin: 0 auto !important;
        text-align: center;
    }

    .theme--light.v-calendar-weekly .v-calendar-weekly__day {
        min-height: 80px;
    }
}
</style>
