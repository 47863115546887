import dayjs from 'dayjs';
import { assertUnreachable } from '@/helpers/typeHelper';

export type PreviousYearSubmissionSituations =
    | 'CAN_SUBMIT'
    | 'ELIGABLE_BUT_DID_NOT_DO_PREVIOUS_ACCOUNTING_YEAR_IN_DEXXTER'
    | 'NOT_ELIGABLE_BUT_HAS_THE_TAX_YEAR_FOR_GIVEN_YEAR'
    | 'NOT_ELIGABLE';

/**
 * Functions which determines if the user must submit
 */
export function getSituationIfCanSubmitPreviousYearOblogation(
    { companyStartDate }: { companyStartDate: string },
    activeYears: number[],
    givenYear: number
): PreviousYearSubmissionSituations {
    if (dayjs(companyStartDate, 'YYYY-MM-DD').year() <= givenYear) {
        if (!activeYears.includes(givenYear)) {
            return 'ELIGABLE_BUT_DID_NOT_DO_PREVIOUS_ACCOUNTING_YEAR_IN_DEXXTER';
        }
    } else {
        // Company start date is after the givenYear
        if (!activeYears.includes(givenYear)) {
            return 'NOT_ELIGABLE';
        } else {
            return 'NOT_ELIGABLE_BUT_HAS_THE_TAX_YEAR_FOR_GIVEN_YEAR';
        }
    }

    return 'CAN_SUBMIT';
}

export function checkIfCanSubmitPreviousYearOblogation(
    userData: { companyStartDate: string },
    activeYears: number[],
    givenYear: number
): boolean {
    const result = getSituationIfCanSubmitPreviousYearOblogation(userData, activeYears, givenYear);

    if (
        result === 'ELIGABLE_BUT_DID_NOT_DO_PREVIOUS_ACCOUNTING_YEAR_IN_DEXXTER' ||
        result === 'NOT_ELIGABLE' ||
        result === 'NOT_ELIGABLE_BUT_HAS_THE_TAX_YEAR_FOR_GIVEN_YEAR'
    ) {
        return false;
    } else if (result === 'CAN_SUBMIT') {
        return true;
    }

    assertUnreachable(result);
}
