<template>
    <div ref="dragAndDropField" class="dragAndDropField" @drop.prevent="drop" @dragover.prevent>
        <input
            id="assetsFieldHandle"
            ref="file"
            :multiple="multiple"
            :accept="acceptedTypes"
            type="file"
            @change="changeFile"
        />

        <label v-if="!hasFiles" for="assetsFieldHandle" style="cursor: pointer" class="text-center">
            <span>{{ maxFilesText }}</span>
        </label>

        <div v-else class="fileWrapper">
            <div class="fileList mb-3">
                {{ $t('general.files') }}:
                <ul>
                    <li v-for="file in currentFiles" :key="file.name">
                        {{ file.name }}
                    </li>
                </ul>
            </div>
            <button @click.prevent="resetFileStatus">
                <span style="text-decoration: underline">{{ $t('general.delete_files') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import { replace } from 'lodash';

import mime from 'mime-types';

class ExceedsFileSizeLimitError extends Error {}
class WrongFileTypeError extends Error {}

export default {
    props: {
        acceptedFileTypes: {
            type: Array,
            required: true
        },
        minHeight: {
            default: null
        },
        maxFileSizeMb: {
            default: 2,
            type: Number
        },
        maxNumberOfFiles: {
            default: -1,
            type: Number
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            currentFiles: []
        };
    },

    computed: {
        hasFiles() {
            return this.currentFiles.length > 0;
        },
        acceptedTypes() {
            return (
                this.acceptedFileTypes
                    // .map(this.removeNonLetters)
                    // .map(lowerCase)
                    // .map(this.addPointExtension)
                    .join(',')
            ); // ex output: .pdf,.jpg,.jpeg,.png;
        },
        maxFilesText() {
            if (this.maxNumberOfFiles === -1) {
                return;
            }

            return this.$t(`addExpense.upload_expenses`, {
                totalFiles: this.maxNumberOfFiles,
                totalMB: this.maxFileSizeMb
            });
        }
    },

    watch: {
        minHeight: {
            immediate: true,
            handler(value) {
                this.$nextTick(() => {
                    if (!this.$refs.dragAndDropField) {
                        return;
                    }

                    if (!value) {
                        return;
                    }

                    this.$refs.dragAndDropField.style.minHeight = `${value}px`;
                });
            }
        }
    },

    methods: {
        changeFile() {
            this.currentFiles = this.$refs.file.files;

            if (this.maxNumberOfFiles !== -1 && this.currentFiles.length > this.maxNumberOfFiles) {
                this.$emit('error:max-file-limit-exceeded');
                this.clearInputField();
                return;
            }

            for (let i = 0; i < this.currentFiles.length; i++) {
                if (!this.checkFileRequirementsAndEmitError(this.currentFiles.item(i))) {
                    this.clearInputField();
                    return;
                }
            }

            this.$emit('change', this.currentFiles);
        },
        clearInputField() {
            this.$refs.file.value = '';
        },
        removeNonLetters(value) {
            return replace(value, /\W/g, '');
        },
        addPointExtension(value) {
            return `.${value}`;
        },
        checkFileRequirementsAndEmitError(file) {
            try {
                this.checkFileRequirements(file);
                return true;
            } catch (e) {
                if (e instanceof ExceedsFileSizeLimitError) {
                    this.$emit('error:max-upload-size-exceeded');
                }
                if (e instanceof WrongFileTypeError) {
                    this.$emit('error:wrong-file-type');
                }
                this.clearInputField();
                return false;
            }
        },
        checkFileRequirements(file) {
            if (!this.checkValidExtension(file.name)) {
                throw new WrongFileTypeError();
            }
            if (this.isExceedingFileLimit(file, this.maxFileSizeMb * 1_000_000)) {
                throw new ExceedsFileSizeLimitError();
            }
        },
        isExceedingFileLimit(file, limitInBytes) {
            return file.size > limitInBytes;
        },
        drop(event) {
            if (event.dataTransfer.files && event.dataTransfer.files.length === 0) {
                return;
            }

            this.$refs.file.files = event.dataTransfer.files;
            this.changeFile();
        },
        checkValidExtension(fileName) {
            const splittedParts = fileName.split('.');
            const extension = splittedParts[splittedParts.length - 1];

            return this.acceptedFileTypes.some((acceptedType) => acceptedType === mime.lookup(extension));
        },
        resetFileStatus() {
            this.currentFiles = [];
            this.$refs.file.value = '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/sass/variables';

.dragAndDropField {
    position: relative;
    padding: 30px;
    background-color: #e3ebf6;
    border: 1px solid $grey-100;
    display: flex;
    justify-content: center;

    label,
    div.fileList {
        display: flex;
        align-items: center;
    }
    div.fileList {
        flex-direction: column;
    }

    div.fileWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    input {
        display: none;
    }
}
</style>
