
import Vue, { PropOptions } from 'vue';
import CommonModal from '@/components/Modal/CommonModal.vue';
import { mapState } from 'vuex';
import { formRulesMixin } from '@/mixins/formRulesMixin';
import { uploadVATSubmissionRequest } from '@/services/vat';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { Quarters } from '@/config/constants';

export default Vue.extend({
    name: 'SubmitVatDeclarationModal',
    components: { CommonModal },
    mixins: [formRulesMixin],
    props: {
        i18nTitle: {
            type: String,
            required: true
        },
        i18nText: {
            type: String,
            required: true
        },
        year: {
            type: Number,
            required: true
        } as PropOptions<number>,
        selectedQuarter: {
            type: String,
            required: true
        } as PropOptions<Quarters>,
        xmlFile: {
            type: Object,
            required: true
        } as PropOptions<Record<string, unknown>>
    },
    data() {
        return {
            file: null,
            valid: false,
            loading: false
        };
    },
    computed: {
        ...mapState(['selectedYear'])
    },
    watch: {
        valid() {
            if (this.valid && this.file) this.$emit('vat-declaration-selected');
        },
        file() {
            if (!this.file) this.$emit('vat-declaration-reset');
        }
    },
    methods: {
        async emitNextEvent() {
            if (!this.file) {
                return;
            }
            try {
                this.loading = true;
                const quarterNumber = this.selectedQuarter.replace('Q', '');

                await uploadVATSubmissionRequest(this.file, this.year, quarterNumber);

                this.$emit('next');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
});
