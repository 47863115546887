
import { defineComponent, PropType, Ref } from 'vue';
import {
    DataTableHeader,
    OptionsType,
    PaginationDataTableV2,
    renderClassFunctionType
} from './PaginationDataTableV2.types';

export default defineComponent<PaginationDataTableV2>({
    props: {
        headers: {
            type: Array as PropType<DataTableHeader[]>,
            required: true
        },
        items: {
            type: Array as PropType<unknown[]>,
            required: true
        },
        itemsPerPageOptions: {
            type: Array as PropType<number[]>,
            default: () => [10, 20, 50]
        },
        renderClassFunction: {
            type: Function as PropType<renderClassFunctionType>,
            required: false
        },
        showSelect: {
            default: false,
            type: Boolean
        },
        id: {
            type: String,
            required: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        totalServerSideItems: {
            type: Number,
            required: true
        },
        options: {
            type: Object as PropType<OptionsType>,
            required: true
        }
    },

    emits: ['update-options', 'refetch-data', 'click-row', 'selected-items'],

    setup(props, { emit: $emit }) {
        function handleUpdateOptions(options: OptionsType): void {
            $emit('update-options', options);
        }

        function renderRowID(item: { id: number }): string[] {
            const classes = [`id-${item.id.toString()}`];
            if (props.renderClassFunction) {
                classes.push(props.renderClassFunction(item));
            }
            return classes;
        }
        function emitRowClickedEvent(item: unknown): void {
            $emit('click-row', item);
        }
        function emitSelectedInputsEvent(items: unknown[]): void {
            $emit('selected-items', items);
        }

        return { handleUpdateOptions, emitSelectedInputsEvent, emitRowClickedEvent, renderRowID };
    }
});
