
import PageHeader from '@/components/PageHeader.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import StartResultatenrekeningForm from './components/StartResultatenrekeningForm.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import {
    sendGetStartResultatenrekeningRequest,
    sendSetStartResultatenrekeningRequest
} from '@/services/startResultatanrekening';
import { mapActions, mapState } from 'vuex';
import { notify } from '@/helpers/successNotification';
import { sendGetGrootboekrekeningRequest } from '@/services/grootboekrekening';
import Vue from 'vue';
import {
    Grootboekrekening,
    grootboekrekeningBookingIncomeTypes,
    IStartResultatenrekeningBookingViewModel
} from 'dexxter-types';
import Loader from '@/components/Loader.vue';
import { GrootboekrekeningMapType } from './types';
import AddExpenseCategory from '@/components/BottomSheets/AddExpenseCategory.vue';

export default Vue.extend({
    components: { ViewLayout, PageHeader, StartResultatenrekeningForm, Loader, AddExpenseCategory },

    data() {
        return {
            loading: true,
            startResultatenrekeningBookings: [] as GrootboekrekeningMapType[],
            grootboekrekeningen: [] as Grootboekrekening[]
        };
    },

    computed: {
        ...mapState(['selectedYear'])
    },

    async created() {
        try {
            this.loading = true;

            // We need first the grootboekrekningen to load to do some transformations to the bookings
            await this.getGrootboekrekeningen();
            await this.getBookings();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['newExpenseCategory']),
        async getBookings() {
            this.startResultatenrekeningBookings = this.mapData(
                await sendGetStartResultatenrekeningRequest(this.selectedYear)
            );
        },
        async getGrootboekrekeningen(): Promise<void> {
            this.grootboekrekeningen = await sendGetGrootboekrekeningRequest();
        },
        mapData(data: IStartResultatenrekeningBookingViewModel[]): GrootboekrekeningMapType[] {
            return data.map((_item) => {
                return {
                    description: _item.description,
                    amount: _item.amount,
                    grootboekrekeningNumber: _item.grootboekrekeningNumber,
                    name: this.findGrootboekrekeningName(_item.grootboekrekeningNumber),
                    incomeType: this.findGrootboekrekeningIncometype(_item.grootboekrekeningNumber)
                };
            });
        },
        findGrootboekrekeningName(number: number): string {
            const children = this.grootboekrekeningen.flatMap((_parent) => _parent.children);

            const child = children.find((_child) => _child.number === number);
            if (!child) {
                throw new Error('Cannot find name');
            }
            return child.name as string;
        },
        findGrootboekrekeningIncometype(number: number): grootboekrekeningBookingIncomeTypes {
            for (const parent of this.grootboekrekeningen) {
                if (parent.children.some((_child) => _child.number === number)) {
                    return parent.incomeType;
                }
            }

            throw new Error('Incometype not found');
        },
        async setResultatenrekeningBookings(data: any) {
            try {
                this.loading = true;

                await sendSetStartResultatenrekeningRequest(this.selectedYear, data);

                notify.call(this, {
                    title: this.$t('general.succesfuly_saved'),
                    text: this.$t('general.succesfuly_saved')
                });

                await this.getBookings();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
});
