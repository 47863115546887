
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import Loader from '@/components/Loader.vue';
import ErrorModal from '@/components/Modal/ErrorModal.vue';
import PageHeader from '@/components/PageHeader.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import CurrentPreviousQuarterResult from '@/views/VAT/VATListing/components/CurrentPreviousQuarterResult.vue';
import VatDeclarationActions from '@/views/VAT/VATListing/components/VatDeclarationActions.vue';
import VatDeclarationContent from '@/views/VAT/VATListing/components/VatDeclarationContent.vue';
import VatModalController from '@/views/VAT/VATListing/components/VatModalController.vue';
import VatSubmissionStatus from '@/views/VAT/VATListing/components/VatSubmissionStatus.vue';
import ViewRasterBookings from './components/ViewRasterBookings.vue';
// --- Mixins ---
import { Q1, Q2, Q3, Q4, Quarters } from '@/config/constants';
import { getCurrentDateString } from '@/helpers/dateHelpers';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { assertUnreachable } from '@/helpers/typeHelper';
import { MapStateToComputed } from '@/helpers/types';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import {
    getAllVATListingResultsRequest,
    sendGetQuarterResultRequest,
    sendUpdateQuarterRequest
} from '@/services/quarter';
import { IQuarterViewModel } from '@/services/quarter.types';
import {
    getVATSubmissionsRequest,
    sendDeleteVATSubmissionsRequest,
    sendDownloadVATDeclarationXMLRequest
} from '@/services/vat';
import { InitializedUseraccountAuthState } from '@/store/modules/auth';
import { canDownloadVatOrIcListing, getUserFriendlyQuarter } from '@/views/VAT/businessServices/vatdeclaration.service';
import dayjs from 'dayjs';
import VueRouter from 'vue-router';
import mixins from 'vue-typed-mixins';
import { mapActions, mapState } from 'vuex';
import { IVATSubmissionViewModel } from '../models/models';
const { isNavigationFailure } = VueRouter;

export default mixins(middlewareMixin, screenSizeMixin).extend({
    components: {
        Loader,
        ViewLayout,
        PageHeader,
        ConfirmationDialog,
        SubmitButton,
        ViewRasterBookings,
        VatDeclarationContent,
        CurrentPreviousQuarterResult,
        VatDeclarationActions,
        VatSubmissionStatus,
        VatModalController
    },
    mixins: [screenSizeMixin, middlewareMixin],
    data() {
        return {
            currentlyActiveQuarter: Q1 as Quarters,
            texts: {
                Q1: null,
                Q2: null,
                Q3: null,
                Q4: null
            } as any,
            vatSubmissions: [] as IVATSubmissionViewModel[],
            generalLoading: false as boolean,
            resultMap: {} as Record<
                number,
                {
                    Q1?: number | null | undefined;
                    Q2?: number | null | undefined;
                    Q3?: number | null | undefined;
                    Q4: number | null | undefined;
                }
            >,
            quarterData: {
                Q1: null,
                Q2: null,
                Q3: null,
                Q4: null
            } as {
                [key in Quarters]: IQuarterViewModel | null;
            },
            toggleVatModalValue: false as boolean
        };
    },
    watch: {
        currentlyActiveQuarter(value: Quarters) {
            this.updateQuery(value);
        }
    },
    async created(): Promise<void> {
        await Promise.all([this.getVATDeclaration(), this.getVATSubmissions()]);
        this.updateCurrentlyActiveQuarter(this.getCurrentlyActiveQuarter());

        this.resultMap = {
            [this.selectedYear - 1]: {
                Q4: undefined
            },
            [this.selectedYear]: {
                Q1: undefined,
                Q2: undefined,
                Q3: undefined,
                Q4: undefined
            }
        };

        this.getVATResults();
    },
    computed: {
        ...(mapState(['selectedYear']) as MapStateToComputed<{ selectedYear: number }>),
        ...(mapState('auth', ['currentUserData']) as MapStateToComputed<InitializedUseraccountAuthState>),
        vatSubmission(): IVATSubmissionViewModel | null {
            return this.getVATSubmission(this.vatSubmissions, this.currentQuarterNumber);
        },
        quarterText(): string {
            return this.texts[this.currentlyActiveQuarter];
        },
        currentQuarterNumber(): number {
            return this.currentTab + 1;
        },
        selectedQuarter(): Quarters {
            return `Q${this.currentQuarterNumber}` as Quarters;
        },
        quarterDataSelectedQuarter(): IQuarterViewModel | null {
            const result = this.quarterData[this.currentlyActiveQuarter];
            if (!result) {
                return null;
            }

            return result;
        },
        currentPeriodResult(): number {
            const quarter = this.currentlyActiveQuarter;

            if (!this.isResultMapLoaded()) {
                return 0;
            }

            return this.resultMap[this.selectedYear][quarter] ?? 0;
        },
        previousPeriodResult(): number {
            const quarter = this.currentlyActiveQuarter;

            if (!this.isResultMapLoaded()) {
                return 0;
            }

            if (quarter === Q1) {
                return this.resultMap[this.selectedYear - 1]['Q4'] ?? 0;
            } else if (quarter === Q2) {
                return this.resultMap[this.selectedYear]['Q1'] ?? 0;
            } else if (quarter === Q3) {
                return this.resultMap[this.selectedYear]['Q2'] ?? 0;
            } else if (quarter === Q4) {
                return this.resultMap[this.selectedYear]['Q3'] ?? 0;
            }

            assertUnreachable(quarter);
        },
        isLoading(): boolean {
            return !this.isTextDataLoaded() || !this.isResultMapLoaded() || !this.isQuarterDataLoaded();
        },
        currentTab: {
            get(): number {
                return this.getCurrentTab(this.currentlyActiveQuarter);
            },
            set(value: number) {
                this.updateCurrentlyActiveQuarter(`Q${value + 1}` as Quarters);
            }
        }
    },

    methods: {
        sendUpdateQuarterRequest,
        sendDeleteVATSubmissionsRequest,
        sendDownloadVatDeclarationXmlRequest: sendDownloadVATDeclarationXMLRequest,
        ...mapActions('vatDeclaration', [
            'getVATDeclarationFromQuarter',
            'downloadVATDeclarationXML',
            'getVATDeclarationText'
        ]),
        async handleRefreshSubmission() {
            await this.getVATSubmissions();
        },
        async handleRefreshQuarterData() {
            await Promise.all([this.getQuarterData(), this.getVATDeclaration()]);
        },
        async getQuarterData() {
            const quarterArray = await getAllVATListingResultsRequest(this.selectedYear);
            for (const [, quarter] of quarterArray.entries()) {
                this.quarterData[quarter.type] = quarter;
            }
        },
        updateCurrentlyActiveQuarter(value: Quarters) {
            this.currentlyActiveQuarter = value;
        },
        updateQuery(quarter: Quarters) {
            this.$router
                .replace({
                    query: {
                        quarter
                    }
                })
                .catch((e: unknown) => {
                    if (isNavigationFailure(e)) {
                        return;
                    }

                    throw e;
                });
        },
        isTextDataLoaded(): boolean {
            if (!this.texts) {
                return false;
            }
            return this.texts['Q1'] != undefined;
        },
        isResultMapLoaded(): boolean {
            if (!this.resultMap) {
                return false;
            }
            if (!this.resultMap[this.selectedYear]) {
                return false;
            }

            return this.resultMap[this.selectedYear]['Q1'] !== undefined;
        },
        isQuarterDataLoaded: function (): boolean {
            if (!this.quarterData) {
                return false;
            }
            return this.quarterData['Q1'] != undefined;
        },
        getDefaultQuarter(): Quarters {
            return Q1;
        },
        getCurrentlyActiveQuarter(): Quarters {
            if (this.$route.query.quarter) {
                return this.$route.query.quarter as Quarters;
            }
            return getUserFriendlyQuarter(this.selectedYear, this.vatSubmissions);
        },
        getVATSubmission(vatSubmissions: IVATSubmissionViewModel[], quarter: number): IVATSubmissionViewModel | null {
            const result = vatSubmissions.find((_submission) => {
                return _submission.quarter === quarter;
            });

            if (!result) {
                return null;
            }

            return result;
        },
        getCurrentTab(activeQuarter: Quarters): number {
            return parseInt(activeQuarter.substr(1, 1)) - 1;
        },
        getText(quarter: Quarters): Promise<void> {
            return this.getVATDeclarationText(quarter)
                .then((result) => result.result)
                .then((text) => {
                    this.texts[quarter] = text;
                });
        },
        async getVATDeclaration() {
            try {
                this.generalLoading = true;
                if (this.selectedYear) {
                    await this.getQuarterData();
                    await Promise.all([this.getText('Q1'), this.getText('Q2'), this.getText('Q3'), this.getText('Q4')]);
                }
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.generalLoading = false;
            }
        },
        async getVATResults() {
            try {
                await Promise.all(
                    Object.keys(this.resultMap).map(async (_year) => {
                        return Object.keys(this.resultMap[parseInt(_year)]).map(async (_quarter) => {
                            this.resultMap[parseInt(_year)][_quarter as Quarters] = await sendGetQuarterResultRequest(
                                parseInt(_year),
                                _quarter as Quarters
                            );
                        });
                    })
                );
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }
        },
        async getVATSubmissions() {
            try {
                this.generalLoading = true;
                this.vatSubmissions = await getVATSubmissionsRequest(this.selectedYear);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.generalLoading = false;
            }
        },
        openVatModal(): void {
            const currentYear = dayjs(getCurrentDateString(), 'YYYY-MM-DD').year();

            if (this.selectedYear < currentYear) {
                this.toggleVatModalValue = !this.toggleVatModalValue;
                return;
            }

            if (canDownloadVatOrIcListing(getCurrentDateString(), this.currentlyActiveQuarter, this.selectedYear)) {
                this.toggleVatModalValue = !this.toggleVatModalValue;
            } else {
                this.openCannotSubmitVatModalYet();
            }
        },
        openCannotSubmitVatModalYet(): void {
            this.$store.commit('modal/setModalContent', {
                component: ErrorModal,
                props: {
                    i18nTitle: 'videotooltips.errors.cannotUploadVatListingWhenQuarterIsNotOver.title',
                    i18nText: 'videotooltips.errors.cannotUploadVatListingWhenQuarterIsNotOver.text',
                    actions: [
                        {
                            action: () => this.$store.commit('modal/setIsModalOpen', false),
                            i18nPath: 'videotooltips.errors.cannotUploadVatListingWhenQuarterIsNotOver.primary_button',
                            icon: 'mdi-check'
                        }
                    ]
                },
                dataId: 'cannot-submit-vat-modal'
            });
        }
    }
});
