
import i18n from '@/i18n';
import { computed, defineComponent, ref } from 'vue';

import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';

import Loader from '@/components/Loader.vue';
import EditDeposit from '@/views/deposits/EditDeposit.vue';
import DepositRow from './deposit-row/DepositRow.vue';

import { Quarters } from '@/config/constants';
import { Deposits } from '../deposits.types';

interface DepositsTableProps {
    deposits: Deposits;
    selectedYear: number;
}

export default defineComponent<DepositsTableProps>({
    name: 'DepositsTable',
    components: {
        Loader,
        DepositRow,
        EditDeposit
    },
    props: {
        deposits: {
            type: Object,
            required: true
        },
        selectedYear: {
            type: Number,
            required: true
        }
    },
    emits: ['refresh-data'],
    setup(props, { emit }) {
        const loading = ref<boolean>(false);
        const selectedQuarter = ref<Quarters | null>(null);
        const depositAmountOfSelectedQuarter = ref<null | number>(null);
        const isEditDepositModalOpen = ref<boolean>(false);

        function getDepositAmount(quarter: Quarters) {
            return props.deposits[`deposit${quarter}`];
        }

        function openEditDeposit(quarter: Quarters): void {
            depositAmountOfSelectedQuarter.value = getDepositAmount(quarter);
            selectedQuarter.value = quarter;
            isEditDepositModalOpen.value = true;
        }

        function handleUpdateDeposit(): void {
            depositAmountOfSelectedQuarter.value = null;
            selectedQuarter.value = null;
            emit('refresh-data');
        }

        const totalDepositValue = computed<string>(() => {
            const depositsSum: number = Object.values(props.deposits).reduce((acc, curr) => {
                return acc + curr;
            }, 0);

            return convertToLocaleValuta(depositsSum, { locale: i18n.locale });
        });

        return {
            quarters: Quarters,
            getDepositAmount,
            openEditDeposit,
            totalDepositValue,
            isEditDepositModalOpen,
            selectedQuarter,
            depositAmountOfSelectedQuarter,
            handleUpdateDeposit,
            loading
        };
    }
});
