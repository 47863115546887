<template>
    <section id="addDailyReceiptsPage">
        <view-layout>
            <template #header>
                <back-button class="mt-5" @click="goBackToOverview" />

                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                        <sub>
                            <tooltip
                                :underlined="false"
                                :title="$t('tooltips.addDailyreceipt.title.title')"
                                :content="$t('tooltips.addDailyreceipt.title.content')"
                            >
                                <template slot="activator">
                                    <v-icon small> mdi-information </v-icon>
                                </template>
                            </tooltip>
                        </sub>
                    </template>

                    <template #centerContent>
                        <article class="current-date-wrapper">
                            <v-btn icon @click="handleClickPrevious">
                                <v-icon> mdi-chevron-left</v-icon>
                            </v-btn>
                            <span v-if="!loading" class="current-date"
                                ><span>{{ currentDate }}</span></span
                            >
                            <span v-else class="loading-date">
                                <span class="day-and-month"><text-skeleton /></span>,
                                <span class="year"><text-skeleton /></span>
                            </span>
                            <v-btn icon @click="handleClickNext">
                                <v-icon> mdi-chevron-right</v-icon>
                            </v-btn>
                        </article>
                    </template>

                    <template #menu>
                        <template v-if="canShowMenu">
                            <submit-button class="closed-button" @click="markAsClosed">
                                {{ $t('addDailyreceipts.mark_as_closed') }}
                            </submit-button>
                            <submit-button class="nothing-sold-button" @click="markAsZerosale">
                                {{ $t('addDailyreceipts.mark_as_zero_sales') }}
                            </submit-button>
                        </template>
                    </template>
                </page-header>
            </template>
            <template #content>
                <v-card outlined class="pb-0">
                    <v-card-text v-if="currentUserData">
                        <v-form id="dailyreceiptForm" ref="form" v-model="valid">
                            <v-row class="mx-1">
                                <v-col cols="12" md="12">
                                    <v-simple-table id="invoiceItemTable" dense>
                                        <template #default>
                                            <thead>
                                                <th scope="col" class="text-left">
                                                    {{ $t('general.description') }}
                                                </th>
                                                <template v-if="isVATLiable">
                                                    <th scope="col" class="text-left">
                                                        {{ $t('general.amount_incl_vat') }}
                                                    </th>
                                                </template>
                                                <template v-if="isVATLiable">
                                                    <th scope="col" class="text-left">
                                                        {{ $t('general.vat_amount') }}
                                                    </th>
                                                </template>

                                                <th scope="col" class="text-left">
                                                    {{
                                                        isVATLiable
                                                            ? $t('general.amount_excl_vat')
                                                            : $t('general.total')
                                                    }}
                                                </th>
                                                <th scope="col" class="text-right"></th>
                                            </thead>

                                            <add-daily-receipt-non-editable-lineitem
                                                v-for="item in dailyreceipts"
                                                :key="item.key"
                                                :item="item"
                                                :is-vat-liable="isVATLiable"
                                                :is-loading-receipts="loading"
                                                @edit-dailyreceipt="editIncomeDailyReceipt"
                                            />
                                            <add-daily-receipt-table-loading :is-visible="loading" />

                                            <add-daily-receipt-form-input
                                                v-if="canFillInDay"
                                                v-model="newDailyreceipts"
                                            />

                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="100" class="text-center pa-6">
                                                        <i>{{ $t('addDailyreceipts.fill_in_previous_days_first') }}</i>
                                                        <br />
                                                        <submit-button class="mt-3" small @click="goBackToOverview">
                                                            {{ $t('addDailyreceipts.back_to_overview') }}
                                                        </submit-button>
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tbody class="summary">
                                                <tr>
                                                    <td :colspan="isVATLiable ? 3 : 1" class="text-right">
                                                        {{ $t('general.total_excl_vat') }}
                                                    </td>
                                                    <td colspan="2">
                                                        {{ totalExclVAT | numericFormat | addEuroSign }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td :colspan="isVATLiable ? 3 : 1" class="text-right">
                                                        {{ $t('general.VAT') }}
                                                    </td>
                                                    <td colspan="2">
                                                        {{ totalVAT | numericFormat | addEuroSign }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td :colspan="isVATLiable ? 3 : 1" class="text-right">
                                                        <strong>{{ $t('general.total') }}</strong>
                                                    </td>
                                                    <td colspan="2">
                                                        <strong>
                                                            {{ sumOfAllDepreciations | numericFormat | addEuroSign }}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>

                            <v-row class="ma-0">
                                <v-col cols="12" md="12" class="add-daily-receipt-wrapper">
                                    <div
                                        v-if="showWarningDialog"
                                        class="Warning mb-4"
                                        data-info="dailyreceipt-warning_above_line_limit"
                                        v-html="$t('addDailyreceipts.warning_above_line_limit')"
                                    />
                                    <submit-button
                                        v-if="canShowSubmitButton"
                                        id="create-and-overview"
                                        data-action="create-dailyreceipt-and-overview"
                                        color="primary"
                                        :disabled="!canSubmitForm"
                                        class="add-daily-receipt-button"
                                        @click="runMiddleware(createAndOverviewMiddleware, newDailyreceipts)"
                                    >
                                        {{ $t('addDailyreceipts.create_and_overview') }}
                                    </submit-button>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </template>
        </view-layout>

        <confirmation-dialog
            v-if="showDialog"
            v-model="showDialog"
            v-bind="dialogAttributes.attributes"
            @click-positive="dialogAttributes.actions.clickPositive"
            @click-negative="dialogAttributes.actions.clickNegative"
        />

        <edit-income-daily-receipt @deletedItem="handleDeletedItem" @deleted-item="handleDeletedItem" />
    </section>
</template>

<script>
import EditIncomeDailyReceipt from '@/components/BottomSheets/EditIncomeDailyReceipt';
import BackButton from '@/components/Button/BackButton';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import PageHeader from '@/components/PageHeader';
import SubmitButton from '@/components/SubmitButton.vue';
import ViewLayout from '@/components/ViewLayout';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { reportError } from '@/helpers/logging.js';
import { notify } from '@/helpers/successNotification';
import { dailyreceiptProactiveSmartnotificationsMixin } from '@/mixins/dailyreceiptProactiveSmartnotificationsMixin.js';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import * as DailyreceiptService from '@/services/dailyreceipt.ts';
import AddDailyReceiptTableLoading from '@/views/dailyreceipts/add/components/AddDailyReceiptTableLoading';
import dayjs from 'dayjs';
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';

import { isVatLiable } from '@/helpers/VATHelper';
import { canFillInDayRequest, getDailyreceiptsOfDate } from '@/services/dailyreceipt';
import AddDailyReceiptFormInput from './components/AddDailyReceiptFormInput.vue';
import AddDailyReceiptNonEditableLineitem from './components/AddDailyReceiptNonEditableLineitem.vue';

import TextSkeleton from '@/components/skeleton/TextSkeleton';
import * as DailyreceiptBussinessService from '../businesslogic/dailyreceipt.service';

export default {
    components: {
        TextSkeleton,
        AddDailyReceiptTableLoading,
        BackButton,
        ViewLayout,
        PageHeader,
        SubmitButton,
        ConfirmationDialog,
        EditIncomeDailyReceipt,
        AddDailyReceiptNonEditableLineitem,
        AddDailyReceiptFormInput
    },

    mixins: [middlewareMixin, dailyreceiptProactiveSmartnotificationsMixin],

    data() {
        const baseSubmitMiddleware = [
            this.confirmationCreateDailyreceipt,
            this.dailyreceiptProactiveSmartnotifications,
            this.handleSubmit
        ];

        return {
            canFillInDay: false,
            loading: false,
            showWarningDialog: false,
            dailyreceipts: [],
            newDailyreceipts: [],
            valid: false,
            date: null,
            createAndOverviewMiddleware: [...baseSubmitMiddleware, this.redirectToOverview],
            showDialog: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        canShowSubmitButton() {
            return this.canFillInDay;
        },
        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        },
        canShowMenu() {
            return !this.loading && this.canShowDayButton;
        },
        canShowDayButton() {
            return DailyreceiptBussinessService.canShowDayButton(this.dailyreceipts);
        },
        currentDate() {
            if (this.date && dayjs(this.date, 'YYYY-MM-DD').isValid()) {
                return dayjs(this.date, 'YYYY-MM-DD').format('D MMM, YYYY');
            }
            return '';
        },
        allDepreciations() {
            return [...this.newDailyreceipts, ...this.dailyreceipts];
        },
        totalVAT() {
            return DailyreceiptBussinessService.calculateTotalVAT(this.allDepreciations);
        },
        sumOfAllDepreciations() {
            return DailyreceiptBussinessService.calculateSum(this.allDepreciations);
        },
        totalExclVAT() {
            return this.allDepreciations.reduce(this.addAmountEuroExclVATToAccumulator, 0);
        },
        canSubmitForm() {
            return this.valid && this.newDailyreceipts.length > 0 && this.date;
        }
    },

    watch: {
        newDailyreceipts: {
            handler(newDailyReceipts) {
                this.showWarningDialog = newDailyReceipts.some(
                    DailyreceiptBussinessService.isOverMaximumLineItemAmount
                );
            },
            deep: true
        },
        '$route.params.date': {
            async handler() {
                await this.getDateFromRouteParamsAndFetchItems();
            },
            immediate: true
        }
    },

    async created() {
        await this.getDateFromRouteParamsAndFetchItems();
    },

    methods: {
        ...mapActions('incomes', ['createDailyReceiptsArray']),
        ...mapActions('bottomSheets', ['editIncomeDailyReceipt']),

        async getDateFromRouteParamsAndFetchItems() {
            try {
                this.loading = true;

                if (this.$route.params.date) {
                    if (!dayjs(this.$route.params.date, 'YYYY-MM-DD').isValid()) {
                        return this.redirectToOverview();
                    }

                    [this.dailyreceipts, this.canFillInDay] = await Promise.all([
                        getDailyreceiptsOfDate(this.$route.params.date),
                        canFillInDayRequest(this.$route.params.date)
                    ]);

                    this.date = this.$route.params.date;
                }
            } catch (e) {
                reportError(e);
                apiErrorAndDisplay.call(this, e);
                return this.$router.replace({ name: 'dashboard' });
            } finally {
                this.loading = false;
            }
        },

        goBackToOverview() {
            this.$router.push({ name: 'dailyReceipts', query: { date: this.date } });
        },
        addAmountEuroExclVATToAccumulator(acc, cur) {
            if (cur.isCorrected) {
                return acc + 0;
            }
            return acc + (parseFloat(cur.amountEuroExclVAT) || 0);
        },
        async markAsZerosale() {
            try {
                this.loading = true;

                await DailyreceiptService.markDays([
                    {
                        date: this.date,
                        status: 'ZERO_SALE'
                    }
                ]);

                notify.call(this, {
                    title: this.$t('calendarevent.succesfuly_saved'),
                    text: this.$t('calendarevent.succesfuly_saved')
                });

                this.redirectToOverview();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        async markAsClosed() {
            try {
                this.loading = true;

                await DailyreceiptService.markDays([
                    {
                        date: this.date,
                        status: 'CLOSED'
                    }
                ]);

                notify.call(this, {
                    title: this.$t('calendarevent.succesfuly_saved'),
                    text: this.$t('calendarevent.succesfuly_saved')
                });

                this.redirectToOverview();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },

        handleClickPrevious() {
            const yesterday = dayjs(this.date).subtract(1, 'day').format('YYYY-MM-DD');
            this.navigateToDate(yesterday);
        },

        handleClickNext() {
            const tomorrow = dayjs(this.date).add(1, 'day').format('YYYY-MM-DD');
            this.navigateToDate(tomorrow);
        },

        navigateToDate(date) {
            this.$router.replace({ params: { date } }).catch();
        },

        async handleDeletedItem() {
            try {
                this.loading = true;

                await this.getAllDailyreceipts(this.date);

                this.newDailyreceipts = [];
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },

        async getAllDailyreceipts(date) {
            this.dailyreceipts = await getDailyreceiptsOfDate(date);
        },

        async checkIfUserCanFillInDay(date) {
            return canFillInDayRequest(date);
        },

        async handleSubmit(next) {
            try {
                this.loading = true;

                const dailyReceiptsArrayModified = this.preprocessDailyReceipts(this.newDailyreceipts);

                await this.createDailyReceiptsArray({
                    dailyreceipts: dailyReceiptsArrayModified,
                    date: this.date
                });

                notify.call(this, {
                    title: this.$t('income.dailyreceipt_created_success'),
                    text: this.$t('income.dailyreceipt_created_success')
                });

                this.$store.dispatch('incomes/newDailyReceiptCreated');

                if (next) {
                    return next();
                }

                this.resetForm();

                await this.getAllDailyreceipts(this.date);
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        preprocessDailyReceipts(dailyreceipts) {
            const dailyreceiptsCopy = _.cloneDeep(dailyreceipts);

            dailyreceiptsCopy.forEach((item) => {
                item.amountEuroExclVAT = parseFloat(item.amountEuroExclVAT);
                item.VATPercentage = parseFloat(item.VATPercentage);
                item.amountEuroVAT = parseFloat(item.amountEuroVAT) || 0;

                item.amountEuroVAT = parseFloat(item.amountEuroExclVAT * (item.VATPercentage / 100));
                item.date = this.date;
            });

            return dailyreceiptsCopy;
        },
        confirmationCreateDailyreceipt(next) {
            this.openConfirmationDialog();

            this.setDialogAttributes(
                {
                    title: this.$t('addDailyreceipts.dialogs.confirmationSubmitInfo.title'),
                    message: this.$t('addDailyreceipts.dialogs.confirmationSubmitInfo.content')
                },
                {
                    clickPositive: this.clickPositive.bind(this, next)
                }
            );
        },
        resetForm() {
            this.$refs.form.reset();
            this.newDailyreceipts = [];
        },
        redirectToOverview() {
            this.$router.push({
                name: 'dailyReceipts',
                query: {
                    date: this.getRedirectDate()
                }
            });
        },
        getRedirectDate() {
            const currentDate = dayjs(this.$route.params.date, 'YYYY-MM-DD');
            return dayjs(`${currentDate.year()}-${currentDate.month() + 1}-01`, 'YYYY-M-DD').format('YYYY-MM-DD');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/sass/mixin';

.current-date-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .current-date {
        font-weight: 900;
        color: $dexxter-dark;
        font-size: 1.3rem;
        margin: 0 0.5rem;
    }

    .loading-date {
        display: flex;
        flex-direction: row;

        .day-and-month {
            width: 4rem;
        }
        .year {
            width: 3rem;
        }
    }
}

.nothing-sold-button,
.closed-button {
    flex-grow: 1;

    @include md(md) {
        flex-grow: unset;
        width: auto;
    }
}

#addDailyReceiptsPage :deep() {
    #invoiceItemTable {
        table {
            th {
                padding: 5px 10px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
            }
            td {
                padding: 10px;
            }
            td.td-action {
                vertical-align: baseline;
                width: 1%;
            }
        }
    }

    tbody {
        tr:hover {
            background-color: transparent !important;
        }
    }

    td.no-items-td {
        padding: 15px !important;
    }

    .summary tr td {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    td {
        position: relative;
    }

    tr.deleted > td {
        text-decoration: line-through;
    }

    .Warning {
        font-size: 1.2em;
    }

    .add-daily-receipt-wrapper {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
