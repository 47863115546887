import _ from 'lodash';
import Vue from 'vue';

export type DependencyGraph<T> = Record<
    string,
    {
        onChange: (model: T, newValue: any, oldValue: any, props: any) => void;
    }
>;

export const DepedencyCodeMixin = Vue.extend({
    destroyed() {
        // Dereference watchers
        this.depWatchers.forEach((_watcher) => {
            _watcher();
        });
    },

    data() {
        return {
            depWatchers: [] as any[],
            model: null as any
        };
    },

    methods: {
        initiateWatchersBasedOnGraph(
            depGraph: any,
            model: any,
            mutateChangedModelAccordingToDepGraph: any,
            props: any
        ) {
            const depWatchers: any[] = [];
            this.model = model;

            Object.keys(depGraph).forEach((_property) => {
                depWatchers.push(
                    this.$watch(
                        () => {
                            return _.get(this.model, _property);
                        },
                        (newValue, oldValue) => {
                            mutateChangedModelAccordingToDepGraph(_property, this.model, newValue, oldValue, props);
                        },
                        {
                            deep: true
                        }
                    )
                );
            });

            return depWatchers;
        }
    }
});
