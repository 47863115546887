
import dayjs from 'dayjs';
import { TimelineItems } from 'dexxter-types';
import Vue, { PropOptions } from 'vue';
import Chip from './Chip.vue';

export default Vue.extend({
    components: { Chip },

    props: {
        forGivenYear: {
            type: Number,
            required: false
        } as PropOptions<number>,
        item: {
            type: Object
        } as PropOptions<TimelineItems>,
        expanded: {
            default: true,
            type: Boolean
        } as PropOptions<boolean>,
        truncateDescription: {
            type: Boolean,
            required: false,
            default: false
        } as PropOptions<boolean>,
        truncateLength: {
            type: Number,
            required: false,
            default: 40
        } as PropOptions<number>
    },

    data() {
        return {
            showDescription: this.expanded
        };
    },

    computed: {
        tooltipIcon(): string {
            if (this.item.status === 'notDone') {
                if (this.expiredDate) {
                    return `${this.$t(`timeline.status.expired`)}`;
                } else if (this.expiresToday) {
                    return `${this.$t(`timeline.status.expires_today`)}`;
                }
            }

            return this.$t(`timeline.status.${this.item.status}`) as string;
        },
        timelineIcon(): string {
            if (this.item.status === 'irrelevant') {
                return 'mdi-minus-circle-outline';
            } else if (this.item.status === 'done') {
                return 'mdi-checkbox-outline';
            } else if (this.item.status === 'notDone') {
                if (this.expiredDate) {
                    return 'mdi-checkbox-blank-off-outline';
                } else {
                    return 'mdi-checkbox-blank-outline';
                }
            }

            return 'mdi-bell-outline';
        },
        timelineColor(): string {
            if (this.item.status === 'irrelevant') {
                return 'orange';
            } else if (this.item.status === 'done') {
                return 'green';
            } else if (this.item.status === 'notDone') {
                if (this.expiredDate) {
                    return 'red';
                } else if (this.expiresToday) {
                    return 'red';
                } else {
                    return 'orange';
                }
            }

            return 'primary';
        },
        itemName(): string {
            return this.$i18n.t(`timeline.events.${this.item.calendarEvent.name}.name`) as string;
        },
        itemDescription(): string {
            return this.$i18n.t(`timeline.events.${this.item.calendarEvent.name}.description`) as string;
        },
        getToggleIcon(): string {
            if (this.showDescription) {
                return 'mdi-chevron-right';
            }

            return 'mdi-chevron-down';
        },
        expiredDate(): boolean {
            return dayjs(this.date, 'YYYY-MM-DD').isBefore(dayjs(), 'date');
        },
        expiresToday(): boolean {
            return dayjs(this.date, 'YYYY-MM-DD').isSame(dayjs(), 'date');
        },
        date(): string {
            return this.item.calendarEvent.date.date;
        }
    },

    methods: {
        toggleDescription(): void {
            this.showDescription = !this.showDescription;
        }
    }
});
