
import Vue from 'vue';
import TextSkeleton from '@/components/skeleton/TextSkeleton.vue';

export default Vue.extend({
    name: 'AddDailyReceiptTableLoading',
    components: { TextSkeleton },
    props: {
        isVisible: {
            required: true,
            type: Boolean
        }
    }
});
