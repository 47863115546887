
import BottomSheetHeader from '@/components/BottomSheets/BottomSheetHeader.vue';
import Loader from '@/components/Loader.vue';
import dayjs from 'dayjs';
import Vue, { PropOptions } from 'vue';
import { PartialBookings } from '../types';

export default Vue.extend({
    components: {
        BottomSheetHeader,
        Loader
    },

    props: {
        data: { required: true } as PropOptions<PartialBookings[]>,
        isModalOpen: { required: true, type: Boolean } as PropOptions<boolean>
    },

    data() {
        return {
            valid: true,
            loading: false,
            dialog: false,
            selectedBookingId: null,
            errorMessage: '',
            headers: [
                {
                    text: this.$t('general.type'),
                    align: 'start',
                    value: 'bookableType'
                },
                {
                    text: this.$t('general.date'),
                    value: 'date'
                },
                {
                    text: this.$t('general.amount_money'),
                    value: 'amount'
                },
                {
                    text: this.$t('general.link'),
                    value: 'link'
                }
            ]
        };
    },

    computed: {
        isOpen: {
            get(): boolean {
                return this.isModalOpen;
            },
            set(value: boolean): void {
                this.$emit('update:isModalOpen', value);
            }
        }
    },

    methods: {
        showDate(date: string) {
            if (dayjs(date, 'YYYY-MM-DD', true).isValid()) {
                return (this.$options.filters as any).formatDate(date);
            } else if (dayjs(date, 'YYYY', true).isValid()) {
                return date;
            }

            throw new Error('Illegal date format');
        },
        openExternal(item: PartialBookings) {
            window.open(this.$router.resolve(this.getNavigationObject(item)).href, '_blank');
        },
        clickedRow(item: PartialBookings) {
            this.$router.push(this.getNavigationObject(item));
        },
        getNavigationObject(item: PartialBookings): { name: string; params?: Record<string, any> } {
            if (item.bookableType === 'invoice') {
                return {
                    name: 'edit-accounting-invoice',
                    params: {
                        id: item.bookableId
                    }
                };
            } else if (item.bookableType === 'creditnote') {
                return {
                    name: 'edit-accounting-creditnote',
                    params: {
                        id: item.bookableId
                    }
                };
            } else if (item.bookableType === 'expense') {
                return {
                    name: 'view-accounting-expense',
                    params: {
                        id: item.bookableId
                    }
                };
            } else if (item.bookableType === 'inventory') {
                return {
                    name: 'inventory'
                };
            } else if (item.bookableType === 'startresultatenrekening') {
                return {
                    name: 'startResultatenrekening'
                };
            } else if (item.bookableType === 'dailyreceipt') {
                return {
                    name: 'add-daily-receipt',
                    params: {
                        date: item.date
                    }
                };
            } else if (item.bookableType === 'manualresultatenrekeningbooking') {
                return {
                    name: 'updateManualResultatenrekeningbookings',
                    params: {
                        id: item.bookableId
                    }
                };
            } else if (item.bookableType === 'depreciationfiche') {
                return {
                    name: 'edit-depreciationfiche',
                    params: {
                        id: item.bookableId
                    }
                };
            }

            throw new Error('Did not expected to ge there');
        }
    }
});
