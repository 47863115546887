
import StepperHeader from '@/components/stepper/StepperHeader.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import {
    createPersonaltaxesSubmissionsRequest,
    sendCloseTaxStatusRequest,
    sendGetCalculatedTypeOfCompanyRequest,
    sendGetPersonalTaxRastersRequest,
    sendGetTaxesRequest,
    sendUpdateMarriedRequest,
    sendUpdateStatutenRequest,
    sendUpdateTypeOfCompanyRequest
} from '@/services/tax';
import { PersonalTaxesViewModel } from '@/services/tax.types';
import { ITaxViewModel } from '@/store/modules/taxes.types';
import { UserBookYearType } from '@dexxter/accountancy-tools';
import Vue from 'vue';
import SubmitPersonalTaxesStep1 from './../personal-taxes-wizard/steps/SubmitPersonalTaxesStep1.vue';
import SubmitPersonalTaxesStep3 from './../personal-taxes-wizard/steps/SubmitPersonalTaxesStep3.vue';
import SubmitPersonalTaxesStep4 from './../personal-taxes-wizard/steps/SubmitPersonalTaxesStep4.vue';
import SubmitPersonalTaxesStep5 from './../personal-taxes-wizard/steps/SubmitPersonalTaxesStep5.vue';
import PersonalTaxesTable from './PersonalTaxesTable.vue';

export default Vue.extend({
    components: {
        PersonalTaxesTable,
        StepperHeader,
        SubmitPersonalTaxesStep1,
        SubmitPersonalTaxesStep3,
        SubmitPersonalTaxesStep4,
        SubmitPersonalTaxesStep5
    },

    props: {
        yearOfPersonalTaxes: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            submittingForm: false,
            tax: null as ITaxViewModel | null,
            suggestedTypeOfCompany: null as string | null,
            activeStep: 1,
            amountOfSteps: 4,
            canShowRasters: false,
            submitting: false,
            loading: false,
            personaltaxRasters: null as null | PersonalTaxesViewModel
        };
    },
    computed: {
        wettelijkSamenwonend(): boolean {
            return this.tax?.married ?? false;
        }
    },

    async created() {
        [this.tax, this.suggestedTypeOfCompany] = await Promise.all([
            sendGetTaxesRequest(this.yearOfPersonalTaxes),
            sendGetCalculatedTypeOfCompanyRequest(this.yearOfPersonalTaxes)
        ]);
    },

    methods: {
        async completePersonalTaxes() {
            try {
                this.submitting = true;

                await createPersonaltaxesSubmissionsRequest(this.yearOfPersonalTaxes);
                await sendCloseTaxStatusRequest(this.yearOfPersonalTaxes);

                this.$notify({
                    title: this.$t('general.succesfuly_created').toString(),
                    text: this.$t('general.succesfuly_created').toString(),
                    type: 'success'
                });

                this.$emit('finished');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.submitting = false;
            }
        },

        resetTypeOfCompany() {
            this.$emit('update:companyType', null);
        },

        emitNewWettelijkSamenwonend(wettelijkSamenwonend: boolean) {
            this.$emit('update:wettelijkSamenwonend', wettelijkSamenwonend);
        },

        emitNewCompanyType(type: string) {
            this.$emit('update:companyType', type);
        },

        emitUpdateStatuten() {
            this.$emit('update:statuten');
        },

        goBackToStep(step: number) {
            this.activeStep = step;
        },

        async saveChangesStep1Form(data: {
            wettelijkSamenwonend: boolean;
            companyType: string | null;
            statuten: {
                Q1: UserBookYearType;
                Q2: UserBookYearType;
                Q3: UserBookYearType;
                Q4: UserBookYearType;
            };
        }) {
            try {
                this.submittingForm = true;

                await Promise.all([
                    sendUpdateMarriedRequest(this.yearOfPersonalTaxes, data.wettelijkSamenwonend),
                    sendUpdateTypeOfCompanyRequest(this.yearOfPersonalTaxes, data.companyType),
                    sendUpdateStatutenRequest(this.yearOfPersonalTaxes, data.statuten)
                ]);

                this.tax = await sendGetTaxesRequest(this.yearOfPersonalTaxes);

                this.personaltaxRasters = await sendGetPersonalTaxRastersRequest(
                    this.yearOfPersonalTaxes,
                    (this.tax as ITaxViewModel).pb_typeOfCompany ?? this.suggestedTypeOfCompany
                );

                this.goToNextStep();
            } finally {
                this.submittingForm = false;
            }
        },

        goBackAndHideRasters() {
            this.goToPreviousStep();

            this.toggleRasters();
        },

        goToNextStepAndShowRasters() {
            this.goToNextStep();

            this.toggleRasters();
        },

        goToNextStep() {
            this.activeStep++;
        },

        goToPreviousStep() {
            this.activeStep--;
        },

        toggleRasters() {
            this.canShowRasters = !this.canShowRasters;
        },
        updateWettelijkSamenwonend(newWettelijkSamenwonend: boolean) {
            this.$emit('update:wettelijkSamenwonend', newWettelijkSamenwonend);
        },
        updateCompanyType(type: string) {
            this.$emit('update:companyType', type);
        }
    }
});
