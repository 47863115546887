import { render, staticRenderFns } from "./AddQuotation.vue?vue&type=template&id=674e653a&scoped=true&"
import script from "./AddQuotation.vue?vue&type=script&lang=js&"
export * from "./AddQuotation.vue?vue&type=script&lang=js&"
import style0 from "./AddQuotation.vue?vue&type=style&index=0&id=674e653a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "674e653a",
  null
  
)

export default component.exports