import { render, staticRenderFns } from "./BookHistoricalInvoice.vue?vue&type=template&id=061cec58&scoped=true&"
import script from "./BookHistoricalInvoice.vue?vue&type=script&lang=js&"
export * from "./BookHistoricalInvoice.vue?vue&type=script&lang=js&"
import style0 from "./BookHistoricalInvoice.vue?vue&type=style&index=0&id=061cec58&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "061cec58",
  null
  
)

export default component.exports