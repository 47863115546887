import { mustShowSellInvestementDialog } from '@/mixins/invoices/middleware/sellInvestement.helper';

export const sellInvestementDialogMixin = {
    methods: {
        sellInvestementDialog(next, invoice) {
            if (
                !mustShowSellInvestementDialog({
                    invoice,
                    previousInvoice: this.previousInvoice
                })
            ) {
                return next();
            }

            this.showDialog = true;

            this.dialogAttributes = {
                attributes: {
                    title: this.$i18n.t('general.confirmation'),
                    message: this.$i18n.t('addIncome.addInvoicable.confirm_selling_depreciation'),
                    negativeButton: this.$i18n.t('general.cancel'),
                    positiveButton: this.$i18n.t('general.confirm'),
                    negativeColor: 'message',
                    positiveColor: 'green'
                },
                actions: {
                    clickPositive: this.clickPositive.bind(this, next),
                    clickNegative: this.clickNegative
                }
            };
        }
    }
};
