<template>
    <div class="invoice-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="3">
                <v-select
                    test-id="status-filter"
                    :value="query.status"
                    single-line
                    :items="statusOptions"
                    :label="$t('general.status')"
                    item-text="label"
                    item-value="value"
                    clearable
                    hide-details
                    dense
                    outlined
                    @input="handleFilteringChange('status')($event)"
                />
            </v-col>
            <v-col cols="12" md="9">
                <pagination-search
                    test-id="search-filter"
                    :value="query.q"
                    @input="handleFilteringChange('q')($event)"
                />
            </v-col>
        </v-row>
        <pagination-data-table-v-2
            v-if="invoiceIncomes"
            id="invoicesTable"
            ref="paginationDataTable"
            :loading="loading"
            :headers="headers"
            :items="invoiceIncomes"
            :items-per-page-options="itemsPerPageOptions"
            :total-server-side-items="totalServerSideItems"
            :options="options"
            @update-options="handleUpdateOptions"
        >
            <template #[`item.invoiceNumber`]="{ item }">
                <router-link :to="getRouterData(item)" class="hover-link">{{
                    item.draft === false ? item.invoiceNumber : $t('general.concept')
                }}</router-link>
                <v-icon v-if="invoiceHasTimeregistration(item)">mdi-timer-outline</v-icon>
            </template>

            <template #[`item.date`]="{ item }">
                {{ item.date | formatDate }}
            </template>
            <template #[`item.dueDate`]="{ item }">
                {{ item.dueDate | formatDate }}
            </template>
            <template #[`item.amountEuroExclVAT`]="{ item }">
                € {{ item.amountEuroExclVAT || 0 | numericFormat }}
            </template>
            <template #[`item.amountEuroInclVAT`]="{ item }">
                € {{ item.amountEuroInclVAT || 0 | numericFormat }}
            </template>
            <template #[`item.total`]="{ item }"> € {{ item.amountEuroInclVAT || 0 | numericFormat }} </template>

            <template #[`item.status`]="{ item }">
                <invoice-table-status :item="item" @click-send-icon="navigateToEmailHistory(item)" />
            </template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" data-action="open-menu" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item v-if="item.url && item.draft === false" @click="downloadIncomePDF(item.url)">
                                <v-list-item-title>
                                    {{ $t('general.download') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item data-action="copyInvoice" @click="copyInvoice(item)">
                                <v-list-item-title>
                                    {{ $t('general.copy') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical === false" :to="/edit-accounting-invoice/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.historical" :to="/edit-accounting-historical-invoice/ + item.id">
                                <v-list-item-title>
                                    {{ $t('general.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!item.draft" @click="openCreditNota(item)">
                                <v-list-item-title>
                                    {{ $t('income.make_creditnote') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!item.payed && item.draft === false" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.add_payment') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="item.payed && item.draft === false" @click="togglePayment(item)">
                                <v-list-item-title>
                                    {{ $t('income.remove_payment') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="!item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="item.sendtocustomer && item.draft === false"
                                @click="toggleSendStatus(item)"
                            >
                                <v-list-item-title>
                                    {{ $t('income.did_not_send_to_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item data-action="delete" @click.stop="startDeleteMiddleware(item)">
                                <v-list-item-title class="red--text">
                                    {{ $t('general.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table-v-2>

        <confirmation-dialog
            v-if="showDialog"
            v-model="showDialog"
            v-bind="dialogAttributes.attributes"
            @click-positive="dialogAttributes.actions.clickPositive"
            @click-negative="dialogAttributes.actions.clickNegative"
        />
    </div>
</template>

<script>
import PaginationDataTableV2 from '@/components/DataTables/PaginationDataTableV2/PaginationDataTableV2.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import InvoiceTableStatus from '@/components/InvoiceTableStatus.vue';
import PaginationSearch from '@/components/PaginationSearch.vue';
import { useFilteringV2 } from '@/composables/filteringv2.composable.ts';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { isVatLiable } from '@/helpers/VATHelper';
import { invoiceProactiveSmartnotificationsMixin } from '@/mixins/invoiceProactiveSmartnotificationsMixin';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import { getAccountingInvoicesPagination } from '@/services/accountinginvoice';
import { deleteInvoice, togglePayedInvoice, toggleSendToCustomerInvoice } from '@/services/invoice';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import VueRouter from 'vue-router';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
    components: {
        PaginationSearch,
        ConfirmationDialog,
        InvoiceTableStatus,
        PaginationDataTableV2
    },

    mixins: [middlewareMixin, invoiceProactiveSmartnotificationsMixin],

    setup() {
        const {
            fetchTableItemsPerPageFromLocalStorage,
            updateTableItemsPerPageInLocalStorage,
            options,
            itemsPerPageOptions,
            createItemToBeAddedToUrlQuery,
            optionsToUrlQuery,
            initializeQueryObjectFromUrlQuery,
            initializeOptionsObjectFromUrlQuery
        } = useFilteringV2('accountingInvoiceTable');

        return {
            fetchTableItemsPerPageFromLocalStorage,
            updateTableItemsPerPageInLocalStorage,
            options,
            itemsPerPageOptions,
            createItemToBeAddedToUrlQuery,
            optionsToUrlQuery,
            initializeQueryObjectFromUrlQuery,
            initializeOptionsObjectFromUrlQuery
        };
    },

    data() {
        const currentUserData = this.$store.state.auth.currentUserData;
        const VATLiable = isVatLiable(currentUserData.settings.vatLiable);
        let sortableColumns = ['date', 'dueDate', 'invoiceNumber'];

        if (VATLiable) {
            sortableColumns.push('amountEuroInclVAT', 'amountEuroExclVAT');
        } else {
            sortableColumns.push('total');
        }

        const headers = [
            {
                text: this.$t('general.invoice_number'),
                align: 'start',
                value: 'invoiceNumber',
                sortable: sortableColumns.includes('invoiceNumber')
            },
            {
                text: this.$t('general.client'),
                value: 'customer.company.name',
                sortable: sortableColumns.includes('customer.company.name')
            },
            { text: this.$t('general.date'), value: 'date', sortable: sortableColumns.includes('date') },
            { text: this.$t('general.due_date'), value: 'dueDate', sortable: sortableColumns.includes('dueDate') },
            VATLiable
                ? [
                      {
                          text: this.$t('general.total_excl_vat'),
                          value: 'amountEuroExclVAT',
                          sortable: sortableColumns.includes('amountEuroExclVAT')
                      },
                      {
                          text: this.$t('general.total_incl_vat'),
                          value: 'amountEuroInclVAT',
                          sortable: sortableColumns.includes('amountEuroInclVAT')
                      }
                  ]
                : {
                      text: this.$t('general.total'),
                      value: 'amountEuroExclVAT',
                      sortable: sortableColumns.includes('total')
                  },
            { text: this.$t('general.status'), value: 'status', sortable: sortableColumns.includes('status') },

            {
                text: this.$t('general.actions'),
                value: 'actions',
                sortable: false
            }
        ].flat();

        return {
            deleteInvoiceMiddleware: [
                this.confirmationDeleteInvoice,
                this.deleteProactiveSmartnotifications,
                this.removeInvoice
            ],
            headers,
            showDialog: false,
            dialogDeletePayment: false,
            loading: false,
            invoiceIncomes: [],
            totalServerSideItems: 0,
            query: {
                status: null,
                q: null
            },
            statusOptions: [
                {
                    label: this.$t('general.published'),
                    value: 'published'
                },
                {
                    label: this.$t('general.draft'),
                    value: 'draft'
                },
                {
                    label: this.$t('general.payed'),
                    value: 'payed'
                },
                {
                    label: this.$t('general.unpaid'),
                    value: 'unpaid'
                },
                {
                    label: this.$t('general.expired'),
                    value: 'expired'
                },
                {
                    label: this.$t('general.sent'),
                    value: 'sent'
                },
                {
                    label: this.$t('general.not_sent'),
                    value: 'notsent'
                }
            ],
            sortableColumns
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapState('incomes', ['newInvoiceEvents']),
        ...mapState(['selectedYear'])
    },

    watch: {
        newInvoiceEvents: {
            handler() {
                this.fetchItems();
            },
            deep: true
        }
    },

    created() {
        this.initializeTableBasedOnQueryParams(this.$route.query, ['q', 'status'], {
            page: 1,
            itemsPerPage: this.fetchTableItemsPerPageFromLocalStorage()
        });

        this.fetchItems();
    },
    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        getRouterData(invoice) {
            if (invoice.historical && !invoice.url) {
                return {
                    name: 'edit-accounting-historical-invoice',
                    params: {
                        id: invoice.id
                    }
                };
            }

            return {
                name: 'accounting-invoicable-view',
                params: {
                    type: 'invoice',
                    id: invoice.id
                }
            };
        },
        handleFilteringChange(fieldName) {
            return (newValue) => {
                this.query[fieldName] = newValue;

                this.$router
                    .replace({
                        ...this.$route,
                        query: this.createItemToBeAddedToUrlQuery(this.$route.query, fieldName, newValue)
                    })
                    .catch((e) => {
                        if (VueRouter.isNavigationFailure(e)) {
                            return e;
                        }

                        return Promise.reject(e);
                    });

                this.fetchItems();
            };
        },
        initializeTableBasedOnQueryParams(urlQuery, filteringParams, defaults = { page: 1, itemsPerPage: 10 }) {
            this.query = this.initializeQueryObjectFromUrlQuery(urlQuery, filteringParams, this.query);
            this.options = this.initializeOptionsObjectFromUrlQuery(urlQuery, this.options, defaults);
        },
        handleUpdateOptions(options) {
            this.updateTableItemsPerPageInLocalStorage(options.itemsPerPage);

            this.$router
                .replace({
                    ...this.$route,
                    query: this.optionsToUrlQuery(this.$route.query, options)
                })
                .catch((e) => {
                    if (VueRouter.isNavigationFailure(e)) {
                        return e;
                    }

                    return Promise.reject(e);
                });

            this.options = options;
            this.fetchItems();
        },
        copyInvoice(invoice) {
            this.$router.push({
                name: 'add-accounting-invoice',
                query: {
                    copyFromInvoiceId: invoice.id
                }
            });
        },
        confirmationDeleteInvoice(next) {
            this.openConfirmationDialog();

            this.setDialogAttributes(
                {
                    title: this.$t('general.delete'),
                    message: this.$t('general.confirm_delete_message')
                },
                {
                    clickPositive: () => {
                        this.clickPositive.call(this, next);
                    }
                }
            );
        },
        async startDeleteMiddleware(invoice) {
            this.runMiddleware(this.deleteInvoiceMiddleware, invoice);
        },
        navigateToEmailHistory(item) {
            this.$router.push({
                name: 'customer-mail',
                params: {
                    type: 'invoice',
                    id: item.id
                }
            });
        },
        async fetchItems() {
            this.loading = true;
            try {
                ({ data: this.invoiceIncomes, total: this.totalServerSideItems } =
                    await getAccountingInvoicesPagination(this.selectedYear, {
                        pagination: {
                            currentPage: this.options.page,
                            limit: this.options.itemsPerPage
                        },
                        query: this.query,
                        options: this.options
                    }));
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        downloadIncomePDF(url) {
            window.open(url);
        },
        openCreditNota(item) {
            this.isCreatingNewCreditnote = false;
            this.isAddCreditnoteOpen = true;

            this.$router.push({
                path: `/add-accounting-creditnote`,
                query: {
                    invoiceId: item.id
                }
            });
        },
        async togglePayment(item) {
            try {
                this.startLoading();
                await togglePayedInvoice(item.id);
                item.payed = !item.payed;

                notify.call(this, {
                    title: this.$t('income.payed_success'),
                    text: this.$t('income.payed_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        async toggleSendStatus(item) {
            try {
                this.startLoading();
                await toggleSendToCustomerInvoice(item.id);
                item.sendtocustomer = !item.sendtocustomer;

                notify.call(this, {
                    title: this.$t('income.updated_success'),
                    text: this.$t('income.updated_success')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialogDeletePayment = false;
            }
        },
        invoicePayedInTime(invoice) {
            if (invoice.dueDate && invoice.payed === false) {
                const dueDate = dayjs(invoice.dueDate, 'YYYY-MM-DD');
                const today = dayjs();

                return today.isSameOrBefore(dueDate);
            }

            return true;
        },
        invoiceHasTimeregistration(invoice) {
            return Array.isArray(invoice.timeregistrations) && invoice.timeregistrations.length > 0;
        },
        async removeInvoice(next, invoice) {
            this.startLoading();
            this.showDialog = false;
            try {
                await deleteInvoice(invoice.id);
                await this.fetchItems();
                notify.call(this, {
                    title: this.$t('income.deleted_invoice_succesfuly'),
                    text: this.$t('income.deleted_invoice_succesfuly')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        }
    }
});
</script>

<style lang="scss" scoped>
.v-card-header {
    display: flex;
}
</style>
