
import { mapState } from 'vuex';
import Loader from '@/components/Loader.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import SearchInput from '@/components/SearchInput.vue';
import SelectTableComponent from '@/components/SelectTableComponent.vue';
import NumberField from '@/components/FormComponents/NumberField.vue';
import VCardHeader from '@/components/vCardHeader.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import BaseIconRight from '@/components/BaseIconRight.vue';
import PDFViewer from '@/components/PDFViewer.vue';
import FileViewer from '@/components/FileViewer.vue';

import { isPDFFile } from '@/helpers/general';
import { BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU, PRIVATE, supplierType } from '@/config/constants';
import Vue, { PropOptions } from 'vue';
import { AccountingExpenseModel } from '@/models/accountingexpense';
import { mustUseFictiveAmounts } from '@/businessServices/expense/expense.service';

export default Vue.extend({
    components: {
        Loader,
        PDFViewer,
        ViewLayout,
        SearchInput,
        SubmitButton,
        SelectTableComponent,
        NumberField,
        ConfirmationDialog,
        VCardHeader,
        BaseIconRight,
        FileViewer
    },
    props: {
        value: {
            required: true
        } as PropOptions<AccountingExpenseModel>,
        expensefile: {
            required: true
        } as PropOptions<{ originalFileName: string; url: string } | null>
    },

    data() {
        return {
            typeOfCustomer: [
                {
                    label: this.$t('customer.private'),
                    type: PRIVATE
                },
                {
                    label: this.$t('customer.belgian_company'),
                    type: BELGIAN_COMPANY
                },
                {
                    label: this.$t('customer.company_inside_eu'),
                    type: COMPANY_INSIDE_EU
                },
                {
                    label: this.$t('customer.company_outside_eu'),
                    type: COMPANY_OUTSIDE_EU
                }
            ] as { label: string; type: supplierType }[]
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        canViewFictiveVAT(): boolean {
            return mustUseFictiveAmounts(this.currentAccountingExpense, this.currentUserData.settings.vatLiable);
        },
        currentAccountingExpense(): AccountingExpenseModel {
            return this.value;
        },
        canShowExtraInfo(): boolean {
            return (
                Boolean(this.value.reference) ||
                Boolean(this.currentAccountingExpense.dueDate) ||
                Boolean(this.currentAccountingExpense.description)
            );
        },
        fileName(): string | null {
            return this.expensefile ? this.expensefile.originalFileName : null;
        },
        fileUrl(): string | null {
            return this.expensefile ? this.expensefile.url : null;
        },
        hasFile(): boolean {
            return !!this.expensefile;
        },
        viewComponent(): any {
            if (!this.expensefile) {
                return;
            }
            if (!this.fileUrl) {
                return;
            }

            if (isPDFFile(this.fileUrl)) {
                return PDFViewer;
            }

            return FileViewer;
        }
    },

    methods: {
        supplierTypeToLabel(supplierType: supplierType): string {
            const correctEntry = this.typeOfCustomer.find((_type) => _type.type === supplierType);
            if (!correctEntry) {
                throw new Error('Supliertype not found');
            }

            return correctEntry.label;
        },
        editAccountingExpense(): void {
            this.$router.push({
                name: 'edit-accounting-expense',
                params: {
                    id: this.$route.params.id
                }
            });
        }
    }
});
