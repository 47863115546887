import { Ref, ref } from 'vue';
import { Route } from 'vue-router';

export const usePreventDuplicateRoute = (): {
    routeChangeInProgress: Ref<boolean>;
    preventDuplicateRoute: <RouteParamType>(
        routeFunction: (routeParams: RouteParamType) => Promise<Route>,
        routeParams: RouteParamType
    ) => Promise<void>;
} => {
    const routeChangeInProgress = ref<boolean>(false);

    const preventDuplicateRoute = async <RouteParamType>(
        routeFunction: (routeParams: RouteParamType) => Promise<Route>,
        routeParams: RouteParamType
    ): Promise<void> => {
        if (!routeChangeInProgress.value) {
            routeChangeInProgress.value = true;
            await routeFunction(routeParams);
            routeChangeInProgress.value = false;
        }
    };

    return { routeChangeInProgress, preventDuplicateRoute };
};
