var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('widget',{staticClass:"mb-3",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('socialcontributionsOverview.calculation_table_title'))+" op een "),(_vm.hasInactiveQuarters)?[_c('tooltip',{attrs:{"i18n-path":"tooltips.socialcontributions.SocialContributionInFirstYear","underlined":true}},[_c('template',{slot:"activator"},[_vm._v(" geprorateerde winst ")])],2)]:[_vm._v(" winst ")],_vm._v(" van "+_vm._s(_vm._f("addEuroSign")(_vm._f("numericFormat")(_vm.proration(_vm.socialcontributionInformation.grossProfitEuro))))+" ")]},proxy:true},{key:"content",fn:function(){return [_c('v-simple-table',{attrs:{"id":"socialcontributions-table"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Kwartaal")]),_c('th',{staticClass:"valuta"},[_vm._v(" Te betalen sociale bijdragen "),_c('tooltip',{attrs:{"underlined":false,"i18n-path":"tooltips.socialcontributions.ToPay"}},[_c('template',{slot:"activator"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-information ")])],1)],2)],1),_c('th',{staticClass:"valuta"},[_vm._v(" Beheersbijdragen sociaal verzekeringsfonds ("+_vm._s(_vm.socialcontributionInformation.socialContributionsSettings .socialContributionsFeePercentage)+"%) ")]),_c('th',{staticClass:"valuta"},[_vm._v("Kwartaalbijdragen")])])]),_c('tbody',[_vm._l((['Q1', 'Q2', 'Q3', 'Q4']),function(quarter){return _c('tr',{key:quarter},[_c('td',[_vm._v(" "+_vm._s(_vm.$t('general.quarter'))+" "+_vm._s(quarter[1])+" ("+_vm._s(_vm.$t( `settings.statuten.${_vm.socialcontributionInformation.statuten[quarter]}` ).toLowerCase())+") ")]),_c('valuta-cell',{attrs:{"text-align-right":"","amount":_vm.isActiveQuarter(_vm.socialcontributionInformation.statuten[quarter])
                                        ? _vm.socialcontributionInformation.socialContributions
                                              .socialeBijdragenTeBetalenPerQuarter[quarter]
                                        : undefined,"currency":"EUR"}}),_c('valuta-cell',{attrs:{"text-align-right":"","amount":_vm.isActiveQuarter(_vm.socialcontributionInformation.statuten[quarter])
                                        ? (_vm.socialcontributionInformation.socialContributions
                                              .socialeBijdragenTeBetalenPerQuarter[quarter] *
                                              _vm.socialcontributionInformation.socialContributionsSettings
                                                  .socialContributionsFeePercentage) /
                                          100
                                        : undefined,"currency":"EUR"}}),_c('valuta-cell',{attrs:{"bold":"","text-align-right":"","amount":_vm.isActiveQuarter(_vm.socialcontributionInformation.statuten[quarter])
                                        ? _vm.socialcontributionInformation.socialContributions
                                              .socialeBijdragenTeBetalenPerQuarter[quarter] *
                                          (1 +
                                              _vm.socialcontributionInformation.socialContributionsSettings
                                                  .socialContributionsFeePercentage /
                                                  100)
                                        : undefined,"currency":"EUR"}})],1)}),_c('tr',[_c('td',[_c('b',[_vm._v("Jaar")])]),_c('valuta-cell',{attrs:{"bold":"","text-align-right":"","amount":_vm.bijdrageSom,"currency":"EUR"}}),_c('valuta-cell',{attrs:{"bold":"","text-align-right":"","amount":_vm.administratieveKostSom,"currency":"EUR"}}),_c('valuta-cell',{attrs:{"bold":"","text-align-right":"","amount":_vm.kwartaalbijdrageSom,"currency":"EUR"}})],1),_c('tr',[_c('td',[_c('b',[_vm._v("Reeds betaalde sociale bijdragen")])]),_c('td'),_c('td'),_c('valuta-cell',{attrs:{"bold":"","text-align-right":"","amount":_vm.getTotalAlreadyPaidSocialContributions(),"currency":"EUR"}})],1),_c('tr',{staticClass:"summary"},[_c('td',[_c('div',{staticClass:"d-flex"},[(!_vm.hasToPayExtraSocialContributions)?[_c('b',[_vm._v(_vm._s(_vm.$t('socialcontributionsOverview.table.get_back')))]),_c('tooltip',{staticClass:"ml-1",attrs:{"i18n-path":"tooltips.socialcontributions.inYourAdvantage"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_vm._v("("+_vm._s(_vm.$t('socialcontributionsOverview.table.how_to_get_back'))+")")]},proxy:true}],null,false,2087127995)})]:[_c('b',[_vm._v(_vm._s(_vm.$t('socialcontributionsOverview.table.to_pay')))]),_c('tooltip',{staticClass:"ml-1",attrs:{"i18n-path":"tooltips.socialcontributions.howToPay"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_vm._v("("+_vm._s(_vm.$t( 'socialcontributionsOverview.table.how_to_to_pay' ).toLowerCase())+")")]},proxy:true}])})]],2)]),_c('td'),_c('td'),_c('valuta-cell',{attrs:{"bold":"","text-align-right":"","amount":_vm.totaal,"currency":"EUR"}})],1)],2)]},proxy:true}])})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }