import { dayjs } from '@/setup/dayjs';

export function getInitialDateForCalendar(
    dateParam: string | undefined,
    selectedYear: number,
    currentDate: string
): string {
    if (dayjs(dateParam, 'YYYY-MM-DD', true).isValid()) {
        return dateParam as string;
    }

    if (selectedYear !== dayjs().year()) {
        return `${selectedYear}-01-01`;
    }

    return `${dayjs(currentDate, 'YYYY-MM-DD').format('YYYY-MM')}-01`;
}
