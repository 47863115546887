
import dayjs from 'dayjs';

// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';

import { usePreventDuplicateRoute } from '@/composables/usePreventDuplicateRoute';
import { filteringMixin, tableName } from '@/mixins/filtering';

import { accountingExpensePaginationQueryType, IExpenseViewModel } from 'dexxter-types';

// --- Helpers ---
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import PaginationSearch from '../PaginationSearch.vue';
import PaginationDataTable from './PaginationDataTable.vue';
import { isVatLiable } from '@/helpers/VATHelper';

import { sendGetAllSuppliersRequest } from '@/services/suppliers';

import _ from 'lodash';
import { togglePayedExpense } from '@/services/expenses';
import ExpenseTableStatus from '../ExpenseTableStatus.vue';
import DateField from '../FormComponents/DateField.vue';
import DefaultAutocomplete from '../FormComponents/DefaultAutocomplete.vue';
import ToggleButton from '@/components/Button/ToggleMenuButton.vue';
import mixins from 'vue-typed-mixins';
import { DeepNullable } from '@/helpers/types';
import DefaultFilter from '../FormComponents/DefaultFilter.vue';
import { Route } from 'vue-router';

export default mixins(filteringMixin).extend({
    components: {
        ToggleButton,
        ConfirmationDialog,
        PaginationDataTable,
        PaginationSearch,
        ExpenseTableStatus,
        DateField,
        DefaultAutocomplete,
        DefaultFilter
    },
    mixins: [filteringMixin],

    setup() {
        const { preventDuplicateRoute } = usePreventDuplicateRoute();

        return { preventDuplicateRoute };
    },

    data() {
        const sortableColumns = ['date', 'dueDate', 'documentDate', 'accountingCostEuro'];

        return {
            headers: [
                {
                    text: this.$t('general.supplier'),
                    value: 'supplier',
                    sortable: sortableColumns.includes('supplier')
                },
                {
                    text: this.$t('general.reference'),
                    value: 'reference',
                    sortable: sortableColumns.includes('reference')
                },
                {
                    text: this.$t('general.date'),
                    value: 'documentDate',
                    sortable: sortableColumns.includes('documentDate')
                },
                { text: this.$t('general.due_date'), value: 'dueDate', sortable: sortableColumns.includes('dueDate') },
                { text: this.$t('general.type'), value: 'type', sortable: sortableColumns.includes('type') },
                {
                    text: this.$t('accountantTerms.cost_in_boekhouding'),
                    value: 'accountingCostEuro',
                    sortable: sortableColumns.includes('accountingCostEuro')
                },
                { text: this.$t('general.status'), value: 'status', sortable: sortableColumns.includes('status') },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            extraFiltersVisible: false,
            [tableName]: 'accountingExpenseTable',
            selectedExpense: '',
            query: {
                status: null,
                type: null,
                documentStartDate: null,
                documentEndDate: null,
                supplierId: null,
                fiscalePeriod: null
            } as DeepNullable<accountingExpensePaginationQueryType>,
            statusOptions: [
                {
                    label: this.$t('general.payed'),
                    value: 'payed'
                },
                {
                    label: this.$t('general.unpaid'),
                    value: 'unpaid'
                },
                {
                    label: this.$i18n.t('general.expired'),
                    value: 'expired'
                },
                {
                    label: this.$i18n.t('general.creditnote'),
                    value: 'creditnote'
                },
                {
                    label: this.$t('general.recordInVATDeclaration'),
                    value: 'recordInVATDeclaration'
                },
                {
                    label: this.$t('general.not_recordInVATDeclaration'),
                    value: 'notRecordInVATDeclaration'
                },
                {
                    label: this.$i18n.t('general.good'),
                    value: 'good'
                },
                {
                    label: this.$i18n.t('general.service'),
                    value: 'service'
                },
                {
                    label: this.$i18n.t('general.investement'),
                    value: 'investement'
                }
            ],
            typeOptions: [
                {
                    label: this.$t('general.goods'),
                    value: 'good'
                },
                {
                    label: this.$t('general.services'),
                    value: 'service'
                },
                {
                    label: this.$t('general.investements'),
                    value: 'investement'
                }
            ],
            sortableColumns,
            loading: false,
            expenses: {
                data: [] as IExpenseViewModel[]
            },
            selectedExpenses: [] as IExpenseViewModel[],
            suppliersOfUser: [] as IExpenseViewModel['supplier'][],
            documentDateRangeMin: null as string | null,
            documentDateRangeMax: null as string | null,
            fiscaleDateOptions: [
                {
                    label: `${this.$i18n.t('general.quarter')} 1`,
                    value: 'Q1'
                },
                {
                    label: `${this.$i18n.t('general.quarter')} 2`,
                    value: 'Q2'
                },
                {
                    label: `${this.$i18n.t('general.quarter')} 3`,
                    value: 'Q3'
                },
                {
                    label: `${this.$i18n.t('general.quarter')} 4`,
                    value: 'Q4'
                }
            ],
            currentActiveDateForPickerStartDate: dayjs().format('YYYY-MM-DD'),
            currentActiveDateForPickerEndDate: dayjs().format('YYYY-MM-DD')
        };
    },

    computed: {
        ...mapState(['selectedYear']),
        ...mapState('expenses', ['newExpenseEvents']),
        ...mapState('auth', ['currentUserData']),
        hasSelectedItems(): boolean {
            return Array.isArray(this.selectedExpenses) && this.selectedExpense.length > 0;
        }
    },

    watch: {
        currentUserData: {
            handler(newCurrentUserData) {
                if (newCurrentUserData && !isVatLiable(newCurrentUserData.settings.vatLiable)) {
                    this.statusOptions = _.filter(this.statusOptions, (item) => {
                        return !['recordInVATDeclaration', 'notRecordInVATDeclaration'].includes(item.value);
                    });
                }
            },
            deep: true,
            immediate: true
        },
        newExpenseEvents: {
            handler() {
                this.wrapperFunction();
            },
            deep: true
        }
    },

    async created() {
        this.setDatePickerStandardValues();

        this.wrapperFunction();

        this.suppliersOfUser = await sendGetAllSuppliersRequest();
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('expenses', ['deleteExpense', 'getExpenses']),
        setDatePickerStandardValues() {
            this.documentDateRangeMin = `${this.selectedYear}-01-01`;
            this.documentDateRangeMax = `${this.selectedYear}-12-31`;

            this.currentActiveDateForPickerStartDate =
                dayjs().year() == this.selectedYear ? dayjs().format('YYYY-MM-DD') : `${this.selectedYear}-01-01`;
            this.currentActiveDateForPickerEndDate =
                dayjs().year() == this.selectedYear ? dayjs().format('YYYY-MM-DD') : `${this.selectedYear}-12-31`;
        },
        getSupplierName(supplier: IExpenseViewModel['supplier']) {
            return supplier.company.name;
        },
        getSupplierId(supplier: IExpenseViewModel['supplier']) {
            return supplier.id;
        },
        async removeExpense() {
            this.startLoading();
            this.loading = true;
            try {
                await this.deleteExpense(this.selectedExpense);
                notify.call(this, {
                    title: this.$t('income.deleted_invoice_succesfuly'),
                    text: this.$t('income.deleted_invoice_succesfuly')
                });

                await this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.dialog = false;
                this.loading = false;
            }
        },
        async wrapperFunction() {
            try {
                this.startLoading();
                this.loading = true;

                this.expenses = (await this.getExpenses({
                    pagination: {
                        force: true,
                        currentPage: this.pagination.currentPage,
                        limit: this.pagination.limit
                    },
                    query: this.query
                })) as { data: IExpenseViewModel[] };
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
                this.loading = false;
            }
        },
        downloadExpensePDF(url: string) {
            window.open(url);
        },
        async togglePayment(item: IExpenseViewModel): Promise<void> {
            try {
                this.startLoading();
                await togglePayedExpense(item.id);
                item.payed = !item.payed;

                notify.call(this, {
                    title: this.$i18n.t('expense.payed_success'),
                    text: this.$i18n.t('expense.payed_success'),
                    type: 'success'
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        expensePayedInTime(expense: IExpenseViewModel): boolean {
            if (!expense.dueDate || expense.payed) {
                return true;
            }

            const dueDate = dayjs.utc(expense.dueDate, 'YYYY-MM-DD');
            const today = dayjs.utc();

            return today.isSameOrBefore(dueDate, 'day');
        },
        async routeToViewAccountingExpense({ id }: IExpenseViewModel): Promise<Route> {
            return this.$router.push({
                name: 'view-accounting-expense',
                params: {
                    id: id.toString()
                }
            });
        },
        handleClickRow(expense: IExpenseViewModel): void {
            this.preventDuplicateRoute<IExpenseViewModel>(this.routeToViewAccountingExpense, expense);
        },
        markSelectedItems(items: IExpenseViewModel[]): void {
            this.selectedExpenses = items;
        }
    }
});
