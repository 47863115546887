<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                    <template #content> </template
                    ><template #menu
                        ><submit-button @click="navigateToExpensecategories"
                            >Beheer ontbrekende kosten</submit-button
                        ></template
                    ></page-header
                >
            </template>
            <template #content>
                <div v-if="doneLoading" class="subcategoryItems">
                    <template v-for="expensesubcategory in expenseCategoriesFrequencies">
                        <expense-category-frequency
                            :key="expensesubcategory.id"
                            :expensesubcategory="expensesubcategory"
                        />
                    </template>
                </div>
            </template>
        </view-layout>

        <confirmation-dialog
            v-if="doneLoading && expenseCategoriesFrequencies.length === 0"
            :value="true"
            persistent
            :title="$t('reports.expenseCategoryFrequency.noFrequenciesSetDialog.title')"
            :message="$t('reports.expenseCategoryFrequency.noFrequenciesSetDialog.content')"
            :positive-button="$t('reports.expenseCategoryFrequency.noFrequenciesSetDialog.action')"
            positive-color="primary"
            @click-positive="$router.push({ name: 'manageCostCategory' })"
        />

        <loader v-if="!doneLoading" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import Loader from '@/components/Loader.vue';
import ExpenseCategoryFrequency from './ExpenseCategoryFrequency.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';

export default {
    components: { PageHeader, ViewLayout, Loader, ExpenseCategoryFrequency, ConfirmationDialog },

    data() {
        return {
            expenseCategoriesFrequencies: null,
            loading: {
                getExpensecategoryFrequencies: false
            }
        };
    },

    computed: {
        ...mapState(['selectedYear']),
        ...mapState('taxes', ['taxes']),
        ...mapState('auth', ['currentUserData']),
        doneLoading() {
            return !Object.values(this.loading).some((_loading) => _loading === true);
        }
    },

    created() {
        this.getAllInitData();
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('expenseCategories', ['getExpenseCategoriesFrequencies']),
        async getAllInitData() {
            try {
                this.loading.getExpensecategoryFrequencies = true;

                await this.getExpenseCategoriesFrequencies(this.selectedYear).then((data) => {
                    this.expenseCategoriesFrequencies = data;
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading.getExpensecategoryFrequencies = false;
            }
        },
        navigateToExpensecategories() {
            this.$router.push({
                name: 'manageCostCategory'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.subcategoryItems {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 1em;
}
</style>
