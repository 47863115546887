
// --- State ---
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import PaginationSearch from '@/components/PaginationSearch.vue';
import { filteringMixin } from '@/mixins/filtering';
import PaginationDataTable from '@/components/DataTables/PaginationDataTable.vue';
import { sendDeleteCarRequest, sendGetAllCarsPaginationRequest } from '@/services/car';
import { ICarViewModel, SortablePaginationCarColumnsType, UpdateCarData } from 'dexxter-types';
import mixins from 'vue-typed-mixins';
import { apiErrorAndDisplay } from '../../../../../../helpers/errorHandling';
import { reportError } from '../../../../../../helpers/logging';
import { notify } from '../../../../../../helpers/successNotification';
import CarModal from '../../create/components/CarModal.vue';

export default mixins(filteringMixin).extend({
    components: {
        ConfirmationDialog,
        PaginationSearch,
        PaginationDataTable,
        CarModal
    },
    mixins: [filteringMixin],

    props: {
        clickable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const sortableColumns: SortablePaginationCarColumnsType[] = [
            'name',
            'licenseplate',
            'co2',
            'fuelType',
            'purchaseDate'
        ];

        let headers = [
            {
                text: this.$t('cars.name'),
                value: 'name',
                sortable: sortableColumns.includes('name')
            },
            {
                text: this.$t('cars.licenseplate'),
                value: 'licenseplate',
                sortable: sortableColumns.includes('licenseplate')
            },
            {
                text: this.$t('cars.co2'),
                value: 'co2',
                sortable: sortableColumns.includes('co2')
            },
            {
                text: this.$t('cars.fuelType'),
                value: 'fuelType',
                sortable: sortableColumns.includes('fuelType')
            },
            {
                text: this.$t('cars.purchaseDate'),
                value: 'purchaseDate',
                sortable: sortableColumns.includes('purchaseDate')
            },
            {
                text: this.$t('general.actions'),
                value: 'actions',
                sortable: false
            }
        ];
        return {
            headers,
            dialog: false,
            loading: false,
            toDeleteUid: '',
            selectedCarItemToDelete: null as null | number,
            cars: {
                data: [] as ICarViewModel[]
            },
            sortableColumns,
            openCarModal: false,
            carData: null as UpdateCarData | null,
            carToUpdateId: null as null | number
        };
    },

    created() {
        this.wrapperFunction();
    },

    methods: {
        async wrapperFunction() {
            this.loading = true;
            this.cars = await sendGetAllCarsPaginationRequest({
                pagination: {
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loading = false;
        },
        handleClickRow(item: ICarViewModel) {
            this.$emit('click-row', item);
        },
        editCar(car: ICarViewModel) {
            this.carToUpdateId = car.id;
            this.carData = car;
            this.openCarModal = true;
        },
        async removeCar() {
            if (!this.selectedCarItemToDelete) {
                reportError(new Error('Cannot delete car item before initalizatino'));
                return;
            }
            try {
                this.loading = true;
                await sendDeleteCarRequest(this.selectedCarItemToDelete);

                notify.call(this, {
                    title: this.$t('cars.carSuccesfullyDeleted'),
                    text: this.$t('cars.carSuccesfullyDeleted')
                });

                this.dialog = false;
                this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
});
