<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }} {{ yearOfClientlisting }} ({{
                        $t('clientlisting.submit_in_year', {
                            clientlistingSubmissionYear: parseInt(yearOfClientlisting) + 1
                        })
                    }})
                    <sub>
                        <tooltip :underlined="false" i18n-path="tooltips.customervatlisting.title"
                            ><template slot="activator"> <v-icon small> mdi-information </v-icon></template></tooltip
                        >
                    </sub>
                </template>
            </page-header>
        </template>
        <template #content>
            <v-row>
                <v-col cols="12" md="8">
                    <TableCard>
                        <template #content>
                            <customers-VAT-listing-table
                                v-if="ClientListingCustomers"
                                :loading-customers="loadingCustomers"
                                :v-a-t-customers="ClientListingCustomers"
                            />
                            <v-row v-else>
                                <v-col cols="12">Laden...</v-col>
                            </v-row>
                        </template>
                    </TableCard>
                </v-col>

                <v-col cols="12" md="4" :order="isTablet ? 1 : 2">
                    <v-card class="pa-5 mb-5">
                        <v-card-header>
                            <template #title> {{ $t('general.actions') }} </template>
                        </v-card-header>

                        <submit-button
                            :disabled="getCanDownloadClientlistingsWithDialogs.status === false"
                            data-action="downloadClientListing"
                            color="primary"
                            block
                            :loading="userDataExportLoading"
                            @click="downloadClientListing"
                        >
                            {{ $t('clientlisting.do_clientlisting') }}
                        </submit-button>
                    </v-card>

                    <v-card class="pa-5 mb-5">
                        <v-card-header>
                            <template #title>
                                {{ $t('clientlisting.status_clientlisting') }}
                            </template>
                        </v-card-header>
                        <template v-if="getClientlisting">
                            <template v-if="getClientlisting.submissionFileURL">
                                <div
                                    class="d-flex justify-content cursorPointer mb-4"
                                    @click="downloadClientlistingSubmission(getClientlisting.submissionFileURL)"
                                >
                                    <v-icon>mdi-file-document-outline</v-icon>
                                    {{ $t('clientlisting.download_submission') }}
                                </div>
                            </template>

                            <b>{{ $t('clientlisting.submitted_on') }}:</b>
                            {{ getClientlisting.createdAt | formatTimestamp }}

                            <div
                                class="cursorPointer"
                                @click="openDeleteClientlistingSubmissionDialog(getClientlisting.id)"
                            >
                                <b> {{ $t('clientlisting.delete_submission') }} </b>
                            </div>
                        </template>
                        <template v-else>
                            {{ $t('clientlisting.submission_not_done') }}
                        </template>
                    </v-card>
                </v-col>
            </v-row>

            <submit-clientlisting-confirmation-receipt-dialog
                v-if="downloadXMLDialog"
                v-model="downloadXMLDialog"
                v-bind="dialogAttributes.attributes"
                v-on="{
                    'click-positive': dialogAttributes.actions.clickPositive,
                    'click-negative': dialogAttributes.actions.clickNegative
                }"
            />

            <confirmation-dialog
                v-if="deleteClientlistingSubmissionDialog"
                v-model="deleteClientlistingSubmissionDialog"
                :title="$t('general.confirm')"
                :message="$t('clientlisting.deleteClientlistingSubmission.content')"
                :negative-button="$t('general.cancel')"
                :positive-button="$t('general.confirm')"
                @click-positive="deleteClientlistingSubmission"
                @click-negative="deleteClientlistingSubmissionDialog = false"
            />

            <confirmation-dialog
                v-if="dontNeedToSubmitEmptyClientlistingDialog"
                v-model="dontNeedToSubmitEmptyClientlistingDialog"
                data-id="dontNeedToSubmitEmptyClientlistingDialog"
                :title="$t('clientlisting.clientlistingEmptyNonVATLiable.title')"
                :message="$t('clientlisting.clientlistingEmptyNonVATLiable.content')"
                :positive-button="$t('clientlisting.clientlistingEmptyNonVATLiable.confirm')"
                positive-color="green"
                @click-positive="submitNihilClientListingSubmission"
            />

            <confirmation-dialog
                v-if="showLoadClientlistingDataDialog"
                v-model="showLoadClientlistingDataDialog"
                :persistent="true"
                data-id="loadClientlistingdataDialog"
                :title="$t('clientlisting.loadClientlistingData.title')"
                :message="$t('clientlisting.loadClientlistingData.content')"
                :positive-button="$t('clientlisting.get_data')"
                :negative-button="$t('general.cancel')"
                :loading="loadingCustomers"
                positive-color="green"
                @click-positive="wrapperFunction"
                @click-negative="routeBack"
            />

            <confirmation-dialog
                v-if="hasNoPreviousTaxYear"
                v-model="hasNoPreviousTaxYear"
                persistent
                data-id="hasNoPreviousTaxYearDialog"
                :title="
                    $t('clientlisting.taxYearNotAvailable.title', {
                        year: yearOfClientlisting
                    })
                "
                :message="
                    $t('clientlisting.taxYearNotAvailable.content', {
                        year: yearOfClientlisting
                    })
                "
                :positive-button="$t('general.confirm')"
                positive-color="green"
                @click-positive="hasNoPreviousTaxYear = false"
            />

            <confirmation-dialog
                v-if="didNotDoAccountingInDexxter"
                v-model="didNotDoAccountingInDexxter"
                persistent
                data-id="didNotDoAccountingInDexxterDialog"
                :title="
                    $t('clientlisting.didNotDoAccountingInDexxter.title', {
                        yearOfClientlistingSubmission: yearOfClientlisting
                    })
                "
                :message="
                    $t('clientlisting.didNotDoAccountingInDexxter.content', {
                        yearOfClientlistingSubmission: yearOfClientlisting,
                        startDate: formattedStartDataLocal
                    })
                "
                :positive-button="$t('general.confirm')"
                positive-color="green"
                @click-positive="didNotDoAccountingInDexxter = false"
            />

            <confirmation-dialog
                v-if="startDateAfterClientlistingSubmitDate"
                v-model="startDateAfterClientlistingSubmitDate"
                data-id="startDateAfterClientlistingSubmitDateDialog"
                persistent
                :title="$t('clientlisting.startDateAfterClientlistingSubmitDate.title')"
                :message="
                    $t('clientlisting.startDateAfterClientlistingSubmitDate.content', {
                        startDate: formattedStartDataLocal
                    })
                "
                :positive-button="$t('general.confirm')"
                positive-color="green"
                @click-positive="startDateAfterClientlistingSubmitDate = false"
            />
        </template>
    </view-layout>
</template>

<script>
import CustomersVATListingTable from '@/components/DataTables/CustomersVATListingTable';
import PageHeader from '@/components/PageHeader';
import TableCard from '@/components/TableCard';
import ViewLayout from '@/components/ViewLayout.vue';
import vCardHeader from '@/components/vCardHeader.vue';
import { apiErrorAndDisplay, displayError } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import {
    deleteClientlistingSubmissionsRequest,
    downloadClientListingXML,
    getAllClientListingCustomersRequest,
    getClientlistingSubmissionsRequest,
    uploadClientlistingSubmissionRequest
} from '@/services/clientlisting';
import { sendGetNumberOfTaxesYearsRequest } from '@/services/tax';
import { downloadBlobFile } from '@/services/utils';
import dayjs from 'dayjs';
import { noop } from 'lodash';
import { mapState } from 'vuex';
import { getSituationIfCanSubmitPreviousYearOblogation } from '../../businessServices/previousYearSubmissionSituation';
import SubmitClientlistingConfirmationReceiptDialog from './components/submitClientlistingConfirmationReceiptDialog.vue';
import * as ClientlistingService from './domain/clientlisting.service';

export default {
    components: {
        CustomersVATListingTable,
        TableCard,
        ViewLayout,
        PageHeader,
        vCardHeader,
        SubmitClientlistingConfirmationReceiptDialog
    },

    mixins: [screenSizeMixin, middlewareMixin],

    data() {
        return {
            hasSuccessfulyFetchedClientListing: false,
            deleteClientlistingSubmissionDialog: false,
            userDataExportLoading: false,
            downloadXMLDialog: false,
            currentTab: 0,
            selectedQuarterToDownload: null,
            ClientListingCustomers: [],
            loadingCustomers: false,
            clientlisting: null,
            selectedClientlistingSubmissionIDToDelete: null,
            dontNeedToSubmitEmptyClientlistingDialog: false,
            showLoadClientlistingDataDialog: true,
            activeYears: [],
            hasNoPreviousTaxYear: false,
            didNotDoAccountingInDexxter: false,
            startDateAfterClientlistingSubmitDate: false
        };
    },

    computed: {
        ...mapState(['selectedYear']),
        ...mapState('auth', ['currentUserData']),
        getClientlisting() {
            return this.clientlisting;
        },
        yearOfClientlisting() {
            return parseInt(this.selectedYear) - 1;
        },
        formattedStartDataLocal() {
            return dayjs(this.currentUserData.settings.companyStartDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
        },
        getCanDownloadClientlistingsWithDialogs() {
            /**
             * 4 scenarios:
             * 1. Company start date is less than the year of clientlisting submission AND she does NOT have the previous bookyear created
             *      --> He did his accounting in another software so we cannot generate it for her
             * 2. She started in the current year AND she does have previous year created (weird)
             *      --> Give him a popup that states that this situation is weird and that this cannot happen. He must change is startYear
             * 3. She started in the current year AND she does NOT have previous year created
             *      --> Give her a popup that she does not need to submit any
             * 4. Company start date is less than the year of clientlisting submission AND her DOES have the previous bookyear created
             *      --> OK, let her generate her client listing
             */
            if (this.ClientListingCustomers == null) {
                return {
                    status: false,
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    displayDialogAction: () => {}
                };
            }

            const result = getSituationIfCanSubmitPreviousYearOblogation(
                { companyStartDate: this.currentUserData.settings.companyStartDate },
                this.activeYears,
                this.yearOfClientlisting
            );

            if (result === 'ELIGABLE_BUT_DID_NOT_DO_PREVIOUS_ACCOUNTING_YEAR_IN_DEXXTER') {
                if (!this.activeYears.includes(parseInt(this.yearOfClientlisting))) {
                    return {
                        status: false,
                        displayDialogAction: () => (this.didNotDoAccountingInDexxter = true)
                    };
                }
            } else if (result === 'NOT_ELIGABLE') {
                return {
                    status: false,
                    displayDialogAction: () => (this.hasNoPreviousTaxYear = true)
                };
            } else if (result === 'NOT_ELIGABLE_BUT_HAS_THE_TAX_YEAR_FOR_GIVEN_YEAR') {
                return {
                    status: false,
                    displayDialogAction: () => (this.startDateAfterClientlistingSubmitDate = true)
                };
            } else if (result === 'CAN_SUBMIT') {
                return {
                    status: true,
                    displayDialogAction: noop
                };
            }

            throw new Error('Result not found');
        }
    },

    async created() {
        this.activeYears = await sendGetNumberOfTaxesYearsRequest();

        if (this.getCanDownloadClientlistingsWithDialogs.status === false) {
            this.showLoadClientlistingDataDialog = false;
            this.getCanDownloadClientlistingsWithDialogs.displayDialogAction();
            return;
        }

        this.clientlisting = await getClientlistingSubmissionsRequest(this.yearOfClientlisting);
    },

    methods: {
        routeBack() {
            this.$router.back();
        },
        async downloadClientListing() {
            try {
                this.userDataExportLoading = true;
                if (this.hasSuccessfulyFetchedClientListing === false) {
                    displayError.call(
                        this,
                        'Je moet eerst je klantenlisting ophalen',
                        'Je moet eerst je klantenlisting ophalen door de pagina nog eens te vernieuwen en opnieuw je data te proberen op te halen'
                    );
                    return;
                }

                if (this.ClientListingCustomers.length === 0) {
                    if (
                        ClientlistingService.mustSubmitEmptyClientlisting(this.currentUserData.settings.vatLiable) ===
                        false
                    ) {
                        this.dontNeedToSubmitEmptyClientlistingDialog = true;
                        return;
                    }
                }

                await downloadClientListingXML({
                    year: this.yearOfClientlisting,
                    filename: `${this.$t('clientlisting.clientlisting').toLowerCase()}-${this.yearOfClientlisting}.xml`
                });

                this.showActionstepsIntervat();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.userDataExportLoading = false;
            }
        },
        async wrapperFunction() {
            this.loadingCustomers = true;
            try {
                const result = await getAllClientListingCustomersRequest(this.yearOfClientlisting);

                if (!Array.isArray(result)) {
                    throw new Error('Vies error - vies is down');
                }

                this.ClientListingCustomers = result;
                this.hasSuccessfulyFetchedClientListing = true;
                this.showLoadClientlistingDataDialog = false;
            } catch (e) {
                this.hasSuccessfulyFetchedClientListing = false;
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loadingCustomers = false;
                // this.showLoadClientlistingDataDialog = false;
            }
        },
        async downloadClientlistingSubmission(url) {
            try {
                this.loading = true;

                await downloadBlobFile({
                    url,
                    downloadFileName: `clientlisting-submission-${this.yearOfClientlisting}.xml`
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },

        openDeleteClientlistingSubmissionDialog(submissionID) {
            this.selectedClientlistingSubmissionIDToDelete = submissionID;
            this.deleteClientlistingSubmissionDialog = true;
        },

        async deleteClientlistingSubmission() {
            try {
                this.loading = true;

                await deleteClientlistingSubmissionsRequest(this.selectedClientlistingSubmissionIDToDelete);

                notify.call(this, {
                    title: this.$t('general.succesfuly_deleted'),
                    text: this.$t('general.succesfuly_deleted')
                });

                this.clientlisting = await getClientlistingSubmissionsRequest(this.yearOfClientlisting);
                this.selectedClientlistingSubmissionIDToDelete = null;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.deleteClientlistingSubmissionDialog = false;
            }
        },

        async submitNihilClientListingSubmission() {
            try {
                this.loading = true;

                await uploadClientlistingSubmissionRequest(null, this.yearOfClientlisting);

                this.clientlisting = await getClientlistingSubmissionsRequest(this.yearOfClientlisting);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.dontNeedToSubmitEmptyClientlistingDialog = false;
                this.loading = false;
            }
        },
        showActionstepsIntervat() {
            const clickPositive = async () => {
                this.downloadXMLDialog = false;

                notify.call(this, {
                    title: this.$t('general.succesfuly_created'),
                    text: this.$t('general.succesfuly_created')
                });

                this.clientlisting = await getClientlistingSubmissionsRequest(this.yearOfClientlisting);
            };

            const clickNegative = () => {
                this.downloadXMLDialog = false;
            };

            this.dialogAttributes = {
                attributes: {
                    year: parseInt(this.yearOfClientlisting)
                },
                actions: {
                    clickPositive: clickPositive,
                    clickNegative: clickNegative
                }
            };
            this.downloadXMLDialog = true;
        }
    }
};
</script>
