<template>
    <widget>
        <template #title>
            <div class="wrapper">
                <a @click="toggle"
                    ><v-icon class="mr-2" small> {{ open ? 'mdi-minus' : 'mdi-plus' }} </v-icon></a
                >
                {{ expensesubcategory.name }}
            </div>
        </template>
        <template #right>
            <span :class="{ completed }">{{ totalAmountOfExpenses }}/{{ totalExpectedExpenses }}</span>
        </template>
        <template #content>
            <v-data-table
                v-if="open"
                :headers="headers"
                :items="expensesubcategory.expenses"
                sort-by="date"
                sort-desc
                dense
            >
                <template #[`item.supplier`]="{ item }">
                    {{ item.supplier.company.name }}
                </template>
                <template #[`item.date`]="{ item }">
                    {{ item.date | formatDate }}
                </template>
                <template #[`item.amountInclVAT`]="{ item }">
                    €
                    {{ item.amountEuroInclVAT | numericFormat }}
                </template>
            </v-data-table>
        </template>
    </widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
    components: { Widget },

    props: {
        expensesubcategory: {
            required: true
        }
    },

    data() {
        const sortableColumns = ['date', 'dueDate'];

        return {
            expenseCategoriesFrequencies: null,
            open: false,
            loading: {
                getExpensecategoryFrequencies: false
            },
            headers: [
                {
                    text: this.$t('general.supplier'),
                    value: 'supplier',
                    sortable: sortableColumns.includes('supplier')
                },
                { text: this.$t('general.date'), value: 'date', sortable: sortableColumns.includes('date') },
                {
                    text: this.$t('general.amount_incl_vat'),
                    value: 'amountInclVAT',
                    sortable: sortableColumns.includes('amountInclVAT')
                }
            ]
        };
    },

    computed: {
        completed() {
            return this.totalAmountOfExpenses === this.totalExpectedExpenses;
        },
        totalAmountOfExpenses() {
            return this.expensesubcategory.expenses.length;
        },
        totalExpectedExpenses() {
            return this.getFrequencyOnYearBase(
                this.expensesubcategory.expectedAmount,
                this.expensesubcategory.frequency
            );
        }
    },

    methods: {
        getFrequencyOnYearBase(expectedAmount, frequency) {
            if (frequency === 'YEARLY') {
                return expectedAmount;
            } else if (frequency === 'MONTHLY') {
                const monthsInYear = 12;
                return expectedAmount * monthsInYear;
            } else if (frequency === 'QUARTERLY') {
                const quartersInYear = 4;
                return expectedAmount * quartersInYear;
            } else if (frequency === 'HALFYEARLY') {
                const halfyearsInYear = 2;
                return expectedAmount * halfyearsInYear;
            }
        },
        toggle() {
            this.open = !this.open;
        }
    }
};
</script>

<style lang="scss" scoped>
.completed {
    color: green;
}
</style>
