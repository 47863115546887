<template>
    <v-autocomplete v-bind="$attrs" v-on="$listeners" :items="items" outlined persistent-hint dense />
</template>

<script>
export default {
    inheritAttrs: false,

    props: ['items']
};
</script>

<style></style>
