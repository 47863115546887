
import Vue, { PropOptions } from 'vue';
import { Action } from '@/helpers/errorModals';

export default Vue.extend({
    name: 'CommonModal',
    props: {
        i18nTitle: {
            type: String,
            required: true
        },
        i18nText: {
            type: String,
            default: ''
        },
        i18nTextVariables: {
            type: Object,
            required: false
        },
        actions: {
            type: Array,
            required: false,
            deep: true,
            immediate: true
        } as PropOptions<Action[]>,
        nextButtonDisabled: {
            type: Boolean,
            required: false,
            default: false
        } as PropOptions<boolean>
    }
});
