<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                    <sub>
                        <tooltip :underlined="false" i18n-path="tooltips.depreciationOverview.title"
                            ><template slot="activator"> <v-icon small> mdi-information </v-icon></template></tooltip
                        >
                    </sub>
                </template>

                <template #menu>
                    <v-btn color="primary" :to="{ name: 'expenses' }">
                        {{ $t('general.add_depreciation') }}
                    </v-btn>
                    <v-btn color="primary" to="/add-depreciationfiche">
                        {{ $t('general.add_historical_depreciation') }}
                    </v-btn>
                </template></page-header
            >
        </template>
        <template #content>
            <TableCard>
                <template #content>
                    <depreciations-table />
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
import DepreciationsTable from './components/DepreciationsTable.vue';
import TableCard from '@/components/TableCard.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import { mapState } from 'vuex';

export default {
    components: {
        DepreciationsTable,
        TableCard,
        ViewLayout,
        PageHeader
    },

    data() {
        return {
            currentTab: 0
        };
    },
    computed: {
        ...mapState(['selectedYear'])
    }
};
</script>
