
import Vue, { PropOptions } from 'vue';
import { mapMutations, mapState } from 'vuex';

import VCardHeader from '@/components/vCardHeader.vue';
import CommonModal from '@/components/Modal/CommonModal.vue';

import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import { GeneralFncType } from '@/types/general';
import { QuarterDataViewModel } from '@/views/VAT/models/models';

export default Vue.extend({
    name: 'VatDeclarationActions',
    components: {
        VCardHeader
    },
    props: {
        quarterData: {
            required: true
        } as PropOptions<QuarterDataViewModel>,
        toggleQuarterStatusHandler: {
            required: true
        } as PropOptions<GeneralFncType>,
        selectedQuarter: {
            required: true
        } as PropOptions<string>
    },
    data() {
        return {
            toggleQuarterLoading: false
        };
    },
    computed: {
        ...mapState(['selectedYear']),

        canSubmitVatDeclaration(): boolean {
            return this.quarterData.closed === true;
        }
    },
    methods: {
        ...mapMutations('modal', ['setModalContent']),

        async toggleQuarterStatus(): Promise<void> {
            try {
                this.toggleQuarterLoading = true;

                const previousStateClosed = this.quarterData.closed;

                await this.toggleQuarterStatusHandler(this.selectedYear, this.selectedQuarter, !previousStateClosed);

                notify.call(this, {
                    title: this.$t('accountantTerms.quarter_updated_sucessfuly'),
                    text: this.$t('accountantTerms.quarter_updated_sucessfuly'),
                    type: 'success'
                });

                this.$emit('quarter-toggled');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.toggleQuarterLoading = false;
            }
        },
        emitOpenVatModalEvent() {
            this.$emit('open-vat-modal');
        },
        async openConfirmReopenQuarterModal(): Promise<void> {
            try {
                await this.setModalContent({
                    component: CommonModal,
                    props: {
                        i18nTitle: this.$i18n.t('accountantTerms.confirmOpenQuarterTitle'),
                        i18nText: this.$i18n.t('accountantTerms.confirm_open_quarter'),
                        actions: [
                            {
                                action: () => {
                                    this.$store.commit('modal/setIsModalOpen', false);
                                },
                                outlined: true,
                                i18nPath: 'general.cancel',
                                icon: 'mdi-close'
                            },
                            {
                                action: async () => {
                                    try {
                                        await this.toggleQuarterStatusHandler(
                                            this.selectedYear,
                                            this.selectedQuarter,
                                            !this.quarterData.closed
                                        );
                                        this.$emit('quarter-toggled');
                                        this.$store.commit('modal/setIsModalOpen', false);
                                    } catch (e) {
                                        apiErrorAndDisplay.call(this, e);
                                    }
                                },
                                i18nPath: 'vatdeclaration.reopen',
                                icon: 'mdi-undo',
                                dataAction: 'confirm-delete-vat-submission'
                            }
                        ]
                    }
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }
        }
    }
});
