
import i18n from '@/i18n';
import { computed, defineComponent, PropType } from 'vue';

import { getAdvantageAmount } from '@/helpers/bonification';
import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';

import { Quarters } from '@/config/constants';

interface DepositRowProps {
    quarter: Quarters;
    deposit: number;
    year: number;
}

export default defineComponent<DepositRowProps>({
    name: 'DepositRow',
    props: {
        year: {
            type: Number,
            required: true
        },
        quarter: {
            type: String as PropType<Quarters>,
            required: true
        },
        deposit: {
            type: Number,
            required: true
        }
    },
    emits: ['open-edit-deposit-modal'],
    setup(props, { emit }) {
        const quarterText = computed<string>(() => {
            const quarterNumber = props.quarter.substring(props.quarter.length - 1);
            return `${i18n.t('deposits.deposit_paid_in')} ${i18n
                .t('general.quarter')
                .toString()
                .toLowerCase()} ${quarterNumber}`;
        });

        const advantageAmountPercentage = computed<string>(() => {
            return `${getAdvantageAmount(props.year, props.quarter) * 100}%`;
        });

        const formattedDepositAmount = computed<string>(() => {
            return convertToLocaleValuta(props.deposit, { locale: i18n.locale });
        });

        function emitOpenEditDepositModalEvent(): void {
            emit('open-edit-deposit-modal', props.quarter);
        }

        return { quarterText, advantageAmountPercentage, formattedDepositAmount, emitOpenEditDepositModalEvent };
    }
});
