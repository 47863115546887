<template>
    <v-row>
        <v-col cols="12" sm="6" order-md="2">
            <number-field
                id="amountEuroTotalInclVAT"
                :value="currentAccountingExpense.amountEuroInclVAT"
                :label="$t('general.total')"
                outlined
                prefix="€"
                :allow-negative="true"
                dense
                :rules="
                    currentAccountingExpense.isCreditnote
                        ? numberRulesNotRequired
                        : numberRulesNotRequiredNegativePossible
                "
                @change="changeValues"
            />
        </v-col>
    </v-row>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

export default {
    mixins: [formRulesMixin],
    props: {
        value: {
            required: true
        }
    },

    computed: {
        currentAccountingExpense: {
            get() {
                return this.value;
            },
            set(input) {
                return this.$emit('input', input);
            }
        }
    },

    methods: {
        changeValues(value) {
            this.currentAccountingExpense.amountEuroInclVAT = value;
            this.currentAccountingExpense.amountEuroExclVAT = value;
        }
    }
};
</script>
