import { expenseTypeOfServiceType, supplierType } from '@/config/constants';
import { ICarViewModel } from 'dexxter-types';

export type DepreciationExpenseModel = {
    carId: number | null;
    years: number | null;
    numberOfYears: number | null;
    dateStart: string | null;
    annualAmountEuro: Record<number, number>;
    category: {
        id: number | null;
        name: string | null;
    };
    description: string | null;
};

export type AccountingExpenseSupplier = {
    id: number;
    type: supplierType;
    company: {
        name: string;
    };
};

export type AccountingExpenseModel = {
    id: number | null;
    invoiceNumber: string | null;
    date: string | null;
    documentDate: string | null;
    dueDate: string | null;
    // category: string | null; // ?
    accountingCostEuro: number;
    businessAccountingCostVAT: number;
    description: string | null;
    isDepreciation: boolean;
    btwMedecontractant: boolean;
    depreciation: DepreciationExpenseModel | null;
    carId: null;
    car: ICarViewModel | null;
    expensecategory: {
        id: number | null;
        name: string | null;
        forTypeOfService: expenseTypeOfServiceType | null;
        businessPercentage: number | null;
        VATDeductible: number | null;
        potentialInvestement: boolean | null;
        number: null | number;
    };
    expensecategoryId: number | null; // Type: service, good or investment
    isCreditnote: boolean;
    VATDeductiblePercentage: number | null;
    businessPercentage: number | null;
    amountEuroExcl0VAT: number | null;
    amountEuroExcl6VAT: number | null;
    amountEuroExcl12VAT: number | null;
    amountEuroExcl21VAT: number | null;
    amountEuroExclVAT: number | null;
    amountEuroInclVAT: number | null;
    payed: boolean;
    supplier: AccountingExpenseSupplier | null;
    recordInVATDeclaration: boolean;
    reference: string | null;
    supplierChargedVAT: boolean;
};

export function newAccountingExpenseDepreciationModel(): DepreciationExpenseModel {
    return {
        years: null,
        numberOfYears: null,
        dateStart: null,
        annualAmountEuro: {},
        category: {
            id: null,
            name: null
        },
        description: null,
        carId: null
    };
}

export const newAccountingExpense = ({
    id = null,
    vatLiable
}: {
    id?: number | null;
    vatLiable: boolean;
}): Omit<AccountingExpenseModel, 'car'> => ({
    id: id ?? null,
    invoiceNumber: null,
    date: null,
    documentDate: null,
    dueDate: null,
    accountingCostEuro: 0, // Business - prive part amount
    businessAccountingCostVAT: 0, // VAT deductible amount
    description: null,
    isDepreciation: false,
    btwMedecontractant: false,
    depreciation: null,
    expensecategory: {
        id: null,
        name: null,
        forTypeOfService: null,
        businessPercentage: null,
        VATDeductible: null,
        potentialInvestement: null,
        number: null
    },
    carId: null,
    expensecategoryId: null, // TODO: must remove it and use expensecategory.id
    isCreditnote: false,
    VATDeductiblePercentage: null,
    businessPercentage: null,
    amountEuroExcl0VAT: null,
    amountEuroExcl6VAT: null,
    amountEuroExcl12VAT: null,
    amountEuroExcl21VAT: null,
    amountEuroExclVAT: null,
    amountEuroInclVAT: null,
    payed: false,
    supplier: null,
    recordInVATDeclaration: vatLiable,
    reference: null,
    supplierChargedVAT: false
});
