<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <v-card outlined>
                <v-card-text v-if="currentUserData">
                    <v-form v-if="currentAsEmployee" id="jobstudentIncomeForm" ref="form" v-model="valid">
                        <v-row class="ma-0">
                            <v-col cols="12" md="12">
                                <text-field
                                    id="employername"
                                    v-model="currentAsEmployee.employername"
                                    name="employer"
                                    :label="$t('general.employer')"
                                    outlined
                                    persistent-hint
                                    dense
                                    :hint="$t('addJobstudentincome.add_company_who_you_worked_for')"
                                    required
                                    :rules="employerRules"
                                ></text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                                <text-field
                                    id="grossAmountEuro"
                                    v-model="currentAsEmployee.grossAmountEuro"
                                    :label="$t('general.gross_amount')"
                                    outlined
                                    :hint="$t('addJobstudentincome.enter_amount_earned_as_employee')"
                                    persistent-hint
                                    dense
                                    required
                                    prefix="€"
                                    type="number"
                                    :rules="numberRulesGreaterThanZero"
                                ></text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                                <text-field
                                    id="year"
                                    v-model="currentAsEmployee.year"
                                    v-mask="'####'"
                                    :label="$t('general.year')"
                                    outlined
                                    dense
                                    persistent-hint
                                    required
                                    :rules="[...requiredRules, ...validYearRules]"
                                />
                            </v-col>

                            <v-col cols="12" md="12">
                                <text-area
                                    id="description"
                                    v-model="currentAsEmployee.description"
                                    :label="$t('general.description')"
                                    persistent-hint
                                    :rules="descriptionRulesNoRequired"
                                    :hint="$t('addJobstudentincome.description_hint')"
                                ></text-area>
                            </v-col>

                            <v-col cols="12" md="12">
                                <submit-button
                                    v-if="isCreatingNewAsEmployee"
                                    id="create-jobstudent-income-and-new"
                                    class="mr-2"
                                    color="primary"
                                    :disabled="!valid"
                                    data-action="create-jobstudent-income-and-new"
                                    @click="handleSubmitAndNew"
                                >
                                    {{ $t('addJobstudentincome.create_and_new') }}
                                </submit-button>
                                <submit-button
                                    id="create-jobstudent-income-and-overview"
                                    color="primary"
                                    :disabled="!valid"
                                    data-action="create-jobstudent-income-and-overview"
                                    @click="handleSubmitAndOverview"
                                >
                                    {{
                                        isCreatingNewAsEmployee
                                            ? $t('addJobstudentincome.create_and_overview')
                                            : $t('general.save')
                                    }}
                                </submit-button>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <loader v-if="loading || !currentUserData" />
            </v-card>
        </template>
    </view-layout>
</template>

<script>
import dayjs from 'dayjs';
// --- State ---
import { mapState, mapActions } from 'vuex';
// --- Components ---
import Loader from '@/components/Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import { newJobstudentIncome } from '../models/jobstudentIncome';
import ViewLayout from '@/components/ViewLayout';
import PageHeader from '@/components/PageHeader';
import { notify } from '@/helpers/successNotification';
import SubmitButton from '@/components/SubmitButton.vue';

export default {
    components: {
        Loader,
        ViewLayout,
        PageHeader,
        SubmitButton
    },

    mixins: [formRulesMixin],

    data() {
        return {
            loading: false,
            currentAsEmployee: null,
            valid: false,
            vatPercentages: [0, 6, 12, 21]
        };
    },

    async created() {
        this.startLoading();
        try {
            if (this.$route.params.id) {
                this.$set(this, 'currentAsEmployee', await this.getJobstudentincomeById(this.$route.params.id));
            } else {
                this.initJobstudentIncome();
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        isCreatingNewAsEmployee() {
            return !this.currentAsEmployee.id;
        }
    },

    methods: {
        ...mapActions('incomes', [
            'createJobstudentIncome',
            'updateJobstudentIncome',
            'newJobstudentUpdated',
            'getJobstudentincomeById'
        ]),
        ...mapActions(['startLoading', 'stopLoading']),
        initJobstudentIncome() {
            this.$set(
                this,
                'currentAsEmployee',
                newJobstudentIncome({
                    year: dayjs().year()
                })
            );
        },
        resetForm() {
            this.initJobstudentIncome();

            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });
        },
        async submit() {
            this.loading = true;
            this.currentAsEmployee.grossAmountEuro = parseFloat(this.currentAsEmployee.grossAmountEuro);

            if (this.isCreatingNewAsEmployee) {
                await this.createJobstudentIncome(this.currentAsEmployee);
            } else {
                await this.updateJobstudentIncome(this.currentAsEmployee);
            }

            notify.call(this, {
                title: this.$t('income.income_as_student_employee_succesfuly_added'),
                text: this.$t('income.income_as_student_employee_succesfuly_added')
            });

            this.newJobstudentUpdated();
        },
        async handleSubmitAndNew() {
            try {
                await this.submit();

                this.resetForm();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        async handleSubmitAndOverview() {
            try {
                await this.submit();

                this.$router.push({
                    name: 'jobstudentincomes'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}
</style>
