<template>
    <div>
        <div v-if="currentAccountingExpense && expensefile" class="add-accounting-expense px-4 px-md-10 pt-4 pb-6">
            <expense-accounting-form
                ref="invoicableForm"
                v-model="currentAccountingExpense"
                :expensefile="expensefile"
                :expense-file-ids-user-wants-to-process="expenseFileIdsUserWantsToProcess"
                :total-expense-file-ids-user-wants-to-process="totalExpenseFileIdsUserWantsToProcess"
                @skip-current-expense-file="skipCurrentExpenseFile"
                @submit-go-to-overview="submitGoToOverview"
                @submit-go-to-next-file="submitGoToNextFile"
            />
        </div>

        <confirmation-dialog
            v-if="showDialog"
            v-model="showDialog"
            v-bind="dialogAttributes.attributes"
            @click-positive="dialogAttributes.actions.clickPositive"
            @click-negative="dialogAttributes.actions.clickNegative"
        />

        <loader v-if="loading" />
    </div>
</template>

<script>
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { newAccountingExpense } from '@/models/accountingexpense';
import { mapActions, mapState } from 'vuex';

import * as BusinessExpenseService from '@/businessServices/expense/expense.service';
import { getExpenseFileById } from '@/services/expensefiles';

import { isVatLiable, mustUserHaveArticle56OnDocument } from '@/helpers/VATHelper';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import * as AccountingExpenseService from '@/services/accountingexpenses';
import ExpenseAccountingForm from '@/views/addExpenseShared/AccountingExpenseForm.vue';
import { analyticsTrack } from '../../../services/analytics';

export default {
    components: { ExpenseAccountingForm },
    mixins: [middlewareMixin],

    async beforeRouteUpdate(to, from, next) {
        await this.initData(to.params.id, to.query.expenseFileId);
        next();
    },

    beforeRouteLeave(to, from, next) {
        if (from.name !== to.name) {
            this.setToProcessExpenseFiles([]);
        }

        return next();
    },

    data() {
        return {
            currentExpenseId: undefined,
            currentExpenseFileId: undefined,
            currentAccountingExpense: null,
            loading: false,
            expensefile: null,
            showDialog: false
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapState('expensefiles', ['expenseFileIdsUserWantsToProcess', 'totalExpenseFileIdsUserWantsToProcess']),

        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        },

        isCreatingNewExpense() {
            if (!this.currentAccountingExpense) {
                return true;
            }

            return this.currentAccountingExpense.id === null;
        }
    },
    async created() {
        await this.initData(this.$route.params.id, this.$route.query.expenseFileId);
    },
    methods: {
        ...mapActions('expenses', ['createAccountingExpense', 'updateAccountingExpense', 'newExpenseEvent']),
        ...mapActions('expensefiles', [
            'skipCurrentProcessExpenseFile',
            'markCurrentProcessExpenseFileAsHandled',
            'setToProcessExpenseFiles'
        ]),
        ...mapActions(['startLoading', 'stopLoading']),
        async initData(currentExpenseId, currentExpenseFileId) {
            this.currentExpenseId = currentExpenseId;
            this.currentExpenseFileId = currentExpenseFileId;

            await this.fetchData();

            if (this.isCreatingNewExpense === false) {
                if (
                    this.currentAccountingExpense.article56 !==
                    mustUserHaveArticle56OnDocument(this.currentUserData.settings.vatLiable)
                ) {
                    // User cannot edit this
                    this.setDialogAttributes(
                        {
                            dataId: 'cannot-edit-expense-other-vattype',
                            title: this.$i18n.t('expenses.dialogs.cannotEditExpenseCreatedWithOtherVAT.title'),
                            message: this.$i18n.t('expenses.dialogs.cannotEditExpenseCreatedWithOtherVAT.content'),
                            negativeColor: 'message',
                            positiveColor: 'orange',
                            onlyPositiveButton: true,
                            props: {
                                persistent: true
                            }
                        },
                        {
                            clickPositive: () => {
                                this.$router.back();
                            }
                        }
                    );

                    this.showDialog = true;
                }
            }
        },
        async submitGoToOverview() {
            await this.submitForm(this.redirectToOverview);
        },
        async submitGoToNextFile() {
            await this.submitForm(this.redirectToNextExpenseFile);
        },
        async fetchData() {
            this.startLoading();

            try {
                if (this.currentExpenseId) {
                    await this.fetchAccountingExpense(this.currentExpenseId);

                    this.expensefile = this.currentAccountingExpense.expensefile;
                } else {
                    if (!this.currentExpenseFileId) {
                        this.$router.push({ name: 'dashboard' });
                    }

                    this.expensefile = await getExpenseFileById(this.currentExpenseFileId);
                    this.$set(this, 'currentAccountingExpense', newAccountingExpense({ vatLiable: this.isVATLiable }));
                }
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
                if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                    this.$router.push({ name: 'dashboard' });
                }
            } finally {
                this.stopLoading();
            }
        },
        skipCurrentExpenseFile() {
            this.skipCurrentProcessExpenseFile();
            this.redirectToNextExpenseFile();
        },
        async fetchAccountingExpense(expenseId) {
            this.$set(this, 'currentAccountingExpense', await AccountingExpenseService.getExpense(expenseId));
            this.preprocessFetchedExpense();
        },
        preprocessFetchedExpense() {
            // Save this, because there is a watcher that resets when settings the parent category
            const expensecategoryId = this.currentAccountingExpense.expensecategoryId;

            this.currentAccountingExpense.expensecategoryId = expensecategoryId;

            // Preprocessing to match the form
            if (this.currentAccountingExpense.isDepreciation) {
                this.$set(this.currentAccountingExpense, 'depreciation', {
                    numberOfYears: this.currentAccountingExpense.depreciationfiche.numberOfYears,
                    category: this.currentAccountingExpense.depreciationfiche.category,
                    description: this.currentAccountingExpense.depreciationfiche.description,
                    dateStart: this.currentAccountingExpense.depreciationfiche.dateStart,
                    annualAmountEuro: null,
                    carId: this.currentAccountingExpense.depreciationfiche.carId
                });
            } else {
                this.$set(
                    this.currentAccountingExpense,
                    'depreciation',
                    newAccountingExpense({ vatLiable: this.isVATLiable }).depreciation
                );
            }
        },
        async submitForm(afterSubmitFnc) {
            try {
                this.loading = true;

                // Set correct values
                const expenseCopy = BusinessExpenseService.setCorrectValuesForSubmission(
                    this.currentAccountingExpense,
                    this.isVATLiable,
                    this.expensefile.id
                );

                if (this.isCreatingNewExpense) {
                    await this.createAccountingExpense(expenseCopy);

                    analyticsTrack('User Processes New Expense', {
                        Added_Expense_Date: expenseCopy.date,
                        Add_to_VAT_declaration: expenseCopy.recordInVATDeclaration,
                        Add_Price_ExclVat: expenseCopy.amountEuroExclVAT,
                        Add_Price_InclVat: expenseCopy.amountEuroInclVAT,
                        Is_Creditnote: expenseCopy.isCreditnote,
                        Is_Depreciation: expenseCopy.isDepreciation,
                        Add_Expense_Category: this.currentAccountingExpense.expensecategory.name,
                        Add_Expense_Category_Number_Type: this.currentAccountingExpense.expensecategory.number
                            .toString()
                            .slice(0, 4),
                        Expense_Paid: expenseCopy.payed,
                        Supplier_Type: this.currentAccountingExpense.supplier.type
                    });
                } else {
                    await this.updateAccountingExpense({ expense: expenseCopy, id: this.currentExpenseId });
                    await this.fetchAccountingExpense(this.currentExpenseId);
                }

                this.stepper = 1;

                this.$notify({
                    title: this.isCreatingNewExpense
                        ? this.$t('addExpense.expense_created_success')
                        : this.$t('addExpense.expense_updated_success'),
                    text: this.isCreatingNewExpense
                        ? this.$t('addExpense.expense_created_success')
                        : this.$t('addExpense.expense_updated_success'),
                    type: 'success'
                });

                this.newExpenseEvent();

                // wait for all requests to finish, resolving error in e2e tests on v-stepper-content: Uncaught TypeError: Cannot read properties of undefined (reading ‘scrollHeight’)
                setTimeout(() => {
                    afterSubmitFnc();
                }, 0);
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        redirectToNextExpenseFile() {
            this.markCurrentProcessExpenseFileAsHandled();

            if (this.expenseFileIdsUserWantsToProcess.length === 0) {
                return this.$router.replace({
                    name: 'expenses'
                });
            }

            this.$router.replace(`/add-accounting-expense?expenseFileId=${this.expenseFileIdsUserWantsToProcess[0]}`);
        },
        redirectToOverview() {
            this.$router.push({
                name: 'expenses'
            });
        }
    }
};
</script>

<style lang="scss">
@import '../../../sass/variables.scss';

.add-accounting-expense {
    height: calc(100vh - 64px);
    overflow-y: auto;

    @media screen and (min-width: $md) {
        overflow-y: hidden;
    }
}
</style>
