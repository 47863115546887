import axios from 'axios';
import { downloadBlobFile } from '@/services/utils';
import { generateEndpoint } from '@/services/config';
import { ClientListingCustomer, IClientListingSubmissionViewModel } from '@/services/clientlisting.types';

const BASE_URL = generateEndpoint('/api/clientlisting');

export function getClientlistingSubmissionsRequest(year: number): Promise<IClientListingSubmissionViewModel> {
    return axios.get(`${BASE_URL}/clientlistingsubmission/${year}`).then((response) => response.data);
}

export function getAllClientListingCustomersRequest(year: number): Promise<ClientListingCustomer[]> {
    return axios.get(`${BASE_URL}/vat/year/${year}`).then((response) => response.data);
}

export function downloadClientListingXML({ year, filename }: { filename: string; year: number }) {
    return downloadBlobFile({
        url: `${BASE_URL}/year/${year}/clientlisting`,
        method: 'get',
        downloadFileName: filename
    });
}

export function deleteClientlistingSubmissionsRequest(id: number): Promise<void> {
    return axios.delete(`${BASE_URL}/clientlistingsubmission/${id}`).then((response) => response.data);
}

export function uploadClientlistingSubmissionRequest(
    file: any,
    year: number
): Promise<IClientListingSubmissionViewModel> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('year', year.toString());

    return axios.post(`${BASE_URL}/clientlistingsubmission`, formData);
}
