<template>
    <button
        :id="id"
        v-bind="$attrs"
        :disabled="disabled"
        class="toggle-button"
        :class="{ active: active }"
        @click="handleClick"
    >
        <v-icon v-if="icon" class="icon mr-2" dark> {{ icon }}</v-icon>
        <slot />
        <v-icon v-if="icon" class="menu-icon ml-1" small dark>mdi-menu-down</v-icon>
    </button>
</template>

<script>
export default {
    name: 'ToggleMenuButton',
    props: {
        id: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        prependIcon: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        }
    },

    created() {
        if (!this.$slots.default) {
            throw new Error('Submitbutton must be provided in the slot.');
        }
    },

    methods: {
        handleClick(e) {
            this.$emit('click', e);
        }
    }
};
</script>

<style lang="scss">
@import '../../sass/variables';
.toggle-button {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 15%;
    color: white !important;
    background-color: $color-primary !important;
    border-radius: 5px;

    &.active {
        color: #3e3e3e !important;
        background-color: white !important;
        border: 2px solid #828282 !important;

        .menu-icon,
        .icon {
            color: #3e3e3e !important;
        }

        .menu-icon {
            transform: rotate(180deg);
        }
    }
}
</style>
