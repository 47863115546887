<template>
    <div class="v-stepper-wrapper">
        <v-stepper-step :editable="canEditStepper" :complete="stepper > 2" step="2" data-action="open-basic-info">
            <base-icon-right :i18n-path="getBasicinformationPath" small>
                {{ $t('general.basicinformation') }}
            </base-icon-right>
        </v-stepper-step>

        <v-stepper-content step="2" :class="{ 'stepper-content-active': stepper == 2 }">
            <v-row>
                <v-col cols="12" md="6">
                    <date-field
                        id="date"
                        v-model="currentAccountingExpense.documentDate"
                        :label="$t('general.invoice_date')"
                        outlined
                        persistent-hint
                        dense
                        required
                        :rules="requiredRules"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <DateFieldMonthYear
                        id="fiscaledate"
                        date-picker-id="fiscaledatePicker"
                        :value="formatDate(currentAccountingExpense.date)"
                        :label="$t('addExpense.fiscale_date')"
                        outlined
                        persistent-hint
                        dense
                        required
                        :rules="requiredRules"
                        @input="changeDate"
                    />

                    <tooltip
                        :title="$t(`${getBasicinformationPath}.title`)"
                        :content="$t(`${getBasicinformationPath}.content`)"
                        ><template slot="activator">
                            <a class="px-2 small-text" text target="_blank">{{
                                $t('addExpense.what_is_fiscale_date')
                            }}</a></template
                        ></tooltip
                    >
                </v-col>

                <v-col cols="12" md="6">
                    <date-field
                        id="dueDate"
                        v-model="currentAccountingExpense.dueDate"
                        :label="$t('general.due_date')"
                        outlined
                        persistent-hint
                        dense
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <text-field
                        id="reference"
                        v-model="currentAccountingExpense.reference"
                        :label="$t('general.reference')"
                        outlined
                        persistent-hint
                        dense
                    />
                </v-col>

                <v-col cols="12" sm="12" order="10">
                    <div>
                        <base-icon-right i18n-path="tooltips.addExpense.isCreditnote">
                            <v-checkbox
                                id="isCreditnote"
                                v-model="currentAccountingExpense.isCreditnote"
                                hide-details="auto"
                                dense
                                :label="$t('expenses.is_creditnote')"
                            />
                        </base-icon-right>
                    </div>
                    <div v-if="canRecordInVatDeclaration">
                        <base-icon-right i18n-path="tooltips.addExpense.recordInVAT">
                            <v-checkbox
                                id="recordInVATDeclaration"
                                v-model="currentAccountingExpense.recordInVATDeclaration"
                                hide-details="auto"
                                dense
                                :label="$t('expenses.record_in_vat_declaration')"
                            />
                        </base-icon-right>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" order="10">
                    <v-btn :disabled="!canClickNext" color="primary" small @click="$emit('next')">{{
                        $t('general.next')
                    }}</v-btn>
                </v-col>
            </v-row>
        </v-stepper-content>
    </div>
</template>

<script>
import SuppliersTable from '@/components/DataTables/SuppliersTable';
import { mapState } from 'vuex';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import DateFieldMonthYear from '@/components/FormComponents/DateFieldMonthYear.vue';
import dayjs from 'dayjs';
import * as BusinessExpenseService from '@/businessServices/expense/expense.service';
import { isVatLiable } from '@/helpers/VATHelper';

export default {
    components: {
        DateFieldMonthYear
    },

    mixins: [formRulesMixin],

    props: {
        value: {},

        stepper: {
            required: true
        },
        canEditStepper: {
            type: Boolean,
            required: true
        },
        canClickNext: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            suppliersTable: SuppliersTable
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData']),

        currentAccountingExpense: {
            get() {
                return this.value;
            },
            set(input) {
                return this.$emit('input', input);
            }
        },
        canRecordInVatDeclaration() {
            return BusinessExpenseService.canRecordInVATDeclaration(this.currentUserData);
        },
        getBasicinformationPath() {
            if (this.isVATLiable) {
                return `tooltips.addExpense.Basicinformation_VATLiable`;
            }

            return `tooltips.addExpense.Basicinformation_NonVATLiable`;
        },
        isVATLiable() {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        }
    },

    methods: {
        formatDate() {
            if (!this.currentAccountingExpense.date) {
                return null;
            }

            return dayjs(this.currentAccountingExpense.date, 'YYYY-MM-DD').format('YYYY-MM');
        },
        changeDate(value) {
            this.currentAccountingExpense.date = `${value}-01`;
        }
    }
};
</script>

<style></style>
