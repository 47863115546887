
import { mapActions } from 'vuex';
import { sumBy } from 'lodash';
import SelectTableComponent from '@/components/SelectTableComponent.vue';
import SearchInput from '@/components/SearchInput.vue';
import ExpensecategoriesTableOnlyCategoryVue from '@/components/DataTables/ExpensecategoriesTableOnlyCategory.vue';
import { formRulesMixin } from '@/mixins/formRulesMixin';
import mixins from 'vue-typed-mixins';
import { PropOptions } from 'vue';
import { GrootboekrekeningMapType } from '../types';
import { DeepNullable } from '@/helpers/types';
import DefaultAutocomplete from '@/components/FormComponents/DefaultAutocomplete.vue';
import { Grootboekrekening, GrootboekrekeningChild } from 'dexxter-types';
import {
    isExpenseGrootboekrekening,
    isIncomeGrootboekrekening
} from '../../../grootboekrekeningBookings/domain/general';
import { GrootboekrekeningNumbersIncome } from '@/config/constants';
import { strictPostiveNumber } from '@/helpers/validationRules';

export default mixins(formRulesMixin).extend({
    components: { SelectTableComponent, SearchInput, DefaultAutocomplete },

    props: {
        existingBookings: {
            default: [],
            required: true
        } as PropOptions<GrootboekrekeningMapType[]>,

        grootboekrekeningen: {
            default: [],
            required: true
        } as PropOptions<Grootboekrekening[]>
    },

    mixins: [formRulesMixin],

    data() {
        return {
            bookings: [] as DeepNullable<GrootboekrekeningMapType>[],
            grootboekrekeningTable: ExpensecategoriesTableOnlyCategoryVue,
            totalIncome: 0,
            totalExpenses: 0,
            valid: false
        };
    },

    created() {
        this.bookings = [...this.existingBookings];
    },

    watch: {
        bookings: {
            deep: true,
            handler() {
                this.totalExpenses = sumBy(
                    this.bookings.filter(
                        (_booking) =>
                            _booking.grootboekrekeningNumber &&
                            isExpenseGrootboekrekening(_booking.grootboekrekeningNumber)
                    ),
                    (_booking) => _booking.amount ?? 0
                );
                this.totalIncome = sumBy(
                    this.bookings.filter(
                        (_booking) =>
                            _booking.grootboekrekeningNumber &&
                            isIncomeGrootboekrekening(_booking.grootboekrekeningNumber)
                    ),
                    (_booking) =>
                        _booking.grootboekrekeningNumber === GrootboekrekeningNumbersIncome.income_creditnote
                            ? _booking.amount
                                ? -_booking.amount
                                : 0
                            : _booking.amount ?? 0
                );
            }
        }
    },

    computed: {
        children(): GrootboekrekeningChild[] {
            return this.grootboekrekeningen.flatMap((_grootboekrening) => _grootboekrening.children);
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['newGrootboekrekening']),
        submit() {
            this.$emit('submit', this.bookings);
        },
        addNewItem() {
            const newItem = {
                amount: 0,
                description: null,
                grootboekrekeningNumber: null,
                name: null,
                incomeType: null
            };
            this.bookings.push({ ...newItem });
        },
        removeItem(index: number) {
            if (this.bookings.length > 0) {
                this.bookings.splice(index, 1);
            }
        },
        strictPostiveNumber
    }
});
