<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>

                <template #menu>
                    <v-btn data-action="openCreateSupplierBottomsheet" color="primary" @click="newSupplier">
                        {{ $t('supplier.add_supplier') }}
                    </v-btn>
                </template></page-header
            >
        </template>
        <template #content>
            <TableCard>
                <template #content>
                    <suppliers-table @click-row="editSupplier" />
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
import SuppliersTable from '@/components/DataTables/SuppliersTable';
import TableCard from '@/components/TableCard';
import { screenSizeMixin } from '../mixins/screenSizeMixin';
import { mapFields } from 'vuex-map-fields';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';
import { useSupplierStore } from '@/store/modules/suppliers';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        SuppliersTable,
        TableCard,
        ViewLayout,
        PageHeader
    },

    mixins: [screenSizeMixin],

    setup() {
        const { editSupplier, newSupplier } = useSupplierStore();
        return { editSupplier, newSupplier };
    },

    data() {
        return {
            userDataExportLoading: false
        };
    },

    computed: {
        ...mapFields(['selectedYear'])
    }
});
</script>
