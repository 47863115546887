import { Q1, Q4, Quarters, vatSubmissionMonths } from '@/config/constants';
import { getCurrentDateString, getQuarterOfDateAndVatSubmissions } from '@/helpers/dateHelpers';
import { IICSubmissionViewModel } from '@/services/viewmodels/general';
import { IVATSubmissionViewModel } from '@/views/VAT/models/models';
import dayjs from 'dayjs';
import _ from 'lodash';

export type QuarterObj = { bookings: Bookings; type: Quarters; closed: boolean };
type Booking = { raster: number };
export type Bookings = Booking[];

export function hasICListingData(bookings: Bookings) {
    return bookings.some((booking) => {
        return [44, 46, 48].includes(booking.raster);
    });
}

export function getAmountLowerBoundGetMoneyBackQuarter(quarter: Quarters): number {
    // TODO: Move to backend
    if (quarter === 'Q4') {
        return 50;
    } else {
        return 400;
    }
}

export function checkIfUserGetsMoneyBackFromVAT(quarter: QuarterObj) {
    // Check if user has 72 raster above the limit
    const lowerBound = getAmountLowerBoundGetMoneyBackQuarter(quarter.type);
    const raster72Value = getRasterData(quarter, 72);

    return raster72Value && raster72Value >= lowerBound;
}

function getRasterData(quarter: QuarterObj, requestedRaster: number): number {
    return (
        _.result(
            _.find(quarter.bookings, (raster) => {
                return raster.raster === requestedRaster;
            }),
            'amount'
        ) || 0
    );
}

export function canDownloadVatOrIcListing(
    currentDate: string,
    selectedQuarter: Quarters,
    selectedYear: number
): boolean {
    const currentMonth = dayjs(currentDate, 'YYYY-MM-DD').month();
    const currentYear = dayjs(currentDate, 'YYYY-MM-DD').year();

    if (currentYear > selectedYear) {
        return true;
    }

    if (currentYear < selectedYear) {
        return false;
    }

    // currentYear === selectedYear
    if (selectedQuarter === Q4) {
        return false;
    }

    const quarterVatSubmissionMonth = vatSubmissionMonths[selectedQuarter];

    return currentMonth >= quarterVatSubmissionMonth;
}

const defaultQuarter = Q1;

export function getUserFriendlyQuarter(
    selectedYear: number,
    submissions: IVATSubmissionViewModel[] | IICSubmissionViewModel[]
): Quarters {
    const currentDateString = getCurrentDateString();
    const currentYear = new Date().getFullYear();

    if (selectedYear == currentYear) {
        return getQuarterOfDateAndVatSubmissions(currentDateString, submissions);
    } else {
        return defaultQuarter;
    }
}
