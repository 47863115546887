
import { CreateCarData } from 'dexxter-types';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import mixins from 'vue-typed-mixins';
import { DeepNullable } from '@/helpers/types';
import { PropOptions } from 'vue';
import { computedWatcherResetMixin } from '@/mixins/computedWatcherResetMixin';
import { isNil } from 'lodash';
import * as businessservice from '../../car.businessservice';
import { FormFields } from '../../model';

type CarData = DeepNullable<CreateCarData>;

export default mixins(formRulesMixin, computedWatcherResetMixin).extend({
    mixins: [formRulesMixin],

    props: {
        value: { required: true } as PropOptions<CarData>,
        carId: { required: true } as PropOptions<number | null>
    },

    data() {
        return {
            fueltypes: [
                {
                    label: this.$t('cars.fuelTypes.benzine'),
                    id: 'benzine'
                },
                {
                    label: this.$t('cars.fuelTypes.diesel'),
                    id: 'diesel'
                },
                {
                    label: this.$t('cars.fuelTypes.cng'),
                    id: 'cng'
                },
                {
                    label: this.$t('cars.fuelTypes.elektriciteit'),
                    id: 'elektriciteit'
                },
                {
                    label: this.$t('cars.fuelTypes.lpg'),
                    id: 'lpg'
                },
                {
                    label: this.$t('cars.fuelTypes.waterstof'),
                    id: 'waterstof'
                },
                {
                    label: this.$t('cars.fuelTypes.hybride'),
                    id: 'hybride'
                },
                {
                    label: this.$t('cars.fuelTypes.plugin-hybride-diesel'),
                    id: 'plugin-hybride-diesel'
                },
                {
                    label: this.$t('cars.fuelTypes.plugin-hybride-benzine'),
                    id: 'plugin-hybride-benzine'
                }
            ],
            valid: true
        };
    },

    watch: {
        canShow_fiscalePK() {
            this.currentCar.fiscalePK = null;
        },
        canShow_weight() {
            this.currentCar.weight = null;
        },
        canShow_kWh() {
            this.currentCar.kWh = null;
        },
        canShow_co2() {
            this.currentCar.co2 = null;
        }
    },

    computed: {
        isCreatingNewCar(): boolean {
            return isNil(this.carId);
        },
        currentCar: {
            get(): CarData {
                return this.value;
            },
            set(value: CarData) {
                this.$emit('input', value);
            }
        },
        canShow_fiscalePK(): boolean {
            if (isNil(this.currentCar.fuelType)) {
                return true;
            }
            return businessservice.canShow_fiscalePK(this.currentCar.fuelType);
        },
        canShow_weight(): boolean {
            if (isNil(this.currentCar.fuelType)) {
                return true;
            }

            return businessservice.canShow_weight(this.currentCar.fuelType);
        },
        canShow_kWh(): boolean {
            if (isNil(this.currentCar.fuelType)) {
                return true;
            }
            return businessservice.canShow_kWh(this.currentCar.fuelType);
        },
        canShow_co2(): boolean {
            if (isNil(this.currentCar.fuelType)) {
                return true;
            }

            return businessservice.canShow_co2(this.currentCar.fuelType);
        }
    },

    methods: {
        shouldFieldBeDisabled(fieldName: FormFields): boolean {
            if (this.isCreatingNewCar) {
                return false;
            }

            return businessservice.shouldDisableFieldOnUpdate(fieldName);
        },
        handleSubmit() {
            this.$emit('submit');
        }
    }
});
