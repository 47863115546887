
import i18n from '@/i18n';
import { defineComponent } from 'vue';

import OrderedRawHtmlList from '@/components/ordered-raw-html-list/OrderedRawHtmlList.vue';

export default defineComponent({
    name: 'SubmitPersonalTaxesStep3',
    components: { OrderedRawHtmlList },
    setup(props, { emit }) {
        const steps = i18n.t('personalTaxes.submit_personal_taxes_step_2.children_steps', {
            returnObjects: true
        });

        return {
            emit,
            steps,
            i18n
        };
    }
});
