<template>
    <div>
        <template v-if="item.draft === true">
            <table-draft-status />
        </template>
        <template v-else>
            <chip v-if="hasQuotationInvoice(item)" color="green">
                {{ $t('general.invoiced') }}
            </chip>

            <table-send-status :sendtocustomer="item.sendtocustomer" @click.stop="$emit('click-send-icon')" />

            <chip v-if="item.accepted === 'accepted'" color="green">
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" small v-on="on">mdi-account-check</v-icon>
                    </template>
                    <span> {{ $t('income.quotation_accepted') }}</span>
                </v-tooltip>
            </chip>
            <chip v-if="item.accepted === 'declined'" color="red">
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" small v-on="on">mdi-account-check</v-icon>
                    </template>
                    <span> {{ $t('income.quotation_not_accepted') }}</span>
                </v-tooltip>
            </chip>
            <chip v-if="!item.accepted" color="grey">
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" small v-on="on">mdi-account-check</v-icon>
                    </template>
                    <span> {{ $t('income.quotation_not_yet_answered_accepted') }}</span>
                </v-tooltip>
            </chip>
        </template>
    </div>
</template>

<script>
import Chip from './Chip.vue';
import TableDraftStatus from './TableDraftStatus.vue';
import TableSendStatus from './TableSendStatus.vue';
export default {
    components: { Chip, TableDraftStatus, TableSendStatus },
    props: ['item'],

    methods: {
        hasQuotationInvoice(quotation) {
            return quotation.invoiceId !== null;
        }
    }
};
</script>

<style lang="scss" scoped></style>
