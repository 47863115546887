<template>
    <div data-id="ActivitiesTable" class="billed_income-table">
        <pagination-data-table
            v-if="activities.data"
            id="CustomerPaginationTable"
            ref="paginationDataTable"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="activities"
            :loading="loading"
            sort-by="date"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
        >
            <template #[`item.resourceType`]="{ item }">
                {{ $i18n.t(`activity.types.${item.resourceType}`) }}
            </template>
            <template #[`item.operation`]="{ item }">
                {{ $i18n.t(`activity.operations.${item.operation}`) }}
            </template>
            <template #[`item.createdAt`]="{ item }">
                {{ item.createdAt | formatDate }}
            </template>
        </pagination-data-table>
    </div>
</template>

<script>
// --- State ---
import { mapActions } from 'vuex';
// --- Components ---
// --- Helper ---
import { filteringMixin } from '../../../mixins/filtering';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import PaginationDataTable from '../PaginationDataTable.vue';
import { getActivitiesPagination } from '@/services/activity';

export default {
    components: {
        PaginationDataTable
    },
    mixins: [filteringMixin],

    data() {
        const sortableColumns = [];

        return {
            headers: [
                {
                    text: this.$t('activity.operation'),
                    align: 'start',
                    value: 'operation',
                    sortable: sortableColumns.includes('operation')
                },
                {
                    text: this.$t('activity.resourceType'),
                    value: 'resourceType',
                    sortable: sortableColumns.includes('resourceType')
                },
                {
                    text: this.$t('activity.createdBy'),
                    value: 'createdBy',
                    sortable: sortableColumns.includes('createdBy')
                },
                {
                    text: this.$t('activity.createdAt'),
                    value: 'createdAt',
                    sortable: sortableColumns.includes('createdAt')
                }
            ],
            loading: false,
            activities: {
                data: []
            },
            sortableColumns
        };
    },

    created() {
        this.wrapperFunction();
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        async wrapperFunction() {
            try {
                this.loading = true;
                this.activities = await getActivitiesPagination({
                    pagination: {
                        force: true,
                        currentPage: this.pagination.currentPage,
                        limit: this.pagination.limit
                    },
                    query: this.query
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
