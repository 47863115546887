<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                        <sub>
                            <tooltip :underlined="false" :i18n-path="titleI18nPath"
                                ><template slot="activator">
                                    <v-icon small> mdi-information </v-icon></template
                                ></tooltip
                            >
                        </sub>
                    </template>
                    <template #content> </template
                ></page-header>
            </template>
            <template #content>
                <TableCard v-if="!loading">
                    <template #content>
                        <v-simple-table id="personaltax-information-table" dense class="mb-5">
                            <template #default>
                                <thead>
                                    <tr class="same-width-cols">
                                        <th scope="col">
                                            {{ $t('general.year') }}
                                        </th>
                                        <th scope="col">{{ $t('general.inventoryBegin') }} (01-01)</th>
                                        <th scope="col">{{ $t('general.inventoryEnd') }} (31-12)</th>
                                        <th scope="col">
                                            {{ $t('general.actions') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="same-width-cols">
                                        <td>{{ taxes.year }}</td>
                                        <td>€{{ taxes.inventoryBegin | numericFormat }}</td>
                                        <td>€{{ taxes.inventoryEnd | numericFormat }}</td>
                                        <td>
                                            <v-btn icon color="orange" @click="editInventory">
                                                <v-icon small> mdi-pencil </v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody></template
                            >
                        </v-simple-table>
                    </template>
                </TableCard>
                <loader v-if="loading" />
            </template>
        </view-layout>

        <edit-inventory
            v-if="taxes"
            :inventory-begin="taxes.inventoryBegin"
            :inventory-end="taxes.inventoryEnd"
            :year="taxes.year"
            @updated="handleInventoryUpdate"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
// --- Components ---
import Loader from '@/components/Loader';
import EditInventory from '@/components/BottomSheets/EditInventory.vue';
import TableCard from '@/components/TableCard';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';
import { vatStatuses } from '@/config/constants';

export default {
    components: { EditInventory, Loader, TableCard, ViewLayout, PageHeader },

    data() {
        return {
            currentTab: 0,
            loading: false,
            taxes: null
        };
    },

    created() {
        this.fetchTaxes();
    },

    computed: {
        ...mapState(['selectedYear']),
        ...mapState('auth', ['currentUserData']),
        vatLiable() {
            return this.currentUserData.settings.vatLiable === vatStatuses.VATLIABLE;
        },
        titleI18nPath() {
            if (this.vatLiable) {
                return 'tooltips.inventory.title.vatLiable';
            } else {
                return 'tooltips.inventory.title.nonVatLiable';
            }
        }
    },

    methods: {
        ...mapActions('taxes', ['getTaxes']),
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('bottomSheets', ['openEditInventoryModal']),
        fetchTaxes() {
            this.startLoading();
            this.loading = true;
            this.getTaxes({
                year: this.selectedYear,
                force: true
            })
                .then((taxes) => {
                    this.taxes = taxes;
                })
                .catch((error) => {
                    apiErrorAndDisplay.call(this, error);
                })
                .finally(() => {
                    this.stopLoading();
                    this.loading = false;
                });
        },
        numericFormatEuroAndSign(value) {
            let sign = '+';
            // Make sure the value is a number.
            value = parseFloat(value);
            // Make positive number for proper string parsing.
            if (value < 0) {
                value *= -1;
                sign = '-';
            }
            return `${sign} €${this.$options.filters.numericFormat(value)}`;
        },
        editInventory() {
            this.openEditInventoryModal();
        },
        handleInventoryUpdate() {
            this.fetchTaxes();
        }
    }
};
</script>
