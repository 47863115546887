import { emptyOrNil, removeFromObject } from '@/helpers/general';
import { cloneDeep } from 'lodash';
import { ref } from 'vue';
import { OptionsType } from '../components/DataTables/PaginationDataTableV2/PaginationDataTableV2.types';
import { reportError } from '../helpers/logging';
export const tableName = Symbol('TableNameFiltering');
export const itemsPerPageOptionsDefault = [10, 20, 50];
export const standardLimit = 10;

const generalTableKey = 'GeneralTableSettings';

const defaultSettings = {
    limit: standardLimit
};

export type TableConfig = Record<string, { limit: number }>;

/**
 * @description
 * A url query param can be null when not prividing a value like https://dexxter.be?test. In this case, the query object will look like this: `{ test: null }`.
 *
 * An array is possible when providing multiple same-name keys: https://dexxter.be?test=hello&test=world&test. In this case, the query object will look like this:`{ test: ['hello', 'world', null] }`
 *
 * @example
 * { test: ['hello', 'world', null] }
 * { test: null }
 * { test: 'hello' }
 * {}
 */
export type UrlQueryType = Record<string, string | null | (string | null)[]>;

export const useFilteringV2 = (tableName?: string) => {
    const itemsPerPageOptions = ref(itemsPerPageOptionsDefault);
    const options = ref({
        page: 1,
        itemsPerPage: 10,
        sortBy: [] as string[],
        sortDesc: [] as boolean[],
        groupBy: [] as string[],
        groupDesc: [] as boolean[],
        multiSort: false,
        mustSort: false
    });

    const createItemToBeAddedToUrlQuery = (
        query: UrlQueryType,
        fieldName: string,
        newValue: string | null
    ): UrlQueryType => {
        return removeFromObject(
            {
                ...query,
                [fieldName]: newValue as string
            },
            (v) => !emptyOrNil(v)
        );
    };

    const optionsToUrlQuery = (query: UrlQueryType, options: OptionsType): UrlQueryType => {
        return removeFromObject(
            {
                ...query,
                page: options.page.toString(),
                itemsPerPage: options.itemsPerPage.toString(),
                sortBy: options.sortBy.toString(),
                sortDesc: options.sortDesc.toString()
            },
            (v) => !emptyOrNil(v)
        );
    };

    const initializeQueryObjectFromUrlQuery = <T extends Record<string, any>>(
        query: UrlQueryType,
        filteringKeys: (keyof T)[],
        defaultQuery: { [key in keyof T]?: any }
    ): UrlQueryType => {
        return Object.keys(query).reduce((prev, currentQueryKey) => {
            // Remove
            if (!filteringKeys.includes(currentQueryKey)) {
                return prev;
            }

            // Remove the nil values and the empty strings
            if (query[currentQueryKey] == null || query[currentQueryKey] === '') {
                return prev;
            }

            return {
                ...prev,
                [currentQueryKey]: query[currentQueryKey]
            };
        }, defaultQuery);
    };

    const initializeOptionsObjectFromUrlQuery = (
        query: UrlQueryType,
        options: OptionsType,
        { page: defaultPage, itemsPerPage: defaultItemsPerPage } = { page: 1, itemsPerPage: 10 }
    ): OptionsType => {
        let sortBy: string[];
        let page = defaultPage;
        let itemsPerPage = defaultItemsPerPage;
        let sortDesc: boolean[] = [];

        if (typeof query.sortBy === 'string') {
            sortBy = [query.sortBy];
        } else if (query.sortBy == null) {
            sortBy = [];
        } else {
            sortBy = query.sortBy.filter((_value) => _value != null) as string[];
        }

        const getNumberOfQueryParamOrDefault = (queryValue: string, defaultValue: number): number =>
            Number.isNaN(parseInt(queryValue)) ? defaultValue : parseInt(queryValue);

        if (typeof query.page === 'string') {
            page = getNumberOfQueryParamOrDefault(query.page, defaultPage);
        }

        if (typeof query.itemsPerPage === 'string') {
            itemsPerPage = getNumberOfQueryParamOrDefault(query.itemsPerPage, defaultItemsPerPage);
        }

        if ((typeof query.sortDesc === 'string' && query.sortDesc === 'true') || query.sortDesc === 'false') {
            sortDesc = [query.sortDesc === 'true'];
        }

        return {
            ...options,
            page,
            itemsPerPage,
            sortBy,
            sortDesc
        };
    };

    const getGeneralTableConfig = (): TableConfig => {
        try {
            const limits = localStorage.getItem(generalTableKey);
            return limits ? JSON.parse(limits) : {};
        } catch (e) {
            reportError(e);
            localStorage.removeItem(generalTableKey);
            return {};
        }
    };
    const setGeneralTableSettings = (config: TableConfig): void => {
        localStorage.setItem(generalTableKey, JSON.stringify(config));
    };
    const fetchTableItemsPerPageFromLocalStorage = (): number => {
        const generalTableSettings = getGeneralTableConfig();

        if (generalTableSettings[getTableName()]) {
            return generalTableSettings[getTableName()]?.limit ?? standardLimit;
        }

        return standardLimit;
    };
    const getUpdatedTableSettingsConfig = (
        generalTableSettings: TableConfig,
        { newLimit }: { newLimit: number }
    ): TableConfig => {
        const cp = cloneDeep(generalTableSettings);

        if (generalTableSettings[getTableName()]) {
            cp[getTableName()] = {
                ...cp[getTableName()],
                limit: newLimit
            };
        } else {
            cp[getTableName()] = {
                ...defaultSettings,
                limit: newLimit
            };
        }

        return cp;
    };
    const getTableName = (): string => {
        return tableName ?? 'defaultTable';
    };

    const updateTableItemsPerPageInLocalStorage = (newLimit: number): void => {
        const generalTableSettings = getGeneralTableConfig();

        const newTableConfig = getUpdatedTableSettingsConfig(generalTableSettings, {
            newLimit
        });

        setGeneralTableSettings(newTableConfig);
    };

    return {
        fetchTableItemsPerPageFromLocalStorage,
        updateTableItemsPerPageInLocalStorage,
        options,
        itemsPerPageOptions,
        createItemToBeAddedToUrlQuery,
        optionsToUrlQuery,
        initializeQueryObjectFromUrlQuery,
        initializeOptionsObjectFromUrlQuery
    };
};
