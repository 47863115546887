<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }} <v-chip class="ma-2" color="primary" small> Nieuw </v-chip>
                    </template>
                </page-header>
            </template>
            <template #content>
                <div v-if="!load.loadSocialcontributions">
                    <socialcontributions-overview-banner
                        v-if="canShowCalculation"
                        :socialcontribution-information="socialcontributionInformation"
                    />

                    <widget class="mb-3">
                        <template #title>
                            <base-icon-right small i18n-path="tooltips.settings.calculations.statuten">
                                {{
                                    $t('personalTaxes.general_information.change_statuten', {
                                        year: selectedYear
                                    })
                                }}
                            </base-icon-right>
                        </template>
                        <template #content>
                            <change-statuten-form
                                :year="selectedYear"
                                dense
                                @changed="loadSocialcontributionsStatement(selectedYear)"
                            />
                        </template>
                    </widget>

                    <v-row v-if="canShowCalculation">
                        <v-col cols="12">
                            <socialcontributions-overview-table
                                v-if="socialcontributionInformation"
                                :socialcontribution-information="socialcontributionInformation"
                            />
                        </v-col>
                    </v-row>
                </div>
            </template>
        </view-layout>

        <tooltip-popup
            id="socialContributionInformationPopup"
            v-model="socialContributionInformationPopupModal"
            :title="socialContributionInformationPopupTitle"
            :content="socialContributionInformationPopupText"
        />

        <loader v-if="loading" />
    </div>
</template>

<script>
import ChangeStatutenForm from '@/components/Forms/ChangeStatutenForm.vue';
import Loader from '@/components/Loader.vue';
import PageHeader from '@/components/PageHeader.vue';
import TooltipPopup from '@/components/TooltipPopup.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import { InformationpopupTypes, actionNames, popupNotificationNames } from '@/config/constants';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { informationpopupMixin } from '@/mixins/informationpopupMixin';
import { analyticsIdentify } from '@/services/analytics';
import { sendGetAllSocialsecretariatsRequest } from '@/services/socialsecretariat';
import { updateSocialcontributionsExemptStatus } from '@/services/usersettings';
import SocialcontributionsOverviewTable from '@/views/socialcontributions/components/SocialcontributionsOverviewTable.vue';
import { mapActions, mapState } from 'vuex';
import { sendGetTaxesRequest } from '../../../services/tax';
import SocialcontributionsOverviewBanner from '../components/SocialcontributionsOverviewBanner.vue';

export default {
    components: {
        ChangeStatutenForm,
        SocialcontributionsOverviewTable,
        ViewLayout,
        PageHeader,
        Loader,
        TooltipPopup,
        SocialcontributionsOverviewBanner
    },

    mixins: [informationpopupMixin],
    data() {
        return {
            canShowCalculation: false,
            socialContributionInformationPopupModal: false,
            showSocialcontributionsExemptStatusDialog: false,
            valid: false,
            load: {
                loadSocialcontributions: false,
                isChangingSocialsecretriat: false,
                isSettingSocialcontributionsExemptStatus: false
            },
            socialcontributionInformation: null,
            actionPopupToFunctionMap: {
                [actionNames.SOCIALCONTRIBUTIONS_SELECTEXEMPTSTATUS]: this.showSelectSocialcontributionsExemptStatus
            }
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapState(['selectedYear']),
        informationpopupPriority() {
            return [
                popupNotificationNames.SOCIALCONTRIBUTIONS_OPENFIRSTTIME,
                actionNames.SOCIALCONTRIBUTIONS_SELECTEXEMPTSTATUS
            ];
        },
        loading() {
            return Object.values(this.load).some((loading) => loading);
        },
        canShowExemptDialog() {
            if (!this.showSocialcontributionsExemptStatusDialog) {
                return false;
            }

            if (!this.socialcontributionInformation) {
                return false;
            }

            return true;
        },
        canShowChangeExemptDialog() {
            if (!this.socialcontributionInformation) {
                return false;
            }

            return true;
        },
        getSocialContributionsUpperLimit() {
            if (!this.socialcontributionInformation) {
                return null;
            }
            return this.socialcontributionInformation.socialContributionsSettings.socialContributionsUpperLimit;
        },
        getSocialContributionsExemptStatus() {
            if (!this.socialcontributionInformation) {
                return null;
            }
            return this.socialcontributionInformation.socialContributionsSettings.exempt;
        },
        socialContributionInformationPopupTitle() {
            if (this.getSocialContributionsExemptStatus) {
                return this.$t(
                    'socialcontributionsOverview.popups.socialcontributionSummary.socialcontributionExempt.title'
                );
            }
            return this.$t(
                'socialcontributionsOverview.popups.socialcontributionSummary.socialcontributionObligatory.title'
            );
        },
        socialContributionInformationPopupText() {
            if (this.getSocialContributionsExemptStatus) {
                return this.$t(
                    'socialcontributionsOverview.popups.socialcontributionSummary.socialcontributionExempt.text',
                    {
                        socialContributionsUpperLimit: this.getSocialContributionsUpperLimit
                    }
                );
            }
            return this.$t(
                'socialcontributionsOverview.popups.socialcontributionSummary.socialcontributionObligatory.text'
            );
        }
    },

    watch: {
        currentInformationpopup: {
            immediate: true,
            handler: 'showActionPopup'
        }
    },

    async created() {
        this.loadSocialcontributionsStatement(this.selectedYear);
    },

    methods: {
        ...mapActions('informationpopup', ['markInformationpopupByIDAsReadAndRemoveFromPopupList']),
        ...mapActions('auth', ['setSocialSecretariatPercentage']),
        ...mapActions('taxes', ['getSocialcontributionsStatement']),
        showSelectSocialcontributionsExemptStatus() {
            this.showSocialcontributionsExemptStatusDialog = true;
        },
        showActionPopup(newCurrentInformationpopup) {
            if (!newCurrentInformationpopup) {
                return;
            }

            if (newCurrentInformationpopup.type !== InformationpopupTypes.ACTION) {
                return;
            }

            this.actionPopupToFunctionMap[newCurrentInformationpopup.name]();
        },
        async loadSocialcontributionsStatement(year) {
            const userTax = await sendGetTaxesRequest(year);

            if (
                userTax.statuutQ1 === null ||
                userTax.statuutQ2 === null ||
                userTax.statuutQ3 === null ||
                userTax.statuutQ4 === null
            ) {
                this.canShowCalculation = false;
            } else {
                try {
                    this.load.loadSocialcontributions = true;
                    this.$set(
                        this,
                        'socialcontributionInformation',
                        await this.getSocialcontributionsStatement({ year })
                    );

                    this.canShowCalculation = true;
                } catch (e) {
                    apiErrorAndDisplay.call(this, e);
                    this.$router.push({
                        name: 'dashboard'
                    });
                } finally {
                    this.load.loadSocialcontributions = false;
                }
            }
        },
        async changeSocialcontributionsExemptStatusAndCloseModal(selectedStatus) {
            this.showSocialcontributionsExemptStatusDialog = false;
            this.changeSocialcontributionsExemptStatus(selectedStatus);

            // Reload data
            this.loadSocialcontributionsStatement(this.selectedYear);

            // Show summary dialog
            this.socialContributionInformationPopupModal = true;

            // Reset notification in backend
            await this.markInformationpopupByIDAsReadAndRemoveFromPopupList(this.currentInformationpopup.id);
        },
        async changeSocialcontributionsExemptStatus(selectedStatus) {
            try {
                this.load.isSettingSocialcontributionsExemptStatus = true;
                await updateSocialcontributionsExemptStatus(selectedStatus.exempt, this.selectedYear);

                notify.call(this, {
                    title: this.$t(
                        'socialcontributionsOverview.notifications.succesfuly_updated_socialcontributionsexemptstatus.title'
                    ),
                    text: this.$t(
                        'socialcontributionsOverview.notifications.succesfuly_updated_socialcontributionsexemptstatus.text'
                    )
                });

                await this.loadSocialcontributionsStatement(this.selectedYear);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.load.isSettingSocialcontributionsExemptStatus = false;
            }
        },
        async changeSocialsecretariat(socialsecretariatId) {
            this.load.isChangingSocialsecretriat = true;
            try {
                await this.setSocialSecretariatPercentage({
                    socialsecretariatId
                });

                this.$notify({
                    title: this.$t('settings.social_secretariat_fee_percentage_updated'),
                    text: this.$t('settings.social_secretariat_fee_percentage_updated_long'),
                    type: 'success'
                });

                await this.loadSocialcontributionsStatement(this.selectedYear);

                const socialSecretariats = await sendGetAllSocialsecretariatsRequest();

                analyticsIdentify(this.currentUserData.id, {
                    Social_Insurance_Fund: socialSecretariats
                        .find((x) => x.id === socialsecretariatId)
                        ?.name.toLowerCase()
                });

                this.showChangeSocialsecretariatScreen = false;
            } catch (e) {
                this.$notify({
                    title: this.$t('general.error'),
                    text: this.$t('errors.user/' + e.err.message),
                    type: 'error'
                });
            } finally {
                this.load.isChangingSocialsecretriat = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
#socialcontributions-table table {
    tr td:first-child {
        width: 75%;
    }
    tr td:last-child {
        width: 25%;
    }

    tr.child td:first-child {
        padding-left: 50px;
    }
    tr.child td {
        font-size: 0.75em;
    }
}
</style>
