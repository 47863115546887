<template>
    <v-simple-table dense>
        <template #default>
            <thead>
                <tr>
                    <th scope="col" class="text-left">
                        {{ $t('general.year') }}
                    </th>
                    <th scope="col" class="text-right">
                        {{ $t('general.amount_money') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="year in years" :key="year">
                    <td class="text-left">
                        {{ year }}
                    </td>
                    <td class="text-right">€{{ annualAmountEuro[year] | numericFormat }}</td>
                </tr>
                <tr v-if="years.length === 0">
                    <td colspan="2">{{ $t('general.table_no_data') }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    props: {
        data: {
            required: true
        }
    },

    data() {
        return {};
    },

    computed: {
        years() {
            return Object.keys(this.data).sort();
        },
        annualAmountEuro() {
            return this.data;
        }
    }
};
</script>

<style lang="scss" scoped></style>
