import Vue from 'vue';
import { RenderModalProps } from '@/store/modules/modal';

export const modalMixin = Vue.extend({
    data() {
        return {
            isModalOpen: false as boolean,
            activeComponentId: 0 as number,
            nextTransition: 'slide-to-next' as string,
            modalComponents: [] as Vue[],
            modalComponentPropsArray: [] as Record<string, unknown>[],
            modalComponentListenersArray: [] as Record<string, () => void>[]
        };
    },
    methods: {
        setModalContent({ component, props, listeners }: RenderModalProps): void {
            this.modalComponents = [component];
            this.modalComponentPropsArray = [props ?? {}];
            this.modalComponentListenersArray = [listeners ?? {}];
            this.activeComponentId = 0;
            this.isModalOpen = true;
        },
        goToNextModal() {
            this.nextTransition = 'slide-to-next';
            this.activeComponentId++;
        },
        addAndGoToNextModal({ component, props, listeners }: RenderModalProps): void {
            this.modalComponents.push(component);
            this.modalComponentPropsArray.push(props ?? {});
            this.modalComponentListenersArray.push(listeners ?? {});
            this.goToNextModal();
        },
        goToPreviousModal() {
            this.nextTransition = 'slide-to-previous';
            this.activeComponentId--;
        },
        goToPreviousAndRemove() {
            this.goToPreviousModal();
            this.modalComponents.pop();
            this.modalComponentPropsArray.pop();
            this.modalComponentListenersArray.pop();
        }
    }
});
