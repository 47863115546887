<template>
    <div>
        <chip v-if="expensePayedInTime(item) === false" color="red">
            {{ $t('income.invoice_not_payed_in_time') }}
        </chip>
        <table-paid-status v-else :paid="item.payed" />

        <chip v-if="item.isCreditnote" color="orange">
            {{ $t('general.creditnote') }}
        </chip>

        <chip v-if="item.isDepreciation" color="orange">
            {{ $t('general.depreciation') }}
        </chip>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import Chip from './Chip.vue';
import TablePaidStatus from './TablePaidStatus.vue';
export default {
    components: { Chip, TablePaidStatus },
    props: ['item'],

    methods: {
        expensePayedInTime(expense) {
            if (!expense.dueDate || expense.payed) {
                return true;
            }

            const dueDate = dayjs.utc(expense.dueDate, 'YYYY-MM-DD');
            const today = dayjs.utc();

            return today.isSameOrBefore(dueDate, 'day');
        }
    }
};
</script>

<style lang="scss" scoped></style>
