<template>
    <div>
        <v-simple-table dense>
            <template #default>
                <thead>
                    <tr>
                        <th scope="col">{{ $t('raster.raster') }}</th>
                        <th scope="col">{{ $t('general.amount_money') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="rasterHeading in rasterHeadings">
                        <tr :key="rasterHeading.label">
                            <th scope="col" colspan="2">{{ rasterHeading.label }}</th>
                        </tr>
                        <template v-for="raster in rasterHeading.rasters">
                            <tr :key="raster">
                                <td>
                                    <tooltip
                                        :title="`${$t('raster.raster')} ${raster}`"
                                        :content="$t(`tooltips.btwAangifte.btwAangifte.rooster${raster}.content`)"
                                        ><template slot="activator">{{ rasterName(raster) }}</template></tooltip
                                    >
                                </td>
                                <td>
                                    <span v-if="rasterHeading.unclickable">{{
                                        getRasterData(parseInt(raster)) | numericFormat | addEuroSign
                                    }}</span>
                                    <a v-else class="link-decoration" @click="openBookings(parseInt(raster))">{{
                                        getRasterData(parseInt(raster)) | numericFormat | addEuroSign
                                    }}</a>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </template>
        </v-simple-table>
        <loader v-if="loading" />
        <view-raster-bookings />
    </div>
</template>

<script>
// --- Components ---
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ViewRasterBookings from './ViewRasterBookings.vue';
import Loader from '@/components/Loader';

import _ from 'lodash';
import Tooltip from '@/components/Tooltip.vue';

export default {
    components: {
        Loader,
        ViewRasterBookings,
        Tooltip
    },
    props: {
        vatData: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },

    data() {
        return {
            loading: false,
            selectedQuarter: null,
            rasterHeadings: [
                {
                    label: 'Verkopen (exclusief btw)',
                    rasters: [0, 1, 2, 3]
                },
                {
                    label: 'Verkopen (medecontractant)',
                    rasters: [45]
                },
                {
                    label: 'Verkopen (bijzondere handelingen en positieve correcties)',
                    rasters: [44, 46, 47]
                },
                {
                    label: "Verkopen (creditnota's en negatieve correcties)",
                    rasters: [48, 49]
                },
                {
                    label: 'Detail aankopen (exclusief btw)',
                    rasters: [81, 82, 83]
                },
                {
                    label: "Creditnota's en negatieve correcties",
                    rasters: [84, 85]
                },
                {
                    label: 'Bijzondere inkomende handelingen',
                    rasters: [86, 87, 88]
                },
                {
                    label: 'Te betalen btw',
                    rasters: [54, 55, 56, 57, 61, 63]
                },
                {
                    label: 'Aftrekbare btw',
                    rasters: [59, 62, 64]
                },
                {
                    label: 'Saldo',
                    rasters: [71, 72],
                    unclickable: true
                }
            ]
        };
    },

    computed: {
        ...mapFields('bottomSheets', ['isViewingCurrentRasterBookingsOpen', 'currentRasterWithBookings'])
    },

    methods: {
        ...mapActions('bookings', ['getBookingsFromQuarterAndRaster']),
        rasterName(name) {
            return `${this.$t('raster.raster')} [${name}]`;
        },
        openBookings(raster) {
            this.loading = true;
            this.getBookingsFromQuarterAndRaster({
                quarter: this.vatData.type,
                raster
            })
                .then((data) => {
                    this.isViewingCurrentRasterBookingsOpen = true;
                    this.currentRasterWithBookings = {
                        data,
                        raster
                    };
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getRasterData(requestedRaster) {
            return (
                _.result(
                    _.find(this.vatData.bookings, (raster) => {
                        return raster.raster === requestedRaster;
                    }),
                    'amount'
                ) || 0
            );
        }
    }
};
</script>
