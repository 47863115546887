<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ title }}
                        <sub>
                            <tooltip :underlined="false" i18n-path="tooltips.createDepreciationfiche.title"
                                ><template slot="activator">
                                    <v-icon small> mdi-information </v-icon></template
                                ></tooltip
                            >
                        </sub>
                    </template>
                    <template #menu>
                        <submit-button v-if="canShowNavigateToExpenseButton" @click="navigateToExpense">
                            {{ $t('depreciation.navigate_to_expense') }}
                        </submit-button>
                        <v-chip v-if="canShowNavigateNoDocAvailable">
                            {{ $t('depreciation.no_document_available') }}
                        </v-chip>
                    </template>
                </page-header>
            </template>
            <template #content>
                <v-card v-if="depreciationfiche" outlined class="pb-0">
                    <v-card-text>
                        <v-form
                            :id="isCreatingNew ? 'create-depreciationfiche-form' : 'edit-depreciationfiche-form'"
                            ref="form"
                            v-model="valid"
                        >
                            <v-row class="ma-0">
                                <v-col v-if="isDepreciationSold" cols="12" class="mb-4">
                                    <div class="d-flex justify-space-between">
                                        <div>
                                            <h2 class="mb-2">
                                                <span v-if="depreciationfiche.invoice">
                                                    {{
                                                        $t('depreciation.depreciation_sold_for_amount', {
                                                            amount: $options.filters.numericFormat(
                                                                depreciationfiche.invoice.amountSold
                                                            )
                                                        })
                                                    }}</span
                                                >
                                            </h2>

                                            <p>
                                                {{ $t('depreciation.depreciation_sold_edit_text') }}
                                            </p>
                                        </div>
                                        <submit-button @click="navigateToInvoice(depreciationfiche.invoiceId)">
                                            {{ $t('depreciation.check_document') }}
                                        </submit-button>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6" data-select-container="type">
                                    <v-select
                                        v-model="depreciationfiche.category.id"
                                        outlined
                                        dense
                                        :items="depreciationTypes"
                                        :label="$t('general.type')"
                                        item-text="label"
                                        item-value="value"
                                        clearable
                                        hide-details
                                        @input="handleCategoryChange"
                                    />
                                </v-col>
                                <v-col cols="12" md="3"
                                    ><number-field
                                        v-model="depreciationfiche.totalDepreciationAmount"
                                        data-form="amountEuroExclVAT"
                                        :readonly="isDepreciationSold"
                                        :disabled="isDepreciationSold"
                                        :label="labelTotalDepreciationAmount"
                                        prefix="€"
                                        required
                                        :rules="numberRulesGreaterThanZero"
                                    />
                                </v-col>
                                <v-col cols="12" md="3"
                                    ><number-field
                                        v-model="depreciationfiche.numberOfYears"
                                        data-form="totalYears"
                                        :readonly="isDepreciationSold"
                                        :disabled="isDepreciationSold"
                                        :label="$t('depreciation.number_of_years')"
                                        value-as-integer
                                        required
                                        :rules="requiredRules"
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <date-field
                                        v-model="depreciationfiche.dateStart"
                                        data-form="dateStart"
                                        :readonly="isDepreciationSold"
                                        :disabled="isDepreciationSold"
                                        :label="$t('depreciation.date_start')"
                                        outlined
                                        dense
                                        clearable
                                        persistent-hint
                                        required
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <date-field
                                        v-model="depreciationfiche.purchaseDate"
                                        data-form="purchaseDate"
                                        :readonly="isDepreciationSold"
                                        :disabled="isDepreciationSold"
                                        :label="$t('depreciation.purchase_date')"
                                        outlined
                                        dense
                                        clearable
                                        persistent-hint
                                        required
                                    />
                                </v-col>
                                <v-col v-if="canShowSelectedCar" cols="12" md="6">
                                    <select-car v-model="depreciationfiche.carId" />
                                </v-col>
                                <v-col cols="12" md="12" class="pt-0">
                                    <depreciation-preview-table
                                        v-if="annualAmountEuro(depreciationfiche) !== null"
                                        :data="annualAmountEuro(depreciationfiche)"
                                        :number-of-years-input="depreciationfiche.numberOfYears"
                                        :annual-amount-euro-input="annualAmountEuro(depreciationfiche)"
                                        :start-date-input="depreciationfiche.dateStart"
                                    />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <text-area
                                        v-model="depreciationfiche.description"
                                        data-form="description"
                                        :label="$t('general.description')"
                                        outlined
                                        persistent-hint
                                        required
                                        auto-grow
                                        :rules="minRules(3)"
                                    />
                                </v-col>

                                <v-col cols="12" md="12">
                                    <submit-button
                                        data-action="submit"
                                        :disabled="!valid"
                                        @click="
                                            isCreatingNew
                                                ? runMiddleware(createMiddleware)
                                                : runMiddleware(updateMiddleware)
                                        "
                                    >
                                        {{ isCreatingNew ? $t('general.create') : $t('general.save') }}
                                    </submit-button>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </template>
        </view-layout>
        <loader v-if="loading" />

        <component
            :is="dialogAttributes.component"
            v-model="showDialog"
            v-bind="dialogAttributes.attributes"
            v-on="dialogAttributes.actions"
        />
    </div>
</template>

<script>
import Chip from '@/components/Chip.vue';
import DepreciationPreviewTable from '@/components/DataTables/DepreciationPreviewTable';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import NumberField from '@/components/FormComponents/NumberField.vue';
import Loader from '@/components/Loader';
import PageHeader from '@/components/PageHeader';
import SubmitButton from '@/components/SubmitButton.vue';
import ViewLayout from '@/components/ViewLayout';
import { depreciationStatus } from '@/config/constants';
import { isVatLiable } from '@/helpers/VATHelper';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { calculateDepreciationMixin } from '@/mixins/calculateDepreciationMixin';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import { getAllDepreciationfichecategoriesRequest } from '@/services/depreciationfichecategories';
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';
import { newDepreciationfiche } from '../models/depreciation';
import SelectCar from './addAccountingExpense/components/select-car.vue';

export default {
    components: {
        Loader,
        SubmitButton,
        DepreciationPreviewTable,
        ViewLayout,
        PageHeader,
        NumberField,
        Chip,
        SelectCar
    },

    mixins: [formRulesMixin, calculateDepreciationMixin, middlewareMixin],

    data() {
        return {
            depreciationfichecategories: [],
            valid: true,
            isCreatingNew: false,
            loading: false,
            depreciationfiche: newDepreciationfiche(),
            createMiddleware: [this.depreciationShouldBeBookUnderExpenseMiddleware, this.handleSubmit],
            updateMiddleware: [this.depreciationShouldBeBookUnderExpenseMiddleware, this.handleSubmit]
        };
    },

    computed: {
        ...mapState(['selectedYear']),
        ...mapState('auth', ['currentUserData']),
        canShowSelectedCar() {
            const id = this.depreciationfiche.category.id;

            const category = this.depreciationfichecategories.find((_x) => _x.id === id);
            if (!category) {
                return false;
            }

            return category.name === 'ELECTIRC_CAR';
        },
        canShowNavigateNoDocAvailable() {
            if (this.isCreatingNew) {
                return false;
            } else {
                if (!this.depreciationfiche) {
                    return false;
                }

                if (this.depreciationfiche.expenseId) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        title() {
            if (this.isCreatingNew) {
                return this.$t(`depreciation.title.add_historical_depreciation`);
            } else {
                if (!this.depreciationfiche) {
                    return '';
                }

                if (this.depreciationfiche.expenseId) {
                    return this.$t(`depreciation.title.update_depreciation`);
                } else {
                    return this.$t(`depreciation.title.update_historical_depreciation`);
                }
            }
        },
        canShowNavigateToExpenseButton() {
            if (!this.depreciationfiche) {
                return false;
            }
            return Boolean(this.depreciationfiche.expenseId);
        },
        canShowDepreciationPreviewTable() {
            return this.depreciationfiche.totalDepreciationAmount > 0;
        },
        depreciationTypes() {
            return this.depreciationfichecategories.map((_type) => {
                return {
                    value: _type.id,
                    label: this.$t(`depreciation.types.${_type.name}`)
                };
            });
        },
        isDepreciationSold() {
            return this.depreciationfiche.status === depreciationStatus.SOLD;
        },
        labelTotalDepreciationAmount() {
            if (isVatLiable(this.currentUserData.settings.vatLiable)) {
                return this.$t('depreciation.totalDepreciationAmountExclVAT');
            }

            return this.$t('depreciation.totalDepreciationAmountInclVAT');
        }
    },

    async created() {
        this.startLoading();
        try {
            this.depreciationfichecategories = await getAllDepreciationfichecategoriesRequest();

            if (this.$route.params.id) {
                this.isCreatingNew = false;
                this.depreciationfiche = await this.getDepreciationficheByID(this.$route.params.id);
            } else {
                this.isCreatingNew = true;
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
        }
    },

    methods: {
        ...mapActions('depreciationfiches', [
            'updateDepreciationfiche',
            'createDepreciationfiche',
            'newDepreciationficheEvent',
            'getDepreciationficheByID'
        ]),
        ...mapActions(['startLoading', 'stopLoading']),
        handleCategoryChange(newId) {
            if (newId) {
                const category = this.depreciationfichecategories.find((_x) => _x.id === newId);
                if (!category) {
                    return false;
                }

                if (category.name !== 'ELECTIRC_CAR') {
                    this.depreciationfiche.carId = null;
                }
            } else {
                this.depreciationfiche.carId = null;
            }
        },
        navigateToExpense() {
            this.$router.push({
                name: 'edit-accounting-expense',
                params: {
                    id: this.depreciationfiche.expenseId
                }
            });
        },
        depreciationShouldBeBookUnderExpenseMiddleware(next) {
            if (dayjs(this.depreciationfiche.dateStart, 'YYYY-MM-DD', true).year() != this.selectedYear) {
                next();
                return;
            }

            this.setDialogAttributes(
                {
                    component: ConfirmationDialog,
                    dataId: 'depreciationShouldBeBookUnderExpense',
                    title: this.$t('depreciation.edit.middleware.maybeBookedAsANormalExpense.title'),
                    message: this.$t('depreciation.edit.middleware.maybeBookedAsANormalExpense.content'),
                    positiveColor: 'orange'
                },
                {
                    clickPositive: this.clickPositive.bind(this, next),
                    clickNegative: this.clickNegative.bind(this)
                }
            );

            this.showDialog = true;
        },
        async handleSubmit() {
            try {
                this.startLoading();

                if (this.isCreatingNew) {
                    await this.createDepreciationfiche({
                        depreciationfiche: {
                            ...this.depreciationfiche,
                            categoryId: this.depreciationfiche.category.id
                        }
                    });
                } else {
                    await this.updateDepreciationfiche({
                        depreciationfiche: {
                            ...this.depreciationfiche,
                            categoryId: this.depreciationfiche.category.id
                        }
                    });
                }

                notify.call(this, {
                    title: this.$t('settings.succesfuly_saved'),
                    text: this.$t('settings.succesfuly_saved'),
                    type: 'success'
                });

                this.newDepreciationficheEvent();

                this.$router.push({
                    name: 'depreciations'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.stopLoading();
            }
        },
        navigateToInvoice(invoiceID) {
            this.$router.push({
                name: 'invoicable-view',
                params: { type: 'invoice', id: invoiceID }
            });
        },
        annualAmountEuro(depreciationfiche) {
            if (!depreciationfiche) {
                return null;
            }

            if (
                !depreciationfiche.dateStart ||
                dayjs(depreciationfiche.dateStart, 'YYYY-MM-DD', true).isValid() === false
            ) {
                return null;
            }

            if (!depreciationfiche.numberOfYears) {
                return null;
            }

            if (!depreciationfiche.totalDepreciationAmount) {
                return null;
            }

            return this.calculateAnnualDepreciationAmount(
                depreciationfiche.dateStart,
                depreciationfiche.numberOfYears,
                depreciationfiche.totalDepreciationAmount,
                true
            );
        }
    }
};
</script>
