<template>
    <div>
        <div class="Content">
            <div class="Title">
                <h5 class="mr-auto">
                    <a :href="link.link" target="_blank">{{ link.name }}</a>
                </h5>
            </div>
            <div class="Text mb-2">{{ link.description }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        link: {
            type: Object
        }
    },

    methods: {
        openLink(link) {
            window.open(link, '_blank');
        }
    }
};
</script>

<style lang="scss" scoped>
.Content {
    padding: 10px;
}
</style>
