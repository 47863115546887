<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';

import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

import { formRulesMixin } from '@/mixins/formRulesMixin.js';

import BottomSheetHeader from '@/components/BottomSheets/BottomSheetHeader.vue';
import Loader from '@/components/Loader.vue';
import SubmitButton from '@/components/SubmitButton.vue';

import { Quarters } from '@/config/constants';

export default {
    components: {
        BottomSheetHeader,
        Loader,
        SubmitButton
    },

    mixins: [formRulesMixin],

    props: {
        depositInput: {
            type: Number
        },
        quarterInput: {
            type: String,
            validator: function (value) {
                value.substring(1, 1);
                return Quarters.includes(value);
            }
        },
        year: {},
        open: {
            type: Boolean
        }
    },

    data() {
        return {
            valid: true,
            loading: false,
            deposit: _.cloneDeep(this.depositInput),
            quarter: _.cloneDeep(this.quarterInput)
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        isModalOpen: {
            get() {
                return this.open;
            },
            set() {
                this.$emit('update:open');
            }
        },
        quarterNumber() {
            return this.quarter.replace('Q', '');
        }
    },
    methods: {
        ...mapActions('taxes', ['editDeposit']),
        resetForm() {
            this.$refs.form.reset();
        },
        async handleSubmit() {
            try {
                this.loading = true;
                await this.editDeposit({
                    deposit: this.deposit,
                    quarter: this.quarter,
                    year: this.year
                });

                notify.call(this, {
                    title: this.$t('settings.succesfuly_saved'),
                    text: this.$t('settings.succesfuly_saved'),
                    type: 'success'
                });

                // Close bottom sheet when submit successful.
                this.$emit('updated');
                this.isModalOpen = false;
                this.resetForm();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<template>
    <base-bottom-sheet
        v-if="isModalOpen"
        v-model="isModalOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
        class="edit-deposit-modal"
    >
        <template #header>
            <bottom-sheet-header>{{ $t('personalTaxes.edit_deposit') }} {{ quarterNumber }} </bottom-sheet-header>
        </template>
        <template #content>
            <v-form ref="form" v-model="valid" class="edit-deposit-form" @submit="handleSubmit">
                <div class="text-field-wrapper">
                    <text-field
                        v-model="deposit"
                        :label="$t('personalTaxes.disposal')"
                        outlined
                        dense
                        persistent-hint
                        prefix="€"
                        type="number"
                        data-cy="depositAmount"
                        required
                        :rules="numberRules"
                    />
                </div>

                <submit-button
                    id="edit-published"
                    data-cy="submit"
                    prepend-icon="mdi-content-save"
                    :disabled="!valid"
                    @click="handleSubmit"
                >
                    {{ $t('general.save') }}
                </submit-button>
            </v-form>

            <loader v-if="loading" />
        </template>
    </base-bottom-sheet>
</template>

<style lang="scss" scoped>
.edit-deposit-modal {
    padding: 16px;
}

.edit-deposit-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px 0;
    padding-top: 4px;

    .text-field-wrapper {
        width: 100%;
    }

    #edit-deposit-button {
        width: auto;
    }
}
</style>
