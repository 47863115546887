
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from '@/store';

import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import Loader from '@/components/Loader.vue';
import TableCard from '@/components/TableCard.vue';
import DepositsTable from '@/views/deposits/deposits-table/DepositsTable.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';

import { ITaxViewModel } from '@/store/modules/taxes.types';
import { Deposits } from '@/views/deposits/deposits.types';

export default defineComponent({
    name: 'DepositsPage',
    components: { Loader, DepositsTable, TableCard, ViewLayout, PageHeader },
    setup(props, context) {
        const loading = ref(false);
        const deposits = ref<Deposits | null>(null);

        const store = useStore();

        const selectedYear = store.state.selectedYear;

        onMounted(() => {
            loading.value = true;
            fetchTaxes();
        });

        function extractDepositsFromTaxes(taxes: ITaxViewModel): Deposits {
            return Object.keys(taxes)
                .filter((key) => key.includes('deposit'))
                .reduce((cur, key) => {
                    return Object.assign(cur, { [key]: taxes[key as keyof Deposits] });
                }, {}) as Deposits;
        }

        async function fetchTaxes(): Promise<void> {
            try {
                const taxes = await store.dispatch('taxes/getTaxes', { year: selectedYear, force: true });
                deposits.value = extractDepositsFromTaxes(taxes);
            } catch (error) {
                apiErrorAndDisplay.call(context, error);
            } finally {
                loading.value = false;
            }
        }

        return {
            loading,
            deposits,
            selectedYear,
            fetchTaxes
        };
    }
});
