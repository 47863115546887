<template>
    <vue-apex-charts type="bar" :options="chartOptions" :series="series" />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import VueApexCharts from 'vue-apexcharts';
import { getIncomeSum } from '@/services/income';

export default {
    components: {
        VueApexCharts
    },

    data() {
        return {
            loading: false,
            quarters: ['Q1', 'Q2', 'Q3', 'Q4'],
            series: [],
            chartOptions: {
                colors: ['#335eea', '#b7c3e9'],
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: false
                        },
                        decimalsInFloat: 2
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '60%'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    title: {
                        text: '€'
                    },
                    decimalsInFloat: 2
                },
                tooltip: {
                    y: {
                        formatter: this.tooltipFormatter(this.$options.filters.numericFormat)
                    }
                }
            }
        };
    },

    computed: {
        ...mapState('taxes', ['taxes']),
        ...mapState('expenses', ['newExpenseEvents']),
        ...mapState('auth', ['currentUserData']),
        ...mapState(['selectedYear'])
    },

    watch: {
        selectedYear() {
            this.getAllInitData();
        }
    },

    created() {
        this.getAllInitData();
    },

    methods: {
        ...mapActions('expenseCategories', ['getExpenseCategories']),
        ...mapActions('expenses', ['getExpensesSum', 'getAccountingExpensesSum']),
        async getAllInitData() {
            try {
                this.startLoading();

                await Promise.all([
                    this.getExpenseCategories({
                        force: true
                    }),
                    this.loadGraphData()
                ]);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.stopLoading();
            }
        },
        startLoading() {
            this.loading = true;
        },
        stopLoading() {
            this.loading = false;
        },
        async loadGraphData() {
            const tasks = [this.loadMonths(), getIncomeSum({ year: this.selectedYear, group: 'month' })];

            if (this.$can('READ', 'Accountingexpense')) {
                tasks.push(this.getAccountingExpensesSum({ year: this.selectedYear, group: 'month' }));
            } else {
                tasks.push(this.getExpensesSum({ year: this.selectedYear, group: 'month' }));
            }

            const data = await Promise.all(tasks);

            const incomesPerUnit = data[1];
            const expensePerUnit = data[2];

            this.series.push({
                name: this.$t('graphs.incomes'),
                data: Object.keys(incomesPerUnit).map((unit) => {
                    return incomesPerUnit[unit].amountEuroExclVAT;
                })
            });
            this.series.push({
                name: this.$t('graphs.expenses'),
                data: Object.keys(expensePerUnit).map((unit) => {
                    return expensePerUnit[unit].total;
                })
            });

            return;
        },
        loadMonths() {
            this.chartOptions.xaxis.categories = [
                this.$t('months.jan'),
                this.$t('months.feb'),
                this.$t('months.mar'),
                this.$t('months.apr'),
                this.$t('months.may'),
                this.$t('months.jun'),
                this.$t('months.jul'),
                this.$t('months.aug'),
                this.$t('months.sep'),
                this.$t('months.oct'),
                this.$t('months.nov'),
                this.$t('months.dec')
            ];
        },
        tooltipFormatter(formatter) {
            return function (val) {
                const number = typeof formatter === 'function' ? formatter(val) : val;
                return `€ ${number}`;
            };
        }
    }
};
</script>

<style lang="scss" scoped></style>
