import { VatStatus } from '@/config/constants';
import { AccountingExpenseModel } from '@/models/accountingexpense';
import { getVATAmountToUseRealOrFictive } from './expenseCalculations';

/**
 * This function determines what the accounting cost is of a given expense and vattype (vatliable, non-vatliable, vat-exempt)
 *
 * Important to note is that some expenses will use a fictive VATAmount, since the VAT is not present on the invoice, but it is 'verlegd'
 * This means that we have to check if we have to use the fictive VATAmounts or the real VAT.
 *
 * Calculation is as follows:
 * 1) The amount excl VAT which can be added directly to the businessAmount (this includes the % beroepsmatige uitgave)
 *  --> 1000 euro with 50% beroepsmatig then the amount is 500
 *
 * 2) Get the percentage of the VAT which will be added directly to the cost
 *  --> 50% beroepsmatig and 35% vat deductability, then the percentage is 60-35 = 25 which will be added to the businessAmount
 *
 * 3) Get the VAT, this depends on the fictive or actual VAT.
 *      a) In case of fictive vat, calculate the amount in 6,12 and 21 and multiply with the correspondig VAT% (6*0.06, 12*0.12, 21*0.21)
 *      b) In case of real vat, just do incl - excl
 *
 * Result: 1 + (2*3)
 *
 */
export function calculateBusinessAccountingCost(accountingexpense: AccountingExpenseModel, vattype: VatStatus): number {
    if (accountingexpense.businessPercentage == null || accountingexpense.VATDeductiblePercentage == null) {
        throw new Error('Null not allowed');
    }

    if (accountingexpense.businessPercentage < accountingexpense.VATDeductiblePercentage) {
        throw new Error('businessPercentageMustBeGTEVATPercentage');
    }

    const totalExclVATOnExpense = calculateTotalAmountEuroExclVATWithBusinssPercentage(
        accountingexpense.businessPercentage,
        {
            amountEuroExclVAT: accountingexpense.amountEuroExclVAT || 0
        }
    );

    const totalVATOrFictiveVatOnExpense = getVATAmountToUseRealOrFictive(accountingexpense, vattype);

    const percentageOfVATThatCanBeAddedToTheCost = percentageVATThatIsInsertedInCost(accountingexpense);

    return totalExclVATOnExpense + (totalVATOrFictiveVatOnExpense * percentageOfVATThatCanBeAddedToTheCost) / 100;
}

function percentageVATThatIsInsertedInCost(accountingexpense: AccountingExpenseModel): number {
    if (accountingexpense.businessPercentage == null || accountingexpense.VATDeductiblePercentage == null) {
        throw new Error('Null not allowed');
    }

    return accountingexpense.businessPercentage - accountingexpense.VATDeductiblePercentage;
}

/**
 * This does not take into account the extra VAT that might come in (so this is not the same as calcualte the businessAmount)
 */
function calculateTotalAmountEuroExclVATWithBusinssPercentage(
    businessPercentage: number,
    {
        amountEuroExclVAT
    }: {
        amountEuroExclVAT: number;
    }
) {
    return (amountEuroExclVAT * businessPercentage) / 100;
}
