
import BottomSheetHeader from '@/components/BottomSheets/BottomSheetHeader.vue';
import Loader from '@/components/Loader.vue';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { lookupCompanynumberMixin } from '@/mixins/lookupCompanynumberMixin';

import { cloneDeep, isNil } from 'lodash';
import mixins from 'vue-typed-mixins';
import CarFormSubmitLogicWrapper from './CarFormSubmitLogicWrapper.vue';

export default mixins(formRulesMixin, lookupCompanynumberMixin).extend({
    components: {
        BottomSheetHeader,
        Loader,
        CarFormSubmitLogicWrapper
    },

    mixins: [formRulesMixin, lookupCompanynumberMixin],

    props: {
        carData: {
            required: true
        },
        value: {
            required: true,
            type: Boolean
        },
        carId: {
            required: true
        }
    },

    data() {
        return {
            valid: true,
            loading: false,
            currentCarCopy: null
        };
    },

    computed: {
        isOpen: {
            get(): boolean {
                return this.value;
            },
            set(value: boolean) {
                this.$emit('input', value);
            }
        },
        modalTitle(): string {
            if (isNil(this.carId)) {
                return this.$t('cars.form.createCar') as string;
            }

            return this.$t('cars.form.editCar') as string;
        }
    },

    watch: {
        carData: {
            handler(newValue) {
                this.currentCarCopy = cloneDeep(newValue);
            },
            immediate: true
        }
    },

    methods: {
        handleSubmissionDone() {
            this.$emit('submission-done');
            this.isOpen = false;
        }
    }
});
