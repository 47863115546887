
import { convertToLocaleValuta } from '@/helpers/number-helpers/numberHelpers';
import i18n from '@/i18n';
import { computed, defineComponent } from 'vue';

interface ValutaCellProps {
    amount?: number;
    currency: string;
    bold: boolean;
    textAlignRight: boolean;
}

export default defineComponent<ValutaCellProps>({
    name: 'ValutaCell',
    props: {
        textAlignRight: {
            type: Boolean,
            default: false
        },
        bold: {
            type: Boolean,
            default: false
        },
        amount: {
            type: Number,
            required: false
        },
        currency: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const isNegative = computed((): boolean => {
            if (props.amount == null) {
                return false;
            }

            return props.amount < 0;
        });

        const formattedAmount = computed((): string => {
            if (props.amount == null) {
                return '-';
            }
            return convertToLocaleValuta(props.amount, { currency: props.currency, locale: i18n.locale });
        });

        return { isNegative, formattedAmount };
    }
});
