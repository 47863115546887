<template>
    <div class="horiontal-scroll-mobile-wrapper">
        <div class="horiontal-scroll-mobile">
            <v-row>
                <v-col v-if="canShowNonVATLiableRevenueBorderKPI" cols>
                    <v-card class="Widget" outlined>
                        <data-card-limit
                            :amount="totalIncomeOrganisationEuroExclVAT"
                            :limit="nonVATLiableRevenueBorder"
                            leading="€"
                            :data-text="$t('dashboard.kpi.nonvatliable_border_amount')"
                            avatar-color="red lighten-5"
                            icon-color="red lighten-1"
                            data-id="nonVATLiableRevenueBorder"
                            exceeded-id="nonVATLiableRevenueBorderExceeded"
                            :loading="loading.nonVATLiableRevenueBorder"
                            i18n-path="tooltips.dashboard.kpi.nonVATLiableRevenueBorder"
                            i18n-path-exceeded="tooltips.dashboard.kpi.nonVATLiableRevenueBorderExceeded"
                        />
                    </v-card>
                </v-col>
                <v-col v-if="canShow('TOTAL_INCOME')" cols>
                    <v-card class="Widget pa-5" outlined>
                        <data-card-multiple
                            :data="totalRevenueExclVAT"
                            leading="€"
                            :trailing="undefined"
                            :data-text="$t('dashboard.kpi.income')"
                            avatar-color="red lighten-5"
                            icon-color="red lighten-1"
                            :loading="loading.totalRevenueExclVAT"
                        />
                    </v-card>
                </v-col>
                <v-col v-if="canShow('TOTAL_COST')" cols>
                    <v-card class="Widget pa-5" outlined>
                        <data-card-multiple
                            :data="totalCost"
                            leading="€"
                            :trailing="undefined"
                            :data-text="$t('dashboard.kpi.expenses')"
                            avatar-color="red lighten-5"
                            icon-color="red lighten-1"
                            :loading="loading.totalCost"
                        />
                    </v-card>
                </v-col>
                <v-col v-if="canShow('OUTSTANDING_INVOICES')" cols>
                    <v-card class="Widget pa-5" outlined>
                        <data-card-multiple
                            :data="unpayedInvoicesAmountEuroInclVAT"
                            leading="€"
                            :trailing="undefined"
                            :data-text="$t('dashboard.kpi.open_income_invoices')"
                            avatar-color="red lighten-5"
                            icon-color="red lighten-1"
                            :loading="loading.unpayedInvoices"
                        >
                        </data-card-multiple>
                    </v-card>
                </v-col>
                <v-col v-if="canShow('OUTSTANDING_EXPENSES')" cols>
                    <v-card class="Widget pa-5" outlined>
                        <data-card-multiple
                            :data="unpaidExpensesAmountEuroInclVAT"
                            leading="€"
                            :data-text="$t('dashboard.kpi.open_expense_invoices')"
                            avatar-color="red lighten-5"
                            icon-color="red lighten-1"
                            :disable-numeric-format="false"
                            :loading="loading.unpayedExpenses"
                        />
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
// --- Components ---
import DataCardMultiple from '@/components/DataCardMultiple';
// --- Actions ---
import { mapActions, mapState } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import { getTotalAccountingCost } from '@/services/accountingexpenses';
import { getTotalCost } from '@/services/expenses.ts';
import { getTotalRevenue } from '@/services/income';
import { getUserNotVATLiableBorderRequest } from '@/services/vat';
import { getCountAllUnpayedInvoices } from '@/services/invoice';
import { isNonVatLiable } from '@/helpers/VATHelper';
import DataCardLimit from './DataCardLimit.vue';
import { reportError } from '@/helpers/logging';

export default {
    components: {
        DataCardMultiple,
        DataCardLimit
    },

    data() {
        return {
            totalRevenueExclVAT: null,
            unpayedInvoicesAmountEuroInclVAT: null,
            unpaidExpensesAmountEuroInclVAT: null,
            totalIncomeOrganisationEuroExclVAT: null,
            totalCost: null,
            loading: {
                totalRevenueExclVAT: false,
                totalCost: false,
                unpayedInvoices: false,
                unpayedExpenses: false,
                nonVATLiableRevenueBorder: false
            },
            nonVATLiableRevenueBorder: null
        };
    },

    computed: {
        ...mapState('taxes', ['taxes']),
        ...mapState('auth', ['currentUserData']),
        ...mapState(['selectedYear']),
        selectedKPIs() {
            return ['TOTAL_INCOME', 'TOTAL_COST', 'OUTSTANDING_INVOICES', 'OUTSTANDING_EXPENSES'];
        },
        canShowNonVATLiableRevenueBorderKPI() {
            return isNonVatLiable(this.currentUserData.settings.vatLiable);
        }
    },

    created() {
        this.getAllInitData();
    },

    methods: {
        ...mapActions('expenses', ['getCountAllUnpaidExpenses']),
        ...mapActions('taxes', ['getIncomeStatement']),
        canShow(type) {
            return this.selectedKPIs.includes(type);
        },
        async getAllInitData() {
            try {
                this.loading.totalRevenueExclVAT = true;
                this.loading.unpayedInvoices = true;
                this.loading.totalCost = true;
                this.loading.unpayedExpenses = true;
                this.loading.nonVATLiableRevenueBorder = true;

                const tasks = [];

                if (this.canShowNonVATLiableRevenueBorderKPI) {
                    tasks.push(
                        Promise.all([
                            getUserNotVATLiableBorderRequest(this.selectedYear),
                            this.getIncomeStatement({
                                year: this.selectedYear
                            })
                        ])
                            .then((responses) => {
                                const [borderResponse, taxResponse] = responses;
                                this.totalIncomeOrganisationEuroExclVAT =
                                    taxResponse.totalIncomeOrganisationEuroExclVAT;
                                this.nonVATLiableRevenueBorder = borderResponse;
                            })
                            .catch((e) => {
                                reportError(e);
                            })
                            .finally(() => {
                                this.loading.nonVATLiableRevenueBorder = false;
                            })
                    );
                }

                if (this.canShow('TOTAL_INCOME')) {
                    tasks.push(
                        getTotalRevenue({
                            year: this.selectedYear
                        }).then((revenue) => {
                            this.loading.totalRevenueExclVAT = false;
                            this.totalRevenueExclVAT = revenue.revenueExclVAT;
                        })
                    );
                }

                if (this.canShow('TOTAL_COST')) {
                    // TODO: This logic should be abstracted in the backend
                    let getTotalFunction;
                    if (this.$can('READ', 'Accountingexpense')) {
                        getTotalFunction = getTotalAccountingCost;
                    } else {
                        getTotalFunction = getTotalCost;
                    }
                    tasks.push(
                        getTotalFunction(this.selectedYear)
                            .then((revenue) => {
                                this.totalCost = revenue.total;
                            })
                            .finally(() => {
                                this.loading.totalCost = false;
                            })
                    );
                }

                if (this.canShow('OUTSTANDING_INVOICES')) {
                    tasks.push(
                        getCountAllUnpayedInvoices()
                            .then((unpayedInvoices) => {
                                this.unpayedInvoicesAmountEuroInclVAT = unpayedInvoices.amountEuroExclVAT;
                            })
                            .finally(() => {
                                this.loading.unpayedInvoices = false;
                            })
                    );
                }

                if (this.canShow('OUTSTANDING_EXPENSES')) {
                    tasks.push(
                        this.getCountAllUnpaidExpenses()
                            .then((unpaidExpenses) => {
                                this.unpaidExpensesAmountEuroInclVAT = unpaidExpenses.amountEuroExclVAT;
                            })
                            .finally(() => {
                                this.loading.unpayedExpenses = false;
                            })
                    );
                }

                await Promise.all(tasks);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading.totalRevenueExclVAT = false;
                this.loading.unpayedInvoices = false;
                this.loading.totalCost = false;
                this.loading.unpayedExpenses = false;
                this.loading.nonVATLiableRevenueBorder = false;
            }
        }
    }
};
</script>
