/**
 * Expense categories
 */
// TODO: Make shareable
export const ExpenseCategoryNumbers = {
    Handelsgoederen: 604000,
    Aankoop_goederen: 604010,
    Verpakkingsmateriaal_goederen: 604020,
    Transportkosten_goederen: 604030,

    Onderaannemingen: 603000,
    Onderaannemingen_sub: 603001,

    Algemene_kosten: 611000,
    Kleine_aankopen: 611010,
    Boeken_opleidingen: 611020,
    Lidgeld: 611030,
    Gsm: 611040,
    Onthaalkosten: 611050,
    Relatiegeschenken_gt_50: 611060,
    Relatiegeschenken_lt_50: 611065,
    Restaurantkosten: 611070,
    Uitzendkrachten: 617010,
    Diverse_kosten: 640000,
    Diverse_financiele_kosten: 650000,

    Erelonen: 615800,
    Erelonen_advocaat: 615810,
    Erelonen_boekhouder: 615820,
    Erelonen_culstancy: 615830,
    Erelonen_notaris: 615840,

    Kosten_woning: 611100,
    Water: 611110,
    Elektriciteit: 611120,
    Brandverzekering: 611130,
    Onroerende_voorheffing: 640010,
    Onderhoud_woning_gedeeltelijk: 611150,
    Onderhoud_woning_100: 611160,

    Marketing: 611600,
    Sociale_media: 611610,
    Marketing_sub: 611620,

    Software: 610300,
    Dexxter: 610310,
    Informatica: 610320,

    Huur: 610100,
    Huur_machines: 610110,
    Huur_kantoor: 610120,
    Huur_lichte_vracht: 610140,

    Kosten_personenwagen: 610800,
    Algemene_kosten_personenwagen: 610810,
    Brandstof_personenwagen: 610820,
    Parkeergeld_personenwagen: 610830,
    Verkeersbelasting_personenwagen: 610850,
    Interesten_personenwagen: 610860,
    Huur_personenwagen: 610870,
    Personenwagen_verzekering: 610880,

    Kosten_motorvoertuig: 610700,
    Algene_kosten_onderhoud_motor: 610710,
    Brandstof_motor: 610720,
    Parkeergeld_motor: 610730,
    Verkeersbelasting_motor: 610750,

    Kosten_lichte_vracht: 610600,
    Algene_kosten_onderhoud_lichte_vracht: 610610,
    Brandstof_lichte_vracht: 610620,
    Parkeergeld_lichte_vracht: 610630,
    Verkeersbelasting_lichte_vracht: 610640,

    Verzekeringen: 613000,
    Verzekeringen_sub: 613001,
    Verzekeringen_burgerlijke_aansprakelijkheid: 613010,
    Motor_verzekering: 613030,
    Lichte_vracht_verzekeringen: 613040,

    Overige: 612000,
    Overige_sub: 612001,

    // PROBLEEM
    Socialebijdragen: 618000,
    Socialebijdragen_sub: 618020,

    // PROBLEEM
    Opstart_kosten: 610400,
    Opstart_kosten_sub: 610401,

    Investering: 230000,
    Investering_sub: 230001
};
