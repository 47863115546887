<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                    <sub>
                        <tooltip :underlined="false" i18n-path="tooltips.timeregistration.title"
                            ><template slot="activator"> <v-icon small> mdi-information </v-icon></template></tooltip
                        >
                    </sub>
                </template>

                <template #menu>
                    <v-btn color="primary" large @click="createProject">
                        {{ $t('timeRegistration.create_project') }}
                    </v-btn>
                </template></page-header
            >
        </template>
        <template #content>
            <TableCard>
                <template #content>
                    <projects-table />
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
// --- Modules ---
// --- State ---
import { mapFields } from 'vuex-map-fields';
import { mapState, mapActions } from 'vuex';
// --- Components ---
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

import ProjectsTable from '@/components/DataTables/ProjectsTable.vue';
import TableCard from '@/components/TableCard.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';

export default {
    components: {
        ProjectsTable,
        TableCard,
        ViewLayout,
        PageHeader
    },

    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false,
            currentTab: 0
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapFields('bottomSheets', ['isAddProjectOpen', 'isCreatingNewProject'])
    },

    created() {
        this.fetchAllProjects();
    },

    methods: {
        ...mapActions('projects', ['getProjects']),
        ...mapActions(['startLoading', 'stopLoading']),
        fetchAllProjects() {
            this.startLoading();
            this.getProjects()
                .then((result) => {
                    this.projects = result;
                })
                .finally(() => {
                    this.stopLoading();
                });
        },
        createProject() {
            this.isCreatingNewProject = true;
            this.isAddProjectOpen = true;
        }
    }
};
</script>

<style lang="scss" scoped>
/* --- Override the default list behaviour for active childs --- */
.v-list {
    & :deep() {
        .v-list-group.active-child > .v-list-group__header {
            color: $color-secondary !important;
            caret-color: $color-secondary !important;

            .v-icon {
                color: $color-secondary !important;
            }
        }
        .v-list-group__header.active-child {
            color: $color-secondary !important;
            caret-color: $color-secondary !important;
        }
    }
}
</style>
