<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <template v-if="dialogAttributes.component">
                <component
                    :is="dialogAttributes.component"
                    v-model="showDialog"
                    v-bind="dialogAttributes.attributes"
                    v-on="dialogAttributes.actions"
                />
            </template>
            <template v-else>
                <confirmation-dialog
                    v-if="showDialog"
                    v-model="showDialog"
                    v-bind="dialogAttributes.attributes"
                    @click-positive="dialogAttributes.actions.clickPositive"
                    @click-negative="dialogAttributes.actions.clickNegative"
                />
            </template>

            <AccountingInvoicableForm
                ref="invoicableComponent"
                type="invoice"
                :is-creating-new-document="isCreatingNew"
                :document-input="currentInvoice"
                :invoicable-number-text="$t('general.invoice_number')"
                :button-text-publish="$t('addIncome.create_invoice')"
                @submit-create-draft="handleSubmitCreateDraft"
                @submit-edit="runMiddleware(getEditMiddleware(), $event)"
                @submit-publish="runMiddleware(getCreateMiddleware(), $event)"
            />
        </template>
    </view-layout>
</template>

<script>
import AccountingInvoicableForm from '@/components/Forms/accounting/AccountingInvoicableForm.vue';
import { accountingInvoicableFormMixin } from '@/mixins/accountingInvoicableFormMixin.js';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { middlewareMixin } from '@/mixins/middlewareMixin';
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';

import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import PageHeader from '@/components/PageHeader.vue';
import PopupVideo from '@/components/PopupVideo';
import TooltipVideoWrapper from '@/components/TooltipVideoWrapper.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import { invoicableTypes, popupNotificationNames } from '@/config/constants';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { invoicableTransformCurrencyFieldToIntegers } from '@/helpers/invoicableHelper';
import { informationpopupMixin } from '@/mixins/informationpopupMixin';
import { canCreateInvoicableMiddlewareMixin } from '@/mixins/invoicable/middleware/canCreateInvoicableMiddlewareMixin';
import { invoiceProactiveSmartnotificationsMixin } from '@/mixins/invoiceProactiveSmartnotificationsMixin';
import { maybeMedecontractantDialogMixin } from '@/mixins/invoices/middleware/maybeMedecontractant';
import { sellInvestementDialogMixin } from '@/mixins/invoices/middleware/sellInvestement';
import { currentInvoicableNewInstance } from '@/models/invoicable';
import { getAccountingInvoice } from '@/services/accountinginvoice';
import { getInvoice } from '@/services/invoice';
import { getQuotation } from '@/services/quotation';
import bottomSheets from '@/store/modules/bottomSheets';
import { reportError } from '../../../helpers/logging';
import { analyticsTrack } from '../../../services/analytics';

export default {
    components: {
        AccountingInvoicableForm,
        ViewLayout,
        PageHeader,
        ConfirmationDialog,
        TooltipVideoWrapper,
        PopupVideo
    },
    mixins: [
        middlewareMixin,
        formRulesMixin,
        accountingInvoicableFormMixin,
        informationpopupMixin,
        sellInvestementDialogMixin,
        invoiceProactiveSmartnotificationsMixin,
        maybeMedecontractantDialogMixin,
        canCreateInvoicableMiddlewareMixin,
        bottomSheets
    ],

    data() {
        return {
            currentInvoice: currentInvoicableNewInstance(invoicableTypes.INVOICE),
            previousInvoice: null,
            cannotCreateInvoiceDueToBookYear: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        informationpopupPriority() {
            return [popupNotificationNames.INVOICESETTINGS_OPENFIRSTTIME];
        }
    },

    async created() {
        this.startLoading();
        try {
            if (this.$route.query.quotationId) {
                await this.setInvoiceFromQuotation();
            } else if (this.$route.query.copyFromInvoiceId) {
                await this.setInvoiceFromOtherInvoice();
            } else {
                if (this.$route.params.id) {
                    this.isCreatingNew = false;

                    const fetchedInvoice = await getAccountingInvoice(this.$route.params.id);

                    this.$set(fetchedInvoice, 'createPaymentlink', fetchedInvoice.paymentLink ? true : false);

                    this.currentInvoice = fetchedInvoice;
                    this.previousInvoice = fetchedInvoice;
                } else {
                    this.isCreatingNew = true;
                    this.setInitialData();

                    const depreciationficheId = this.$route.query?.depreciationficheId;
                    if (depreciationficheId) {
                        this.currentInvoice.depreciationficheId = parseInt(depreciationficheId);
                    }
                }
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
            if (e.response && (e.response.status === 404 || e.response.status === 403)) {
                this.stopLoading();
                this.$router.push({ name: 'dashboard' });
            }
        } finally {
            this.stopLoading();
        }
    },

    methods: {
        ...mapActions('incomes', ['createInvoicable', 'updateInvoicable']),
        ...mapActions(['startLoading', 'stopLoading']),
        getEditMiddleware() {
            return [
                this.sellInvestementDialog,
                this.maybeMedecontractantDialog,
                this.invoiceProactiveSmartnotifications,
                this.handleEditSubmitMiddleware
            ];
        },
        getCreateMiddleware() {
            return [
                this.sellInvestementDialog,
                this.maybeMedecontractantDialog,
                this.invoiceProactiveSmartnotifications,
                this.handleSubmitMiddleware
            ];
        },
        async setInvoiceFromQuotation() {
            this.isCreatingNew = true;
            this.setInitialData();

            const fetchedQuotation = await getQuotation(this.$route.query.quotationId);

            this.currentInvoice = {
                ...this.currentInvoice,
                ...this.setInvoiceFromOtherInvoicable(fetchedQuotation),
                quotationId: this.$route.query.quotationId
            };

            const depreciationficheId = this.$route.query?.depreciationficheId;
            if (depreciationficheId) {
                this.currentInvoice.depreciationficheId = parseInt(depreciationficheId);
            }
        },
        async setInvoiceFromOtherInvoice() {
            this.isCreatingNew = true;
            this.setInitialData();

            const fetchedInvoice = await getInvoice(this.$route.query.copyFromInvoiceId);

            this.currentInvoice = {
                ...this.currentInvoice,
                ...this.setInvoiceFromOtherInvoicable(fetchedInvoice)
            };

            const depreciationficheId = this.$route.query?.depreciationficheId;
            if (depreciationficheId) {
                this.currentInvoice.depreciationficheId = parseInt(depreciationficheId);
            }
        },
        setInvoiceFromOtherInvoicable(invoicable) {
            return {
                creator: this.currentUserData.company,
                customer: invoicable.customer,
                comment: invoicable.comment,
                draft: false,
                items: invoicable.items,
                discount: invoicable.discount,
                isDiscountPercentage: invoicable.isDiscountPercentage,
                amountEuroInclVAT: invoicable.amountEuroInclVAT,
                vatOnInvoicable: false,
                itemsAllSameVat: false,
                allItemsVat: 0,
                btwMedecontractant: invoicable.btwMedecontractant,
                typeOfService: null,
                quotationId: null,
                languageId: invoicable.languageId
            };
        },
        handleEditSubmitMiddleware(next, invoice) {
            return this.handleSubmitEdit(invoice);
        },
        handleSubmitMiddleware(next, invoice) {
            return this.handleSubmitPublish(invoice);
        },
        handleAfterSubmit(newInvoice) {
            if (!newInvoice.draft && this.isCreatingNew) {
                try {
                    analyticsTrack('User Completes An Invoice', {
                        Add_Invoice_Price: newInvoice.amountEuroInclVAT,
                        Add_Invoice_Discount: newInvoice.discount,
                        Add_Invoice_Discount_Type: newInvoice.isDiscountPercentage ? 'percentage' : 'amount',
                        Add_Invoice_Comment: newInvoice.comment,
                        Type: 'normal'
                    });
                } catch (e) {
                    reportError(e);
                }
            }

            this.$store.dispatch('incomes/newInvoiceCreated');

            this.isCreatingNew = false;
            this.currentInvoice = newInvoice;

            if (newInvoice.draft === false) {
                this.increaseLocalInvoiceCounter();
            }

            this.$router.push({
                name: 'accounting-invoicable-view',
                params: {
                    id: this.currentInvoice.id,
                    type: 'invoice'
                },
                query: {
                    newlyCreated: true
                }
            }).catch;
        },
        setInitialData() {
            this.currentInvoice.date = dayjs.utc().format('YYYY-MM-DD');
            this.currentInvoice.dueDate = dayjs
                .utc()
                .add(this.currentUserData.settings.defaultPaymentDays, 'day')
                .format('YYYY-MM-DD');
        },
        setCorrectValues(invoicableCopy) {
            invoicableCopy.invoiceNumber = null;
            invoicableCopy.historical = false;
            invoicableCopy.creator = this.currentUserData.company;
            invoicableCopy.type = 'invoice';

            return invoicableTransformCurrencyFieldToIntegers(invoicableCopy, 'invoice');
        },
        increaseLocalInvoiceCounter() {
            // Increment the counter
            this.currentUserData.settings.invoiceCounter++;
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    width: 100%;
}
</style>
