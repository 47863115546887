
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import NumberField from '@/components/FormComponents/NumberField.vue';
import Loader from '@/components/Loader.vue';
import PageHeader from '@/components/PageHeader.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import TableCard from '@/components/TableCard.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { sendGetDependendOnNumbersRequest, sendUpdateDependendOnNumbersRequest } from '@/services/tax';
import mixins from 'vue-typed-mixins';
import { mapState } from 'vuex';

import { MapStateToComputed } from '@/helpers/types';
import { DEPENDENT_ON_TYPE, DependOnRate, IGlobalsettingsStudentViewModel, dependendOnNumbers } from 'dexxter-types';
import { cloneDeep } from 'lodash';
import * as dependendOnService from '../../businessServices/dependendOn';
import { numericFormat } from '../../setup/filters';

type dependendOnRate = { type: DEPENDENT_ON_TYPE; upperLimit: number };

export default mixins(formRulesMixin).extend({
    mixins: [formRulesMixin],
    data() {
        return {
            resultDialog: false,
            valid: false,
            loading: false,
            dependendOnNumbers: {
                studentNettoIncome: 0,
                tenLaste_alimentatiegeld: 0,
                tenLaste_belastbaarLoonAndereVerdiensten: 0,
                tenLaste_leefloonEnAnderen: 0,
                tenLaste_situatieOuders: null
            } as dependendOnNumbers,
            dependentOnType: null as null | DEPENDENT_ON_TYPE,
            isDependentOn: false,
            resultDialogText: null as null | string
        };
    },

    computed: {
        ...(mapState(['selectedYear', 'globalSettingsStudent']) as MapStateToComputed<{
            selectedYear: number;
            globalSettingsStudent: IGlobalsettingsStudentViewModel;
        }>),
        dependentOnItems(): { value: DependOnRate['type']; label: string }[] {
            return this.globalSettingsStudent.dependentOnRates.map((_dependentOn) => {
                return {
                    value: _dependentOn.type,
                    label: this.$i18n.t(`dependentOn.${_dependentOn.type}`) as string
                };
            });
        },
        hasAbove0Total(): boolean {
            return (
                this.dependendOnNumbers.studentNettoIncome +
                    this.dependendOnNumbers.tenLaste_belastbaarLoonAndereVerdiensten -
                    this.globalSettingsStudent.dependOnCalculation.ignoreTotalIncomeAmount >=
                0
            );
        },
        nettoBestaandsMinimum(): number {
            return dependendOnService.calculateNettoBestaandsMinimum(
                this.dependendOnNumbers,
                this.globalSettingsStudent
            );
        }
    },

    async created() {
        try {
            this.loading = true;
            this.dependendOnNumbers = await sendGetDependendOnNumbersRequest(this.selectedYear);
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        async saveDependendOnDataForUser() {
            try {
                await sendUpdateDependendOnNumbersRequest(
                    this.selectedYear,
                    this.formatForUpdate(this.dependendOnNumbers)
                );
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }
        },
        startDependentOnCalculation(): void {
            this.saveDependendOnDataForUser();

            const dependentOnType = this.globalSettingsStudent.dependentOnRates.find(
                (_dependentOnItem: dependendOnRate) => {
                    return _dependentOnItem.type === this.dependendOnNumbers.tenLaste_situatieOuders;
                }
            );

            if (!dependentOnType) {
                throw new Error('depedentontype not found');
            }

            this.isDependentOn = dependendOnService.calculateDependentOn(
                this.dependendOnNumbers,
                this.globalSettingsStudent
            );

            const upperLimit = dependentOnType.upperLimit;

            this.resultDialogText = this.isDependentOn
                ? this.$i18n
                      .t('dependentOn.resultDialog.dependentOn_text', {
                          upperLimit
                      })
                      .toString()
                : this.$i18n
                      .t('dependentOn.resultDialog.notDependentOn_text', {
                          upperLimit
                      })
                      .toString();

            this.resultDialog = true;
        },

        formatForUpdate(input: dependendOnNumbers): dependendOnNumbers {
            const cp = cloneDeep(input);

            cp.tenLaste_belastbaarLoonAndereVerdiensten = cp.tenLaste_belastbaarLoonAndereVerdiensten ?? 0;
            cp.tenLaste_leefloonEnAnderen = cp.tenLaste_leefloonEnAnderen ?? 0;
            cp.tenLaste_alimentatiegeld = cp.tenLaste_alimentatiegeld ?? 0;

            return cp;
        }
    },

    components: {
        ViewLayout,
        PageHeader,
        Loader,
        TableCard,
        NumberField,
        SubmitButton,
        ConfirmationDialog
    }
});
