import * as BusinessExpenseService from '@/businessServices/expense/expense.service';
import { isNil } from 'lodash';
import { isGrootboekrekeningCar } from '../../views/UserApp/grootboekrekening/grootboekrekening';

export const accountingExpenseStepCompleteMixin = {
    computed: {
        stepGeneralDataComplete() {
            return Boolean(this.currentAccountingExpense.date);
        },
        stepSupplierDataComplete() {
            return !!this.currentAccountingExpense.supplier;
        },
        stepInvestementComplete() {
            if (!this.currentAccountingExpense.isDepreciation) {
                return true;
            }

            return Boolean(
                this.currentAccountingExpense.depreciation &&
                    this.currentAccountingExpense.depreciation.numberOfYears &&
                    this.currentAccountingExpense.depreciation.description &&
                    this.currentAccountingExpense.depreciation.category &&
                    this.currentAccountingExpense.depreciation.category.id
            );
        },
        stepBusinessPercentageDataComplete() {
            let result = false;

            const currentAccountingExpense = this.currentAccountingExpense;

            const vatLiable = this.isVATLiable;

            const number = currentAccountingExpense.expensecategory.number;

            if (isNil(number)) {
                return false;
            }

            if (isGrootboekrekeningCar(number) && !currentAccountingExpense.carId) {
                return false;
            }

            result =
                currentAccountingExpense.expensecategoryId &&
                !Number.isNaN(Number(currentAccountingExpense.businessPercentage)) &&
                !Number.isNaN(Number(currentAccountingExpense.accountingCostEuro));

            if (vatLiable) {
                result =
                    result &&
                    !Number.isNaN(Number(currentAccountingExpense.VATDeductiblePercentage)) &&
                    !Number.isNaN(Number(currentAccountingExpense.businessAccountingCostVAT));
            }

            return !!result;
        },

        stepAmountDataComplete() {
            const data = {
                recordInVATDeclaration: this.currentAccountingExpense.recordInVATDeclaration,
                amountEuroExcl0VAT: this.currentAccountingExpense.amountEuroExcl0VAT,
                amountEuroExcl6VAT: this.currentAccountingExpense.amountEuroExcl6VAT,
                amountEuroExcl12VAT: this.currentAccountingExpense.amountEuroExcl12VAT,
                amountEuroExcl21VAT: this.currentAccountingExpense.amountEuroExcl21VAT,
                amountEuroExclVAT: this.currentAccountingExpense.amountEuroExclVAT,
                amountEuroInclVAT: this.currentAccountingExpense.amountEuroInclVAT,
                supplier: this.currentAccountingExpense.supplier,
                btwMedecontractant: this.currentAccountingExpense.btwMedecontractant,
                supplierChargedVAT: this.currentAccountingExpense.supplierChargedVAT
            };

            if (!this.currentAccountingExpense.supplier) {
                return false;
            }

            return BusinessExpenseService.areAmountsFilledIn(data, this.currentUserData.settings.vatLiable);
        }
    }
};
